import { useEffect, useState } from 'react'
import NetInfo from '@react-native-community/netinfo'

export const useNetServices = () => {
  const [netState, setNetState] = useState({})

  useEffect(() => {
    const unsubscribe = NetInfo.addEventListener(state => {
      setNetState(state)
    })

    return () => {
      unsubscribe()
    }
  }, [])

  return netState
}
