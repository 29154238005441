import * as yup from 'yup'
import firebase from 'firebase/compat/app'

const isFirebaseTimestamp = (val: any): boolean => {
  return val instanceof firebase.firestore.Timestamp
}

export const TimestampSchema = yup.mixed().test('isFirebaseTimestamp', 'Invalid Timestamp', function (value) {
  if (value === undefined || isFirebaseTimestamp(value)) {
    return true
  } else {
    return false
  }
})
