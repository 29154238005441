// External
import { object, string, array, InferType, mixed } from 'yup'
// Models
import { ExtraInformation } from './extra-information.enum'
import { UserTypes } from './user-types.enum'
import { LoginWith } from './login-with.enum'

export const UserSchema = object().shape({
  company: string().required(),
  email: string().email().required(),
  extraInformation: object().shape({
    [ExtraInformation.Sealink]: string().optional(),
    [ExtraInformation.SCAC]: string().optional()
  }),
  name: string().required(),
  surname: string().required(),
  phoneNumber: string().optional(),
  phonePrefix: string().optional(),
  profilePhoto: string().optional(),
  licensePlate: string().optional(),
  terminals: array().of(string().required()).optional(),
  userType: mixed<UserTypes>().oneOf(Object.values(UserTypes)).required(),
  loginWith: object().shape({
    [LoginWith.DriversLivence]: string().optional(),
    [LoginWith.Passport]: string().optional(),
    [LoginWith.Sealink]: string().optional(),
    [LoginWith.Personnummer]: string().optional()
  }).required()
})

export type User = InferType<typeof UserSchema>
