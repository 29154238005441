// External
import { useContext, useEffect, useState } from 'react'
import { ScrollView, StyleSheet, View } from 'react-native'
import { ActivityIndicator, Checkbox, Text, RadioButton } from 'react-native-paper'
import { useTranslation } from 'react-i18next'
import { Formik } from 'formik'
import { FontAwesome5 } from '@expo/vector-icons'
import {
  Collapse,
  CollapseBody,
  CollapseHeader
  // @ts-expect-error - package has no types
} from 'accordion-collapse-react-native'
import { StackScreenProps } from '@react-navigation/stack'
// Components
import { Button } from '@common/components/Button'
import { H2, H3, P } from '@common/components/Typography'
// Constants
import { colors } from '@common/constants'
// Context
import AppContext from '@context/AppContext'
// Hooks
import { useCustomDrawerOptions } from '@hooks/useCustomDrawerOptions'
// Models
import { NewPermitStackParamList, HighRiskCategory } from '@permitToWork/models'
// Layout
import { Container, SafeArea, StepFeatureCompact } from '@common/layout'
// Services
import { getPermitToWorkData } from '@permitToWork/services'
// Utils
import { addOrRemoveFromArray } from '@common/utils'

type Props = StackScreenProps<NewPermitStackParamList, 'StepHighRisks'>

const StepHighRisks = ({ navigation, route }: Props): JSX.Element => {
  const [workIncludesHighRisks, setWorkIncludesHighRisks] = useState<'yes' | 'no' | ''>('')
  const [highRisks, setHighRisks] = useState<Partial<Record<HighRiskCategory, string[]>>>()
  const [expandedCollapse, setExpandedCollapse] = useState<Partial<Record<HighRiskCategory, boolean>>>()
  const { selectedTerminal } = useContext(AppContext)
  const { t } = useTranslation()

  useCustomDrawerOptions({
    options: {
      goBack: () => navigation.goBack()
    }
  })

  useEffect(() => {
    if (workIncludesHighRisks === 'yes') {
      void getPermitToWorkData({ selectedTerminal }).then((res) => {
        const highRiskCategories = Object.keys(res.highRisks) as HighRiskCategory[]
        setExpandedCollapse(
          Object.fromEntries(highRiskCategories.map(category => [category, false]))
        )

        setHighRisks(res.highRisks)
      })
    }
  }, [workIncludesHighRisks])

  return (
    <SafeArea>
      <StepFeatureCompact
        title={t('common.newPermit')}
        subtitle={`${t('signup.step') as string} 7 ${t('signup.of') as string} 8`}
        selectedStep={7}
        totalSteps={8}
      />

      {workIncludesHighRisks === ''
        ? (
          <Container home style={{ flex: 1 }}>
            <P>{t('permitToWorkStepHighRisks.doesWorkIncludeHighRisks')}</P>

            <RadioButton.Group
              onValueChange={(newValue) => {
                if (newValue === 'yes') {
                  setWorkIncludesHighRisks(newValue)
                  return
                }

                navigation.navigate('StepAttachments', route.params)
              }}
              value={workIncludesHighRisks}
            >
              <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                <RadioButton.Android value='yes' color={colors.primary} />

                <P>{t('common.yes')}</P>
              </View>

              <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                <RadioButton.Android value='no' color={colors.primary} />

                <P>{t('common.no')}</P>
              </View>
            </RadioButton.Group>
          </Container>
          )
        : (
          <Formik<{ highRisks: Partial<Record<HighRiskCategory, string[]>> }>
            validateOnChange={false}
            initialValues={{
              highRisks: {}
            }}
            onSubmit={values => {
              const highRiskEntries = Object.entries(values.highRisks)
              let highRisks

              if (highRiskEntries.length > 0) {
                const nonEmptyEntries = []

                for (const entry of highRiskEntries) {
                  if (entry[1].length > 0) {
                    nonEmptyEntries.push(entry)
                  }
                }

                highRisks = Object.fromEntries(nonEmptyEntries)
              }
              navigation.navigate('StepAttachments', {
                ...route.params,
                ...((highRisks !== undefined) && { highRisks })
              })
            }}
          >
            {({ handleSubmit, isValid, values, setFieldValue }) => (
              <Container home style={{ flex: 1 }}>
                {((highRisks) !== undefined)
                  ? (
                    <>
                      <ScrollView
                        style={{ flex: 1 }}
                        contentContainerStyle={{ flexGrow: 1 }}
                      >
                        <H2 bold style={{ marginBottom: 24 }}>
                          {t('permitToWorkStepHighRisks.typeOfWork')}
                        </H2>

                        {(Object.entries(highRisks) as Array<[HighRiskCategory, string[]]>).map((entry) => (
                          <Collapse
                            style={{ marginBottom: 48 }}
                            key={entry[0]}
                            touchableOpacityProps={{ activeOpacity: 0.5 }}
                            isExpanded={expandedCollapse?.[entry[0]]}
                            onToggle={(isExpanded: boolean) => {
                              setExpandedCollapse({
                                ...expandedCollapse,
                                [entry[0]]: isExpanded
                              })
                            }}
                          >
                            <CollapseHeader style={styles.collapseHeader}>
                              <H3 style={styles.highRiskCategoryTitle}>
                                {t('highRisks.' + entry[0])}
                              </H3>

                              <View style={styles.divider} />

                              <FontAwesome5
                                name={(() => {
                                  const isExpanded = expandedCollapse?.[entry[0]]

                                  if (isExpanded !== undefined && isExpanded) {
                                    return 'caret-up'
                                  }

                                  return 'caret-down'
                                })()}
                                size={14}
                                color='#ccc'
                              />
                            </CollapseHeader>

                            <CollapseBody>
                              {entry[1].map((risk) => (
                                <View style={styles.checkItem} key={risk}>
                                  <Checkbox.Android
                                    color={colors.primary}
                                    status={(() => {
                                      const whatever = values.highRisks[entry[0]]

                                      if (whatever !== undefined) {
                                        return whatever.includes(risk) ? 'checked' : 'unchecked'
                                      }

                                      return 'unchecked'
                                    })()}
                                    onPress={() => {
                                      void setFieldValue('highRisks', {
                                        ...values.highRisks,
                                        [entry[0]]: addOrRemoveFromArray(
                                          risk,
                                          values.highRisks[entry[0]]
                                        )
                                      })
                                    }}
                                  />

                                  <Text style={{ marginTop: 6, flexShrink: 1 }}>{t('highRisks.' + risk)}</Text>
                                </View>
                              ))}
                            </CollapseBody>
                          </Collapse>
                        ))}
                      </ScrollView>

                      <Button
                        style={styles.continueButton}
                        disabled={!isValid || highRisks === undefined}
                        onPress={handleSubmit}
                      >
                        {t('common.continue')}
                      </Button>
                    </>
                    )
                  : <ActivityIndicator />}
              </Container>
            )}
          </Formik>
          )}
    </SafeArea>
  )
}
export default StepHighRisks

const styles = StyleSheet.create({
  continueButton: {
    alignSelf: 'flex-end',
    width: '100%',
    marginBottom: 24
  },
  divider: {
    marginHorizontal: 8,
    marginBottom: 4,
    backgroundColor: '#eee',
    flex: 1,
    height: 1
  },
  collapseHeader: {
    flexDirection: 'row',
    alignItems: 'flex-end',
    marginBottom: 2,
    marginLeft: 8
  },
  checkItem: {
    flexDirection: 'row',
    marginLeft: 8,
    marginVertical: 1
  },
  highRiskCategoryTitle: {
    textTransform: 'uppercase',
    marginBottom: 8
  }
})
