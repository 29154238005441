//  External
import { useContext, useState } from 'react'
import { IconButton, Menu } from 'react-native-paper'
import { useToast } from 'react-native-toast-notifications'
import { useTranslation } from 'react-i18next'
// Context
import AppContext from '@context/AppContext'
// COnstants
import { colors } from '@common/constants'
// Services
import { cancelPermit } from '@permitToWork/services'
// Models
import { PTWStates } from '@permitToWork/models'

interface Props {
  permitId: string
  permitState: PTWStates
}

export const PermitToWorkDropdownMenu = ({ permitId, permitState }: Props): JSX.Element => {
  const toast = useToast()
  const [menuVisible, setMenuVisible] = useState(false)
  const { selectedTerminal } = useContext(AppContext)
  const { t } = useTranslation()
  const disabled = permitState === PTWStates.Approved || permitState === PTWStates.Closed || permitState === PTWStates.Cancelled

  return (
    <Menu
      visible={menuVisible}
      onDismiss={() => setMenuVisible(false)}
      anchor={<IconButton size={32} icon='dots-vertical' iconColor={colors.accent} onPress={() => setMenuVisible(true)} />}
    >
      <Menu.Item
        disabled={disabled}
        onPress={() => {
          cancelPermit({ selectedTerminal, permitId })
            .then(() => {
              toast.show(t('permitToWorkDropdownMenu.permitCancelled'))
              setMenuVisible(false)
            })
            .catch((error) => {
              toast.show(error.message)
            })
        }}
        title={t('permitToWorkDropdownMenu.cancelPermit')}
      />
    </Menu>
  )
}
