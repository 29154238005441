// Firebase
import firebase from '@config/firebase'

export const UserServices = {
  updateMetadata: ({ email, key, value }) => {
    return firebase
      .firestore()
      .collection('usersMetadata')
      .doc(email)
      .update({
        [key]: value
      })
  },
  updateProfile: async ({
    loginWithValue,
    loginWithName,
    data,
    email,
    selectedTerminal
  }) => {
    try {
      if (loginWithValue !== data[loginWithName]) {
        await firebase.functions().httpsCallable('auth-updateLoginWith')({
          email,
          loginWithName,
          loginWithValue: data[loginWithName],
          selectedTerminal
        })
      }
      delete data.email
      delete data[loginWithName]
      return firebase.firestore().collection('users').doc(email).update(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }
}
