// Components
import { H3 } from '@components/Typography/H3'
// Paper
import { useTheme } from 'react-native-paper'
// Expo
import * as Linking from 'expo-linking'
// Icons
import { MaterialIcons } from '@expo/vector-icons'
// RN
import { Platform, View } from 'react-native'
// i18 next
import { useTranslation } from 'react-i18next'

export const DrawerButtonSupport = () => {
  const { colors } = useTheme()
  const { t } = useTranslation()
  return (
    <View style={{ marginHorizontal: '10%' }}>
      <MaterialIcons.Button
        name='support-agent'
        onPress={() => {
          if (Platform.OS === 'web') {
            window.open('https://support.estudiocactus.com/visitor-hsse', '_blank')
          } else {
            Linking.openURL('https://support.estudiocactus.com/visitor-hsse')
          }
        }}
        backgroundColor='transparent'
        underlayColor='transparent'
        size={28}
        color={colors.primary}
        iconStyle={{ marginRight: 24 }}
      >
        <H3 style={{ letterSpacing: 0 }}>
          {t('drawerButtonSupport.support')}
        </H3>
      </MaterialIcons.Button>
    </View>
  )
}
