import React, { useEffect, useRef, useState } from 'react'
// Lottie
import LottieView from 'lottie-react-native'
// React Native
import {
  View,
  StyleSheet,
  Platform
} from 'react-native'

export const Splash = ({ appIsReady }) => {
  const lottie = useRef(null)
  const SPLASH_DELAY = 2
  const [splashCounter, setSplashCounter] = useState(0)
  const [showSplash, setShowSplash] = useState(true)
  const [timer, setTimer] = useState()
  const [unmountSplash, setUnmountSplash] = useState(false)

  useEffect(() => {
    setShowSplash(true)
    setSplashCounter(0)
  }, [])

  useEffect(() => {
    lottie.current?.play(0, 60)
    setTimer(window.setInterval(() => {
      setSplashCounter(prevTime => prevTime + 1)
    }, 1000))
    return () => {
      window.clearInterval(timer)
    }
  }, [lottie])

  useEffect(() => {
    if (splashCounter > SPLASH_DELAY && appIsReady && showSplash) {
      lottie.current?.play(60, 200)
      setShowSplash(false)
      window.clearInterval(timer)
    }
  }, [splashCounter])

  if (unmountSplash || Platform.OS === 'web') {
    return null
  }

  return (
    <View style={styles.splashContainer}>
      <LottieView
        ref={lottie}
        style={{
          width: '100%',
          height: '100%'
        }}
        onAnimationFinish={() => {
          if (!showSplash) {
            setUnmountSplash(true)
          }
        }}
        loop={false}
        source={require('@assets/animations/apm-orange-slow.json')}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  splashContainer: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center'
  }
})
