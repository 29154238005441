// React
import React, { useContext } from 'react'
// Context
import UserContext from '@context/UserContext'
// RN
import { View } from 'react-native'
// Components
import { P } from '@components/Typography/P'
// Icons
import { Ionicons, Feather } from '@expo/vector-icons'
// Paper
import { useTheme } from 'react-native-paper'
// i18 next
import { useTranslation } from 'react-i18next'

export const InductionIndicator = () => {
  const { colors } = useTheme()
  const userContext = useContext(UserContext)
  const { induction } = userContext
  const { t } = useTranslation()

  return (
    <View
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
      }}
    >
      <P color='caption'>{t('inductionIndicator.inductionStatus')} </P>

      {!induction?.inductionStatus && (
        <P bold color='primary'>
          <Feather name='clock' size={14} color={colors.primary} /> {t('common.pending')}
        </P>
      )}

      {induction?.inductionStatus === 'done' && (
        <P bold color='success'>
          <Ionicons name='checkmark-sharp' size={16} color={colors.success} />{' '}
          {t('common.done')}
        </P>
      )}
      {induction?.inductionStatus === 'pending' && (
        <P bold color='primary'>
          <Feather name='clock' size={14} color={colors.primary} /> {t('common.pending')}
        </P>
      )}
      {induction?.inductionStatus === 'expired' && (
        <P bold color='danger'>
          <Ionicons name='ios-warning' size={16} color={colors.danger} />{' '}
          {t('common.expired')}
        </P>
      )}
    </View>
  )
}
