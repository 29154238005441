import { object, array, string, InferType, boolean, number } from 'yup'

export const PermitToWorkDataSchema = object().shape({
  counter: number().required(),
  canChooseAuthorizer: boolean().required(),
  locations: array().of(string().required()).required(),
  arrangements: object().shape({
    pre: array().of(string().required()).required(),
    onSite: array().of(string().required()).required()
  }),
  ppeRequirements: array().of(string().required()).required(),
  highRisks: object().shape({
    earthWorking: array(string().required()).optional(),
    confinedSpace: array(string().required()).optional(),
    workingAtHeight: array(string().required()).optional(),
    hazardousSubstances: array(string().required()).optional(),
    nearWater: array(string().required()).optional(),
    hotWork: array(string().required()).optional(),
    storedEnergy: array(string().required()).optional(),
    suspendedLoads: array(string().required()).optional()
  }).required()
})

export type PermitToWorkData = InferType<typeof PermitToWorkDataSchema>
