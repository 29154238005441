// External
import { PaperSelect } from 'react-native-paper-select'
import { TextInput, HelperText } from 'react-native-paper'
import { useTranslation } from 'react-i18next'
// Config
import { colors } from '@common/constants'
import { FormikErrors, FormikTouched } from 'formik'

interface ListItem { _id: string, value: string }

interface Props<T> {
  name: keyof T
  arrayList?: ListItem[]
  value?: string[]
  label: string
  multiEnable: boolean
  onSelection: (selection: string[]) => void
  selectAllEnable?: boolean
  touched?: FormikTouched<T>
  errors?: FormikErrors<T>
}

export const SelectInput = <T,>({
  name,
  arrayList = [],
  value,
  label,
  multiEnable,
  onSelection,
  selectAllEnable = false,
  touched,
  errors
}: Props<T>): JSX.Element => {
  const selectedArrayList = value !== undefined
    ? arrayList.filter(item => value.includes(item._id))
    : []

  const { t } = useTranslation()

  return (
    <>
      <PaperSelect
        label={label}
        value={typeof value === 'string'
          ? value
          : multiEnable && Array.isArray(value)
            ? arrayList
              .filter(item => value.includes(item._id))
              .map(item => item.value)
              .join(', ')
            : ''}
        onSelection={(item) => {
          onSelection(item.selectedList.map((listItem) => listItem._id))
        }}
        arrayList={arrayList}
        selectedArrayList={selectedArrayList}
        hideSearchBox
        textInputMode='outlined'
        textInputProps={{
          left: undefined,
          right: <TextInput.Icon icon='menu-down' />,
          underlineColor: undefined,
          activeUnderlineColor: undefined,
          outlineColor: colors.placeholder,
          activeOutlineColor: colors.primary
        }}
        checkboxProps={{
          checkboxColor: colors.primary,
          checkboxUncheckedColor: colors.caption,
          checkboxLabelStyle: {
            color: colors.text,
            fontFamily: 'Maersk-Regular',
            fontSize: 14,
            lineHeight: 20
          }
        }}
        dialogCloseButtonText={t('common.cancel')}
        dialogDoneButtonText={t('common.confirm')}
        multiEnable={multiEnable}
        selectAllEnable={selectAllEnable}
      />

      <HelperText
        style={{ marginBottom: 4 }}
        type='info'
        visible={Boolean(touched?.[name])}
      >
        {errors?.[name] !== undefined ? String(errors[name]) : ''}
      </HelperText>
    </>
  )
}
