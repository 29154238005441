import React, { useContext, useRef, useState } from 'react'
// Context
import AppContext from '@context/AppContext'
// Components
import { AddAttachment } from '@components/Attachments/AddAttachment'
import { Button } from '@components/Button/Button'
import { FormikInput } from '@components/Formik/FormikInput'
import { Container } from '@components/Layout/Container'
import { KeyboardFix } from '@components/Layout/KeyboardFix'
import { SafeArea } from '@components/Layout/SafeArea'
// RN
import { View } from 'react-native'
// Paper
import { Button as PaperButton, useTheme } from 'react-native-paper'
// Formik
import { Formik } from 'formik'
import * as Yup from 'yup'
// Toast
import { useToast } from 'react-native-toast-notifications'
// Services
import { SuspensionServices } from '@services/SuspensionServices'
// i18next
import { useTranslation } from 'react-i18next'

export const AppealFormScreen = ({ navigation, route }) => {
  const toast = useToast()
  const appContext = useContext(AppContext)
  const { selectedTerminal } = appContext
  const { t } = useTranslation()
  const { suspensionId = {} } = route.params
  const [attachments, setAttachments] = useState([])

  const { colors } = useTheme()

  const INPUTS = [
    {
      name: 'description',
      type: 'textInput',
      initialValue: '',
      validationSchema: Yup.string().required(
        t('appealFormScreen.descriptionRequired')
      ),
      ref: useRef(),
      props: {
        style: { minHeight: 180 },
        label: 'Appeal description',
        returnKeyType: 'next',
        autoCorrect: true,
        dense: true,
        mode: 'outlined',
        blurOnSubmit: true,
        multiline: true,
        numberOfLines: 10
      }
    }
  ]

  return (
    <SafeArea>
      <KeyboardFix>
        <Formik
          enableReinitialize
          validateOnMount
          initialValues={INPUTS.reduce(
            (acc, key) => ({ ...acc, [key.name]: key.initialValue }),
            {}
          )}
          validationSchema={Yup.object().shape(
            INPUTS.reduce(
              (acc, key) => ({ ...acc, [key.name]: key.validationSchema }),
              {}
            )
          )}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(true)
            SuspensionServices.submitAppeal({
              attachments,
              description: values.description,
              selectedTerminal,
              suspensionId
            })
              .then(() => {
                setSubmitting(false)
                toast.show(t('appealFormScreen.appealSubmitted'))
                navigation.navigate('AllSuspensions')
              })
              .catch(error => {
                setSubmitting(false)
                console.log(error)
                toast.show(error.message)
              })
          }}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            isValid,
            isSubmitting,
            touched,
            errors,
            values,
            setFieldValue
          }) => (
            <Container
              style={{
                flex: 1
              }}
            >
              <View style={{ flex: 1, justifyContent: 'center' }}>
                <PaperButton
                  icon='chevron-left'
                  color={colors.placeholder}
                  style={{ alignSelf: 'flex-start', marginVertical: 14 }}
                  uppercase={false}
                  compact
                  onPress={() => {
                    navigation.navigate('AllSuspensions')
                  }}
                >
                  {t('appealFormScreen.backToSuspensions')}
                </PaperButton>
                {INPUTS.map((input, index) => (
                  <React.Fragment key={input.name}>
                    <FormikInput
                      input={input}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      inputs={INPUTS}
                      index={index}
                      handleSubmit={handleSubmit}
                      errors={errors}
                      touched={touched}
                      value={values[input.name]}
                      setFieldValue={setFieldValue}
                    />
                  </React.Fragment>
                ))}

                <AddAttachment
                  onAttachment={(result) => setAttachments(result)}
                />
              </View>
              <Button
                loading={isSubmitting}
                mode='contained'
                style={{ marginVertical: 34 }}
                disabled={!isValid || isSubmitting}
                onPress={handleSubmit}
              >
                {t('common.submitAppeal')}
              </Button>
            </Container>
          )}
        </Formik>
      </KeyboardFix>
    </SafeArea>
  )
}
