import React from 'react'
// RN
import { Platform, StyleSheet, View, useWindowDimensions } from 'react-native'

export const Container = ({ style, home, ...props }) => {
  const dimensions = useWindowDimensions()
  const isLargeScreen = Platform.OS === 'web' && dimensions.width >= 768

  const styles = StyleSheet.create({
    container: {
      paddingHorizontal: isLargeScreen ? '25%' : '15%',
      width: '100%',
      ...style
    },
    homeContainer: {
      paddingHorizontal: isLargeScreen ? '25%' : '5%',
      paddingVertical: '6%',
      width: '100%',
      ...style
    }
  })

  return (
    <View style={home ? styles.homeContainer : styles.container}>
      {props.children}
    </View>
  )
}
