// Config
import firebase from '@config/firebase'
import { AdminUserWithEmail, AdminUserWithEmailSchema } from '@common/models'
// Models
import { PermitToWorkDataSchema, CompanySchema, ContractorSchema } from '@permitToWork/models'

interface Params {
  selectedTerminal: string
  contractorEmail: string
}

export const getStepPersonInChargeData = async ({ selectedTerminal, contractorEmail }: Params): Promise<{
  companies: Array<{
    uid: string
    name: string
  }>
  authorizers?: AdminUserWithEmail[]
}> => {
  try {
    const permitToWorkRef = firebase.firestore()
      .collection('terminals')
      .doc(selectedTerminal)
      .collection('modules')
      .doc('permitToWork')

    const snapshot = await permitToWorkRef
      .get()

    const { canChooseAuthorizer } = PermitToWorkDataSchema.validateSync(snapshot.data())

    const contractorsSnapshot = await permitToWorkRef
      .collection('contractors')
      .where('email', '==', contractorEmail)
      .get()

    const parsedContractors = contractorsSnapshot.docs.map((contractor) => ContractorSchema.validateSync({ id: contractor.id, ...contractor.data() }))

    const contractorIsAdmin = parsedContractors.filter((contractor) => contractor.isAuthorized && contractor.isCompanyAdmin)
    const companiesWhereContractorIsAdmin = contractorIsAdmin.map((contractor) => contractor.company)

    const authorizedCompaniesWhereContractorIsAdmin = []
    for (const company of companiesWhereContractorIsAdmin) {
      const companySnapshot = await permitToWorkRef
        .collection('companies')
        .where('uid', '==', company.uid)
        .get()

      if (!companySnapshot.empty) {
        const parsedCompanyData = CompanySchema.validateSync(companySnapshot.docs[0].data())
        authorizedCompaniesWhereContractorIsAdmin.push({ uid: parsedCompanyData.uid, name: parsedCompanyData.name })
      }
    }

    if (canChooseAuthorizer) {
      const adminUsersSnapshot = await firebase.firestore()
        .collection('terminals')
        .doc(selectedTerminal)
        .collection('modules')
        .doc('permitToWork')
        .collection('authorizers')
        .get()

      if (!adminUsersSnapshot.empty) {
        const validatedAdminUsers = adminUsersSnapshot.docs.map((doc) => {
          return AdminUserWithEmailSchema.validateSync({ email: doc.data().email, ...doc.data().adminUser })
        })

        return {
          companies: authorizedCompaniesWhereContractorIsAdmin,
          authorizers: validatedAdminUsers
        }
      }
    }

    return {
      companies: authorizedCompaniesWhereContractorIsAdmin
    }
  } catch (error) {
    console.error('Error getting create PTW step 1 data:', error)
    return await Promise.reject(error)
  }
}
