// External
import { StackScreenProps } from '@react-navigation/stack'
import { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Keyboard,
  Platform,
  ScrollView,
  TouchableWithoutFeedback,
  View
} from 'react-native'
import { Checkbox, TextInput } from 'react-native-paper'
// Components
import { Button } from '@common/components/Button'
import { H2, P } from '@common/components/Typography'
import { ShareDropdown, SharedWithList } from '@permitToWork/components/Share'
// Context
import AppContext from '@context/AppContext'
import UserContext from '@context/UserContext'
// Constants
import { colors } from '@common/constants'
// Hooks
import { useCustomDrawerOptions } from '@hooks/useCustomDrawerOptions'
import useCompanyContractors from '@permitToWork/hooks/useCompanyContractors'
// Models
import { Contractor, NewPermitStackParamList } from '@permitToWork/models'
// Layouts
import { Container, SafeArea, StepFeatureCompact } from '@common/layout'

type Props = StackScreenProps<NewPermitStackParamList, 'StepShare'>

const StepShare = ({ navigation, route }: Props): JSX.Element => {
  const [showUsers, setShowUsers] = useState(false)
  const [sharedWith, setSharedWith] = useState<Contractor[]>([])
  const [filteredUsers, setFilteredUsers] = useState<Contractor[]>([])
  const [originalUsers, setOriginalUsers] = useState<Contractor[]>([])
  const [checkAgreement, setCheckAgreement] = useState(false)
  const { selectedTerminal } = useContext(AppContext)
  const {
    userAuth: { email }
  } = useContext(UserContext)
  const { t } = useTranslation()
  const users = useCompanyContractors({
    selectedTerminal,
    companyUid: route.params.company
  })

  useCustomDrawerOptions({
    options: {
      goBack: () => navigation.goBack()
    }
  })

  useEffect(() => {
    const usersCopy = [...users]
    usersCopy.sort((a, b) => {
      if (a?.user?.name < b?.user?.name) {
        return -1
      }
      if (a?.user?.name > b?.user?.name) {
        return 1
      }
      return 0
    })

    const sharedWithEmails =
        sharedWith.length > 0 ? sharedWith.map(user => user.email) : []
    const filteredUsersCopy = usersCopy.filter(
      user => user.email !== email && !sharedWithEmails.includes(user.email)
    )
    setFilteredUsers(filteredUsersCopy)
    setOriginalUsers(filteredUsersCopy)
  }, [users, sharedWith])

  return (
    <SafeArea>
      <TouchableWithoutFeedback
        style={{ flex: 1 }}
        accessible={false}
        onPress={() => {
          Platform.OS !== 'web' && setShowUsers(false)
          Platform.OS !== 'web' && Keyboard.dismiss()
        }}
      >
        <View style={{ flex: 1 }}>
          <StepFeatureCompact
            title={t('common.newPermit')}
            subtitle={`${t('signup.step') as string} 4 ${t('signup.of') as string} 8`}
            selectedStep={4}
            totalSteps={8}
          />

          <Container home style={{ flex: 1 }}>
            <H2 style={{ marginBottom: 16 }}>
              {t('permitToWorkStepShare.addContractors')}
            </H2>

            <View style={{ zIndex: 1 }}>
              <TextInput
                returnKeyType='search'
                onFocus={() => setShowUsers(true)}
                onBlur={() => setTimeout(() => setShowUsers(false), 100)}
                onChange={(event) => {
                  const { text } = event.nativeEvent
                  if (text.length > 0) {
                    const filteredUsersCopy = filteredUsers.filter(user =>
                      user.user.name.toLowerCase().includes(text.toLowerCase())
                    )
                    setFilteredUsers(filteredUsersCopy)
                  } else {
                    setFilteredUsers(originalUsers)
                  }
                }}
                style={{ marginBottom: 16 }}
                label={t('common.emailOrName')}
                mode='outlined'
                dense
              />

              {showUsers && (
                <ShareDropdown
                  users={filteredUsers}
                  onSelect={(user) => {
                    setSharedWith(prevSharedWith => [...prevSharedWith, user])
                  }}
                />
              )}
            </View>

            <ScrollView
              style={{ flex: 1 }}
              contentContainerStyle={{ flexGrow: 1 }}
            >
              <View onStartShouldSetResponder={() => true}>
                <SharedWithList
                  totalItems={sharedWith.length}
                  items={sharedWith}
                  onRemove={user => {
                    setSharedWith(prevSharedWith =>
                      prevSharedWith.filter(
                        sharedWithUser => sharedWithUser.email !== user.email
                      )
                    )
                  }}
                />
              </View>
            </ScrollView>

            <View
              style={{
                marginVertical: 20,
                display: 'flex',
                flexDirection: 'row'
              }}
            >
              <Checkbox.Android
                color={colors.primary}
                status={checkAgreement ? 'checked' : 'unchecked'}
                onPress={() => {
                  setCheckAgreement((prev) => !prev)
                }}
              />

              <P
                light
                style={{
                  fontSize: 12,
                  lineHeight: 14,
                  flex: 1,
                  flexWrap: 'wrap'
                }}
              >
                {t('permitToWorkStepShare.agreement')}
              </P>
            </View>

            <Button
              style={{
                marginTop: 'auto',
                width: '100%',
                marginBottom: 24
              }}
              disabled={!checkAgreement}
              onPress={() => {
                navigation.navigate('StepArrangements', {
                  ...route.params,
                  sharedWith
                })
              }}
            >
              {t('common.continue')}
            </Button>
          </Container>
        </View>
      </TouchableWithoutFeedback>
    </SafeArea>
  )
}
export default StepShare
