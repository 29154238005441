export const MODULES = {
  induction: {
    icon: 'file-document-edit',
    screen: 'Induction',
    userTypes: [
      'containerTrucker',
      'reeferTrucker',
      'fruitTrucker',
      'truckDriver',
      'visitor',
      'containerVisitor',
      'reeferVisitor',
      'contractor',
      'shipService',
      'employee'
    ]
  },
  visits: {
    icon: 'account-arrow-right',
    screen: 'Visits',
    userTypes: [
      'containerTrucker',
      'reeferTrucker',
      'fruitTrucker',
      'truckDriver',
      'visitor',
      'containerVisitor',
      'reeferVisitor',
      'contractor',
      'shipService',
      'employee'
    ]
  },
  visitsManagement: {
    icon: 'account-arrow-right',
    screen: 'VisitsManagementHome',
    userTypes: [
      'containerTrucker',
      'reeferTrucker',
      'fruitTrucker',
      'truckDriver',
      'visitor',
      'containerVisitor',
      'reeferVisitor',
      'contractor',
      'shipService',
      'employee'
    ]
  },
  suspensions: {
    icon: 'whistle',
    screen: 'Suspensions',
    userTypes: [
      'containerTrucker',
      'reeferTrucker',
      'fruitTrucker',
      'truckDriver',
      'visitor',
      'containerVisitor',
      'reeferVisitor',
      'contractor',
      'shipService',
      'employee'
    ]
  },
  permitToWork: {
    icon: 'newspaper-variant-outline',
    screen: 'PermitToWork',
    userTypes: [
      'contractor'
    ]
  },
  geoposition: {
    icon: 'map-marker',
    screen: 'Geoposition',
    userTypes: [
      'containerTrucker',
      'reeferTrucker',
      'fruitTrucker',
      'truckDriver',
      'visitor',
      'containerVisitor',
      'reeferVisitor',
      'contractor',
      'shipService',
      'employee'
    ]
  },
  map3d: {
    icon: 'map-legend',
    screen: 'Map3D',
    userTypes: [
      'containerTrucker',
      'reeferTrucker',
      'fruitTrucker',
      'truckDriver',
      'visitor',
      'containerVisitor',
      'reeferVisitor',
      'contractor',
      'shipService',
      'employee'
    ]
  }
}
