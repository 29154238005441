// External
import {
  StyleSheet, View
} from 'react-native'
import { useTranslation } from 'react-i18next'
// Components
import { P } from '@common/components/Typography'
// Models
import { PermitToWork } from '@permitToWork/models'

interface Props {
  item: PermitToWork
}

export const DetailCompany = ({ item }: Props): JSX.Element => {
  const { t } = useTranslation()

  return (
    <View style={styles.sectionContainer}>
      <View style={styles.dividerContainer}>
        <P color='accent' bold style={styles.sectionTitle}>
          {t('permitToWorkItem.company')}
        </P>

        <View style={styles.divider} />
      </View>

      <P light style={styles.sectionContent}>

        {item?.company?.name} [{item?.company?.uid}]
      </P>
    </View>
  )
}

const styles = StyleSheet.create({
  dividerContainer: {
    flexDirection: 'row',
    alignItems: 'flex-end'
  },
  divider: {
    height: 0.5,
    backgroundColor: '#DADADA',
    flex: 1,
    marginBottom: 6,
    marginLeft: 18
  },
  sectionContainer: {
    flex: 1,
    marginVertical: 12
  },
  sectionContent: {
    fontSize: 12,
    marginLeft: 8,
    lineHeight: 18
  },
  sectionTitle: {
    fontSize: 11,
    textTransform: 'uppercase',
    letterSpacing: 1
  }
})
