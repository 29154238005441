import React, { useRef } from 'react'
// Components
import { FormikInput } from '@components/Formik/FormikInput'
import { SafeArea } from '@components/Layout/SafeArea'
import { KeyboardFix } from '@components/Layout/KeyboardFix'
import { Button } from '@components/Button/Button'
import { H2 } from '@components/Typography/H2'
import { Container } from '@components/Layout/Container'
import { LanguageSelector } from '@components/Dropdown/LanguageSelector'
import { SignupFooter } from '@components/Footer/SignupFooter'
import { StepFeature } from '@components/Layout/StepFeature'
// RN
import { View } from 'react-native'
// Paper
import { Button as PaperButton, useTheme } from 'react-native-paper'
// Formik
import { Formik } from 'formik'
import * as Yup from 'yup'
// i18next
import { useTranslation } from 'react-i18next'

export const Step2 = ({ route, navigation }) => {
  const { selectedTerminalToSignup, formValues = {} } = route.params
  const { colors } = useTheme()
  const { t } = useTranslation()

  const STEP = {
    current: 2,
    total: 3
  }

  const INPUTS = [
    {
      name: 'email',
      type: 'textInput',
      lowerCase: true,
      initialValue: formValues.email,
      validationSchema: Yup.string()
        .email(t('step2.emailRequired'))
        .required(t('step2.userTypeRequired')),
      ref: useRef(),
      props: {
        label: t('common.emailLabel'),
        returnKeyType: 'next',
        autoCapitalize: 'none',
        autoCorrect: false,
        dense: true,
        mode: 'outlined',
        blurOnSubmit: true,
        keyboardType: 'email-address'
      }
    },
    {
      name: 'password',
      type: 'textInput',
      initialValue: formValues.password,
      validationSchema: Yup.string()
        .required(t('step2.passwordRequired'))
        .min(6, t('step2.minPasswordLength')),
      ref: useRef(),
      props: {
        label: t('common.passwordLabel'),
        returnKeyType: 'next',
        autoCapitalize: 'none',
        autoCorrect: false,
        dense: true,
        mode: 'outlined',
        blurOnSubmit: true,
        secureTextEntry: true
      }
    },
    {
      name: 'repeatPassword',
      type: 'textInput',
      initialValue: formValues.repeatPassword,
      validationSchema: Yup.string()
        .oneOf([Yup.ref('password'), null], t('step2.confirmPasswordMatch'))
        .required(t('step2.confirmPasswordRequired')),
      ref: useRef(),
      props: {
        label: t('common.confirmPasswordLabel'),
        returnKeyType: 'done',
        autoCapitalize: 'none',
        autoCorrect: false,
        dense: true,
        mode: 'outlined',
        blurOnSubmit: true,
        secureTextEntry: true
      }
    }
  ]

  return (
    <SafeArea>
      <KeyboardFix>
        <LanguageSelector />
        <StepFeature
          title={t('signup.title')}
          subtitle={`${t('signup.step')} ${STEP.current} ${t('signup.of')} ${STEP.total}`}
          selectedStep={STEP.current}
          totalSteps={STEP.total}
        />
        <Formik
          enableReinitialize
          validateOnMount
          initialValues={INPUTS.reduce(
            (acc, key) => ({ ...acc, [key.name]: key.initialValue }),
            {}
          )}
          validationSchema={Yup.object().shape(
            INPUTS.reduce(
              (acc, key) => ({ ...acc, [key.name]: key.validationSchema }),
              {}
            )
          )}
          onSubmit={async values => {
            navigation.navigate('Step3', {
              selectedTerminalToSignup,
              formValues: { ...formValues, ...values }
            })
          }}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            isValid,
            isSubmitting,
            touched,
            errors,
            values,
            setFieldValue
          }) => (
            <Container
              style={{
                flex: 1
              }}
            >
              <View
                style={{ flex: 1, justifyContent: 'center', marginTop: -18 }}
              >
                <H2 regular>{t('step2.accessDetails')}</H2>
                <PaperButton
                  icon='chevron-left'
                  color={colors.placeholder}
                  style={{ alignSelf: 'flex-start' }}
                  uppercase={false}
                  compact
                  onPress={() => {
                    navigation.navigate('Step1', {
                      selectedTerminalToSignup,
                      formValues: { ...formValues }
                    })
                  }}
                >
                  {t('step2.backTo1')}
                </PaperButton>
                {INPUTS.map((input, index) => (
                  <React.Fragment key={input.name}>
                    <FormikInput
                      input={input}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      inputs={INPUTS}
                      index={index}
                      handleSubmit={handleSubmit}
                      errors={errors}
                      touched={touched}
                      value={values[input.name]}
                      setFieldValue={setFieldValue}
                    />
                  </React.Fragment>
                ))}
              </View>
              <Button
                style={{ marginVertical: 34 }}
                disabled={!isValid || isSubmitting}
                onPress={handleSubmit}
              >
                {t('common.continue')}
              </Button>
            </Container>
          )}
        </Formik>
      </KeyboardFix>
      <SignupFooter />
    </SafeArea>
  )
}
