// Config
import firebase from '@config/firebase'
// Models
import { PermitToWorkDataSchema, PermitToWorkData } from '@permitToWork/models'

interface Params {
  selectedTerminal: string
}

export const getPermitToWorkData = async ({ selectedTerminal }: Params): Promise<PermitToWorkData> => {
  try {
    const permitToWokSnapshot = await firebase.firestore()
      .collection('terminals')
      .doc(selectedTerminal)
      .collection('modules')
      .doc('permitToWork')
      .get()

    return PermitToWorkDataSchema.validateSync(permitToWokSnapshot.data())
  } catch (error) {
    console.error(`Error getting ${selectedTerminal}'s PTW module data:`, error)
    return await Promise.reject(error)
  }
}
