export enum AppStates {
  Approved = 'approved',
  Pending = 'pending',
  Rejected = 'rejected',
  Cancelled = 'cancelled',
  Closed = 'closed',
  Queued = 'queued',
  Active = 'active',
  Expired = 'expired'
}
