// Models
import { PermitToWork, PermitToWorkWithStates } from '../models'
// Utils
import { getAppState } from './getAppState'
import { getSubtitleState } from './getSubtitleState'
import { getIntervalItemDuration } from './getIntervalItemDuration'

interface Params {
  permits: PermitToWork[]
}

export const getPermitToWorksWithStates = ({ permits }: Params): PermitToWorkWithStates[] => {
  const permitsWithState = permits.map(ptw => ({
    ...ptw,
    appState: getAppState({ ptw }),
    subtitleState: getSubtitleState({ ptw }),
    intervalDuration: getIntervalItemDuration({ ptw })
  }))

  return permitsWithState
}
