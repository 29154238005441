export const REGIONS = [
  {
    identifier: 'estudioCactus',
    latitude: 39.98501330676837,
    longitude: -0.03852636588263616,
    radius: 2000
  },
  {
    identifier: 'gothenburg',
    latitude: 57.6956911,
    longitude: 11.840583,
    radius: 2000
  },
  {
    identifier: 'portElizabeth',
    latitude: 40.672429,
    longitude: -74.157859,
    radius: 2000
  },
  {
    identifier: 'pier400',
    latitude: 33.722630,
    longitude: -118.253061,
    radius: 2000
  },
  {
    identifier: 'mobile',
    latitude: 30.668405,
    longitude: -88.037743,
    radius: 2000
  },
  {
    identifier: 'castellon',
    latitude: 39.963452,
    longitude: 0.02877,
    radius: 2000
  },
  {
    identifier: 'barcelona',
    latitude: 41.3556526,
    longitude: 2.1644024,
    radius: 2000
  },
  {
    identifier: 'gijon',
    latitude: 43.5529495,
    longitude: -5.6914454,
    radius: 2000
  },
  {
    identifier: 'valencia',
    latitude: 39.4590248,
    longitude: -0.3223148,
    radius: 2000
  },
  {
    identifier: 'vadoLigure',
    latitude: 44.267794,
    longitude: 8.441153,
    radius: 2000
  }]
