// External
import {
  SafeAreaView,
  StyleSheet,
  Platform,
  StatusBar,
  ViewStyle
} from 'react-native'
import { ReactNode } from 'react'

interface Props {
  style?: ViewStyle
  children: ReactNode
}

export const SafeArea = ({ style, children }: Props): JSX.Element => {
  return (
    <SafeAreaView style={[styles.androidSafeArea, style]}>
      {children}
    </SafeAreaView>
  )
}

const styles = StyleSheet.create({
  androidSafeArea: {
    flex: 1,
    backgroundColor: 'white',
    paddingTop: Platform.OS === 'android' ? StatusBar.currentHeight : 0
  }
})
