// External
import { View, StyleSheet, Text, Pressable } from 'react-native'
import { NavigationProp, useNavigation } from '@react-navigation/native'
import { format } from 'date-fns'
import { useTranslation } from 'react-i18next'
// Components
import { H2, P } from '@common/components/Typography'
// Constants
import { STATE_COLORS } from '@permitToWork/constants'
import { colors } from '@common/constants'
// Models
import { PermitToWorkStackParamList, PermitToWorkWithStates } from '@permitToWork/models'
// Utils
import { getAppState, getSubtitleState } from '@permitToWork/utils'

interface Props {
  item: PermitToWorkWithStates
}

export const PermitToWorkCard = ({ item }: Props): JSX.Element => {
  const navigation = useNavigation<NavigationProp<PermitToWorkStackParamList>>()
  const { t } = useTranslation()
  const subtitleState = getSubtitleState({ ptw: item })

  return (
    <Pressable
      onPress={() => {
        navigation.navigate('PermitToWorkDetail', { permitId: item.id })
      }}
      style={styles.container}
    >
      <View style={styles.innerContainer}>
        <View style={styles.timerContainer}>
          {item.intervalDuration !== null
            ? (
              <H2 color={STATE_COLORS[getAppState({ ptw: item })]} bold>
                {item.intervalDuration}
              </H2>
              )
            : (
              <P
                color={STATE_COLORS[getAppState({ ptw: item })]}
                light
                style={{
                  letterSpacing: 2,
                  textTransform: 'uppercase',
                  fontSize: 11
                }}
              >
                {t('permitToWorkStates.' + getAppState({ ptw: item }))}
              </P>
              )}

          {subtitleState !== null && (
            <P style={{ fontSize: 10 }} color='textLight' light>
              {t('permitToWorkSubtitles.' + subtitleState)}
            </P>
          )}
        </View>

        <View style={styles.divider} />

        <View style={styles.infoContainer}>
          <View style={styles.rowInfo}>
            <View style={{ width: 44 }}>
              <P style={{ fontSize: 12 }} color='textLight'>
                {t('permitToWorkItem.from')}
              </P>
            </View>

            <View style={styles.dateBadge}>
              <Text style={styles.dateText}>
                {format(item.startDate.toDate(), 'y/MM/dd HH:mm')}
              </Text>
            </View>
          </View>

          <View style={styles.rowInfo}>
            <View style={{ width: 44 }}>
              <P style={{ fontSize: 12 }} color='textLight'>
                {t('permitToWorkItem.to')}
              </P>
            </View>

            <View style={styles.dateBadge}>
              <Text style={styles.dateText}>
                {format(item.endDate.toDate(), 'y/MM/dd HH:mm')}
              </Text>
            </View>
          </View>

          <View style={styles.rowInfo}>
            <View style={{ width: 44 }}>
              <P style={{ fontSize: 12 }} color='textLight'>
                ID
              </P>
            </View>

            <View>
              <P bold style={styles.ptwNumber}>
                #{item.id}
              </P>
            </View>
          </View>
        </View>

        <View
          style={{
            ...styles.indicatorContainer,
            backgroundColor:
              colors[STATE_COLORS[getAppState({ ptw: item })]]
          }}
        >
          <P bold style={styles.indicatorText}>
            {t('permitToWorkStates.' + getAppState({ ptw: item }))}
          </P>
        </View>
      </View>
    </Pressable>
  )
}

const styles = StyleSheet.create({
  innerContainer: {
    flexDirection: 'row',
    width: '100%',
    minHeight: 100
  },
  container: {
    marginVertical: 8,
    flexDirection: 'row',
    width: '100%',
    borderRadius: 4,
    backgroundColor: '#fff',
    borderWidth: 0.5,
    borderColor: colors.placeholder,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2
    },
    shadowOpacity: 0.15,
    shadowRadius: 4
  },
  divider: {
    backgroundColor: '#eee',
    marginVertical: 10,
    width: 1
  },
  timerContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 120,
    padding: 8
  },
  infoContainer: {
    flex: 4,
    justifyContent: 'center',
    paddingVertical: 6,
    paddingHorizontal: 20
  },
  rowInfo: {
    flexDirection: 'row',
    paddingVertical: 2,
    justifyContent: 'space-between'
  },
  indicatorContainer: {
    borderTopEndRadius: 4,
    borderBottomEndRadius: 4,
    width: 24
  },
  indicatorText: {
    textTransform: 'uppercase',
    color: 'white',
    opacity: 0.8,
    textAlign: 'center',
    width: 90,
    letterSpacing: 2,
    transform: [{ rotate: '270deg' }, { translateX: -36 }, { translateY: -34 }],
    fontSize: 8
  },
  dateBadge: {
    backgroundColor: '#F7F7F7',
    borderRadius: 4,
    paddingHorizontal: 4,
    borderColor: '#E0E0E0',
    borderWidth: 1,
    justifyContent: 'center'
  },
  dateText: {
    fontSize: 11,
    color: '#BDBDBD',
    fontWeight: '500',
    fontVariant: ['tabular-nums']
  },
  ptwNumber: {
    color: colors.caption
  }
})
