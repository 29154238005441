// Navigation
import { createStackNavigator } from '@react-navigation/stack'
// Views
import { WelcomeScreen } from '@views/Welcome/WelcomeScreen'
import { ForegroundScreen } from '@views/Welcome/ForegroundScreen'
import { BackgroundScreen } from '@views/Welcome/BackgroundScreen'
import { NotificationsScreen } from '@views/Welcome/NotificationsScreen'

const Stack = createStackNavigator()

export const WelcomeNavigator = ({ setShowWelcomeScreen }) => {
  return (
    <Stack.Navigator screenOptions={{ headerShown: false }}>
      <Stack.Screen name='Welcome' component={WelcomeScreen} />
      <Stack.Screen name='Foreground' component={ForegroundScreen} />
      <Stack.Screen name='Background' component={BackgroundScreen} />
      <Stack.Screen name='Notifications'>
        {props => <NotificationsScreen {...props} setShowWelcomeScreen={setShowWelcomeScreen} />}
      </Stack.Screen>
    </Stack.Navigator>
  )
}
