// Firebase
import firebase from '@config/firebase'

const uriToBlob = async (url: string): Promise<Blob> => {
  return await new Promise((resolve, reject) => {
    // eslint-disable-next-line
      var xhr = new XMLHttpRequest()
    xhr.onerror = reject
    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4) {
        resolve(xhr.response)
      }
    }
    xhr.open('GET', url)
    xhr.responseType = 'blob' // convert type
    xhr.send()
  })
}

interface Params {
  attachments: any[]
  uploadUrl: string
}

export const uploadAttachments = async ({ attachments, uploadUrl }: Params): Promise<Array<{
  mimeType: string
  name: string
  url: string
}>> => {
  try {
    const newAttachments = []

    for (const attachment of attachments) {
      const blob = await uriToBlob(attachment.uri)
      const fileRef = firebase.storage().ref().child(`${uploadUrl}/${attachment.name as string}`)

      const uploadTaskSnapshot = await fileRef.put(blob)
      const downloadURL = await uploadTaskSnapshot.ref.getDownloadURL()

      newAttachments.push({
        mimeType: attachment.mimeType,
        name: attachment.name,
        url: downloadURL
      })
    }

    return newAttachments
  } catch (error) {
    console.error(`Error uploading attachments to ${uploadUrl}:`, error)
    return await Promise.reject(error)
  }
}
