import * as React from 'react'
// Paper
import {
  Paragraph,
  Dialog as DialogPaper,
  Portal,
  ActivityIndicator
} from 'react-native-paper'

export const Loader = ({ visible, title, subtitle }) => {
  return (
    <Portal style={{ zIndex: 9 }}>
      <DialogPaper dismissable={false} visible={visible}>
        <DialogPaper.Title style={{ textAlign: 'center' }}>{title}</DialogPaper.Title>
        <DialogPaper.Content>
          <Paragraph style={{ textAlign: 'center' }}>{subtitle}</Paragraph>
          <ActivityIndicator style={{ marginTop: 12 }} />
        </DialogPaper.Content>
      </DialogPaper>
    </Portal>
  )
}
