import * as React from 'react'
import { Paragraph, Dialog as DialogPaper, Portal } from 'react-native-paper'
// Components
import { Button as VisitorButton } from '@components/Button/Button'
// i18next
import { useTranslation } from 'react-i18next'

export const Dialog = ({
  visible,
  onDismiss,
  title = '',
  subtitle = '',
  showAgreeText = false,
  agreeText = '',
  children,
  onAgree
}) => {
  const { t } = useTranslation()

  return (
    <Portal>
      <DialogPaper dismissable={false} visible={visible} onDismiss={onDismiss}>
        <DialogPaper.Title>{title}</DialogPaper.Title>
        <DialogPaper.Content>
          <Paragraph>{subtitle}</Paragraph>
          {children}
        </DialogPaper.Content>
        <DialogPaper.Actions>
          <VisitorButton uppercase={false} mode='text' onPress={onDismiss}>
            {t('common.close')}
          </VisitorButton>
          {showAgreeText && (
            <VisitorButton
              style={{ marginLeft: 24 }}
              mode='contained'
              onPress={onAgree}
            >
              {agreeText}
            </VisitorButton>
          )}
        </DialogPaper.Actions>
      </DialogPaper>
    </Portal>
  )
}
