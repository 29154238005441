// Config
import firebase from '@config/firebase'
import { VisitManagementHosts, VisitManagmentHostsSchema } from '../models/VisitManagementHost'
// Common
import { COLLECTIONS } from '@modules/common/constants'

interface Params {
  selectedTerminal: string
}

export const getTerminalVisitHosts = async ({ selectedTerminal }: Params): Promise<VisitManagementHosts> => {
  try {
    const hostsDocs = await firebase.firestore()
      .collection(COLLECTIONS.TERMINALS)
      .doc(selectedTerminal)
      .collection(COLLECTIONS.VISITS_MANAGEMENT_HOSTS)
      .orderBy('name', 'asc')
      .get()

    const data = hostsDocs.docs.map((doc) => {
      const docData = doc.data()
      return {
        ...docData,
        id: doc.id,
        email: String(docData.email).toLowerCase().trim()
      }
    })

    const validatedData = await VisitManagmentHostsSchema.validate(data)

    if (validatedData == null) {
      await Promise.reject(new Error('Error validating data'))
    }

    return validatedData
  } catch (error) {
    console.error('Error fetching hosts:', error)
    return await Promise.reject(error)
  }
}
