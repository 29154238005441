// Paper
import { Headline, useTheme } from 'react-native-paper'

export const H1 = ({ bold, style, color, ...props }) => {
  const { colors } = useTheme()

  return (
    <Headline
      style={{
        fontSize: 42,
        lineHeight: 46,
        color: colors[color] || colors.accent,
        fontFamily: bold ? 'Maersk-Bold' : 'Maersk-Light',
        ...style
      }}
      {...props}
    >
      {props.children}
    </Headline>
  )
}
