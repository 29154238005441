// React
import { useEffect, useContext } from 'react'
// Context
import DrawerContext from '@context/DrawerContext'
// React Navigation
import { useNavigation } from '@react-navigation/native'

export const useCustomDrawerOptions = ({ options, dependencyArray = [] }) => {
  const navigation = useNavigation()
  const drawerContext = useContext(DrawerContext)

  useEffect(() => {
    const unsubscribeFocus = navigation.addListener('focus', () => {
      drawerContext.setDrawerScreenOptions(options)
    })

    const unsubscribeBlur = navigation.addListener('blur', () => {
      drawerContext.setDrawerScreenOptions({})
    })

    return () => {
      unsubscribeFocus()
      unsubscribeBlur()
    }
  }, dependencyArray)
}
