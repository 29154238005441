export enum COLLECTIONS {
  TERMINALS = 'terminals',
  MODULES = 'modules',
  INDUCTIONS = 'inductions',
  USERS = 'users',
  STATS = 'stats',
  PAYMENT_LOGS = 'paymentLogs',
  QUESTIONS = 'questions',
  SUSPENSIONS = 'suspensions',
  VIDEOS = 'videos',

  // visits management
  VISITS_MANAGEMENT = 'visitsManagement',
  VISITS_MANAGEMENT_PENDING_VISITS = 'visitsManagementPendingVisits',
  VISITS_MANAGEMENT_QUESTIONS = 'visitsManagementQuestions',
  VISITS_MANAGEMENT_HOSTS = 'visitsManagementHosts',
  VISITS_MANAGEMENT_VISITS = 'visitsManagementVisits',
}
