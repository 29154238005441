// Config
import firebase from '@config/firebase'
// Models
import { PermitToWorkDataSchema } from '@permitToWork/models'

interface Params {
  selectedTerminal: string
}

export const getStepInformationData = async ({ selectedTerminal }: Params): Promise<{
  locations: string[]
}> => {
  try {
    const permitToWorkRef = firebase.firestore()
      .collection('terminals')
      .doc(selectedTerminal)
      .collection('modules')
      .doc('permitToWork')

    const snapshot = await permitToWorkRef
      .get()

    const { locations } = PermitToWorkDataSchema.validateSync(snapshot.data())

    return {
      locations
    }
  } catch (error) {
    console.error('Error getting create PTW step 1 data:', error)
    return await Promise.reject(error)
  }
}
