import { useState, useEffect } from 'react'
import { Platform } from 'react-native'
import * as Device from 'expo-device'
import * as Notifications from 'expo-notifications'
import AsyncStorage from '@react-native-async-storage/async-storage'

export const usePushToken = () => {
  const [pushToken, setPushToken] = useState()

  useEffect(() => {
    const getToken = async () => {
      if (Platform.OS !== 'web' && Device.isDevice) {
        const pushToken = (await Notifications.getExpoPushTokenAsync()).data
        setPushToken(pushToken)
        await AsyncStorage.setItem('pushToken', pushToken)
      } else {
        setPushToken(null)
      }
    }

    getToken()
  }, [])

  return pushToken
}
