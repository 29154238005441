import { useContext, useEffect, useState } from 'react'
// Context
import AppContext from '@context/AppContext'
// Navigation
import { useNavigation } from '@react-navigation/native'
// Components
import { MapButton } from '@components/Button/MapButton'
import { SOSButton } from '@components/Button/SOSButton'
import { Container } from '@components/Layout/Container'
import { TerminalSelectorModal } from '@components/Modal/TerminalSelectorModal'
import { P } from '@components/Typography/P'
// RN
import { StyleSheet, TouchableOpacity, View } from 'react-native'
// Hooks
import { useKeyboard } from '@hooks/useKeyboard'
import { useTerminals } from '@hooks/useTerminals'
// i18n
import { useTranslation } from 'react-i18next'

export const LoginFooter = () => {
  const { t } = useTranslation()
  const navigation = useNavigation()
  const [showModal, setShowModal] = useState(false)
  const appContext = useContext(AppContext)
  const { selectedTerminal } = appContext
  const [isKeyboardVisible] = useKeyboard()
  const [selectedTerminalToSignup, setSelectedTerminalToSignup] = useState()

  const terminals = useTerminals()
  const selectedTerminalData =
    terminals !== undefined ? Object.entries(terminals)?.find(([id, data]) => id === selectedTerminal)?.[1] : undefined
  const selectedTerminalIsMigrated = selectedTerminalData?.migrated ?? false

  useEffect(() => {
    if (selectedTerminalToSignup && selectedTerminal) {
      if (selectedTerminalIsMigrated) {
        navigation.navigate('DisabledFacility')
        return
      }
      navigation.navigate('Signup',
        {
          screen: 'Step1',
          params: {
            selectedTerminalToSignup
          }
        }
      )
    }
  }, [selectedTerminalToSignup, selectedTerminal])

  if (isKeyboardVisible) { return null }

  return (
    <Container>
      <TerminalSelectorModal
        onDismiss={() => setShowModal(false)}
        onSelectedTerminal={terminal => {
          appContext.setSelectedTerminal(terminal)
          setSelectedTerminalToSignup(terminal)
        }}
        showModal={showModal}
      />
      <View style={styles.footerContainer}>
        <View style={styles.buttonsContainer}>
          <SOSButton disabled={!appContext.selectedTerminal} />
          <MapButton disabled={!appContext.selectedTerminal} />
        </View>
        <View style={styles.divider} />
        <View style={styles.footerBox}>
          <P>{t('loginFooter.dontHaveAccount')}</P>
          <TouchableOpacity
            style={{
              opacity: selectedTerminal ? 1 : 0.5
            }}
            disabled={
            !selectedTerminal
          } onPress={() => {
            try {
              appContext.setSelectedTerminal()
              setSelectedTerminalToSignup()
              const urlParams = new URLSearchParams(window.location?.search)
              const urlTerminal = urlParams.get('terminal')
              console.log(urlTerminal)
              if (urlTerminal) {
                appContext.setSelectedTerminal(urlTerminal)
                setSelectedTerminalToSignup(urlTerminal)
              } else {
                setShowModal(true)
              }
            } catch (error) {
              console.log(error)
              setShowModal(true)
            }
          }}
          >
            <P link color='primary'>
              {t('loginFooter.createOne')}
            </P>
          </TouchableOpacity>
        </View>
      </View>
    </Container>
  )
}

const styles = StyleSheet.create({
  footerContainer: {
    flexDirection: 'column'
  },
  buttonsContainer: {
    paddingVertical: 12,
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  divider: {
    width: '100%',
    backgroundColor: '#F2F2F2',
    height: 1
  },
  footerBox: {
    marginBottom: 1,
    paddingVertical: 12,
    flexDirection: 'row',
    justifyContent: 'space-between'
  }
})
