import React from 'react'
// RN
import { Dimensions, Image, Platform, StyleSheet, View } from 'react-native'
// Components
import { H1 } from '@components/Typography/H1'
import { H2 } from '@components/Typography/H2'

const windowWidth = Dimensions.get('window').width
const RATIO = 1500 / 1153

export const Hero = ({ intro, title, subtitle, ...props }) => {
  return (
    <View
      style={styles.container}
    >
      <Image
        defaultSource={require('@assets/images/stripes.png')}
        source={require('@assets/images/bg.png')}
        style={styles.bgImage}
      />
      <View>
        <H2>{intro}</H2>
        <H1>{title}</H1>
      </View>
      <H1 style={{ alignSelf: 'center' }} bold>{subtitle}</H1>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    paddingTop: 100,
    width: '100%',
    minHeight: Platform.OS !== 'web' ? windowWidth / RATIO : '50vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  bgImage: {
    position: 'absolute',
    alignSelf: 'center',
    zIndex: -1,
    resizeMode: 'contain',
    width: '100%',
    opacity: 0.8,
    height: Platform.OS !== 'web' ? windowWidth / RATIO : '50vh'
  },
  titleContainer: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center'
  }
})
