// Navigation
import { createStackNavigator } from '@react-navigation/stack'
// Screens
import { ProfileScreen } from '@views/Home/Profile/ProfileScreen'
import { UpdateProfileScreen } from '@views/Home/Profile/UpdateProfileScreen'

const Stack = createStackNavigator()

export const ProfileNavigator = () => {
  return (
    <Stack.Navigator
      initialRouteName='ProfilePassport'
      screenOptions={{
        headerShown: false
      }}
    >
      <Stack.Screen
        options={{ headerShown: false }}
        name='ProfilePassport'
        component={ProfileScreen}
      />
      <Stack.Screen
        options={{ headerShown: false }}
        name='UpdateProfile'
        component={UpdateProfileScreen}
      />
    </Stack.Navigator>
  )
}
