// Config
import firebase from '@config/firebase'

export const checkUserAllowedToCreatePermit = async ({ selectedTerminal, email }) => {
  const contractorSnapshot = await firebase.firestore()
    .collection('terminals')
    .doc(selectedTerminal)
    .collection('modules')
    .doc('permitToWork')
    .collection('contractors')
    .where('email', '==', email)
    .where('isAuthorized', '==', true)
    .get()

  if (contractorSnapshot.empty) {
    return {
      result: 'error',
      message: 'You are not authorized to create a permit to work in any company.'
    }
  } else {
    const companies = contractorSnapshot.docs.map((contractor) => contractor.data().company)
    const contractorIsAdmin = contractorSnapshot.docs.filter((contractor) => contractor.data().isCompanyAdmin)
    if (contractorIsAdmin.length === 0) {
      return {
        result: 'error',
        message: 'You are not authorized to create a permit to work because you are not company admin in any of your companies.'
      }
    } else {
      const authorizedCompanies = []
      for (const company of companies) {
        const companySnapshot = await firebase.firestore()
          .collection('terminals')
          .doc(selectedTerminal)
          .collection('modules')
          .doc('permitToWork')
          .collection('companies')
          .where('uid', '==', company.uid)
          .get()

        if (!companySnapshot.empty) {
          const companyData = companySnapshot.docs[0].data()
          if (companyData.isAuthorized) {
            authorizedCompanies.push(companyData.name)
          }
        }
      }
      if (authorizedCompanies.length === 0) {
        return {
          result: 'error',
          message: 'None of your companies is authorized to create a permit to work.'
        }
      } else {
        return {
          result: 'success',
          message: 'You are authorized to create a permit to work.'
        }
      }
    }
  }
}
