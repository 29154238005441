import { View, ViewStyle, Text, StyleProp } from 'react-native'
import { countries } from '@common/constants'

interface Props {
  style?: StyleProp<ViewStyle>
  size?: number
  code: string
}

export const Flag = ({ style, size = 16, code }: Props): JSX.Element => {
  return (
    <View style={style}>
      <Text style={{ fontSize: size }}>{countries.find((country) => country.code === code)?.emoji}</Text>
    </View>
  )
}
