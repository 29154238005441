// External
import { createStackNavigator } from '@react-navigation/stack'
// Models
import { NewPermitStackParamList } from '@permitToWork/models'
// Views
import StepPersonInCharge from '@permitToWork/views/NewPermit/StepPersonInCharge'
import StepInformation from '@permitToWork/views/NewPermit/StepInformation'
import StepLocations from '@permitToWork/views/NewPermit/StepLocations'
import StepArrangements from '@permitToWork/views/NewPermit/StepArrangements'
import StepRequirements from '@modules/permitToWork/views/NewPermit/StepRequirements'
import StepHighRisks from '@permitToWork/views/NewPermit/StepHighRisks'
import StepShare from '@permitToWork/views/NewPermit/StepShare'
import StepAttachments from '@permitToWork/views/NewPermit/StepAttachments'

const Stack = createStackNavigator<NewPermitStackParamList>()

const NewPermitStack = (): JSX.Element => {
  return (
    <Stack.Navigator screenOptions={{ headerShown: false }}>
      <Stack.Screen name='StepPersonInCharge' component={StepPersonInCharge} />

      <Stack.Screen name='StepInformation' component={StepInformation} />

      <Stack.Screen name='StepLocations' component={StepLocations} />

      <Stack.Screen name='StepShare' component={StepShare} />

      <Stack.Screen name='StepArrangements' component={StepArrangements} />

      <Stack.Screen
        name='StepPPERequirements'
        component={StepRequirements}
      />

      <Stack.Screen name='StepHighRisks' component={StepHighRisks} />

      <Stack.Screen name='StepAttachments' component={StepAttachments} />
    </Stack.Navigator>
  )
}
export default NewPermitStack
