// External
import { StyleSheet, View } from 'react-native'
import { Avatar } from 'react-native-paper'
// Components
import { P } from '@common/components/Typography'
// Constants
import { colors } from '@common/constants'
// Responsive
// i18next
import { useTranslation } from 'react-i18next'
// Models
import { PermitToWork } from '@permitToWork/models'

interface Props {
  item: PermitToWork
  user: 'creator' | 'personInCharge'
}

export const DetailUser = ({ item, user }: Props): JSX.Element => {
  const { t } = useTranslation()

  const data = user === 'creator' ? item.creator : item.personInCharge
  const text = user === 'creator' ? t('permitToWorkItem.createdBy') : t('common.personInCharge')

  return (
    <View>
      <View style={styles.dividerContainer}>
        <P color='accent' bold style={styles.sectionTitle}>
          {text}
        </P>

        <View style={styles.divider} />
      </View>

      <View style={styles.avatarContainer}>
        {data.profilePhoto !== undefined
          ? (
            <Avatar.Image
              size={36}
              style={{
                backgroundColor: colors.placeholder
              }}
              source={{ uri: data.profilePhoto }}
            />
            )
          : (
            <Avatar.Text
              size={36}
              color='white'
              style={{
                backgroundColor: colors.placeholder
              }}
              label={data?.name?.charAt(0) + data?.surname?.charAt(0)}
            />
            )}

        <View>
          <P bold color='textLight' style={styles.userName}>
            {data?.name} {data?.surname}
          </P>

          <P color='textLight' style={styles.sectionContent}>
            {data?.email}
          </P>
        </View>
      </View>
    </View>

  )
}

const styles = StyleSheet.create({
  divider: {
    height: 0.5,
    backgroundColor: '#DADADA',
    flex: 1,
    marginBottom: 6,
    marginLeft: 18,
    marginRight: 32
  },
  sectionTitle: {
    fontSize: 11,
    textTransform: 'uppercase',
    letterSpacing: 1
  },
  avatarContainer: {
    marginTop: 8,
    marginLeft: 8,
    flexDirection: 'row'
  },
  userName: {
    fontSize: 12,
    marginLeft: 8,
    lineHeight: 14
  },
  sectionContent: {
    fontSize: 11,
    marginLeft: 8,
    lineHeight: 14
  },
  dividerContainer: {
    flexDirection: 'row',
    alignItems: 'flex-end'
  }
})
