// Socket.io
import io from 'socket.io-client'

const socketEndpoint = 'https://visitor-hsse-tracking.herokuapp.com'
// const socketEndpoint = 'http://localhost:3000'

const socket = io(socketEndpoint, {
  transports: ['websocket']
})

socket.io.on('open', () => console.log('Socket.io connected'))
socket.io.on('close', () => console.log('Socket.io closed'))

export const SocketServices = {
  connect: () => {
    if (!socket.connected) {
      socket.io.connect()
    }
  },
  emit: (title, message) => {
    if (!socket.connected) {
      socket.io.connect()
    }
    socket.emit(title, JSON.stringify(message))
  }
}
