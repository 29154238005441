// Components
import { H3 } from '@components/Typography/H3'
// Paper
import { useTheme } from 'react-native-paper'
// Icons
import { MaterialCommunityIcons } from '@expo/vector-icons'
// RN
import { View } from 'react-native'

export const DrawerButton = ({
  isSelected,
  label,
  icon,
  navigation,
  onPressOverride,
  screen
}) => {
  const { colors } = useTheme()
  return (
    <View style={{ marginHorizontal: '10%', marginVertical: 1 }}>
      <MaterialCommunityIcons.Button
        name={icon}
        onPress={() => {
          if (onPressOverride) {
            onPressOverride()
            return
          }

          navigation.navigate(screen)
        }}
        backgroundColor='transparent'
        size={28}
        underlayColor='transparent'
        color={colors.primary}
        iconStyle={{ marginRight: 24 }}
      >
        <H3 bold={isSelected} style={{ letterSpacing: 0 }}>
          {label}
        </H3>
      </MaterialCommunityIcons.Button>
    </View>
  )
}
