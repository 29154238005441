import { useState, useEffect } from 'react'
import AsyncStorage from '@react-native-async-storage/async-storage'

export const useSelectedTerminal = () => {
  const [selectedTerminal, setSelectedTerminal] = useState()

  useEffect(() => {
    const getItem = async () => {
      const value = await AsyncStorage.getItem('selectedTerminal')
      if (value !== undefined && value !== null) {
        setSelectedTerminal(value)
      }
    }
    getItem()
  }, [])

  const setValue = async value => {
    await AsyncStorage.setItem('selectedTerminal', value)
    setSelectedTerminal(value)
  }

  return [selectedTerminal, setValue]
}
