import { object, string, boolean, number, InferType } from 'yup'

export const CompanySchema =
  object({
    uid: string().required(),
    isAuthorized: boolean().required(),
    name: string().required(),
    totalContractors: number().required()
  })

export type Company = InferType<typeof CompanySchema>
