import React, { useContext, useRef } from 'react'
// Context
import AppContext from '@context/AppContext'
import UserContext from '@context/UserContext'
// Services
import { VisitServices } from '@services/VisitServices'
// Components
import { Button } from '@components/Button/Button'
import { FormikInput } from '@components/Formik/FormikInput'
import { Container } from '@components/Layout/Container'
import { KeyboardFix } from '@components/Layout/KeyboardFix'
import { SafeArea } from '@components/Layout/SafeArea'
import { H2 } from '@components/Typography/H2'
// RN
import { View } from 'react-native'
// Formik
import { Formik } from 'formik'
import * as Yup from 'yup'
// Toast
import { useToast } from 'react-native-toast-notifications'

export const VisitsScreen = ({ navigation }) => {
  const toast = useToast()
  const userContext = useContext(UserContext)
  const appContext = useContext(AppContext)
  const { userAuth, userInfo } = userContext
  const { selectedTerminal } = appContext

  const INPUTS = [
    {
      name: 'contactDepartment',
      type: 'dropdown',
      dropdownValues: [
        {
          label: 'Administration',
          value: 'administration'
        },
        {
          label: 'Direction',
          value: 'direction'
        },
        {
          label: 'Operations',
          value: 'operations'
        },
        {
          label: 'Maintenance & Repair',
          value: 'maintenance'
        },
        {
          label: 'Other',
          value: 'other'
        }
      ],
      validationSchema: Yup.string().required('Contact department required'),
      ref: useRef(),
      props: {
        label: 'Contact Department',
        returnKeyType: 'next',
        autoCapitalize: 'none',
        autoCorrect: false,
        dense: true,
        mode: 'outlined',
        blurOnSubmit: true
      }
    },
    {
      name: 'visitReason',
      type: 'dropdown',
      dropdownValues: [
        {
          label: 'Visit',
          value: 'visit'
        },
        {
          label: 'Reception/Delivery',
          value: 'receptionDelivery'
        },
        {
          label: 'External Works',
          value: 'externalWorks'
        },
        {
          label: 'Ship Operations',
          value: 'shipOperations'
        },
        {
          label: 'Other',
          value: 'other'
        }
      ],
      validationSchema: Yup.string().required('Visit reason required'),
      ref: useRef(),
      props: {
        label: 'Visit Reason',
        returnKeyType: 'next',
        autoCapitalize: 'none',
        autoCorrect: false,
        dense: true,
        mode: 'outlined',
        blurOnSubmit: true
      }
    },
    {
      name: 'visitDate',
      type: 'datePicker',
      validationSchema: Yup.string().required('Date is a required field.'),
      ref: useRef(),
      props: {
        label: 'Visit Date',
        returnKeyType: 'next',
        autoCapitalize: 'words',
        autoCorrect: false,
        dense: true,
        mode: 'outlined',
        blurOnSubmit: true
      }
    }
  ]

  return (
    <SafeArea>
      <KeyboardFix>
        <Formik
          enableReinitialize
          validateOnMount
          initialValues={INPUTS.reduce(
            (acc, key) => ({ ...acc, [key.name]: key.initialValue }),
            {}
          )}
          validationSchema={Yup.object().shape(
            INPUTS.reduce(
              (acc, key) => ({ ...acc, [key.name]: key.validationSchema }),
              {}
            )
          )}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            setSubmitting(true)
            const { contactDepartment, visitDate, visitReason } = values
            VisitServices.createVisit({
              selectedTerminal,
              userType: userInfo.userType,
              email: userAuth.email,
              contactDepartment,
              visitReason,
              visitDate,
              userInfo
            }).then(() => {
              setSubmitting(false)
              toast.show('Visit registered successfully.')
              resetForm()
            }).catch(error => {
              setSubmitting(false)
              resetForm()
              console.log(error)
              toast.show(error.message)
            })
          }}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            isValid,
            isSubmitting,
            touched,
            errors,
            values,
            dirty,
            setFieldValue,
            setFieldTouched
          }) => (
            <Container
              style={{
                flex: 1
              }}
            >
              <View
                style={{
                  flex: 1,
                  justifyContent: 'center',
                  marginTop: -18
                }}
              >
                <H2 regular style={{ alignSelf: 'center', paddingVertical: 24 }}>Register your next visit</H2>
                {INPUTS.map((input, index) => (
                  <React.Fragment key={input.name}>
                    <FormikInput
                      input={input}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      inputs={INPUTS}
                      index={index}
                      handleSubmit={handleSubmit}
                      errors={errors}
                      touched={touched}
                      value={values[input.name]}
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched}
                    />
                  </React.Fragment>
                ))}
              </View>
              <Button
                loading={isSubmitting}
                mode='contained'
                style={{ marginVertical: 34 }}
                disabled={!isValid || isSubmitting || !dirty}
                onPress={handleSubmit}
              >
                Register visit
              </Button>
            </Container>
          )}
        </Formik>
      </KeyboardFix>
    </SafeArea>
  )
}
