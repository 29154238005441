// External
import { Platform, StyleSheet, View, ViewProps, useWindowDimensions } from 'react-native'

interface Props extends ViewProps {
  home?: boolean
}

export const Container = ({ style, home = false, ...props }: Props): JSX.Element => {
  const dimensions = useWindowDimensions()
  const isLargeScreen = Platform.OS === 'web' && dimensions.width >= 768
  const styles = StyleSheet.create({
    container: {
      paddingHorizontal: isLargeScreen ? '25%' : '15%',
      width: '100%'
    },
    homeContainer: {
      paddingHorizontal: '5%',
      paddingVertical: '6%',
      width: '100%'
    }
  })
  const viewStyle = home ? styles.homeContainer : styles.container

  return (
    <View style={[viewStyle, style]}>
      {props.children}
    </View>
  )
}
