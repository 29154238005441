// External
import { useContext, useEffect, useState, useCallback, useRef } from 'react'
import { StyleSheet, View, TextInput as RNTextInput } from 'react-native'
import { Formik } from 'formik'
import { object, date, string, InferType } from 'yup'
import { ActivityIndicator } from 'react-native-paper'
import moment from 'moment'
import { useToast } from 'react-native-toast-notifications'
import { useTranslation } from 'react-i18next'
import { StackScreenProps } from '@react-navigation/stack'
// Components
import { Button } from '@common/components/Button'
import { CalendarInput, DropdownInput, PhoneNumberInput } from '@modules/common/components/Input'
// Constants
import { TIME_VALUES } from '@permitToWork/constants'
import { phoneNumberRegEx } from '@modules/common/constants'
// Context
import AppContext from '@context/AppContext'
// Hooks
import { useCustomDrawerOptions } from '@hooks/useCustomDrawerOptions'
// Layout
import { Container, KeyboardFix, SafeArea, StepFeatureCompact } from '@common/layout'
// Models
import { NewPermitStackParamList } from '@modules/permitToWork/models'
// Services
import { getStepInformationData } from '@permitToWork/services'
import { updateUser } from '@common/services'

const FormSchema = object().shape({
  startDate: date().required('Start date is required'),
  startTime: string().required('Start time is required'),
  endDate: date().required('End date is required'),
  endTime: string().required('End time is required'),
  personInChargePhonePrefix: string().required(),
  personInChargePhoneNumber: string().matches(phoneNumberRegEx, 'Invalid number').required('Number required'),
  emergencyContactPhonePrefix: string().required(),
  emergencyContactPhoneNumber: string().matches(phoneNumberRegEx, 'Invalid number').required('Number required')
})

type FormValues = InferType<typeof FormSchema>

type Props = StackScreenProps<NewPermitStackParamList, 'StepInformation'>

const StepInformation = ({ navigation, route }: Props): JSX.Element => {
  const toast = useToast()
  const [stepInformationData, setStepInformationData] = useState<{
    locations: string[]
  }>()
  const { selectedTerminal } = useContext(AppContext)
  const { t } = useTranslation()

  const personInChargePhoneInputRef = useRef<RNTextInput>(null)
  const emergencyContactInputRef = useRef<RNTextInput>(null)

  const initialValues = {
    startDate: new Date(),
    startTime: '0800AM',
    endDate: new Date(),
    endTime: '0500PM',
    personInChargePhonePrefix: route.params.personInCharge.phonePrefix ?? '+1',
    personInChargePhoneNumber: route.params.personInCharge.phoneNumber ?? '',
    emergencyContactPhonePrefix: '+1',
    emergencyContactPhoneNumber: '',
    description: ''
  }

  const onSubmit = useCallback(async (values: FormValues) => {
    try {
      const { personInCharge } = route.params
      const { startTime, startDate, endTime, endDate } = values

      const starDateTime = moment(startTime, 'HHmmA')
      starDateTime.date(moment(startDate).date())
      starDateTime.month(moment(startDate).month())
      starDateTime.year(moment(startDate).year())

      const endDateTime = moment(endTime, 'HHmmA')
      endDateTime.date(moment(endDate).date())
      endDateTime.month(moment(endDate).month())
      endDateTime.year(moment(endDate).year())

      if (endDateTime.isBefore(starDateTime)) {
        toast.show('Start date must be before end date')
        return
      }

      if (
        values.personInChargePhonePrefix !== personInCharge.phonePrefix ||
        values.personInChargePhoneNumber !== personInCharge.phoneNumber
      ) {
        await updateUser({
          email: personInCharge.email,
          data: {
            phonePrefix: values.personInChargePhonePrefix,
            phoneNumber: values.personInChargePhoneNumber
          }
        })

        personInCharge.phonePrefix = values.personInChargePhonePrefix
        personInCharge.phoneNumber = values.personInChargePhoneNumber
      }

      navigation.navigate('StepLocations', {
        ...route.params,
        startDate: starDateTime.toDate(),
        endDate: endDateTime.toDate(),
        emergencyContactPhonePrefix: values.emergencyContactPhonePrefix,
        emergencyContactPhoneNumber: values.emergencyContactPhoneNumber
      })
    } catch (error: any) {
      toast.show(error.message)
    }
  }, [])

  useCustomDrawerOptions({
    options: {
      goBack: () => navigation.goBack()
    }
  })

  useEffect(() => {
    void getStepInformationData({ selectedTerminal })
      .then((res) => {
        setStepInformationData(res)
      })
      .catch((error) => {
        toast.show(error.message)
        navigation.goBack()
      })
  }, [selectedTerminal])

  return (
    <SafeArea>
      <KeyboardFix>
        <StepFeatureCompact
          title={t('common.newPermit')}
          subtitle={`${t('signup.step') as string} 2 ${t('signup.of') as string} 8`}
          selectedStep={2}
          totalSteps={8}
        />

        <Formik<FormValues>
          validateOnChange={false}
          initialValues={initialValues}
          validationSchema={FormSchema}
          onSubmit={onSubmit}
        >
          {({
            handleSubmit,
            handleBlur,
            isValid,
            isSubmitting,
            touched,
            errors,
            values,
            setFieldValue,
            setFieldTouched
          }) => (

            <Container
              home
              style={{
                flex: 1
              }}
            >
              {(stepInformationData !== undefined)
                ? (
                  <>
                    <View style={styles.datePickerContainer}>
                      <CalendarInput
                        style={{ flex: 1 }}
                        name='startDate'
                        value={values.startDate}
                        label={t('permitToWorkStepInformation.startDate')}
                        touched={touched}
                        errors={errors}
                        onDayPress={(day) => {
                          void setFieldValue('startDate', new Date(day.timestamp))
                        }}
                      />

                      <DropdownInput
                        style={{ flex: 1 }}
                        name='startTime'
                        label={t('permitToWorkStepInformation.startTime')}
                        placeholder={t('permitToWorkStepInformation.startTime')}
                        value={values.startTime}
                        setValue={(value) => {
                          void setFieldValue('startTime', value)
                          void setFieldTouched('startTime', true)
                        }}
                        list={TIME_VALUES}
                        touched={touched}
                        errors={errors}
                      />
                    </View>

                    <View style={styles.datePickerContainer}>
                      <CalendarInput
                        style={{ flex: 1 }}
                        name='endDate'
                        value={values.endDate}
                        label={t('permitToWorkStepInformation.endDate')}
                        touched={touched}
                        errors={errors}
                        onDayPress={(day) => {
                          void setFieldValue('endDate', new Date(day.timestamp))
                        }}
                      />

                      <DropdownInput
                        style={{ flex: 1 }}
                        name='endTime'
                        label={t('permitToWorkStepInformation.endTime')}
                        placeholder={t('permitToWorkStepInformation.endTime')}
                        value={values.endTime}
                        setValue={(value) => {
                          void setFieldValue('endTime', value)
                        }}
                        list={TIME_VALUES}
                        touched={touched}
                        errors={errors}
                      />
                    </View>

                    <PhoneNumberInput
                      ref={personInChargePhoneInputRef}
                      label={t('permitToWorkStepInformation.personInChargePhoneNumber')}
                      prefixName='personInChargePhonePrefix'
                      numberName='personInChargePhoneNumber'
                      prefixValue={values.personInChargePhonePrefix}
                      numberValue={values.personInChargePhoneNumber}
                      onChangePrefix={(prefix) => {
                        void setFieldValue('personInChargePhonePrefix', prefix)
                      }}
                      onChangeNumber={(number) => {
                        void setFieldValue('personInChargePhoneNumber', number)
                      }}
                      onBlurNumber={handleBlur('personInChargePhoneNumber')}
                      touched={touched}
                      errors={errors}
                      onSubmitEditing={() => {
                        emergencyContactInputRef.current?.focus()
                      }}
                    />

                    <PhoneNumberInput
                      ref={emergencyContactInputRef}
                      label={t('permitToWorkStepInformation.emergencyContactPhoneNumber')}
                      prefixName='emergencyContactPhonePrefix'
                      numberName='emergencyContactPhoneNumber'
                      prefixValue={values.emergencyContactPhonePrefix}
                      numberValue={values.emergencyContactPhoneNumber}
                      onChangePrefix={(prefix) => {
                        void setFieldValue('emergencyContactPhonePrefix', prefix)
                      }}
                      onChangeNumber={(number) => {
                        void setFieldValue('emergencyContactPhoneNumber', number)
                      }}
                      onBlurNumber={handleBlur('emergencyContactPhoneNumber')}
                      touched={touched}
                      errors={errors}
                      returnKeyType='done'
                      onSubmitEditing={handleSubmit}
                    />

                    <Button disabled={!isValid || isSubmitting} loading={isSubmitting} onPress={handleSubmit}>
                      {t('common.continue')}
                    </Button>
                  </>
                  )
                : <ActivityIndicator />}
            </Container>
          )}
        </Formik>
      </KeyboardFix>
    </SafeArea>
  )
}

const styles = StyleSheet.create({
  datePickerContainer: {
    display: 'flex',
    height: 'auto',
    flexDirection: 'row',
    gap: 10,
    maxHeight: 60,
    marginBottom: 14
  },
  formikContainer: {
    flex: 1,
    justifyContent: 'center',
    marginTop: -18
  }
})

export default StepInformation
