// External
import { useState, useEffect } from 'react'
// Config
import firebase from '@config/firebase'
// Models
import { AdminUserSchema, AdminUser } from '@common/models'

interface Props {
  permitAuthorizer?: string
  permitIssuer?: string
}

const usePermitSignatures = ({ permitAuthorizer, permitIssuer }: Props): {
  permitAuthorizer: AdminUser | null
  permitIssuer: AdminUser | null
} => {
  const [users, setUsers] = useState<{
    permitAuthorizer: AdminUser | null
    permitIssuer: AdminUser | null
  }>({
    permitAuthorizer: null,
    permitIssuer: null
  })

  useEffect(() => {
    void (async () => {
      const users = await Promise.all(
        [permitAuthorizer, permitIssuer].map(async (userId) => {
          if (userId === undefined) {
            return null
          }

          const user = await firebase
            .firestore()
            .collection('adminUsers')
            .doc(userId)
            .get()

          const parsedAdminUser = AdminUserSchema.validateSync(user.data())
          return parsedAdminUser
        })
      )

      setUsers({
        permitAuthorizer: users[0],
        permitIssuer: users[1]
      })
    })()
  }, [])

  return users
}
export default usePermitSignatures
