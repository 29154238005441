export const TIME_VALUES = [
  { value: '1200AM', label: '12:00 AM' },
  { value: '1230AM', label: '12:30 AM' },
  { value: '0100AM', label: '01:00 AM' },
  { value: '0130AM', label: '01:30 AM' },
  { value: '0200AM', label: '02:00 AM' },
  { value: '0230AM', label: '02:30 AM' },
  { value: '0300AM', label: '03:00 AM' },
  { value: '0330AM', label: '03:30 AM' },
  { value: '0400AM', label: '04:00 AM' },
  { value: '0430AM', label: '04:30 AM' },
  { value: '0500AM', label: '05:00 AM' },
  { value: '0530AM', label: '05:30 AM' },
  { value: '0600AM', label: '06:00 AM' },
  { value: '0630AM', label: '06:30 AM' },
  { value: '0700AM', label: '07:00 AM' },
  { value: '0730AM', label: '07:30 AM' },
  { value: '0800AM', label: '08:00 AM' },
  { value: '0830AM', label: '08:30 AM' },
  { value: '0900AM', label: '09:00 AM' },
  { value: '0930AM', label: '09:30 AM' },
  { value: '1000AM', label: '10:00 AM' },
  { value: '1030AM', label: '10:30 AM' },
  { value: '1100AM', label: '11:00 AM' },
  { value: '1130AM', label: '11:30 AM' },
  { value: '1200PM', label: '12:00 PM' },
  { value: '1230PM', label: '12:30 PM' },
  { value: '0100PM', label: '01:00 PM' },
  { value: '0130PM', label: '01:30 PM' },
  { value: '0200PM', label: '02:00 PM' },
  { value: '0230PM', label: '02:30 PM' },
  { value: '0300PM', label: '03:00 PM' },
  { value: '0330PM', label: '03:30 PM' },
  { value: '0400PM', label: '04:00 PM' },
  { value: '0430PM', label: '04:30 PM' },
  { value: '0500PM', label: '05:00 PM' },
  { value: '0530PM', label: '05:30 PM' },
  { value: '0600PM', label: '06:00 PM' },
  { value: '0630PM', label: '06:30 PM' },
  { value: '0700PM', label: '07:00 PM' },
  { value: '0730PM', label: '07:30 PM' },
  { value: '0800PM', label: '08:00 PM' },
  { value: '0830PM', label: '08:30 PM' },
  { value: '0900PM', label: '09:00 PM' },
  { value: '0930PM', label: '09:30 PM' },
  { value: '1000PM', label: '10:00 PM' },
  { value: '1030PM', label: '10:30 PM' },
  { value: '1100PM', label: '11:00 PM' },
  { value: '1130PM', label: '11:30 PM' }
]
