// External
import { useEffect, useState, useRef } from 'react'
import { getFirestore, collection, query, where, orderBy, onSnapshot, or } from 'firebase/firestore'
// Config
import firebase from '@config/firebase'
// Models
import { PermitToWork, PermitToWorkSchema } from '@permitToWork/models'

interface Props {
  selectedTerminal: string
  userAuthEmail: string
}

const usePermitsToWork = ({ selectedTerminal, userAuthEmail }: Props): {
  permitsToWork: PermitToWork[]
  loading: boolean
  error: boolean
} => {
  const [permitsToWork, setPermitsToWork] = useState<PermitToWork[]>([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const snapshotRef = useRef<(() => void) | null>(null)

  useEffect(() => {
    const firestore = getFirestore(firebase)

    const permitsSubcollectionRef = collection(firestore, 'terminals', selectedTerminal, 'modules', 'permitToWork', 'data')

    const q = query(
      permitsSubcollectionRef,
      or(where('personInCharge.email', '==', userAuthEmail), where('creator.email', '==', userAuthEmail)),
      orderBy('requestDate', 'desc')
    )

    snapshotRef.current = onSnapshot(q, (items) => {
      if (items.docs.length > 0) {
        const allItems = items.docs
          .map(item => ({
            id: item.id,
            ...item.data()
          }))
        const validatedItems: PermitToWork[] = []

        for (const item of allItems) {
          try {
            const validatedItem = PermitToWorkSchema.validateSync(item)

            validatedItems.push(validatedItem as PermitToWork)
          } catch (error) {
            console.error(`Error parsing PTW #${item.id}:`, error)
          }
        }

        setPermitsToWork(validatedItems)
        setLoading(false)
        setError(false)
      } else {
        setPermitsToWork([])
        setLoading(false)
        setError(false)
      }
    },
    (error) => {
      console.error('Error on usePermitsToWork snapshot:', error)
      setPermitsToWork([])
      setLoading(false)
      setError(true)
    }
    )

    return () => {
      if (snapshotRef.current !== null) {
        snapshotRef.current()
      }
    }
  }, [selectedTerminal, userAuthEmail])

  return { permitsToWork, loading, error }
}
export default usePermitsToWork
