// RN
import { View, StyleSheet } from 'react-native'
// Components
import { H2 } from '@components/Typography/H2'
import { P } from '@components/Typography/P'
import { Caption } from '@components/Typography/Caption'
import { Button } from '@components/Button/Button'
// Navigation
import { useNavigation } from '@react-navigation/native'
// i18next
import { useTranslation } from 'react-i18next'
// Config
import { SUSPENSION_STATES } from '@config/suspensionStates'
import { PAYMENT_STATES } from '@config/paymentStates'

export const SuspensionItem = ({ suspensionInfo = {} }) => {
  const { t } = useTranslation()
  const navigation = useNavigation()

  return (
    <View
      style={{
        opacity: suspensionInfo.status === SUSPENSION_STATES.ACTIVE ? 1 : 0.3
      }}
    >
      <H2 regular>{suspensionInfo.title}</H2>
      <P style={{ marginTop: 6 }} light color='textLight'>
        {suspensionInfo.description}
      </P>
      <View style={{ flexDirection: 'row', marginTop: 24 }}>
        <P color='textLight'>{t('suspensionItem.suspensionDate')}</P>
        <P bold light style={{ marginLeft: 4 }} color='textLight'>
          {suspensionInfo.date.toDate().toLocaleString('en-US', {
            dateStyle: 'medium',
            timeStyle: 'short'
          })}
        </P>
      </View>
      <View style={{ flexDirection: 'row' }}>
        <P color='textLight'>{t('suspensionItem.returnDate')}</P>
        <P bold light style={{ marginLeft: 4 }} color='textLight'>
          {suspensionInfo.permanent
            ? 'Permanent Ban'
            : suspensionInfo.returnDate.toDate().toLocaleString('en-US', {
              dateStyle: 'medium',
              timeStyle: 'short'
            }
            )}
        </P>
      </View>
      {suspensionInfo.payment && (
        <>
          <View style={{ flexDirection: 'row', marginTop: 14 }}>
            <P color='textLight'>{t('suspensionItem.payment')}:</P>
            <P bold light style={{ marginLeft: 4 }} color='textLight'>
              {suspensionInfo.payment.amount}
              {suspensionInfo.payment.currency}
            </P>
          </View>
          <View style={{ flexDirection: 'row' }}>
            <P color='textLight'>{t('suspensionItem.paymentStatus')}</P>
            <P bold style={{ marginLeft: 4 }} color='textLight'>
              {t('paymentStatus.' + suspensionInfo.payment.status)}
            </P>
          </View>
        </>
      )}
      {suspensionInfo.appeal && (
        <View style={{ flexDirection: 'row', marginTop: 14 }}>
          <P color='textLight'>{t('suspensionItem.appealStatus')}:</P>
          <P bold light style={{ marginLeft: 4 }} color='textLight'>
            {t(`appealStatus.${suspensionInfo.appeal.status}`)}
          </P>
        </View>
      )}
      {suspensionInfo.status === SUSPENSION_STATES.ACTIVE && (
        <View style={{ flexDirection: 'row' }}>
          {!suspensionInfo.appeal && (
            <Button
              onPress={() =>
                navigation.navigate('AppealForm', {
                  suspensionId: suspensionInfo.id
                })}
              style={{ flex: 1, marginRight: 8 }}
            >
              {t('common.submitAppeal')}
            </Button>
          )}
          {suspensionInfo?.payment?.status === PAYMENT_STATES.PENDING && (
            <Button
              mode='contained'
              onPress={() => {
                navigation.navigate('Payment', {
                  uri: suspensionInfo.payment.url,
                  suspensionId: suspensionInfo.id
                })
              }}
              style={{ flex: 1, marginLeft: 8 }}
            >
              {t('suspensionItem.goToPayment')}
            </Button>
          )}
        </View>
      )}
      {suspensionInfo.status === SUSPENSION_STATES.ACTIVE
        ? (
          <View
            style={{
              flexDirection: 'column',
              marginTop: 14,
              backgroundColor: '#f7f7f7',
              borderRadius: 2,
              paddingHorizontal: 10,
              paddingVertical: 4
            }}
          >
            <P bold color='primary'>
              {t('suspensionItem.activeSuspension')}
            </P>
            <Caption color='accent' style={{ marginTop: -4 }}>
              {t('suspensionItem.accessDenied')}
            </Caption>
          </View>
          )
        : (
          <View
            style={{
              flexDirection: 'column',
              marginTop: 14,
              backgroundColor: '#f7f7f7',
              borderRadius: 2,
              paddingHorizontal: 10,
              paddingVertical: 4
            }}
          >
            <P bold color='textLight'>
              {t('suspensionItem.inactiveSuspension')}
            </P>
            <Caption color='textLight' style={{ marginTop: -4 }}>
              {t('suspensionItem.suspensionIsNotActive')}
            </Caption>
          </View>
          )}
      <View style={styles.divider} />
    </View>
  )
}

const styles = StyleSheet.create({
  divider: {
    width: '60%',
    marginTop: 14,
    marginBottom: 34,
    backgroundColor: '#F2F2F2',
    height: 1.5
  }
})
