// External
import { ReactNode } from 'react'
import { StyleProp, TextStyle } from 'react-native'
import { Subheading } from 'react-native-paper'
// Constants
import { colors } from '@common/constants'

interface Props {
  bold?: boolean
  regular?: boolean
  color?: keyof typeof colors
  style?: StyleProp<TextStyle>
  children: ReactNode
}

export const H2 = ({ bold = false, regular = false, color, style, children }: Props): JSX.Element => {
  return (
    <Subheading
      style={[{
        fontSize: 20,
        lineHeight: 24,
        color: color !== undefined ? colors[color] : colors.accent,
        fontFamily: bold
          ? 'Maersk-Bold'
          : regular
            ? 'Maersk-Regular'
            : 'Maersk-Light'
      }, style]}
    >
      {children}
    </Subheading>
  )
}
