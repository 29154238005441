import { useEffect } from 'react'
// RN
import { View, StyleSheet } from 'react-native'
// Components
import { H2 } from '@components/Typography/H2'
import { P } from '@components/Typography/P'
// Paper
import { Button } from 'react-native-paper'
// Expo
import * as Linking from 'expo-linking'
// Services
import { NotificationServices } from '@services/NotificationServices'
// Navigation
import { useNavigation } from '@react-navigation/native'
// i18next
import { useTranslation } from 'react-i18next'

export const NotificationItem = ({
  title,
  body,
  date,
  id,
  email,
  notificationData,
  read
}) => {
  const navigation = useNavigation()
  const { t } = useTranslation()

  useEffect(() => {
    const unsubscribe = navigation.addListener('focus', () => {
      if (id && email) {
        if (!read) {
          NotificationServices.addRead({
            notificationId: id,
            email
          })
        }
      }
    })

    return unsubscribe
  }, [navigation])

  return (
    <View style={{ width: '100%' }}>
      <H2 regular>{title}</H2>
      <P style={{ marginTop: 6 }} light color='textLight'>
        {body}
      </P>
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'baseline',
          justifyContent: 'space-between'
        }}
      >
        <P style={{ marginTop: 18 }} color='caption'>
          {new Date(date.toDate()).toLocaleString('en-US', {
            dateStyle: 'medium',
            timeStyle: 'short'
          })}
        </P>
        {notificationData?.action === 'tel' && (
          <Button
            icon='phone'
            mode='text'
            onPress={() => Linking.openURL(`tel:${notificationData?.data?.phone}`)}
          >
            {t('notificationItem.call')}
          </Button>
        )}
        {notificationData?.action === 'url' && (
          <Button
            icon='open-in-new'
            mode='text'
            onPress={() => Linking.openURL(notificationData?.data?.url)}
          >
            {t('notificationItem.openLink')}
          </Button>
        )}
        {notificationData?.action === 'navigate' && (
          <Button
            icon='arrow-right-bold'
            mode='text'
            onPress={() => {
              try {
                navigation.navigate(notificationData?.data?.route)
              } catch (error) {
                console.log(error)
              }
            }}
          >
            {t('notificationItem.go')}
          </Button>
        )}
      </View>
      <View style={styles.divider} />
    </View>
  )
}

const styles = StyleSheet.create({
  divider: {
    width: '60%',
    marginVertical: 14,
    backgroundColor: '#F2F2F2',
    height: 1.5
  }
})
