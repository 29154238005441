// External
import { useEffect } from 'react'
import { Platform } from 'react-native'
// Services
import { startLocationService } from '@services/LocationServices'
import { SocketServices } from '@services/SocketServices'

export const useLocationServices = ({
  user,
  selectedTerminal,
  deviceId,
  pushToken,
  showWelcomeScreen,
  isConnected,
  isInternetReachable
}) => {
  useEffect(() => {
    if (Platform.OS === 'web') {
      return
    }

    if (showWelcomeScreen === false) {
      SocketServices.connect()
      startLocationService({ user, selectedTerminal, deviceId, pushToken })
    }
  }, [
    user,
    selectedTerminal,
    deviceId,
    pushToken,
    showWelcomeScreen,
    isConnected,
    isInternetReachable
  ])
}
