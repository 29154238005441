// External
import { useContext, useEffect, useRef, useState } from 'react'
import { ScrollView, StyleSheet, View } from 'react-native'
import {
  Collapse,
  CollapseBody,
  CollapseHeader
  // @ts-expect-error - This package is written in pure JS, it doesn't have types
} from 'accordion-collapse-react-native'
import { useTranslation } from 'react-i18next'
import { useToast } from 'react-native-toast-notifications'
import { StackScreenProps } from '@react-navigation/stack'
// Components
import { Container } from '@components/Layout/Container'
import { SafeArea } from '@components/Layout/SafeArea'
import { PermitToWorkCard } from '@permitToWork/components'
import { P } from '@common/components/Typography'
import { Button } from '@common/components/Button'
// Constants
import { PTW_MAP_STATES } from '@permitToWork/constants'
// Context
import AppContext from '@context/AppContext'
import UserContext from '@context/UserContext'
// Hooks
import usePermitsToWork from '@permitToWork/hooks/usePermitsToWork'
import useInvitedPermitsToWork from '@permitToWork/hooks/useInvitedPermitsToWork'
// Icons
import { FontAwesome5 } from '@expo/vector-icons'
// Models
import { PermitToWorkStackParamList, PermitToWorkWithStates } from '@permitToWork/models'
// Services
import {
  checkUserAllowedToCreatePermit
} from '@permitToWork/services'
// Utils
import { getPermitToWorksWithStates } from '@permitToWork/utils'

type Props = StackScreenProps<PermitToWorkStackParamList, 'PermitToWorkDashboard'>

const PermitToWorkDashboard = ({ navigation }: Props): JSX.Element => {
  const [ptwWithAppStates, setPtwWithAppStates] = useState<PermitToWorkWithStates[]>([])
  const [expandedCollapse, setExpandedCollapse] = useState<Record<string, boolean>>(
    Object.keys(PTW_MAP_STATES).reduce((acc, state) => {
      return { ...acc, [state]: true }
    }, {})
  )
  const interval = useRef<NodeJS.Timeout>()
  const userContext = useContext(UserContext)
  const appContext = useContext(AppContext)
  const { selectedTerminal } = appContext

  const toast = useToast()
  const { t } = useTranslation()

  const { permitsToWork, loading, error } = usePermitsToWork({
    selectedTerminal,
    userAuthEmail: userContext.userAuth.email
  })
  const { invitedPermitsToWork, loadingInvited, errorInvited } =
  useInvitedPermitsToWork({
    selectedTerminal,
    userAuthEmail: userContext.userAuth.email
  })

  useEffect(() => {
    setPtwWithAppStates(() =>
      getPermitToWorksWithStates({
        permits: permitsToWork.concat(invitedPermitsToWork)
      })
    )

    interval.current = setInterval(() => {
      setPtwWithAppStates(() =>
        getPermitToWorksWithStates({
          permits: permitsToWork.concat(invitedPermitsToWork)
        })
      )
    }, 10000)

    return () => {
      clearInterval(interval.current)
    }
  }, [permitsToWork, invitedPermitsToWork])

  const checkPermissions = async (): Promise<void> => {
    const checkResult = await checkUserAllowedToCreatePermit({
      selectedTerminal,
      email: userContext.userAuth.email
    })
    if (checkResult.result === 'error') {
      toast.show(checkResult.message)
    } else {
      navigation.navigate('NewPermitStack', { screen: 'StepPersonInCharge' })
    }
  }

  return (
    <SafeArea>
      <ScrollView
        style={{ flex: 1 }}
        contentContainerStyle={{ flexGrow: 1 }}
      >
        <Container home style={styles.container}>
          <View style={{ flex: 1, width: '100%' }}>
            {(loading || loadingInvited) && (
              <P color='textLight'>{t('common.loading')}...</P>
            )}

            {(error || errorInvited) && (
              <P color='textLight'>{t('permitToWorkScreen.error')}</P>
            )}

            {!error &&
              !errorInvited &&
              !loading &&
              !loadingInvited &&
              ptwWithAppStates.length === 0 && (
                <P style={{ textAlign: 'center' }} color='textLight'>
                  {t('permitToWorkScreen.empty')}
                </P>
            )}

            {!error &&
              !errorInvited &&
              !loading &&
              !loadingInvited &&
              ptwWithAppStates.length > 0 && (
                <>
                  {Object.keys(PTW_MAP_STATES).map(state => (
                    <Collapse
                      touchableOpacityProps={{ activeOpacity: 0.5 }}
                      isExpanded={expandedCollapse[state]}
                      onToggle={(isExpanded: boolean) => {
                        setExpandedCollapse({
                          ...expandedCollapse,
                          [state]: isExpanded
                        })
                      }}
                      style={styles.groupContainer}
                      key={state}
                    >
                      <CollapseHeader>
                        <View style={styles.groupText}>
                          <P color='textLight'>
                            {t('permitToWorkStates.' + state)}
                          </P>

                          <P color='placeholder' style={{ marginLeft: 4 }}>
                            (
                            {
                              ptwWithAppStates.filter((ptw) =>
                                PTW_MAP_STATES[state as keyof typeof PTW_MAP_STATES].includes(ptw.appState)
                              ).length
                            }
                            )
                          </P>

                          <View style={styles.divider} />

                          <FontAwesome5
                            name={
                              expandedCollapse[state]
                                ? 'caret-up'
                                : 'caret-down'
                            }
                            size={14}
                            color='#ccc'
                          />
                        </View>
                      </CollapseHeader>

                      <CollapseBody>
                        {ptwWithAppStates
                          .filter((ptw) =>
                            PTW_MAP_STATES[state as keyof typeof PTW_MAP_STATES].includes(ptw.appState)
                          )
                          .map(ptw => (
                            <PermitToWorkCard
                              key={ptw.id}
                              item={ptw}
                            />
                          ))}
                      </CollapseBody>
                    </Collapse>
                  ))}
                </>
            )}
          </View>
        </Container>
      </ScrollView>

      <View style={styles.footerContainer}>
        <Button
          mode='outlined'
          style={{ marginVertical: 34 }}
          onPress={() => {
            void checkPermissions()
          }}
        >
          {t('permitToWorkScreen.newPtw')}
        </Button>
      </View>
    </SafeArea>
  )
}
export default PermitToWorkDashboard

const styles = StyleSheet.create({
  footerContainer: {
    height: 120,
    marginHorizontal: 20
  },
  groupContainer: {
    marginBottom: 12,
    width: '100%'
  },
  container: {
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center'
  },
  groupText: {
    flexDirection: 'row',
    alignItems: 'flex-end',
    marginBottom: 12
  },
  divider: {
    marginHorizontal: 8,
    marginBottom: 4,
    backgroundColor: '#eee',
    flex: 1,
    height: 1
  }
})
