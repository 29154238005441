import { object, string, InferType } from 'yup'

export const AdminUserSchema = object({
  name: string().required(),
  surname: string().required(),
  role: string().required(),
  terminal: string().required(),
  profilePhoto: string().optional()
})

export type AdminUser = InferType<typeof AdminUserSchema>

export const AdminUserWithEmailSchema = AdminUserSchema.shape({
  email: string().email().required()
})

export type AdminUserWithEmail = InferType<typeof AdminUserWithEmailSchema>
