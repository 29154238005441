import * as React from 'react'
import Svg, { Path } from 'react-native-svg'

function SvgComponent (props) {
  return (
    <Svg
      width={18}
      height={18}
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <Path
        d='M9 0a9 9 0 110 18A9 9 0 019 0zm1.979 10H7.022a25.77 25.77 0 00.941 5.822l-.041-.158c.72.116 1.453.115 2.173-.002l-.055-.018c.539-1.836.854-3.731.939-5.643V10zm4.697 0H12.98a25.56 25.56 0 01-.672 4.886A6.754 6.754 0 0015.676 10zM5.02 10H2.324a6.755 6.755 0 003.34 4.87c-.362-1.6-.577-3.23-.644-4.87zm.65-6.873l-.14.082a6.754 6.754 0 00-3.207 4.792h2.695c.061-1.638.279-3.263.652-4.874zm6.652-.004l.094.423c.317 1.491.505 2.976.564 4.455h2.697a6.755 6.755 0 00-3.355-4.878zM9 2.25a6.8 6.8 0 00-1.058.082A23.983 23.983 0 007.02 8h3.958a25.027 25.027 0 00-.94-5.742l.02.074A6.8 6.8 0 009 2.25z'
        fill='#FF6319'
      />
    </Svg>
  )
}

export default SvgComponent
