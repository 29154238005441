import React from 'react'
// RN
import { Dimensions, Image, Platform, StyleSheet, View } from 'react-native'
// Components
import { InductionIndicator } from '@components/Drawer/InductionIndicator'
import { H2 } from '@components/Typography/H2'
import { P } from '@components/Typography/P'

const windowWidth = Dimensions.get('window').width
const RATIO = 1500 / 1153

export const ProfileHero = React.memo(
  ({
    fullName,
    userTypeLabel,
    email,
    loginWithName,
    loginWithValue
  }) => {
    return (
      <View style={styles.container}>
        <Image
          defaultSource={require('@assets/images/stripes.png')}
          source={require('@assets/images/stripes.png')}
          style={styles.bgImage}
        />
        <View style={styles.titleContainer}>
          <H2 color='text' bold>
            {fullName}
          </H2>
          <P color='primary'>{userTypeLabel}</P>
          <P color='textLigh'>{email}</P>
          <P color='caption'>
            {loginWithName}: {loginWithValue}
          </P>
        </View>
        <InductionIndicator />
        <View style={styles.divider} />
      </View>
    )
  }
)

const styles = StyleSheet.create({
  container: {
    width: '100%',
    minHeight: Platform.OS !== 'web' ? windowWidth / RATIO : 500,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center'
  },
  bgImage: {
    opacity: 1,
    position: 'absolute',
    alignSelf: 'center',
    zIndex: -1,
    width: '100%',
    height: Platform.OS !== 'web' ? windowWidth / RATIO : 300
  },
  titleContainer: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center'
  },
  divider: {
    width: '60%',
    marginVertical: 6,
    backgroundColor: '#F2F2F2',
    height: 1.5
  }
})
