import { InferType, object, date, string, array } from 'yup'

export const CreateVisitFormSchema = object().shape({
  date: date().required('Start date is required'),
  checkIn: string().required('Check in time is required'),
  checkOut: string().required('Check out time is required'),
  host: string().required('Host is required'),
  questions: array().of(object().shape({
    question: string().required('Question is required'),
    options: array().of(string().required('Option is required')),
    answer: string().required('Answer is required')
  })).required('Questions are required')
})

export type CreateVisitFormValues = InferType<typeof CreateVisitFormSchema>
