// External
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import { PropsWithChildren } from 'react'

interface Props extends PropsWithChildren {
  keyboardShouldPersistTaps?: boolean | 'always' | 'never' | 'handled'
}

export const KeyboardFix = ({ children, keyboardShouldPersistTaps }: Props): JSX.Element => {
  return (
    <KeyboardAwareScrollView
      enableOnAndroid
      extraHeight={120}
      style={{ flex: 1 }}
      contentContainerStyle={{ flexGrow: 1 }}
      keyboardShouldPersistTaps={keyboardShouldPersistTaps}
    >
      {children}
    </KeyboardAwareScrollView>
  )
}
