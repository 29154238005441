// External
import { FormikTouched, FormikErrors } from 'formik'
import { ReturnKeyType, View, NativeSyntheticEvent, TextInputFocusEventData, TextInput as RNTextInput } from 'react-native'
import { TextInput, HelperText } from 'react-native-paper'
import { forwardRef, ForwardedRef } from 'react'
// Components
import { DropdownInput } from './DropdownInput'
import { P } from '@common/components/Typography'
// Constants
import { COUNTRY_PREFIXES } from '@common/constants'

interface Props<T> {
  label: string
  prefixName: keyof T
  numberName: keyof T
  prefixValue: string
  numberValue: string
  onChangePrefix: (prefix: string) => void
  onChangeNumber: (number: string) => void
  onBlurNumber: (((e: NativeSyntheticEvent<TextInputFocusEventData>) => void) & ((args: any) => void)) | undefined
  returnKeyType?: ReturnKeyType
  onSubmitEditing?: () => void
  touched?: FormikTouched<T>
  errors?: FormikErrors<T>
}

const PhoneNumberInputInner = <T,>({
  label,
  prefixName,
  numberName,
  prefixValue,
  numberValue,
  onChangePrefix,
  onChangeNumber,
  onBlurNumber,
  returnKeyType = 'next',
  onSubmitEditing,
  touched,
  errors
}: Props<T>, ref: ForwardedRef<RNTextInput>): JSX.Element => {
  return (
    <View>
      <P color='placeholder' style={{ marginBottom: 4 }}>{label}</P>

      <View style={{ flexDirection: 'row', gap: 10 }}>
        <DropdownInput style={{ width: '50%' }} name={prefixName} list={COUNTRY_PREFIXES} value={prefixValue} setValue={onChangePrefix} />

        <View style={{ flex: 1 }}>
          <TextInput
            dense
            style={{ flex: 1, height: 40 }}
            ref={ref}
            mode='outlined'
            value={numberValue}
            onChange={(e) => onChangeNumber(e.nativeEvent.text)}
            onBlur={onBlurNumber}
            keyboardType='number-pad'
            returnKeyType={returnKeyType}
            onSubmitEditing={onSubmitEditing}
          />

          <HelperText
            style={{ marginBottom: 4 }}
            type='error'
            visible={Boolean(touched?.[numberName])}
          >
            {errors?.[numberName] !== undefined ? String(errors[numberName]) : ''}
          </HelperText>
        </View>
      </View>
    </View>
  )
}

export const PhoneNumberInput = forwardRef(PhoneNumberInputInner)
