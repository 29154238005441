// Firebase
import firebase from '@config/firebase'

export const VisitServices = {
  createVisit: ({
    selectedTerminal,
    userType,
    email,
    contactDepartment,
    visitReason,
    visitDate,
    userInfo
  }) => {
    return firebase
      .firestore()
      .collection('terminals')
      .doc(selectedTerminal)
      .collection('visits')
      .add({
        email,
        contactDepartment,
        visitReason,
        visitDate,
        user: userInfo,
        userType
      })
  }
}
