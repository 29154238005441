import { useContext, useMemo } from 'react'
// Context
import UserContext from '@context/UserContext'
// RN
import { View, Image, TouchableOpacity, StyleSheet } from 'react-native'
// Components
import { P } from '@components/Typography/P'
// Paper
import { useTheme } from 'react-native-paper'
// Navigation
import { useNavigation } from '@react-navigation/native'

export const NotificationBell = () => {
  const navigation = useNavigation()
  const { colors } = useTheme()
  const userContext = useContext(UserContext)
  const { notifications } = userContext
  const memoNotificationsLength = useMemo(() => {
    return userContext.notifications?.filter(notification => !notification?.read)
      .length
  }, [notifications])

  const styles = StyleSheet.create({
    badge: {
      backgroundColor: colors.accent,
      width: 18,
      height: 18,
      borderRadius: 9,
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      top: -9,
      left: -9,
      borderWidth: 1,
      borderColor: colors.placeholder
    }
  })

  return (
    <TouchableOpacity
      onPress={() => navigation.navigate('Notifications')}
      style={{ marginRight: 18 }}
    >
      <Image
        source={require('assets/images/bell.png')}
        style={{
          width: 20,
          height: 20,
          resizeMode: 'contain'
        }}
      />
      {memoNotificationsLength > 0 && (
        <View style={styles.badge}>
          <P bold style={{ fontSize: 9, marginTop: -2, color: 'white' }} color='placeholder'>
            {memoNotificationsLength}
          </P>
        </View>
      )}
    </TouchableOpacity>
  )
}
