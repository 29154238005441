import React from 'react'
// Navigation
import { createStackNavigator } from '@react-navigation/stack'
// Steps
import { Step1 } from '@views/Auth/Signup/Step1'
import { Step2 } from '@views/Auth/Signup/Step2'
import { Step3 } from '@views/Auth/Signup/Step3'

const Stack = createStackNavigator()

export const SignupScreen = () => {
  return (
    <Stack.Navigator screenOptions={{ headerShown: false }}>
      <Stack.Screen name='Step1' component={Step1} />
      <Stack.Screen name='Step2' component={Step2} />
      <Stack.Screen name='Step3' component={Step3} />
    </Stack.Navigator>
  )
}
