import * as React from 'react'
import Svg, { Path } from 'react-native-svg'

function SvgComponent (props) {
  return (
    <Svg
      width={14}
      height={18}
      viewBox='0 0 14 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <Path
        d='M7 16.389c4.148-3.593 6.222-6.594 6.222-9.006 0-3.617-2.786-6.55-6.222-6.55C3.564.833.778 3.766.778 7.383c0 2.411 2.074 5.413 6.222 9.006z'
        fill='#FF6319'
        fillOpacity={0.5}
        stroke='#FF6319'
        strokeWidth={1.556}
      />
    </Svg>
  )
}

export default SvgComponent
