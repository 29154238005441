// External
import { Linking, TouchableOpacity, View } from 'react-native'
import { AntDesign } from '@expo/vector-icons'
import { useToast } from 'react-native-toast-notifications'
// Components
import { Caption } from '@common/components/Typography'
// Constants
import { colors } from '@common/constants'

interface ListAttachment {
  url?: string
  mimeType?: string
  name?: string
}

interface Props {
  attachments: ListAttachment[]
  onPress?: (attachment: ListAttachment) => void
}

export const AttachmentList = ({ attachments, onPress }: Props): JSX.Element => {
  const toast = useToast()

  const getIcon = (type: string | undefined): JSX.Element => {
    switch (type) {
      case 'application/pdf':
        return <AntDesign name='pdffile1' size={28} color={colors.primary} />
      case 'image/jpeg':
        return <AntDesign name='jpgfile1' size={28} color={colors.primary} />
      default:
        return <AntDesign name='file1' size={28} color={colors.primary} />
    }
  }

  return (
    <>
      {attachments?.map((attachment) => (
        <View
          key={attachment.name}
          style={{
            marginVertical: 4,
            marginHorizontal: 12,
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%'
          }}
        >
          <TouchableOpacity
            style={{ flexDirection: 'row', alignItems: 'center', paddingHorizontal: 12 }}
            {...attachment.url !== undefined && {
              onPress: () => {
                void (async () => {
                  try {
                    const canOpen = await Linking.canOpenURL(attachment.url as string)

                    if (!canOpen) {
                      toast.show('The attachment cannot not be opened')
                      return
                    }

                    await Linking.openURL(attachment.url as string)
                  } catch {
                    toast.show(
                      'The attachment could not be opened, please try again'
                    )
                  }
                })()
              }
            }}
          >
            {getIcon(attachment.mimeType)}

            <Caption
              bold
              color='textLight'
              style={{ marginLeft: 8, flex: 1 }}
            >
              {attachment.name}
            </Caption>

            {onPress !== undefined && (
              <TouchableOpacity style={{ marginLeft: 12 }} onPress={() => onPress(attachment)}>
                <AntDesign name='delete' size={28} color={colors.placeholder} />
              </TouchableOpacity>

            )}
          </TouchableOpacity>

        </View>
      ))}
    </>
  )
}
