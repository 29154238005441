// External
import React, { useState, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { View, StyleSheet, TouchableOpacity } from 'react-native'
// Components
import { P } from '@common/components/Typography'
import { ShareDialog } from '@permitToWork/components/Share'
import { AvatarList } from '@common/components/Avatar'
// Context
import AppContext from '@context/AppContext'
import UserContext from '@context/UserContext'
// Hooks
import { useSharedWith } from '@permitToWork/hooks/useSharedWith'
// Models
import { PTWStates, PermitToWork } from '@modules/permitToWork/models'

interface Props {
  item: PermitToWork
}

export const DetailSharedWith = ({ item }: Props): JSX.Element => {
  const userContext = useContext(UserContext)
  const appContext = useContext(AppContext)
  const { selectedTerminal } = appContext
  const { t } = useTranslation()
  const sharedWith = useSharedWith({
    sharedWith: item.sharedWith,
    selectedTerminal
  })
  const [visible, setVisible] = useState(false)
  const disabled = item?.state === PTWStates.Approved || item?.state === PTWStates.Closed || item?.state === PTWStates.Cancelled
  const useAlternateName = ['barcelona', 'valencia'].includes(selectedTerminal)

  return (
    <View>
      <ShareDialog
        item={item}
        sharedWith={sharedWith}
        visible={visible}
        hideDialog={() => setVisible(false)}
      />

      <View style={styles.dividerContainer}>
        <P color='accent' bold style={styles.sectionTitle}>
          {t('permitToWorkItem.sharedWith')}
        </P>

        <View style={styles.divider} />

        {item?.personInCharge.email === userContext?.userAuth?.email && (
          <TouchableOpacity
            onPress={() => setVisible(true)}
            disabled={disabled}
          >
            <P
              color='primary'
              bold
              style={{
                textDecorationLine: 'underline',
                fontSize: 12,
                marginLeft: 14,
                ...(disabled && { opacity: 0.5 })
              }}
            >
              {t(`permitToWorkItem.${useAlternateName ? 'addWorker' : 'addParticipant'}`)}
            </P>
          </TouchableOpacity>
        )}
      </View>

      <AvatarList
        totalItems={item?.sharedWith.length}
        items={sharedWith.map((contractor) => contractor.user)}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  dividerContainer: {
    flexDirection: 'row',
    alignItems: 'flex-end'
  },
  divider: {
    height: 0.5,
    backgroundColor: '#DADADA',
    flex: 1,
    marginBottom: 6,
    marginLeft: 18
  },
  sectionTitle: {
    fontSize: 11,
    textTransform: 'uppercase',
    letterSpacing: 1
  }
})
