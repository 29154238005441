// Config
import { theme } from '@modules/common/config/theme'
// Navigation
import { createStackNavigator } from '@react-navigation/stack'
// Views
import { LoginScreen } from '@views/Auth/LoginScreen'
import { SignupScreen } from '@views/Auth/Signup/SignupScreen'
import { DisabledFacility } from '@views/DisabledFacility'
import { Map3DLocationScreen } from '@views/Map3D/Map3DLocationScreen'

const Stack = createStackNavigator()

export const AuthNavigator = () => {
  return (
    <Stack.Navigator
      screenOptions={{
        headerTintColor: theme.colors.accent,
        headerTitleStyle: {
          fontFamily: 'Maersk-Regular'
        }
      }}
    >
      <Stack.Screen
        options={{ headerShown: false }}
        name='Login'
        component={LoginScreen}
      />
      <Stack.Screen
        options={{ headerShown: false }}
        name='Signup'
        component={SignupScreen}
      />
      <Stack.Screen
        options={{
          headerShown: true,
          title: 'Your Location',
          headerBackTitle: 'Back',
          headerBackTitleStyle: {
            fontFamily: 'Maersk-Bold'
          }
        }}
        name='3DMapLocation'
        component={Map3DLocationScreen}
      />
      <Stack.Screen
        options={{ headerShown: false }}
        name='DisabledFacility'
        component={DisabledFacility}
      />
    </Stack.Navigator>
  )
}
