// External
import { Image, View, Dimensions, StyleSheet, ImageSourcePropType } from 'react-native'
import { useTheme } from 'react-native-paper'
import { Col, Grid } from 'react-native-easy-grid'
// Components
import { H1, P } from '@common/components/Typography'

const windowWidth = Dimensions.get('window').width
const RATIO = 1500 / 1153
const RATIO_FEATURED = 723 / 1028

interface Props {
  title: string
  subtitle: string
  image: ImageSourcePropType
  selectedStep?: number
  totalSteps?: number
}

export const StepFeature = ({ title, subtitle, image, selectedStep = 1, totalSteps = 0 }: Props): JSX.Element => {
  const { colors } = useTheme()

  return (
    <View style={styles.container}>
      <View style={styles.featureContainer}>
        <View style={{ padding: 10 }}>
          <H1 bold>
            {title}
          </H1>

          <View style={styles.subtitleBox}>
            <View style={[styles.subtitleContainer, { backgroundColor: colors.accent }]} />

            <P bold color='accent'>
              {subtitle}
            </P>
          </View>
        </View>

        <Grid style={styles.gridSteps}>
          {Array.from(Array(totalSteps), (step, index) => {
            if ((index + 1) === selectedStep) {
              // @ts-expect-error - colors.lightBlue is defined in the theme file but doesn't exist on the ThemeColors type
              return <Col key={index} style={{ backgroundColor: colors.lightBlue }} />
            }

            return <Col key={index} />
          })}
        </Grid>
      </View>

      <Image
        source={require('@assets/images/bg.png')}
        style={styles.bgImage}
      />

      <Image
        source={image}
        style={styles.featured}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    marginTop: 2,
    paddingTop: 100,
    width: windowWidth,
    minHeight: windowWidth / RATIO,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  featureContainer: {
    backgroundColor: '#FFFFFFcc',
    width: '80%',
    alignSelf: 'flex-start',
    margin: 24,
    marginVertical: -34,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 4
    },
    shadowOpacity: 0.08,
    shadowRadius: 10,
    elevation: 1
  },
  bgImage: {
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: -1,
    resizeMode: 'cover',
    width: windowWidth,
    height: windowWidth / RATIO
  },
  featured: {
    position: 'absolute',
    top: -20,
    zIndex: -1,
    resizeMode: 'contain',
    height: windowWidth / RATIO,
    width: (windowWidth / RATIO) * RATIO_FEATURED,
    alignSelf: 'flex-end',
    right: -30
  },
  subtitleBox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  subtitleContainer: {
    height: 1.5,
    width: 20,
    marginRight: 10
  },
  gridSteps: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    height: 3
  }
})
