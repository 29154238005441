// External
import { useState, useEffect } from 'react'
// Config
import firebase from '@config/firebase'
// Models
import { Contractor, ContractorSchema } from '@permitToWork/models'

interface Props {
  sharedWith: string[]
  selectedTerminal: string
}

export const useSharedWith = ({ sharedWith, selectedTerminal }: Props): Contractor[] => {
  const [users, setUsers] = useState<Contractor[]>([])

  useEffect(() => {
    void (async () => {
      const users = await Promise.all(
        sharedWith.map(async (contractorId) => {
          const user = await firebase
            .firestore()
            .collection('terminals')
            .doc(selectedTerminal)
            .collection('modules')
            .doc('permitToWork')
            .collection('contractors')
            .doc(contractorId)
            .get()

          return ContractorSchema.validateSync({
            id: user.id,
            ...user.data()
          })
        })
      )

      setUsers(users)
    })()
  }, [sharedWith, selectedTerminal])

  return users
}
