import React, { useContext } from 'react'
// Context
import UserContext from '@context/UserContext'
// RN
import { StyleSheet, View } from 'react-native'
// Components
import { Button } from '@components/Button/Button'
// Icons
import Globe from '@icons/Globe'
// Paper
import { Modal, Portal, List } from 'react-native-paper'
// Services
import { UserServices } from '@services/UserServices'
// i18next
import { useTranslation } from 'react-i18next'

export const LanguageSelector = ({ relative }) => {
  const { i18n } = useTranslation()
  const [visible, setVisible] = React.useState(false)
  const userContext = useContext(UserContext)

  const LANGS = {
    en: 'English',
    es: 'Español',
    it: 'Italiano',
    se: 'Sverige',
    hr: 'Hrvatski'
  }

  return (
    <>
      <Portal>
        <Modal
          visible={visible}
          onDismiss={() => setVisible(false)}
          contentContainerStyle={styles.modalContainer}
        >
          <List.Section>
            <List.Subheader>Select Language</List.Subheader>
            {Object.keys(LANGS).map(lang => {
              return (
                <List.Item
                  key={lang}
                  onPress={() => {
                    if (userContext?.userAuth) {
                      UserServices.updateMetadata({
                        email: userContext.userAuth.email,
                        key: 'language',
                        value: lang
                      })
                    }
                    i18n.changeLanguage(lang)
                    setVisible(false)
                  }}
                  title={LANGS[lang]}
                />
              )
            })}
          </List.Section>
        </Modal>
      </Portal>
      <View style={relative ? styles.relative : styles.container}>
        <Button onPress={() => setVisible(!visible)} mode='text'>
          <Globe style={{ marginTop: -4 }} />
          {'  '}
          {LANGS[i18n.language]}
        </Button>
      </View>
    </>
  )
}

const styles = StyleSheet.create({
  container: {
    alignSelf: 'flex-start',
    justifyContent: 'center',
    position: 'absolute',
    top: 10,
    left: 12,
    zIndex: 1
  },
  relative: {
    position: 'relative'
  },
  modalContainer: {
    backgroundColor: 'white',
    padding: 20,
    width: 240,
    alignSelf: 'center'
  }
})
