// Location
import * as Location from 'expo-location'
// Task Manager
import * as TaskManager from 'expo-task-manager'
// Socket.io
import { SocketServices } from '@services/SocketServices'
// Regions
import { REGIONS } from '@config/regions'

const LOCATION_TASK_NAME = 'background-location-task'
const GEOFENCE_TASK_NAME = 'geofence-region'

export async function startLocationService ({
  user,
  selectedTerminal,
  deviceId,
  pushToken
}) {
  // console.log('-- ========================== --')
  // console.log('🧭 -- START LOCATION SERVICES --')
  // console.log('-- ========================== --')

  if (await checkPermissions()) {
    try {
      // const tasks = await TaskManager.getRegisteredTasksAsync()
      // console.log('📄 Tasks Registered:')
      // tasks.map(item => console.log('-', item.taskName))
    } catch (error) {
      console.log(error)
    }

    try {
      // console.log('Unregister all tasks')
      await TaskManager.unregisterAllTasksAsync()
    } catch (error) {
      console.log(error)
    }

    TaskManager.defineTask(
      GEOFENCE_TASK_NAME,
      ({ data: { eventType, region }, error }) => {
        if (error) {
          console.log(error)
          return
        }
        if (eventType === Location.GeofencingEventType.Enter) {
          // console.log("✅ You've entered region:", region.identifier)
          startBackgroundLocation({
            user,
            deviceId,
            pushToken,
            terminal: region.identifier,
            selectedTerminal
          })
        } else if (eventType === Location.GeofencingEventType.Exit) {
          // console.log("❌ You've left region:", region.identifier)
          SocketServices.emit('stop', {
            deviceId
          })
          stopBackgroundLocation(region.identifier)
        }
      }
    )

    try {
      await Location.startGeofencingAsync(GEOFENCE_TASK_NAME, REGIONS)
    } catch (error) {
      console.log(error)
    }
  }
  // else {
  //   console.log('No background location permissions')
  // }
}

const checkPermissions = async () => {
  try {
    const { status } = await Location.requestForegroundPermissionsAsync()
    // console.log('Foreground', status)
    if (status !== 'granted') {
      return false
    } else {
      const { status } = await Location.requestBackgroundPermissionsAsync()
      // console.log('Background', status)
      if (status !== 'granted') {
        return false
      } else {
        return true
      }
    }
  } catch (error) {
    console.log(error.message)
    return false
  }
}

const startBackgroundLocation = async ({
  user,
  deviceId,
  pushToken,
  terminal,
  selectedTerminal
}) => {
  TaskManager.defineTask(
    LOCATION_TASK_NAME + '-' + terminal,
    ({ data, error }) => {
      if (error) {
        console.log(error)
        return
      }
      if (data) {
        const { locations } = data
        SocketServices.emit(`tracking ${terminal}`, {
          user,
          deviceId,
          pushToken,
          selectedTerminal,
          locationTerminal: terminal,
          locations
        })
      }
    }
  )

  try {
    await Location.startLocationUpdatesAsync(
      LOCATION_TASK_NAME + '-' + terminal,
      {
        accuracy: Location.Accuracy.BestForNavigation,
        deferredUpdatesInterval: 1000,
        foregroundService: {
          notificationTitle: 'APM Terminals is using your location'
        },
        activityType: Location.ActivityType.AutomotiveNavigation,
        pausesUpdatesAutomatically: false
      }
    )
    // console.log('🏁 Start background location updates for terminal ', terminal)
  } catch (error) {
    console.log(error)
  }
}

const stopBackgroundLocation = async terminal => {
  try {
    if (TaskManager.isTaskDefined(LOCATION_TASK_NAME + '-' + terminal)) {
      const resultTask = await TaskManager.isTaskRegisteredAsync(
        LOCATION_TASK_NAME + '-' + terminal
      )
      if (resultTask) {
        // console.log('✋🏻 Stop background location for terminal ', terminal)
        await Location.stopLocationUpdatesAsync(
          LOCATION_TASK_NAME + '-' + terminal,
          {
            accuracy: Location.Accuracy.Balanced
          }
        )
      }
    }
  } catch (error) {
    console.log(error)
  }
}
