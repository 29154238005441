// External
import { Headline } from 'react-native-paper'
import { StyleProp, TextStyle } from 'react-native'
import { ReactNode } from 'react'
// Constants
import { colors } from '@common/constants'

interface Props {
  bold?: boolean
  style?: StyleProp<TextStyle>
  color?: keyof typeof colors
  children: ReactNode
}

export const H1 = ({ bold = false, style, color, children }: Props): JSX.Element => {
  return (
    <Headline
      style={[{
        fontSize: 42,
        lineHeight: 46,
        color: color !== undefined ? colors[color] : colors.accent,
        fontFamily: bold ? 'Maersk-Bold' : 'Maersk-Light'
      }, style]}
    >
      {children}
    </Headline>
  )
}
