// External
import { useState, useEffect } from 'react'
// Models
import { Contractor } from '@permitToWork/models'
// Services
import { getCompanyContractors } from '@permitToWork/services'

interface Props {
  selectedTerminal: string
  companyUid: string
}

const useCompanyContractors = ({ selectedTerminal, companyUid }: Props): Contractor[] => {
  const [contractors, setContractors] = useState<Contractor[]>([])

  useEffect(() => {
    void getCompanyContractors({ selectedTerminal, company: companyUid })
      .then((contractors) => {
        setContractors(contractors)
      })
  }, [selectedTerminal, companyUid])

  return contractors
}
export default useCompanyContractors
