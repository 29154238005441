// External
import { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useToast } from 'react-native-toast-notifications'
import { StackScreenProps } from '@react-navigation/stack'
// Components
import { AddAttachment } from '@common/components/Attachments'
import { Button } from '@common/components/Button'
import { H2 } from '@common/components/Typography'
// Context
import AppContext from '@context/AppContext'
import UserContext from '@context/UserContext'
// Hooks
import { useCustomDrawerOptions } from '@hooks/useCustomDrawerOptions'
// Layout
import { Container, SafeArea, StepFeatureCompact } from '@common/layout'
// Models
import { Attachment } from '@common/models'
import { NewPermitStackParamList } from '@permitToWork/models'
// Services
import { createPermitToWork } from '@permitToWork/services'

type Props = StackScreenProps<NewPermitStackParamList, 'StepAttachments'>

const StepAttachments = ({ navigation, route }: Props): JSX.Element => {
  const toast = useToast()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [attachments, setAttachments] = useState<Attachment[]>([])
  const { selectedTerminal } = useContext(AppContext)
  const { userInfo } = useContext(UserContext)
  const { t } = useTranslation()

  useCustomDrawerOptions({
    options: {
      goBack: () => navigation.goBack()
    }
  })

  return (
    <SafeArea>
      <StepFeatureCompact
        title={t('common.newPermit')}
        subtitle={`${t('signup.step') as string} 8 ${t('signup.of') as string} 8`}
        selectedStep={8}
        totalSteps={8}
      />

      <Container home style={{ flex: 1 }}>
        <H2 style={{ marginBottom: 16 }}>
          {t('permitToWorkStepAttachments.addAttachmentsIfNeeded')}
        </H2>

        <AddAttachment onAttachment={(result) => setAttachments(result)} />

        <Button
          mode='contained'
          style={{
            width: '100%',
            marginVertical: 24
          }}
          onPress={() => {
            setIsSubmitting(true)
            createPermitToWork({
              selectedTerminal,
              creator: userInfo,
              ...route.params,
              attachments
            })
              .then(() => {
                toast.show(t('permitToWorkStepAttachments.permitCreatedSuccessfully'), { type: 'success' })
                // @ts-expect-error - composite screen props seems to be broken on the installed version and when typing with just stack screen props
                // ts doesn't know about the screens in the parent navigator
                navigation.navigate('PermitToWorkDashboard')
              })
              .catch(error => {
                toast.show(error.message)
                setIsSubmitting(false)
              })
          }}
          disabled={isSubmitting}
          loading={isSubmitting}
        >
          {isSubmitting
            ? t('permitToWorkStepAttachments.submittingPermit')
            : t('permitToWorkStepAttachments.submitPermit')}
        </Button>
      </Container>
    </SafeArea>
  )
}
export default StepAttachments
