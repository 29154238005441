// Components
import { Caption } from '@components/Typography/Caption'
// React Native Paper
import { useTheme } from 'react-native-paper'
// React Native
import { Linking, TouchableOpacity, View } from 'react-native'
// Icons
import { AntDesign } from '@expo/vector-icons'
// Toast
import { useToast } from 'react-native-toast-notifications'

export const AttachmentList = ({ attachments, onPress }) => {
  const toast = useToast()
  const { colors } = useTheme()
  const getIcon = type => {
    switch (type) {
      case 'application/pdf':
        return <AntDesign name='pdffile1' size={28} color={colors.primary} />
      case 'image/jpeg':
        return <AntDesign name='jpgfile1' size={28} color={colors.primary} />

      default:
        return <AntDesign name='file1' size={28} color={colors.primary} />
    }
  }

  return (
    <>
      {attachments?.map(attachment => (
        <View
          key={attachment.name}
          style={{
            marginVertical: 4,
            marginHorizontal: 12,
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%'
          }}
        >
          <TouchableOpacity
            style={{ flexDirection: 'row', alignItems: 'center', paddingHorizontal: 12 }}
            onPress={async () => {
              try {
                const canOpen = await Linking.canOpenURL(attachment.url)

                if (!canOpen) {
                  toast.show('The attachment cannot not be opened')
                  return
                }

                await Linking.openURL(attachment.url)
              } catch {
                toast.show(
                  'The attachment could not be opened, please try again'
                )
              }
            }}
          >
            {getIcon(attachment.mimeType)}

            <Caption
              bold
              color='textLight'
              style={{ marginLeft: 8, flex: 1 }}
            >
              {attachment.name}
            </Caption>

            {onPress && (
              <TouchableOpacity style={{ marginLeft: 12 }} onPress={() => onPress(attachment)}>
                <AntDesign name='delete' size={28} color={colors.placeholder} />
              </TouchableOpacity>

            )}
          </TouchableOpacity>

        </View>
      ))}
    </>
  )
}
