import React from 'react'
// Components
import { Container } from '@components/Layout/Container'
import { P } from '@components/Typography/P'
// Navigation
import { useNavigation } from '@react-navigation/native'
// RN
import { StyleSheet, TouchableOpacity, View } from 'react-native'
// i18next
import { useTranslation } from 'react-i18next'

export const SignupFooter = () => {
  const navigation = useNavigation()
  const { t } = useTranslation()

  return (
    <Container>
      <View style={styles.footerContainer}>
        <View style={styles.divider} />
        <View style={styles.footerBox}>
          <P>{t('signupFooter.alreadyHaveAccount')}</P>
          <TouchableOpacity
            onPress={() => {
              navigation.navigate('Login')
            }}
          >
            <P link color='primary'>
              {t('common.login')}
            </P>
          </TouchableOpacity>
        </View>
      </View>
    </Container>
  )
}

const styles = StyleSheet.create({
  footerContainer: {
    flexDirection: 'column'

  },
  divider: {
    width: '100%',
    backgroundColor: '#F2F2F2',
    height: 1
  },
  footerBox: {
    marginBottom: 1,
    paddingVertical: 12,
    flexDirection: 'row',
    justifyContent: 'space-between'
  }
})
