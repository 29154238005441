import { useEffect } from 'react'
import { Platform } from 'react-native'
// Notifications
import * as Notifications from 'expo-notifications'
// Linking
import * as Linking from 'expo-linking'
// Navigation
import { useNavigation } from '@react-navigation/native'

export const useNotificationHandler = () => {
  const navigation = useNavigation()

  if (Platform.OS === 'web') {
    return null
  }

  const handleNotificationResponse = response => {
    if (response.notification.request.content.data?.notificationData) {
      const {
        action,
        data
      } = response.notification.request.content.data.notificationData
      switch (action) {
        case 'tel':
          if (data.phone) {
            Linking.openURL('tel:' + data.phone)
          }
          break

        case 'url':
          if (data.url) {
            Linking.openURL(data.url)
          }
          break

        case 'navigate':
          try {
            navigation.navigate(data.route)
          } catch (error) {
            console.log(error)
          }
          break

        default:
          try {
            navigation.navigate('Notifications')
          } catch (error) {
            console.log(error)
          }
          break
      }
    } else {
      navigation.navigate('Notifications')
    }
  }

  useEffect(() => {
    Notifications.setNotificationHandler({
      handleNotification: async () => ({
        shouldShowAlert: true,
        shouldPlaySound: false,
        shouldSetBadge: false
      })
    })
    const unsubscribeResponse = Notifications.addNotificationResponseReceivedListener(
      handleNotificationResponse
    )

    return () => {
      unsubscribeResponse.remove()
    }
  }, [])
}
