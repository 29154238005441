// External
import { View, StyleSheet } from 'react-native'
import { useTranslation } from 'react-i18next'
import { MaterialCommunityIcons } from '@expo/vector-icons'
// Components
import { P } from '@common/components/Typography'
// Constants
import { colors } from '@common/constants'
// Models
import { PermitToWork } from '@permitToWork/models'

interface Props {
  item: PermitToWork
}

export const DetailLocations = ({ item }: Props): JSX.Element => {
  const { t } = useTranslation()

  return (
    <View>
      <View style={styles.dividerContainer}>
        <P color='accent' bold style={styles.sectionTitle}>
          {t('permitToWorkItem.locations')}
        </P>

        <View style={styles.divider} />
      </View>

      <View style={{ marginTop: 8 }}>
        {item?.locations !== undefined
          ? (
            <>
              {item?.locations?.map((location) => (
                <View style={styles.item} key={location}>
                  <MaterialCommunityIcons
                    name='check-bold'
                    size={16}
                    color={colors.primary}
                  />

                  <P light style={styles.sectionContent}>
                    {t('locations.' + location)}
                  </P>
                </View>
              ))}
            </>
            )
          : (
            <View style={styles.item}>
              <MaterialCommunityIcons
                name='check-bold'
                size={16}
                color={colors.primary}
              />

              <P light style={styles.sectionContent}>
                {item?.otherLocation}
              </P>
            </View>
            )}

      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  dividerContainer: {
    flexDirection: 'row',
    alignItems: 'flex-end'
  },
  divider: {
    height: 0.5,
    backgroundColor: '#DADADA',
    flex: 1,
    marginBottom: 6,
    marginLeft: 18
  },
  sectionContent: {
    fontSize: 12,
    marginLeft: 8,
    paddingBottom: 8,
    lineHeight: 16
  },
  sectionTitle: {
    fontSize: 11,
    textTransform: 'uppercase',
    letterSpacing: 1
  },
  item: {
    marginLeft: 8,
    flexDirection: 'row',
    justifyContent: 'flex-start'
  }
})
