import React from 'react'
// RN
import { Image, View, StyleSheet } from 'react-native'
// Components
import { P } from '@components/Typography/P'
import { Caption } from '@components/Typography/Caption'
import { H3 } from '@components/Typography/H3'
import { InductionIndicator } from '@components/Drawer/InductionIndicator'

const windowWidth = 280
const RATIO = 1500 / 1153

export const HeroDrawer = React.memo(
  ({
    fullName,
    userTypeLabel,
    email,
    loginWithName,
    loginWithValue
  }) => {
    return (
      <View style={styles.container}>
        <Image
          defaultSource={require('@assets/images/stripes.png')}
          source={require('@assets/images/stripes.png')}
          style={styles.bgImage}
        />
        <View style={styles.titleContainer}>
          <H3 color='text' bold>
            {fullName}
          </H3>
          <P color='primary'>{userTypeLabel}</P>
          <Caption color='textLigh'>{email}</Caption>
          <P color='caption'>
            {loginWithName}: {loginWithValue}
          </P>
        </View>
        <InductionIndicator />
        <View style={styles.divider} />
      </View>
    )
  }
)

const styles = StyleSheet.create({
  container: {
    width: '100%',
    minHeight: windowWidth / RATIO,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center'
  },
  bgImage: {
    opacity: 1,
    position: 'absolute',
    alignSelf: 'center',
    zIndex: -1,
    width: '100%',
    height: windowWidth / RATIO
  },
  titleContainer: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center'
  },
  divider: {
    width: '60%',
    marginTop: 6,
    backgroundColor: '#F2F2F2',
    height: 1.5
  }
})
