// External
import { createStackNavigator } from '@react-navigation/stack'
// Models
import { PermitToWorkStackParamList } from '@permitToWork/models'
// Views
import PermitToWorkDashboard from '@permitToWork/views/PermitToWorkDashboard'
import PermitToWorkDetail from '@permitToWork/views/PermitToWorkDetail'
import NewPermitStack from './NewPermitStack'

const Stack = createStackNavigator<PermitToWorkStackParamList>()

const PermitToWorkStack = (): JSX.Element => {
  return (
    <Stack.Navigator
      initialRouteName='PermitToWorkDashboard'
      screenOptions={{
        headerShown: false
      }}
    >
      <Stack.Screen
        options={{ headerShown: false }}
        name='PermitToWorkDashboard'
        component={PermitToWorkDashboard}
      />

      <Stack.Screen
        options={{ headerShown: false }}
        name='PermitToWorkDetail'
        component={PermitToWorkDetail}
      />

      <Stack.Screen
        options={{ headerShown: false }}
        name='NewPermitStack'
        component={NewPermitStack}
      />
    </Stack.Navigator>
  )
}
export default PermitToWorkStack
