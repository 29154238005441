// External
import { format } from 'date-fns'
import { FormikErrors, FormikTouched } from 'formik'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { StyleProp, TouchableOpacity, View, ViewStyle } from 'react-native'
import { Calendar, DateData } from 'react-native-calendars'
import { Dialog, HelperText, Portal, TextInput } from 'react-native-paper'
// Constants
import { colors } from '@common/constants'

interface Props<T> {
  name: keyof T
  style?: StyleProp<ViewStyle>
  value?: Date
  label: string
  touched?: FormikTouched<T>
  errors?: FormikErrors<T>
  minDate?: string
  onDayPress: (day: DateData) => void
  onCancel?: () => void
}

export const CalendarInput = <T,>({ name, style, value, label, touched, errors, minDate, onDayPress, onCancel }: Props<T>): JSX.Element => {
  const [visibleCalendar, setVisibleCalendar] = useState(false)
  const { i18n } = useTranslation()

  return (
    <View style={style}>
      <TouchableOpacity
        onPress={() => {
          setVisibleCalendar(true)
        }}
      >
        <View pointerEvents='none'>
          <TextInput
            value={value?.toLocaleString(i18n.language, {
              dateStyle: 'medium'
            }) ?? ''}
            placeholder='Date'
            placeholderTextColor={colors.placeholder}
            right={
              <TextInput.Icon color={colors.primary} icon='calendar' />
                      }
            editable={false}
            dense
            label={label}
            mode='outlined'
            style={{ fontFamily: 'Maersk-Regular' }}
            outlineColor={colors.placeholder}
          />
        </View>
      </TouchableOpacity>

      <HelperText
        style={{ marginBottom: 4 }}
        type='info'
        visible={Boolean(touched?.[name])}
      >
        {errors?.[name] !== undefined ? String(errors[name]) : ''}
      </HelperText>

      <Portal>
        <Dialog
          visible={visibleCalendar}
          onDismiss={() => setVisibleCalendar(false)}
        >
          <Dialog.Title>{label}</Dialog.Title>

          <Dialog.Content>
            <Calendar
              minDate={minDate}
              onDayPress={(day) => {
                onDayPress(day)
                setVisibleCalendar(false)
              }}
              {...value !== undefined && {
                markedDates: {
                  [format(value, 'yyyy-MM-dd')]: {
                    selected: true,
                    selectedColor: colors.primary
                  }
                }
              }}
              {...onCancel !== undefined && { onCancel }}
              theme={{
                arrowWidth: 33,
                arrowHeight: 32,
                selectedDayTextColor: '#ffffff',
                todayTextColor: colors.text,
                dayTextColor: colors.text,
                arrowColor: colors.primary,
                textDayFontFamily: 'Maersk-Regular',
                textMonthFontFamily: 'Maersk-Regular',
                textDayHeaderFontFamily: 'Maersk-Regular'
              }}
            />
          </Dialog.Content>
        </Dialog>
      </Portal>
    </View>
  )
}
