// External
import { Avatar } from 'react-native-paper'
// Constants
import { colors } from '@common/constants'

interface Props {
  size?: number
  name: string
  surname: string
  profilePhoto?: string
}

export const AvatarImage = ({ size = 32, name, surname, profilePhoto }: Props): JSX.Element => {
  if (profilePhoto !== undefined) {
    return (
      <Avatar.Image
        size={size}
        style={{
          marginRight: 8,
          backgroundColor: colors.placeholder
        }}
        source={{ uri: profilePhoto }}
      />
    )
  }

  return (
    <Avatar.Text
      size={size}
      color='white'
      style={{
        marginRight: 8,
        backgroundColor: colors.placeholder
      }}
      label={name.charAt(0) + surname.charAt(0)}
    />
  )
}
