import React from 'react'
// Icons
import { AntDesign } from '@expo/vector-icons'
// Components
import { P } from '@components/Typography/P'
// RN
import { View, TouchableOpacity } from 'react-native'
// Navigation
import { useNavigation } from '@react-navigation/native'
// i18next
import { useTranslation } from 'react-i18next'

export const InductionMissingAlert = () => {
  const navigation = useNavigation()
  const { t } = useTranslation()

  return (
    <View
      style={{
        position: 'absolute',
        top: 0,
        zIndex: 1,
        backgroundColor: '#D75E72',
        width: '100%',
        paddingHorizontal: 12,
        paddingVertical: 10,
        marginBottom: 24,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >

      <AntDesign name='warning' size={42} color='white' />

      <TouchableOpacity
        onPress={() => navigation.navigate('Induction')}
        style={{ paddingLeft: 12, flex: 1 }}
      >
        <P bold color='background'>
          {t('inductionMissingAlert.inductionMissing')}
        </P>
        <P light color='background'>
          {t('inductionMissingAlert.takeSafetyInduction')}
        </P>
      </TouchableOpacity>

    </View>
  )
}
