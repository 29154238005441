// External
import { FlatList, View } from 'react-native'
import {
  Checkbox,
  Divider,
  Menu,
  TextInput,
  TouchableRipple
} from 'react-native-paper'
import React, {
  useEffect,
  useState,
  useCallback
} from 'react'
// Constants
import { colors } from '@common/constants'
// Models
import { DropDownProps } from '@common/models'

export const Dropdown = ({
  multiSelect = false,
  visible,
  onDismiss,
  showDropDown,
  value,
  setValue,
  activeColor,
  mode,
  label,
  placeholder,
  inputProps,
  list,
  dropDownStyle,
  dropDownItemStyle,
  dropDownItemSelectedStyle,
  dropDownItemTextStyle,
  dropDownItemSelectedTextStyle,
  accessibilityLabel
}: DropDownProps): JSX.Element => {
  const [displayValue, setDisplayValue] = useState('')
  const [inputLayout, setInputLayout] = useState({
    height: 0,
    width: 0,
    x: 0,
    y: 0
  })

  useEffect(() => {
    const _label: string | undefined = list?.find(_ => _.value === value)?.label

    if (typeof _label === 'string') {
      setDisplayValue(_label)
      return
    }
    setDisplayValue('')
  }, [list, value])

  const isActive = useCallback(
    (currentValue: string) => {
      if (multiSelect) {
        return value.includes(currentValue)
      } else {
        return value === currentValue
      }
    },
    [value]
  )

  const setActive = useCallback(
    (currentValue: string) => {
      if (multiSelect) {
        const valueIndex = value.indexOf(currentValue)
        const values = value.split(',')
        if (valueIndex === -1) {
          setValue([...values, currentValue].join(','))
        } else {
          setValue(
            [...values].filter(value => value !== currentValue).join(',')
          )
        }
      } else {
        setValue(currentValue)
      }
    },
    [value]
  )

  const renderItem = useCallback(({ item, index }: { item: { label: string, value: string }, index: number }) => (
    <TouchableRipple
      key={index}
      style={{
        flexDirection: 'row',
        alignItems: 'center'
      }}
      onPress={() => {
        setActive(item.value)

        if (onDismiss !== undefined) {
          onDismiss()
        }
      }}
    >
      <>
        <Menu.Item
          titleStyle={[{
            color: isActive(item.value)
              ? activeColor ?? colors.primary
              : colors.text
          }, ...(isActive(item.value)
            ? [dropDownItemSelectedTextStyle]
            : [dropDownItemTextStyle])]}
          title={item.label}
          style={[{
            flex: 1
          }, ...(isActive(item.value)
            ? [dropDownItemSelectedStyle]
            : [dropDownItemStyle])]}
        />

        {multiSelect && (
          <Checkbox.Android
            theme={{
              colors: { accent: colors.primary }
            }}
            status={isActive(item.value) ? 'checked' : 'unchecked'}
            onPress={() => setActive(item.value)}
          />
        )}
      </>
    </TouchableRipple>
  ), [])

  return (
    <Menu
      visible={visible}
      onDismiss={onDismiss}
      anchor={
        <TouchableRipple
          onPress={showDropDown}
          onLayout={(e) => {
            setInputLayout(e.nativeEvent.layout)
          }}
          accessibilityLabel={accessibilityLabel}
        >
          <View pointerEvents='none'>
            <TextInput
              value={displayValue}
              mode={mode}
              label={label}
              placeholder={placeholder}
              pointerEvents='none'
              right={
                <TextInput.Icon icon={visible ? 'menu-up' : 'menu-down'} />
              }
              {...inputProps}
            />
          </View>
        </TouchableRipple>
      }
      style={[{
        // maxWidth: inputLayout?.width,
        width: inputLayout?.width,
        marginTop: inputLayout?.height
      }, dropDownStyle]}
    >
      <FlatList
        style={{ maxHeight: 248 }}
        bounces={false}
        data={list}
        renderItem={renderItem}
        ItemSeparatorComponent={Divider}
        initialNumToRender={5}
        maxToRenderPerBatch={5}
      />
    </Menu>
  )
}
