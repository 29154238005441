import { useState, useEffect } from 'react'
// Firebase
import firebase from '@config/firebase'

export const useUserAuth = () => {
  const [userAuth, setUserAuth] = useState()

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(async function (user) {
      if (user) {
        setUserAuth({
          ...user,
          email: user.email.toLowerCase()
        })
      } else {
        setUserAuth()
      }
      return () => {
        unsubscribe()
      }
    })
  }, [])

  return userAuth
}
