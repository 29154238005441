import React from 'react'
// RN
import { SafeAreaView, StyleSheet } from 'react-native'
// Components
import { Hero } from '@components/Layout/Hero'
import { Container } from '@components/Layout/Container'
import { P } from '@components/Typography/P'
import { Button } from '@components/Button/Button'
import { LanguageSelector } from '@components/Dropdown/LanguageSelector'
// i18n
import { useTranslation } from 'react-i18next'

export const WelcomeScreen = ({ navigation }) => {
  const { t } = useTranslation()

  return (
    <SafeAreaView style={styles.container}>
      <LanguageSelector />
      <Hero
        intro={t('welcomeScreen.hello')}
        title={t('welcomeScreen.title')}
        subtitle='Visitor HSSE'
      />
      <Container style={styles.content}>
        <P justify>
          {t('welcomeScreen.intro1')}
        </P>
      </Container>
      <Container style={styles.content}>
        <Button
          full
          featured
          onPress={() => navigation.navigate('Foreground')}
        >
          {t('welcomeScreen.buttonText')}
        </Button>
      </Container>
    </SafeAreaView>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'white',
    alignItems: 'center'
  },
  content: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center'
  }
})
