import React from 'react'
// RN
import { Dimensions, Image, Platform, StyleSheet, View } from 'react-native'
// Components
import { H1 } from '@components/Typography/H1'
import { P } from '@components/Typography/P'
// Paper
import { useTheme } from 'react-native-paper'
// Grid
import { Col, Grid } from 'react-native-easy-grid'

const windowWidth = Dimensions.get('window').width
const RATIO = 1500 / 1153
const RATIO_FEATURED = 723 / 1028

export const StepFeature = ({ title, subtitle, image, selectedStep = 1, totalSteps = 0, ...props }) => {
  const { colors } = useTheme()

  const styles = StyleSheet.create({
    container: {
      marginTop: 2,
      paddingTop: 100,
      width: windowWidth,
      minHeight: Platform.OS !== 'web' ? windowWidth / RATIO : '20vh',
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center'
    },
    featureContainer: {
      backgroundColor: '#FFFFFFcc',
      width: '80%',
      alignSelf: 'flex-start',
      margin: 24,
      marginVertical: -34,
      shadowColor: '#000',
      shadowOffset: {
        width: 0,
        height: 4
      },
      shadowOpacity: 0.08,
      shadowRadius: 10,
      elevation: 1
    },
    bgImage: {
      position: 'absolute',
      alignSelf: 'center',
      zIndex: -1,
      resizeMode: 'contain',
      width: '100%',
      opacity: 0.5,
      height: Platform.OS !== 'web' ? windowWidth / RATIO : '50vh'
    },
    featured: {
      position: 'absolute',
      top: -20,
      zIndex: -1,
      resizeMode: 'contain',
      height: windowWidth / RATIO,
      width: (windowWidth / RATIO) * RATIO_FEATURED,
      alignSelf: 'flex-end',
      right: -30
    },
    subtitleBox: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      alignItems: 'center'
    },
    subtitleContainer: {
      height: 1.5,
      width: 20,
      marginRight: 10,
      backgroundColor: colors.accent
    },
    gridSteps: {
      position: 'absolute',
      bottom: 0,
      width: '100%',
      height: 3
    }
  })

  return (
    <View style={styles.container}>
      <View style={styles.featureContainer}>
        <View style={{ padding: 10 }}>
          <H1 bold>
            {title}
          </H1>
          <View style={styles.subtitleBox}>
            <View style={styles.subtitleContainer} />
            <P bold color='accent'>
              {subtitle}
            </P>
          </View>
        </View>
        {totalSteps && (
          <Grid style={styles.gridSteps}>
            {Array.from(Array(totalSteps), (step, index) => {
              if ((index + 1) === selectedStep) {
                return <Col key={index} style={{ backgroundColor: colors.lightBlue }} />
              } else {
                return <Col key={index} />
              }
            })}
          </Grid>
        )}
      </View>
      <Image
        source={require('@assets/images/bg.png')}
        style={styles.bgImage}
      />
      <Image
        source={image}
        style={styles.featured}
      />
    </View>
  )
}
