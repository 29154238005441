import { useState, useEffect } from 'react'
import AsyncStorage from '@react-native-async-storage/async-storage'
import uuid from 'react-native-uuid'

export const useDeviceId = () => {
  const [deviceId, setDeviceId] = useState()

  useEffect(() => {
    const getItem = async () => {
      const ID = uuid.v4()
      const value = await AsyncStorage.getItem('deviceId')
      if (value !== undefined && value !== null) {
        setDeviceId(value)
      } else {
        AsyncStorage.setItem('deviceId', ID)
        setDeviceId(ID)
      }
    }
    getItem()
  }, [])

  return deviceId
}
