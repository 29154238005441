import { useContext, useEffect, useState } from 'react'
// Context
import AppContext from '@context/AppContext'
import UserContext from '@context/UserContext'
// Components
import { Button } from '@components/Button/Button'
import { QuestionItem } from '@components/Induction/QuestionItem'
import { Card } from '@components/Layout/Card'
import { Container } from '@components/Layout/Container'
import { KeyboardFix } from '@components/Layout/KeyboardFix'
import { SafeArea } from '@components/Layout/SafeArea'
import { StepFeatureCompact } from '@components/Layout/StepFeatureCompact'
// Services
import { InductionServices } from '@services/InductionServices'
// RN
import { View } from 'react-native'
// Paper
import { ActivityIndicator, Button as PaperButton, useTheme } from 'react-native-paper'
// Toast
import { useToast } from 'react-native-toast-notifications'
// i18next
import { useTranslation } from 'react-i18next'

export const StepTestScreen = ({ navigation }) => {
  const toast = useToast()
  const appContext = useContext(AppContext)
  const userContext = useContext(UserContext)
  const { userInfo, userAuth } = userContext
  const { terminals, selectedTerminal } = appContext
  const { induction } = userContext
  const { t, i18n } = useTranslation()
  const locale = i18n.language
  const [questions, setQuestions] = useState([])
  const terminalInfo = terminals[selectedTerminal]
  const { colors } = useTheme()
  const [loading, setLoading] = useState(false)

  const checkAnswersStatus = () => {
    let status = 'complete'
    questions.forEach(question => {
      if (question.selectedAnswer === undefined) {
        status = 'incomplete'
      }
    })

    return status
  }

  const checkTest = () => {
    setLoading(true)
    let status = 'success'
    questions.forEach(question => {
      if (!question.answers[question?.selectedAnswer]?.isCorrect) {
        status = 'fail'
      }
    })

    if (status === 'fail') {
      setLoading(false)
      toast.show(t('stepTestScreen.fail'))
    } else {
      const inductionDate = new Date()
      const inductionExpiration = InductionServices.getInductionExpiration({
        inductionDate,
        inductionValid: terminalInfo.inductionValid
      })
      if (induction) {
        InductionServices
          .updateInduction({
            inductionId: induction.id,
            userType: userInfo.userType,
            selectedTerminal,
            inductionStatus: 'done',
            email: userAuth.email,
            inductionDate,
            inductionExpiration,
            userInfo
          })
          .then(() => {
            setLoading(false)
          })
          .catch(error => {
            setLoading(true)
            console.log(error)
          })
      } else {
        InductionServices
          .createInduction({
            selectedTerminal,
            userType: userInfo.userType,
            inductionStatus: 'done',
            email: userAuth.email,
            inductionDate,
            inductionExpiration,
            userInfo
          })
          .then(() => {
            setLoading(false)
          })
          .catch(error => {
            setLoading(true)
            console.log(error)
          })
      }
    }
  }

  useEffect(() => {
    const shuffle = array => {
      let currentIndex = array.length
      let randomIndex
      while (currentIndex !== 0) {
        randomIndex = Math.floor(Math.random() * currentIndex)
        currentIndex--
        ;[array[currentIndex], array[randomIndex]] = [
          array[randomIndex],
          array[currentIndex]
        ]
      }
      return array
    }
    ;(async () => {
      const questionsInitial = await InductionServices.getInductionQuestions({
        selectedTerminal,
        userType: userInfo.userType,
        locale
      })
      const answersShuffle = questionsInitial.map(question => {
        const shuffledAnswers = shuffle(question.answers)
        return {
          ...question,
          answers: shuffledAnswers
        }
      })
      const questionsShuffle = shuffle(answersShuffle).slice(0, 5)
      setQuestions(questionsShuffle)
    })()
  }, [selectedTerminal, userInfo.userType, locale])

  return (
    <SafeArea>
      <KeyboardFix>
        <StepFeatureCompact
          title={t('stepTestScreen.title')}
          subtitle={t('stepTestScreen.subtitle')}
          selectedStep={2}
          totalSteps={2}
        />
        <Container home style={{ flex: 1 }}>
          <View style={{ flex: 1 }}>
            <PaperButton
              icon='chevron-left'
              color={colors.placeholder}
              style={{ alignSelf: 'flex-start' }}
              uppercase={false}
              compact
              onPress={() => {
                navigation.navigate('Video')
              }}
            >
              {t('stepTestScreen.backToVideo')}
            </PaperButton>
            <Card>
              {questions.length === 0 && (
                <ActivityIndicator style={{ marginTop: 24 }} />
              )}
              {questions.map((question, index) => (
                <QuestionItem
                  index={index}
                  selectedAnswer={question.selectedAnswer}
                  onSelectAnswer={selectedIndex => {
                    question.selectedAnswer = selectedIndex
                    setQuestions([...questions])
                  }}
                  key={index}
                  questionNumber={index + 1}
                  question={question.question}
                  answers={question.answers}
                />
              ))}
            </Card>
          </View>
          <Button
            onPress={() => checkTest()}
            loading={loading}
            disabled={checkAnswersStatus() === 'incomplete' || loading || questions.length === 0}
            mode='contained'
            style={{ marginVertical: 34 }}
          >
            {t('stepTestScreen.finishInduction')}
          </Button>
        </Container>
      </KeyboardFix>
    </SafeArea>
  )
}
