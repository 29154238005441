// Config
import firebase from '@config/firebase'
// Models
import { Contractor, ContractorSchema } from '@permitToWork/models'

interface Params {
  selectedTerminal: string
  company: string
}

export const getCompanyContractors = async ({ selectedTerminal, company }: Params): Promise<Contractor[]> => {
  try {
    const contractorSnapshot = await firebase
      .firestore()
      .collection('terminals')
      .doc(selectedTerminal)
      .collection('modules')
      .doc('permitToWork')
      .collection('contractors')
      .where('company.uid', '==', company)
      .where('isAuthorized', '==', true)
      .get()

    return contractorSnapshot.docs.map((doc) => ContractorSchema.validateSync({ id: doc.id, ...doc.data() }))
  } catch (error) {
    console.error('Error getting company contractors:', error)
    return await Promise.reject(error)
  }
}
