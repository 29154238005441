// External
import { View, StyleSheet } from 'react-native'
import { FontAwesome } from '@expo/vector-icons'
import { useTranslation } from 'react-i18next'
import { useContext } from 'react'
// Components
import { P } from '@common/components/Typography'
import { AvatarImage } from '@modules/common/components/Avatar'
// Config
import { theme } from '@modules/common/config/theme'
// Context
import AppContext from '@context/AppContext'
// Hooks
import usePermitSignatures from '@permitToWork/hooks/usePermitSignatures'
// Models
import { PermitToWork } from '@modules/permitToWork/models'

interface Props {
  item: PermitToWork
}

export const DetailSignatures = ({ item }: Props): JSX.Element => {
  const { t } = useTranslation()
  const signatures = usePermitSignatures({
    permitAuthorizer: item?.permitAuthorizer?.email,
    permitIssuer: item?.permitIssuer?.email
  })
  const { selectedTerminal } = useContext(AppContext)
  const signatureKeys = ['barcelona', 'valencia'].includes(selectedTerminal)
    ? {
        permitAuthorizer: 'permitApprover',
        permitIssuer: 'permitAuthorizer'
      }
    : {
        permitAuthorizer: 'permitAuthorizer',
        permitIssuer: 'permitIssuer'
      }

  return (
    <View style={styles.container}>
      {(Object.keys(signatures) as Array<'permitAuthorizer' | 'permitIssuer'>).map((signatureType) => {
        const user = signatures[signatureType]

        if (user !== null) {
          return (
            <View key={signatureType} style={styles.detailContainer}>
              <AvatarImage size={42} name={user.name} surname={user.surname} profilePhoto={user.profilePhoto} />

              <View style={styles.contentDetail}>
                <P bold color='textLight' style={styles.signatureTitle}>
                  {t('common.' + signatureKeys[signatureType])}
                </P>

                <View style={{ flexDirection: 'row' }}>
                  <P color='textLight' style={styles.signature} numberOfLines={1}>
                    {user.name}{' '}
                    {user.surname}
                  </P>

                  {item[signatureType]?.signatureDate === null && (
                    <P color='textLight' style={styles.signature}>
                      {` - ${t('permitToWorkItem.pendingToSign') as string}`}
                    </P>
                  )}
                </View>
              </View>
            </View>
          )
        }

        return (
          <View key={signatureType} style={styles.detailContainer}>
            <FontAwesome
              style={{ marginRight: 8 }}
              name='user-circle'
              size={42}
              color={theme.colors.caption}
            />

            <View style={styles.contentDetail}>
              <P bold color='textLight' style={styles.signatureTitle}>
                {t('common.' + signatureKeys[signatureType])}
              </P>

              <P color='textLight' style={styles.signature}>
                {t('permitToWorkItem.pendingToSign')}
              </P>
            </View>
          </View>
        )
      })}
    </View>
  )
}

const styles = StyleSheet.create({
  detailContainer: {
    flexDirection: 'column',
    alignItems: 'center',
    flex: 1
  },
  contentDetail: {
    alignItems: 'center',
    marginTop: 8
  },
  signatureContainer: {
    flex: 1,
    alignItems: 'center'
  },
  container: {
    backgroundColor: '#F8F8F8',
    borderBottomColor: '#CFCFCF',
    borderBottomWidth: 0.5,
    borderTopColor: '#CFCFCF',
    borderTopWidth: 0.5,
    paddingHorizontal: 8,
    justifyContent: 'space-around',
    alignItems: 'center',
    height: 100,
    flexDirection: 'row',
    gap: 8
  },
  avatarContainer: {
    flexDirection: 'row'
  },
  signature: {
    fontSize: 11,
    lineHeight: 14
  },
  signatureTitle: {
    fontSize: 12,
    lineHeight: 14
  }
})
