import { useEffect, useState, useRef } from 'react'
// Firebase
import firebase from '@config/firebase'

export const useTerminals = () => {
  const [terminals, setTerminals] = useState()
  const snapshotRef = useRef()

  useEffect(() => {
    snapshotRef.current = firebase
      .firestore()
      .collection('terminals')
      .where('enabled', '==', true)
      .onSnapshot(terminals => {
        if (terminals.docs.length > 0) {
          setTerminals(
            Object.fromEntries(
              terminals.docs.map(terminal => [
                terminal.id,
                { ...terminal.data() }
              ])
            )
          )
        } else {
          setTerminals([])
        }
      }, error => {
        console.error(error)
        setTerminals([])
      })

    return () => {
      snapshotRef.current && snapshotRef.current()
    }
  }, [])

  return terminals
}
