import { useContext } from 'react'
// Context
import UserContext from '@context/UserContext'
// Components
import { SafeArea } from '@components/Layout/SafeArea'
import { P } from '@components/Typography/P'
import { KeyboardFix } from '@components/Layout/KeyboardFix'
import { Container } from '@components/Layout/Container'
import { SuspensionItem } from '@components/Suspension/SuspensionItem'
// i18next
import { useTranslation } from 'react-i18next'

export const SuspensionsScreen = () => {
  const userContext = useContext(UserContext)
  const { t } = useTranslation()
  const { suspensions } = userContext

  return (
    <SafeArea>
      <KeyboardFix>
        <Container home style={{ flex: 1, alignItems: 'center' }}>
          {suspensions.length === 0 && (
            <P style={{ opacity: 0.4 }} color='textLight'>
              {t('suspensionsScreen.empty')}
            </P>
          )}
          {suspensions
            .map(suspensionInfo => (
              <SuspensionItem
                key={suspensionInfo.id}
                suspensionInfo={suspensionInfo}
              />
            ))}
        </Container>
      </KeyboardFix>
    </SafeArea>
  )
}
