import { useContext } from 'react'
// Context
import UserContext from '@context/UserContext'
// Navigation
import { createStackNavigator } from '@react-navigation/stack'
// Screens
import { InductionDoneScreen } from '@views/Home/Induction/InductionDoneScreen'
import { StepVideoScreen } from '@views/Home/Induction/StepVideoScreen'
import { StepTestScreen } from '@views/Home/Induction/StepTestScreen'

const Stack = createStackNavigator()

export const InductionNavigator = () => {
  const userContext = useContext(UserContext)
  const { induction } = userContext

  return (
    <Stack.Navigator
      screenOptions={{
        headerShown: false
      }}
    >
      {induction?.inductionStatus === 'done'
        ? (
          <Stack.Screen
            options={{ headerShown: false }}
            name='InductionDone'
            component={InductionDoneScreen}
          />
          )
        : (
          <>
            <Stack.Screen
              options={{ headerShown: false }}
              name='Video'
              component={StepVideoScreen}
            />
            <Stack.Screen
              options={{ headerShown: false }}
              name='Test'
              component={StepTestScreen}
            />

          </>
          )}

    </Stack.Navigator>
  )
}
