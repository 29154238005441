import { useEffect, useRef } from 'react'
import * as Updates from 'expo-updates'
import { Alert, AppState, Platform } from 'react-native'
import * as Sentry from 'sentry-expo'

const showUpdateAlert = (): void => {
  Alert.alert(
    'Update available',
    'There is an app update available. Please restart.',
    [
      {
        text: 'Restart and update',
        onPress: () => {
          void (async () => {
            try {
              await Updates.fetchUpdateAsync()
              await Updates.reloadAsync()
            } catch (error) {
              console.error('Error updating app:', error)
              Sentry.Native.captureException(error)
            }
          })()
        }
      }
    ]
  )
}

const checkForUpdates = async (): Promise<void> => {
  try {
    const update = await Updates.checkForUpdateAsync()

    if (update.isAvailable) {
      showUpdateAlert()
    }
  } catch (error) {
    console.error('Error checking for updates:', error)
    Sentry.Native.captureException(error)
  }
}

export const useAppUpdate = (): void => {
  const appState = useRef(AppState.currentState)

  if (Platform.OS === 'web' || __DEV__) {
    return
  }

  useEffect(() => {
    const subscription = AppState.addEventListener('change', (nextAppState) => {
      if (
        (appState.current.match(/inactive|background/) != null) &&
        nextAppState === 'active'
      ) {
        void checkForUpdates()
      }

      appState.current = nextAppState
    })

    return () => {
      subscription.remove()
    }
  }, [])

  useEffect(() => {
    void checkForUpdates()

    const listener = Updates.addListener((event) => {
      if (event.type === Updates.UpdateEventType.UPDATE_AVAILABLE) {
        showUpdateAlert()
      }
    })

    return listener.remove()
  }, [])
}
