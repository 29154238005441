// RN
import { StyleSheet, Text, View } from 'react-native'
// Constants
import Constants from 'expo-constants'

export const DevIndicator = () => {
  if (Constants.expoConfig.extra.ENVIRONMENT === 'DEV') {
    return (
      <View style={styles.container}>
        <Text style={{ color: 'white' }}>--- DEV MODE ---</Text>
      </View>
    )
  }
  return null
}

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    top: 0,
    zIndex: 9999,
    width: '100%',
    height: 33,
    backgroundColor: '#E96060',
    justifyContent: 'center',
    alignItems: 'center'
  }
})
