// External
import { useEffect, useRef, useState } from 'react'
import fb from 'firebase/compat/app'
import { useToast } from 'react-native-toast-notifications'
import AsyncStorage from '@react-native-async-storage/async-storage'
import Constants from 'expo-constants'
import * as Location from 'expo-location'
import { Platform } from 'react-native'
// Config
import firebase from '@config/firebase'

export const useUserInfo = ({ userAuth, selectedTerminal }) => {
  const toast = useToast()
  const [userInfo, setUserInfo] = useState()
  const snapShotUnsubscribeRef = useRef()

  const addInitialValues = async () => {
    let resultForeground = { granted: false }
    let resultBackground = { granted: false }

    try {
      resultForeground = await Location.getForegroundPermissionsAsync()
      resultBackground = await Location.getBackgroundPermissionsAsync()
    } catch (error) {
      console.log(error)
    }

    let language = null
    try {
      language = await AsyncStorage.getItem('locale')
    } catch (error) {
      console.log(error)
    }

    let pushToken = null
    try {
      pushToken = await AsyncStorage.getItem('pushToken')
    } catch (error) {
      console.log(error)
    }

    const updatedData = {
      ...(Platform.OS !== 'web' && {
        notificationPermission: !!pushToken,
        ...pushToken && { pushTokens: fb.firestore.FieldValue.arrayUnion(pushToken) },
        backgroundPermission: resultBackground.granted,
        foregroundPermission: resultForeground.granted,
        appVersion: Constants.expoConfig.version
      }),
      language,
      lastAccess: new Date(),
      loggedTerminal: selectedTerminal
    }

    firebase
      .firestore()
      .collection('usersMetadata')
      .doc(userAuth.email)
      .set(updatedData, { merge: true })
  }

  const updateUserTerminals = ({ userTerminals = [] }) => {
    if (!userTerminals.includes(selectedTerminal)) {
      firebase
        .firestore()
        .collection('users')
        .doc(userAuth.email)
        .update({
          terminals: [...userTerminals, selectedTerminal]
        })
    }
  }

  useEffect(() => {
    ;(async () => {
      if (selectedTerminal && userAuth) {
        addInitialValues()
        snapShotUnsubscribeRef.current = firebase
          .firestore()
          .collection('users')
          .doc(userAuth.email)
          .onSnapshot(
            snapshot => {
              if (snapshot.exists) {
                setUserInfo(snapshot.data())
                updateUserTerminals({
                  userTerminals: snapshot.data()?.terminals
                })
              } else {
                toast.show('User not exists')
                console.log(
                  `Firestore structure for user ${userAuth.email} invalid.`
                )
                setUserInfo()
              }
            },
            error => {
              console.error(error)
              setUserInfo()
            }
          )

        return () => {
          snapShotUnsubscribeRef.current && snapShotUnsubscribeRef.current()
        }
      } else {
        snapShotUnsubscribeRef.current && snapShotUnsubscribeRef.current()
        setUserInfo()
      }
    })()
  }, [selectedTerminal, userAuth])

  return { userInfo }
}
