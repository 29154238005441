// Context
import AppContext from '@context/AppContext'
import UserContext from '@context/UserContext'
// Navigation
import { NavigationContainer } from '@react-navigation/native'
// Navigators
import { AuthNavigator } from '@navigators/AuthNavigator'
import { HomeNavigator } from '@navigators/HomeNavigator'
import { WelcomeNavigator } from '@navigators/WelcomeNavigator'
// Paper
import { Provider as PaperProvider, Portal } from 'react-native-paper'
// Config
import { theme } from '@common/config'
import '@config/i18n'
// Views
import { NoConnection } from '@views/NoConnection'
import { Splash } from '@views/Splash'
import { DisabledFacility } from '@views/DisabledFacility'
// Hooks
import { useAppUpdate } from '@hooks/useAppUpdate'
import { useAssets } from '@hooks/useAssets'
import { useAsyncStorage } from '@hooks/useAsyncStorage'
import { useDeviceId } from '@hooks/useDeviceId'
import { useInduction } from '@hooks/useInduction'
import { useLocationServices } from '@hooks/useLocationServices'
import { useNetServices } from '@hooks/useNetServices'
import { useNotifications } from '@hooks/useNotifications'
import { usePushToken } from '@hooks/usePushToken'
import { useSelectedTerminal } from '@hooks/useSelectedTerminal'
import { useSuspensions } from '@hooks/useSuspensions'
import { useTerminals } from '@hooks/useTerminals'
import { useUserAuth } from '@hooks/useUserAuth'
// Services
import { SocketServices } from '@services/SocketServices'
// React Native
import { LogBox, Platform, View } from 'react-native'
// Socket.io
import { useUserInfo } from '@hooks/useUserInfo'
// Components
import { DevIndicator } from '@components/DevIndicator'
// Toast
import { ToastProvider } from 'react-native-toast-notifications'
// sentry
import ErrorBoundary from '@modules/common/wrappers/ErrorBoundary'
import * as Sentry from 'sentry-expo'

LogBox.ignoreAllLogs()

// eslint-disable-next-line no-undef
if (!__DEV__) {
  Sentry.init({
    dsn: 'https://82cd07980d0bce044aa16ec1fb15090b@o4505713690935296.ingest.sentry.io/4506225208983552',
    environment: 'production'
  })
}

export default function App () {
  SocketServices.connect()

  // Welcome Screen
  const [showWelcomeScreen, setShowWelcomeScreen] = useAsyncStorage(
    'intro',
    true
  )

  // Selected Terminal
  const [selectedTerminal, setSelectedTerminal] = useSelectedTerminal()

  // 1. APP IS READY
  const assetsReady = useAssets()
  useAppUpdate()
  const terminals = useTerminals()
  const deviceId = useDeviceId()
  const pushToken = usePushToken()

  // 2. INTERNET IS READY
  const { isConnected, isInternetReachable } = useNetServices()

  // 3. HOME IS READY
  const userAuth = useUserAuth()
  const { userInfo } = useUserInfo({
    userAuth,
    selectedTerminal,
    terminals
  })
  const notifications = useNotifications({ userAuth, selectedTerminal })
  const suspensions = useSuspensions({ userAuth, selectedTerminal })
  const induction = useInduction({ userInfo, selectedTerminal, terminals })

  // Location Services
  useLocationServices({
    user: {
      auth: userAuth,
      data: userInfo
    },
    selectedTerminal,
    deviceId,
    pushToken,
    showWelcomeScreen,
    isConnected,
    isInternetReachable
  })

  const APP_IS_READY = terminals && assetsReady && deviceId !== undefined
  const INTERNET_IS_READY = isConnected && isInternetReachable
  const HOME_IS_READY =
    userAuth &&
    userInfo &&
    notifications !== undefined &&
    suspensions !== undefined &&
    induction !== undefined

  const selectedTerminalData =
    terminals !== undefined ? Object.entries(terminals)?.find(([id]) => id === selectedTerminal)?.[1] : undefined
  const selectedTerminalIsMigrated = selectedTerminalData?.migrated ?? false

  return (
    <View
      style={{
        flex: 1
      }}
    >
      <AppContext.Provider
        value={{
          terminals,
          selectedTerminal,
          setSelectedTerminal
        }}
      >
        <ErrorBoundary>
          <PaperProvider theme={theme}>
            <ToastProvider>
              <Portal.Host>
                <DevIndicator />
                {APP_IS_READY &&
                (INTERNET_IS_READY
                  ? (
                    <NavigationContainer>
                      {showWelcomeScreen && Platform.OS !== 'web'
                        ? (
                          <WelcomeNavigator
                            setShowWelcomeScreen={setShowWelcomeScreen}
                          />
                          )
                        : HOME_IS_READY
                          ? (
                            <UserContext.Provider
                              value={{
                                notifications,
                                suspensions,
                                induction,
                                userAuth,
                                userInfo,
                                pushToken
                              }}
                            >
                              {selectedTerminalIsMigrated === true ? <DisabledFacility /> : <HomeNavigator />}
                            </UserContext.Provider>
                            )
                          : (
                            <AuthNavigator />
                            )}
                    </NavigationContainer>
                    )
                  : (
                    <NoConnection />
                    ))}
              </Portal.Host>
              <Splash appIsReady={APP_IS_READY} />
            </ToastProvider>
          </PaperProvider>
        </ErrorBoundary>
      </AppContext.Provider>
    </View>
  )
}
