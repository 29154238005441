// react
import { useState, useContext, useEffect } from 'react'
import AppContext from '@context/AppContext'
import UserContext from '@context/UserContext'
// constants
import { TIME_VALUES } from '@modules/permitToWork/constants'
// ui
import { CreateVisitFormSchema, CreateVisitFormValues } from '../models/CreateVisitFormSchema'
// external
import { VisitManagementHosts } from '../models'
import { createPendingVisit, getTerminalVisitHosts, getTerminalVisitQuestions } from '../services'
import { useToast } from 'react-native-toast-notifications'
import { FormikHelpers, useFormik, FormikProps, getIn } from 'formik'
import moment from 'moment'

export const useCreateVisitForm = (): { hosts: VisitManagementHosts, filteredTimeValues: typeof TIME_VALUES, form: FormikProps<CreateVisitFormValues>, isLoading: boolean } => {
  const [hosts, setHosts] = useState<VisitManagementHosts>([])
  const [isLoading, setIsLoading] = useState(false)
  const { selectedTerminal } = useContext(AppContext)
  const { userInfo } = useContext(UserContext)
  const toast = useToast()
  const [filteredTimeValues, setFilteredTimeValues] = useState<Array<{
    value: string // 1200AM
    label: string // 12:00 AM
  }>>(TIME_VALUES)

  const initialValues: CreateVisitFormValues = {
    date: new Date(),
    checkIn: filteredTimeValues[0].value,
    checkOut: filteredTimeValues[1].value,
    host: '',
    questions: []
  }

  const filterAvailableTimeValues = (selectedDate: moment.Moment): void => {
    const current = moment()

    if (selectedDate.isSame(current, 'day')) {
      const filtered = TIME_VALUES.filter((timeValue) => {
        const currentTime = moment(selectedDate, 'HHmmA')
        const time = moment(timeValue.value, 'HHmmA')
        return time.isAfter(currentTime)
      })

      setFilteredTimeValues(filtered)
    } else {
      setFilteredTimeValues(TIME_VALUES)
    }
  }

  const onSubmit = async (data: CreateVisitFormValues, formikHelpers: FormikHelpers<CreateVisitFormValues>): Promise<void> => {
    setSubmitting(true)
    await createPendingVisit({
      selectedTerminal,
      hostId: data.host,
      date: data.date,
      checkIn: data.checkIn,
      checkOut: data.checkOut,
      userInfo,
      questions: data.questions?.map((question) => ({
        question: question.question,
        answer: question.answer
      }))
    }).then(() => {
      toast.show('Visit created successfully', { type: 'success' })
    }).catch((error) => {
      toast.show(error.message, { type: 'danger' })
    }).finally(() => {
      setSubmitting(false)
      resetFormData()
    })
  }

  const resetFormData = (): void => {
    resetForm({
      values: {
        date: new Date(),
        checkIn: filteredTimeValues[0].value,
        checkOut: filteredTimeValues[1].value,
        host: '',
        questions: []
      },
      errors: {},
      touched: {},
      isSubmitting: false
    })
    void fetchFormData()
  }

  const form: FormikProps<CreateVisitFormValues> = useFormik<CreateVisitFormValues>({
    initialValues,
    validationSchema: CreateVisitFormSchema,
    onSubmit
  })

  const { values, setFieldValue, setSubmitting, resetForm } = form

  const selectedData = getIn(values, 'date')

  const fetchFormData = async (): Promise<void> => {
    try {
      setIsLoading(true)
      const [hosts, questions] = await Promise.all([
        getTerminalVisitHosts({ selectedTerminal }),
        getTerminalVisitQuestions({ selectedTerminal })
      ])

      setHosts(hosts)

      console.log('Data fetched')

      if (questions == null) {
        return
      }

      void setFieldValue('questions', questions?.map((question) => ({
        ...question,
        answer: ''
      })))
    } catch (error: any) {
      toast.show(error.message, { type: 'danger' })
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    void setFieldValue('checkIn', filteredTimeValues[0].value)
    void setFieldValue('checkOut', filteredTimeValues[1].value)
  }, [filteredTimeValues])

  useEffect(() => {
    const date = moment(selectedData)
    if (date.isValid()) {
      filterAvailableTimeValues(date)
    }
  }, [selectedData])

  useEffect(() => {
    void fetchFormData()
  }, [])

  return {
    hosts,
    filteredTimeValues,
    isLoading,
    form
  }
}
