// External
import { StyleSheet, View } from 'react-native'
import { useTranslation } from 'react-i18next'
import { format } from 'date-fns'
// Components
import { P } from '@common/components/Typography'
// Models
import { PermitToWork } from '@permitToWork/models'

interface Props {
  item: PermitToWork
}

export const DetailDates = ({ item }: Props): JSX.Element => {
  const { t } = useTranslation()

  return (
    <View style={styles.container}>
      <View style={styles.itemsContainer}>
        <View style={{ flexDirection: 'row', alignItems: 'flex-end' }}>
          <P color='accent' bold style={styles.sectionTitle}>
            {t('permitToWorkItem.from')}
          </P>

          <View style={styles.divider} />
        </View>

        <P color='textLight' style={styles.dateText}>
          {format(item.startDate.toDate(), 'd MMM yyyy, h:m aaa')}
        </P>
      </View>

      <View style={styles.itemsContainer}>
        <View style={{ flexDirection: 'row', alignItems: 'flex-end' }}>
          <P color='accent' bold style={styles.sectionTitle}>
            {t('permitToWorkItem.to')}
          </P>

          <View style={styles.divider} />
        </View>

        <P color='textLight' style={styles.dateText}>
          {format(item.endDate.toDate(), 'd MMM yyyy, h:m aaa')}
        </P>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  itemsContainer: {
    flexDirection: 'column',
    flex: 1
  },
  divider: {
    height: 0.5,
    backgroundColor: '#DADADA',
    flex: 1,
    marginBottom: 6,
    marginLeft: 18,
    marginRight: 32
  },
  container: {
    flexDirection: 'row',
    width: '100%'
  },
  sectionTitle: {
    fontSize: 11,
    textTransform: 'uppercase',
    letterSpacing: 1
  },
  dateText: {
    marginLeft: 8,
    fontSize: 11,
    fontWeight: '500',
    fontVariant: ['tabular-nums']
  }
})
