import React from 'react'
// Context
import AppContext from '@context/AppContext'
// RN
import { StyleSheet, TouchableOpacity, View } from 'react-native'
// Paper
import { useTheme, Text } from 'react-native-paper'
// Components
import { Loader } from '@components/Dialog/Loader'
import { TerminalSelectorModal } from '@components/Modal/TerminalSelectorModal'
import { Flag } from '@common/components/Flag'
// Icons
import { Ionicons } from '@expo/vector-icons'
// Hooks
import { useClosestTerminal } from '@hooks/useClosestTerminal'
// i18next
import { useTranslation } from 'react-i18next'

export const DropdownTerminal = () => {
  const context = React.useContext(AppContext)
  const closestTerminalLimitReached = useClosestTerminal()
  const [showModal, setShowModal] = React.useState(false)
  const { colors } = useTheme()
  const { t } = useTranslation()

  const styles = StyleSheet.create({
    container: {
      marginTop: 12,
      borderWidth: 1,
      borderColor: colors.placeholder,
      width: '100%',
      height: 46,
      justifyContent: 'center',
      paddingHorizontal: 20,
      paddingTop: 12,
      display: 'flex',
      flexDirection: 'row'
    },
    label: {
      position: 'absolute',
      color: colors.placeholder,
      fontSize: 10,
      top: -10,
      left: 10,
      paddingHorizontal: 6,
      backgroundColor: 'white'
    },
    labelContainer: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      alignItems: 'flex-start',
      paddingLeft: 4
    },
    text: {
      fontSize: 14,
      color: colors.accent,
      flex: 1
    },
    placeholder: {
      fontSize: 14,
      color: colors.placeholder,
      flex: 1
    }
  })

  return (
    <>
      <Loader
        title={t('dropdownTerminal.title')}
        subtitle={t('dropdownTerminal.subtitle')}
        visible={!context.selectedTerminal && !closestTerminalLimitReached}
      />
      <TerminalSelectorModal
        onDismiss={() => setShowModal(false)}
        onSelectedTerminal={terminal => context.setSelectedTerminal(terminal)}
        showModal={showModal}
      />
      <TouchableOpacity
        onPress={() => setShowModal(true)}
        style={styles.container}
      >
        <Text style={styles.label}>Terminal</Text>
        {context.selectedTerminal
          ? (
            <View
              style={styles.labelContainer}
            >
              <Flag
                style={{ marginRight: 4, marginTop: 3 }}
                code={context.terminals[context.selectedTerminal]?.countryISO}
                size={16}
              />
              <Text style={styles.text}>
                {context.terminals[context.selectedTerminal]?.name}
              </Text>
            </View>
            )
          : (
            <Text style={styles.placeholder}>Select Terminal</Text>
            )}
        <Ionicons name='chevron-down' size={20} color={colors.placeholder} />
      </TouchableOpacity>
    </>
  )
}
