// External
import { View, StyleSheet } from 'react-native'
import { format } from 'date-fns'
import { useTranslation } from 'react-i18next'
// Components
import { P } from '@common/components/Typography'
// Constants
import { STATE_COLORS } from '@permitToWork/constants'
import { colors } from '@common/constants'
// Models
import { PermitToWork } from '@modules/permitToWork/models'
// Utils
import { getAppState } from '@permitToWork/utils'

interface Props {
  item: PermitToWork
}

export const DetailHeader = ({ item }: Props): JSX.Element => {
  const { t } = useTranslation()

  return (
    <View style={styles.header}>
      <View style={{ flexDirection: 'row' }}>
        <P bold color='textLight' style={styles.sectionTitle}>
          {t('permitToWorkItem.requestDate')}
        </P>
        <P light style={{ fontSize: 11, marginLeft: 8 }}>
          {format(item.requestDate.toDate(), 'y/MM/dd HH:mm')}
        </P>
      </View>

      <View
        style={{
          ...styles.statusBadge,
          backgroundColor:
            colors[STATE_COLORS[getAppState({ ptw: item })]]
        }}
      >
        <P bold style={styles.badgeText}>
          {t('permitToWorkStates.' + getAppState({ ptw: item }))}
        </P>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingVertical: 6,
    backgroundColor: '#F8F8F8',
    borderBottomColor: '#DCDCDC',
    borderBottomWidth: 0.5,
    paddingHorizontal: 30
  },
  sectionTitle: {
    fontSize: 11,
    textTransform: 'uppercase',
    letterSpacing: 1
  },
  badgeText: {
    fontSize: 11,
    textTransform: 'uppercase',
    color: 'white'
  },
  statusBadge: {
    borderRadius: 4,
    paddingHorizontal: 4,
    marginLeft: 4
  }
})
