import { useState } from 'react'
// Paper
import {
  Checkbox,
  Dialog,
  HelperText,
  Portal,
  TextInput,
  useTheme
} from 'react-native-paper'
// Dropdown
import DropDown from '@components/Dropdown/Dropdown'
// RN
import { TouchableOpacity, View } from 'react-native'
// Components
import { P } from '@components/Typography/P'
// Expo
import * as Linking from 'expo-linking'
// i18next
import { useTranslation } from 'react-i18next'
// Picker
import { Calendar } from 'react-native-calendars'
import DateTimePickerModal from 'react-native-modal-datetime-picker'
// Paper Select
import { PaperSelect } from 'react-native-paper-select'
// Date-fns
import { format } from 'date-fns'
// Icons

export const FormikInput = (
  {
    input = {},
    handleBlur,
    inputs,
    index,
    handleSubmit,
    errors,
    touched,
    value,
    setFieldValue,
    setFieldTouched,
    minDate
  },
  ...props
) => {
  const [visibleDropdown, setVisibleDropdown] = useState(false)
  const [visibleCalendar, setVisibleCalendar] = useState(false)
  const [isDatePickerVisible, setDatePickerVisibility] = useState(false)
  const theme = useTheme()
  const { colors } = theme
  const { t, i18n } = useTranslation()

  if (input.type === 'calendar') {
    return (
      <>
        <TouchableOpacity
          onPress={() => {
            setVisibleCalendar(true)
          }}
        >
          <View pointerEvents='none'>
            <TextInput
              value={
                value?.toLocaleString(i18n.language, {
                  dateStyle: 'medium'
                }) || ''
              }
              placeholder='Date'
              placeholderTextColor={colors.mediumGray}
              right={
                <TextInput.Icon iconColor={colors.orange} icon='calendar' />
              }
              editable={false}
              dense
              label={input.props.label}
              mode='outlined'
              contentStyle={{ fontFamily: 'Maersk-Regular' }}
              outlineColor={colors.lightGray}
              {...input.props}
              {...props}
            />
          </View>
        </TouchableOpacity>
        <HelperText
          style={{ marginBottom: 4 }}
          type='info'
          visible={touched[input.name] || false}
        >
          {errors[input.name]}
        </HelperText>
        <Portal>
          <Dialog
            visible={visibleCalendar}
            onDismiss={() => setVisibleCalendar(false)}
          >
            <Dialog.Title>{input.props.label}</Dialog.Title>
            <Dialog.Content>
              <Calendar
                minDate={minDate || new Date()}
                onDayPress={day => {
                  setFieldValue(input.name, new Date(day.timestamp))
                  setVisibleCalendar(false)
                }}
                markedDates={
                  value
                    ? {
                        [format(value, 'yyyy-MM-dd')]: {
                          selected: true,
                          selectedColor: colors.primary
                        }
                      }
                    : {}
                }
                theme={{
                  arrowWidth: 33,
                  arrowHeight: 32,
                  selectedDayTextColor: '#ffffff',
                  todayTextColor: colors.text,
                  dayTextColor: colors.text,
                  arrowColor: colors.primary,
                  textDayFontFamily: 'Maersk-Regular',
                  textMonthFontFamily: 'Maersk-Regular',
                  textDayHeaderFontFamily: 'Maersk-Regular'
                }}
                {...input.props}
                {...props}
              />
            </Dialog.Content>
          </Dialog>
        </Portal>
      </>
    )
  }

  if (input.type === 'datePicker') {
    return (
      <TouchableOpacity onPress={() => setDatePickerVisibility(true)}>
        <TextInput
          pointerEvents='none'
          editable={false}
          key={input.name}
          ref={input.ref}
          value={
            value?.toLocaleString(i18n.language, {
              timeStyle: 'short',
              dateStyle: 'short'
            }) || ''
          }
          onBlur={handleBlur(input.name)}
          right={
            <TextInput.Icon
              color={colors.text}
              name='calendar'
              onPress={() => setDatePickerVisibility(true)}
            />
          }
          {...input.props}
          {...props}
        />
        <DateTimePickerModal
          textColor={colors.text}
          isDarkModeEnabled={false}
          cancelTextIOS={t('common.cancel')}
          confirmTextIOS={t('common.confirm')}
          locale={i18n.language}
          isVisible={isDatePickerVisible}
          mode='datetime'
          onConfirm={date => {
            setFieldTouched(input.name, true)
            setFieldValue(input.name, date)
            setDatePickerVisibility(false)
          }}
          onCancel={() => {
            setFieldTouched(input.name, true)
            setDatePickerVisibility(false)
          }}
        />
        <HelperText
          style={{ marginBottom: 4 }}
          type='info'
          visible={touched[input.name] || false}
        >
          {errors[input.name]}
        </HelperText>
      </TouchableOpacity>
    )
  }

  if (input.type === 'textInput') {
    return (
      <View style={{ display: input.invisible ? 'none' : 'flex' }}>
        <TextInput
          key={input.name}
          ref={input.ref}
          value={value}
          onChangeText={text => {
            if (input.noWhiteSpace) {
              setFieldValue(input.name, text.replace(/\s/g, ''))
            } else if (input.lowerCase) {
              setFieldValue(input.name, text.toLowerCase())
            } else {
              setFieldValue(input.name, text)
            }
          }}
          onBlur={handleBlur(input.name)}
          onSubmitEditing={() => {
            if (index < inputs.length - 1) {
              inputs[index + 1].ref.current?.focus()
            } else if (input.props.returnKeyType === 'done') {
              handleSubmit()
            }
          }}
          {...input.props}
          {...props}
        />
        <HelperText
          style={{ marginBottom: 4 }}
          type='info'
          visible={touched[input.name] || false}
        >
          {errors[input.name]}
        </HelperText>
      </View>
    )
  }

  if (input.type === 'dropdown') {
    return (
      <View>
        <DropDown
          label={input.props.label}
          placeholder={input.props.label}
          mode='outlined'
          visible={visibleDropdown}
          showDropDown={() => setVisibleDropdown(true)}
          onDismiss={() => setVisibleDropdown(false)}
          value={value || undefined}
          setValue={itemSelected => {
            setFieldValue(input.name, itemSelected)
          }}
          list={input.dropdownValues || []}
          inputProps={{ style: { height: 40 }, ...input.props }}
          {...props}
        />
        <HelperText
          style={{ marginBottom: 4 }}
          type='info'
          visible={touched[input.name] || false}
        >
          {errors[input.name]}
        </HelperText>
      </View>
    )
  }

  if (input.type === 'checkbox') {
    return (
      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'flex-start'
        }}
      >
        <Checkbox.Android
          color={colors.primary}
          status={value}
          onPress={() => {
            setFieldValue(
              input.name,
              value === 'unchecked' ? 'checked' : 'unchecked'
            )
          }}
        />
        <View
          style={{
            marginTop: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start'
          }}
        >
          <P>{t('formikInput.agree')}</P>
          <TouchableOpacity
            onPress={() =>
              Linking.openURL(
                'https://app.estudiocactus.com/visitor-hsse/legal/en.html'
              )}
          >
            <P bold style={{ textDecorationLine: 'underline' }}>
              {t('formikInput.termsAndConditions')}
            </P>
          </TouchableOpacity>
        </View>
      </View>
    )
  }

  if (input.type === 'paperSelect') {
    const arrayList = input.arrayList || []
    const selectedArrayList = value
      ? arrayList.filter(item => value.includes(item._id))
      : []

    return (
      <>
        <PaperSelect
          label={input.props.label}
          value={
            input.multiEnable && value
              ? arrayList
                .filter(item => value.includes(item._id))
                .map(item => item.value)
                .join(', ')
              : value
          }
          onSelection={value => {
            setFieldValue(
              input.name,
              value.selectedList.map(selectedItem => selectedItem._id)
            )
          }}
          arrayList={arrayList}
          selectedArrayList={selectedArrayList}
          hideSearchBox
          textInputMode='outlined'
          textInputProps={{
            right: () => <TextInput.Icon icon='menu-down' />,
            outlineColor: colors.placeholder,
            activeOutlineColor: colors.primary
          }}
          checkboxProps={{
            checkboxColor: colors.primary,
            checkboxUncheckedColor: colors.caption,
            checkboxLabelStyle: {
              color: colors.text,
              fontFamily: 'Maersk-Regular',
              fontSize: 14,
              lineHeight: 20
            }
          }}
          dialogCloseButtonText={t('common.cancel')}
          dialogDoneButtonText={t('common.confirm')}
          multiEnable={input.multiEnable}
          selectAllEnable={input.selectAllEnable}
        />
        <HelperText
          style={{ marginBottom: 4 }}
          type='info'
          visible={touched[input.name] || false}
        >
          {errors[input.name]}
        </HelperText>
      </>
    )
  }

  return null
}
