// External
import { TextStyle } from 'react-native'
import { Caption as PaperCaption } from 'react-native-paper'
import { ReactNode } from 'react'
// Constants
import { colors } from '@common/constants'

interface Props {
  bold?: boolean
  light?: boolean
  link?: boolean
  color?: keyof typeof colors
  style?: TextStyle
  justify?: boolean
  children: ReactNode
}

export const Caption = ({ bold = false, light = false, link = false, color, style, justify = false, children }: Props): JSX.Element => {
  return (
    <PaperCaption
      style={[{
        textAlign: justify ? 'justify' : 'left',
        color: color !== undefined ? colors[color] : colors.accent,
        fontFamily: bold ? 'Maersk-Bold' : light ? 'Maersk-Light' : 'Maersk-Regular',
        ...(link && { textDecorationLine: 'underline', fontFamily: 'Maersk-Bold' })
      }, style]}
    >
      {children}
    </PaperCaption>
  )
}
