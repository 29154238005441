import { useContext, useEffect, useState } from 'react'
// Context
import AppContext from '@context/AppContext'
// Geolib
import { getDistance } from 'geolib'
// Location
import * as Location from 'expo-location'
import { Platform } from 'react-native'

const LIMIT_DIALOG = 10 * 1000

export const useClosestTerminal = () => {
  const context = useContext(AppContext)
  const [
    closestTerminalLimitReached,
    setClosestTerminalLimitReached
  ] = useState(false)
  const [closestTerminal, setClosestTerminal] = useState()

  useEffect(() => {
    (async () => {
      if (!context.selectedTerminal) {
        const { granted } = await Location.getForegroundPermissionsAsync()
        if (granted) {
          getClosestTerminal()
        } else {
          setClosestTerminalLimitReached(true)
        }
      }
    })()
  }, [])

  useEffect(() => {
    if (
      !context.selectedTerminal &&
      closestTerminal &&
      !closestTerminalLimitReached
    ) {
      context.setSelectedTerminal(closestTerminal)
    }
  }, [closestTerminal, closestTerminalLimitReached])

  const getClosestTerminal = async () => {
    const timeout = setTimeout(() => {
      setClosestTerminalLimitReached(true)
    }, LIMIT_DIALOG)

    try {
      const locationPayload = {
        accuracy: Location.Accuracy.Highest,
        maximumAge: LIMIT_DIALOG
      }

      const location = await (
        Platform.OS === 'web'
          ? Location.getCurrentPositionAsync(locationPayload)
          : Location.getLastKnownPositionAsync(locationPayload))

      if (!location) {
        clearTimeout(timeout)
        setClosestTerminalLimitReached(true)
        return
      }

      const terminalsDistances = Object.keys(context.terminals).map(
        terminal => {
          const terminalInfo = context.terminals[terminal]
          const distance = getDistance(
            {
              latitude: location?.coords?.latitude,
              longitude: location?.coords?.longitude
            },
            {
              latitude: terminalInfo.coords.latitude,
              longitude: terminalInfo.coords.longitude
            }
          )
          return {
            terminalName: terminal,
            distance
          }
        }
      )
      if (!context.selectedTerminal) {
        const closestTerminal = terminalsDistances.reduce((prev, curr) =>
          prev.distance < curr.distance ? prev : curr
        )
        setClosestTerminal(closestTerminal.terminalName)
      }
    } catch (error) {
      console.log(error)
      setClosestTerminal()
    }
  }

  return closestTerminalLimitReached
}
