import React from 'react'
// Paper
import { Caption as CaptionPaper, useTheme } from 'react-native-paper'

export const Caption = ({ bold, light, link, color, style, justify, ...props }) => {
  const { colors } = useTheme()

  return (
    <CaptionPaper
      style={{
        textAlign: justify ? 'justify' : 'left',
        color: colors[color] || colors.text,
        fontFamily: bold ? 'Maersk-Bold' : light ? 'Maersk-Light' : 'Maersk-Regular',
        ...(link && { textDecorationLine: 'underline', fontFamily: 'Maersk-Bold' }),
        ...style
      }}
      {...props}
    >
      {props.children}
    </CaptionPaper>
  )
}
