// Navigation
import { createStackNavigator } from '@react-navigation/stack'
// Screens
import { SuspensionsScreen } from '@views/Home/Suspension/SuspensionsScreen'
import { AppealFormScreen } from '@views/Home/Suspension/AppealFormScreen'
import { PaymentScreen } from '@views/Home/Suspension/PaymentScreen'
// Paper
import { useTheme } from 'react-native-paper'

const Stack = createStackNavigator()

export const SuspensionsNavigator = () => {
  const { colors } = useTheme()
  return (
    <Stack.Navigator
      initialRouteName='AllSuspensions'
      screenOptions={{
        headerShown: false
      }}
    >
      <Stack.Screen
        options={{ headerShown: false }}
        name='AllSuspensions'
        component={SuspensionsScreen}
      />
      <Stack.Screen
        options={{ headerShown: false }}
        name='AppealForm'
        component={AppealFormScreen}
      />
      <Stack.Screen
        options={{
          headerShown: true,
          headerBackTitle: 'Back',
          headerTintColor: colors.placeholder,
          headerBackTitleStyle: {
            fontFamily: 'Maersk-Regular'
          },
          headerTitleStyle: {
            fontFamily: 'Maersk-Bold',
            color: colors.text,
            textTransform: 'uppercase'
          }
        }}
        name='Payment'
        component={PaymentScreen}
      />
    </Stack.Navigator>
  )
}
