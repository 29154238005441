import { useContext } from 'react'
// Context
import UserContext from '@context/UserContext'
// RN
import { View } from 'react-native'
// Paper
import { Button, useTheme } from 'react-native-paper'
// Services
import { AuthService } from '@services/AuthService'
// i18next
import { useTranslation } from 'react-i18next'

export const DrawerFooter = ({ navigation }) => {
  const userContext = useContext(UserContext)
  const { userAuth } = userContext
  const { colors } = useTheme()
  const { t } = useTranslation()
  return (
    <View
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        marginBottom: 24,
        marginTop: 8
      }}
    >
      <Button
        color={colors.placeholder}
        icon='logout-variant'
        uppercase={false}
        mode='text'
        onPress={() => AuthService.logout(userAuth.email)}
      >
        {t('common.logout')}
      </Button>
      <Button
        color={colors.placeholder}
        icon='cog'
        uppercase={false}
        mode='text'
        onPress={() => navigation.navigate('Settings')}
      >
        {t('common.settings')}
      </Button>
    </View>
  )
}
