import { useContext } from 'react'
// Context
import AppContext from '@context/AppContext'
import UserContext from '@context/UserContext'
// Components
import { SafeArea } from '@components/Layout/SafeArea'
import { P } from '@components/Typography/P'
import { KeyboardFix } from '@components/Layout/KeyboardFix'
import { Container } from '@components/Layout/Container'
import { NotificationItem } from '@components/Notification/NotificationItem'
// i18next
import { useTranslation } from 'react-i18next'

export const NotificationsScreen = () => {
  const appContext = useContext(AppContext)
  const userContext = useContext(UserContext)
  const { userAuth } = userContext
  const { t } = useTranslation()
  const { notifications } = userContext

  return (
    <SafeArea>
      <KeyboardFix>
        <Container home style={{ flex: 1, alignItems: 'center' }}>
          {notifications.length === 0 && (
            <P style={{ opacity: 0.4 }} color='textLight'>
              {t('notificationsScreen.empty')}
            </P>
          )}
          {notifications
            .map(notificationInfo => (
              <NotificationItem
                id={notificationInfo.id}
                terminal={appContext.selectedTerminal}
                email={userAuth.email}
                key={notificationInfo.id}
                title={notificationInfo.title}
                body={notificationInfo.body}
                date={notificationInfo.date}
                notificationData={notificationInfo.notificationData}
                read={notificationInfo.read}
              />
            ))}
        </Container>
      </KeyboardFix>
    </SafeArea>
  )
}
