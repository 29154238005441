// External
import { useEffect, useContext, useState } from 'react'
import { View, ScrollView, StyleSheet } from 'react-native'
import { useTranslation } from 'react-i18next'
import { Formik } from 'formik'
import { Checkbox, useTheme, ActivityIndicator } from 'react-native-paper'
import {
  Collapse,
  CollapseHeader,
  CollapseBody
  // @ts-expect-error - package has no types
} from 'accordion-collapse-react-native'
import { FontAwesome5 } from '@expo/vector-icons'
import { StackScreenProps } from '@react-navigation/stack'
import { object, array, string, InferType } from 'yup'
// Components
import { H2, P } from '@common/components/Typography'
import { Button } from '@common/components/Button'
// Config
import { defaultRequirements } from '@permitToWork/config'
// Context
import AppContext from '@context/AppContext'
// Hooks
import { useCustomDrawerOptions } from '@hooks/useCustomDrawerOptions'
// Layout
import { SafeArea, StepFeatureCompact, Container } from '@common/layout'
// Models
import { NewPermitStackParamList } from '@modules/permitToWork/models'
// Services
import { getPermitToWorkData } from '@permitToWork/services'
// Utils
import { addOrRemoveFromArray } from '@common/utils'

const FormSchema = object().shape({
  ppeRequirements: array().of(string().required())
})

type FormValues = InferType<typeof FormSchema>

type Props = StackScreenProps<NewPermitStackParamList, 'StepPPERequirements'>

const StepRequirements = ({ navigation, route }: Props): JSX.Element => {
  const [ppeRequirements, setPPERequirements] = useState<string[]>()
  const [expandedCollapse, setExpandedCollapse] = useState({
    ppeRequirements: true
  })
  const { selectedTerminal } = useContext(AppContext)
  const { t } = useTranslation()
  const { colors } = useTheme()

  useCustomDrawerOptions({
    options: {
      goBack: () => navigation.goBack()
    }
  })

  useEffect(() => {
    void getPermitToWorkData({ selectedTerminal }).then(
      (res) => {
        setPPERequirements(res.ppeRequirements)
      }
    )
  }, [])

  return (
    <SafeArea>
      <StepFeatureCompact
        title={t('common.newPermit')}
        subtitle={`${t('signup.step') as string} 6 ${t('signup.of') as string} 8`}
        selectedStep={6}
        totalSteps={8}
      />

      <Formik<FormValues>
        validateOnChange={false}
        initialValues={{
          ppeRequirements: defaultRequirements
        }}
        onSubmit={values => {
          navigation.navigate('StepHighRisks', {
            ...route.params,
            ...((values.ppeRequirements !== undefined && values.ppeRequirements.length > 0) && {
              ppeRequirements: values.ppeRequirements
            })
          })
        }}
      >
        {({ handleSubmit, isValid, values, setFieldValue }) => (
          <Container home style={{ flex: 1 }}>
            {(ppeRequirements !== undefined)
              ? (
                <>
                  <ScrollView
                    style={{ flex: 1 }}
                    contentContainerStyle={{ flexGrow: 1 }}
                  >
                    <P style={{ marginBottom: 16 }} color='placeholder'>{t('permitToWorkStepPpeRequirements.mandatory')}</P>

                    <Collapse
                      touchableOpacityProps={{ activeOpacity: 0.5 }}
                      isExpanded={expandedCollapse.ppeRequirements}
                      onToggle={(isExpanded: boolean) => {
                        setExpandedCollapse({
                          ...expandedCollapse,
                          ppeRequirements: isExpanded
                        })
                      }}
                    >
                      <CollapseHeader style={styles.collapseHeader}>
                        <H2 bold>{t('permitToWorkStepPpeRequirements.ppeRequirements')}</H2>

                        <View style={styles.divider} />

                        <FontAwesome5
                          name={
                            expandedCollapse.ppeRequirements
                              ? 'caret-up'
                              : 'caret-down'
                          }
                          size={14}
                          color='#ccc'
                        />
                      </CollapseHeader>

                      <CollapseBody>
                        {ppeRequirements.map(requirement => (
                          <View style={styles.checkItem} key={requirement}>
                            <Checkbox.Android
                              color={colors.primary}
                              status={
                                  // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
                                  values.ppeRequirements !== undefined && values.ppeRequirements.includes(requirement)
                                    ? 'checked'
                                    : 'unchecked'
                              }
                              onPress={() => {
                                void setFieldValue(
                                  'ppeRequirements',
                                  addOrRemoveFromArray(
                                    requirement,
                                    values.ppeRequirements
                                  )
                                )
                              }}
                            />

                            <P>{t('ppeRequirements.' + requirement)}</P>
                          </View>
                        ))}
                      </CollapseBody>
                    </Collapse>
                  </ScrollView>

                  <Button
                    style={styles.continueButton}
                    disabled={!isValid || ppeRequirements === undefined}
                    onPress={handleSubmit}
                  >
                    {t('common.continue')}
                  </Button>
                </>
                )
              : (
                <ActivityIndicator />
                )}
          </Container>
        )}
      </Formik>
    </SafeArea>
  )
}
export default StepRequirements

const styles = StyleSheet.create({
  continueButton: {
    alignSelf: 'flex-end',
    width: '100%',
    marginBottom: 24
  },
  divider: {
    marginHorizontal: 8,
    marginBottom: 4,
    backgroundColor: '#eee',
    flex: 1,
    height: 1
  },
  collapseHeader: {
    flexDirection: 'row',
    alignItems: 'flex-end',
    marginBottom: 12
  },
  checkItem: {
    marginVertical: 2,
    flexDirection: 'row',
    marginLeft: 8,
    alignItems: 'center'
  }
})
