// External
import { StyleSheet, View } from 'react-native'
import { useTranslation } from 'react-i18next'
// Components
import { P } from '@common/components/Typography'
import { AttachmentList } from '@components/Attachments/AttachmentList'
// Models
import { PermitToWork } from '@permitToWork/models'

interface Props {
  item: PermitToWork
}

export const DetailAttachments = ({ item }: Props): JSX.Element => {
  const { t } = useTranslation()

  return (
    <View>
      <View style={styles.dividerContainer}>
        <P color='accent' bold style={styles.sectionTitle}>
          {t('permitToWorkItem.attachments')}
        </P>

        <View style={styles.divider} />
      </View>

      <View style={{ marginTop: 8 }}>
        {/* @ts-expect-error - component is written in JS and has no defined types where onPress is optional */}
        <AttachmentList attachments={item.files} />
      </View>
    </View>

  )
}

const styles = StyleSheet.create({
  divider: {
    height: 0.5,
    backgroundColor: '#DADADA',
    flex: 1,
    marginBottom: 6,
    marginLeft: 18
  },
  sectionTitle: {
    fontSize: 11,
    textTransform: 'uppercase',
    letterSpacing: 1
  },
  sectionContent: {
    fontSize: 11,
    marginLeft: 8,
    lineHeight: 14
  },
  dividerContainer: {
    flexDirection: 'row',
    alignItems: 'flex-end'
  }
})
