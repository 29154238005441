
import en from '@locales/en.json'
import es from '@locales/es.json'
import it from '@locales/it.json'
import se from '@locales/se.json'
import hr from '@locales/hr.json'
import AsyncStorage from '@react-native-async-storage/async-storage'
import * as Localization from 'expo-localization'
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

const resources = {
  en: {
    translation: en
  },
  es: {
    translation: es
  },
  it: {
    translation: it
  },
  se: {
    translation: se
  },
  hr: {
    translation: hr
  }
}

const STORE_LANGUAGE_KEY = 'locale'

export const getDefaultLocale = locale => {
  if (Object.keys(resources).includes(locale.split('-')[0])) {
    return locale.split('-')[0]
  } else {
    return 'en'
  }
}

const languageDetectorPlugin = {
  type: 'languageDetector',
  async: true,
  init: () => { },
  detect: async function (callback) {
    try {
      await AsyncStorage.getItem(STORE_LANGUAGE_KEY).then((language) => {
        if (language) {
          return callback(language)
        } else {
          return callback(getDefaultLocale(Localization.locale))
        }
      })
    } catch (error) {
      console.log(error.message)
    }
  },
  cacheUserLanguage: async function (language) {
    try {
      await AsyncStorage.setItem(STORE_LANGUAGE_KEY, language)
    } catch (error) { }
  }
}

i18n
  .use(initReactI18next)
  .use(languageDetectorPlugin)
  .init({
    compatibilityJSON: 'v3',
    resources,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false
    },
    react: {
      useSuspense: false
    }
  })
