// React
import { useEffect } from 'react'
// Firebase
import firebase from '@config/firebase'

export const useInitialInduction = ({
  selectedTerminal,
  terminalInfo,
  userInfo,
  userAuth,
  hasLoginWith
}) => {
  useEffect(() => {
    if (hasLoginWith) {
      firebase
        .functions()
        .httpsCallable('auth-insertInitialInduction')({
          selectedTerminal,
          terminalInfo,
          userInfo,
          email: userAuth.email
        })
        .then((res) => console.log(res.data.data))
        .catch(error => {
          console.log(error.code, error.message, error.details)
        })
    }
  }, [selectedTerminal, terminalInfo, userInfo, userAuth, hasLoginWith])
}
