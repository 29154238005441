// Models
import { PermitToWork, AppStates } from '@permitToWork/models'
// Utils
import { getAppState } from './getAppState'

interface Params {
  ptw: PermitToWork
}

export const getSubtitleState = ({ ptw }: Params): string | null => {
  if (getAppState({ ptw }) === AppStates.Active) {
    return 'toFinish'
  }

  if (getAppState({ ptw }) === AppStates.Queued) {
    return 'toStart'
  }

  if (getAppState({ ptw }) === AppStates.Pending) {
    return 'toBeApproved'
  }

  return null
}
