// External
import { object, string, array } from 'yup'
import { PTWStates } from './ptw-states.enum'
import firebase from 'firebase/compat'
// Models
import { TimestampSchema, UserSchema, User } from '@common/models'
import { HighRiskCategory } from './high-risk-category.type'
import { PermitSigner } from './permit-signer.interface'
import { ArrangementAnswer } from './arrangement-answer.enum'

export const PermitToWorkSchema = object().shape({
  id: string().required(),
  company: object({
    uid: string().required(),
    name: string().required()
  }).required(),
  creator: UserSchema.required(),
  personInCharge: UserSchema.required(),
  requestDate: TimestampSchema.required(),
  startDate: TimestampSchema.required(),
  endDate: TimestampSchema.required(),
  state: string().oneOf(Object.values(PTWStates)).required(),
  description: string().required(),
  locations: array(string()).optional(),
  otherLocation: string().optional(),
  sharedWith: array(string()).required(),
  files: array(
    object().shape({
      mimeType: string().required(),
      name: string().required(),
      url: string().required()
    })
  ).optional(),
  // TODO - Fix the validation errors on PermitSignerSchema
  permitAuthorizer: object().optional(),
  permitIssuer: object().optional(),
  approvalDate: TimestampSchema.optional(),
  arrangements: object().required(),
  ppeRequirements: array(string()).optional(),
  highRisks: object().shape({
    earthWorking: array(string()).optional(),
    confinedSpaces: array(string()).optional(),
    workingAtHeight: array(string()).optional(),
    hazardousSubstances: array(string()).optional(),
    nearWater: array(string()).optional(),
    hotWork: array(string()).optional(),
    storedEnergy: array(string()).optional(),
    suspendedLoads: array(string()).optional()
  }).optional()
})

export interface PermitToWork {
  id: string
  company: {
    uid: string
    name: string
  }
  creator: User
  personInCharge: User
  requestDate: firebase.firestore.Timestamp
  startDate: firebase.firestore.Timestamp
  endDate: firebase.firestore.Timestamp
  state: PTWStates
  description: string
  locations?: string[]
  otherLocation?: string
  sharedWith: string[]
  files?: Array<{
    mimeType: string
    name: string
    url: string
  }>
  permitAuthorizer?: PermitSigner
  permitIssuer?: PermitSigner
  approvalDate?: firebase.firestore.Timestamp
  arrangements: Record<string, ArrangementAnswer>
  ppeRequirements?: string[]
  highRisks?: Partial<Record<HighRiskCategory, string[]>>
}
