import React, { useContext, useRef } from 'react'
// Context
import AppContext from '@context/AppContext'
// Components
import { Button } from '@components/Button/Button'
import { LanguageSelector } from '@components/Dropdown/LanguageSelector'
import { SignupFooter } from '@components/Footer/SignupFooter'
import { FormikInput } from '@components/Formik/FormikInput'
import { Container } from '@components/Layout/Container'
import { KeyboardFix } from '@components/Layout/KeyboardFix'
import { SafeArea } from '@components/Layout/SafeArea'
import { StepFeature } from '@components/Layout/StepFeature'
import { H2 } from '@components/Typography/H2'
// RN
import { StyleSheet, View } from 'react-native'
// Formik
import { Formik } from 'formik'
import * as Yup from 'yup'
// i18next
import { useTranslation } from 'react-i18next'

export const Step1 = ({ route, navigation }) => {
  const appContext = useContext(AppContext)
  const { t } = useTranslation()
  const { selectedTerminalToSignup, formValues = {} } = route.params
  const terminalInfo = appContext.terminals[selectedTerminalToSignup]

  const STEP = {
    current: 1,
    total: 3
  }

  const INPUTS = [
    {
      name: 'userType',
      type: 'dropdown',
      dropdownValues: terminalInfo.userTypes.map(userType => ({
        label: t(`userTypes.${userType}`),
        value: userType
      })),
      initialValue: formValues.userType,
      validationSchema: Yup.string().required(t('common.userTypeRequired')),
      ref: useRef(),
      props: {
        label: t('common.userTypeLabel'),
        returnKeyType: 'next',
        autoCapitalize: 'none',
        autoCorrect: false,
        dense: true,
        mode: 'outlined',
        blurOnSubmit: true
      }
    },
    {
      name: 'name',
      type: 'textInput',
      initialValue: formValues.name,
      validationSchema: Yup.string().required(t('step1.nameRequired')),
      ref: useRef(),
      props: {
        label: t('common.nameLabel'),
        returnKeyType: 'next',
        autoCapitalize: 'words',
        autoCorrect: false,
        dense: true,
        mode: 'outlined',
        blurOnSubmit: true
      }
    },
    {
      name: 'surname',
      type: 'textInput',
      initialValue: formValues.surname,
      validationSchema: Yup.string().required(t('common.surnameRequired')),
      ref: useRef(),
      props: {
        label: t('common.surnameLabel'),
        returnKeyType: 'done',
        autoCapitalize: 'words',
        autoCorrect: false,
        dense: true,
        mode: 'outlined',
        blurOnSubmit: true
      }
    }
  ]

  return (
    <SafeArea>
      <KeyboardFix>
        <LanguageSelector />
        <StepFeature
          title={t('signup.title')}
          subtitle={`${t('signup.step')} ${STEP.current} ${t('signup.of')} ${STEP.total}`}
          selectedStep={STEP.current}
          totalSteps={STEP.total}
        />
        <Formik
          enableReinitialize
          initialValues={INPUTS.reduce(
            (acc, key) => ({ ...acc, [key.name]: key.initialValue }),
            {}
          )}
          validationSchema={Yup.object().shape(
            INPUTS.reduce(
              (acc, key) => ({ ...acc, [key.name]: key.validationSchema }),
              {}
            )
          )}
          onSubmit={async values => {
            navigation.navigate('Step2', {
              selectedTerminalToSignup,
              formValues: { ...formValues, ...values }
            })
          }}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            isValid,
            isSubmitting,
            touched,
            errors,
            values,
            setFieldValue
          }) => (
            <Container
              home
              style={{
                flex: 1
              }}
            >
              <View style={styles.formikContainer}>
                <H2 regular>{t('step1.personalDetails')}</H2>
                {INPUTS.map((input, index) => (
                  <React.Fragment key={input.name}>
                    <FormikInput
                      input={input}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      inputs={INPUTS}
                      index={index}
                      handleSubmit={handleSubmit}
                      errors={errors}
                      touched={touched}
                      value={values[input.name]}
                      setFieldValue={setFieldValue}
                    />
                  </React.Fragment>
                ))}
              </View>
              <Button
                style={styles.submit}
                disabled={!isValid || isSubmitting}
                onPress={handleSubmit}
              >
                {t('common.continue')}
              </Button>
            </Container>
          )}
        </Formik>
      </KeyboardFix>
      <SignupFooter />
    </SafeArea>
  )
}

const styles = StyleSheet.create({
  formikContainer: {
    flex: 1,
    justifyContent: 'flex-start',
    marginTop: -18
  },
  submit: {
    alignSelf: 'flex-end',
    width: '100%',
    marginBottom: 24
  }
})
