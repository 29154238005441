import { useContext } from 'react'
// Context
import AppContext from '@context/AppContext'
// Components
import { Hero } from '@components/Layout/Hero'
import { H2 } from '@components/Typography/H2'
import { H3 } from '@components/Typography/H3'
import { P } from '@components/Typography/P'
import { Caption } from '@components/Typography/Caption'
import { SafeArea } from '@components/Layout/SafeArea'
import { KeyboardFix } from '@components/Layout/KeyboardFix'
import { Container } from '@components/Layout/Container'
import { Flag } from '@common/components/Flag'
// RN
import { StyleSheet, View, TouchableOpacity } from 'react-native'
// i18next
import { useTranslation } from 'react-i18next'

export const InductionDoneScreen = ({ navigation }) => {
  const appContext = useContext(AppContext)
  const { terminals, selectedTerminal } = appContext
  const { t } = useTranslation()
  const terminalInfo = terminals[selectedTerminal]

  return (
    <SafeArea>
      <KeyboardFix>
        <Hero
          intro={t('common.congratulations')}
          title={t('inductionDoneScreen.welcome')}
          subtitle={terminalInfo.name}
        />
        <Container home style={styles.container}>
          <View style={{ flexDirection: 'row', marginTop: -24 }}>
            <P color='caption'>{t('inductionDoneScreen.expiration')}</P>
            <P bold color='caption'>
              {t('inductionDoneScreen.expirationTime')}
            </P>
          </View>
          <View style={styles.divider} />
          <H2 regular color='text' style={{ marginBottom: 0, marginTop: 14 }}>
            {t('inductionDoneScreen.inductionSuccesful')}
          </H2>
          <P color='textLight' light style={{ marginBottom: 20 }}>
            {t('inductionDoneScreen.allowedAccessTo')}
          </P>
          <View style={styles.rowCenter}>
            <Flag
              style={{ marginRight: 8 }}
              code={terminalInfo?.countryISO}
              size={16}
            />
            <H3 bold>APM Terminals {terminalInfo.name}</H3>
          </View>
          <Caption color='textLight' light style={{ marginTop: -4 }}>
            {terminalInfo.address}
          </Caption>
          <View style={styles.footer}>
            <View style={{ flexDirection: 'row' }}>
              <P color='caption'>{t('inductionDoneScreen.indication')} {' '}</P>
              <TouchableOpacity onPress={() => navigation.navigate('Profile')}>
                <P link color='primary'>{t('inductionDoneScreen.profilePage')}</P>
              </TouchableOpacity>
            </View>
            <P color='caption'>{t('inductionDoneScreen.getAuthorizationPass')}</P>
          </View>
        </Container>
      </KeyboardFix>
    </SafeArea>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginTop: 0,
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  divider: {
    width: '60%',
    marginVertical: 6,
    backgroundColor: '#F2F2F2',
    height: 1.5
  },
  footer: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  rowCenter: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  }
})
