// RN
import {
  SafeAreaView,
  StyleSheet,
  Platform,
  StatusBar
} from 'react-native'

export const SafeArea = ({ children }) => {
  return (
    <SafeAreaView style={styles.safeArea}>
      {children}
    </SafeAreaView>
  )
}

const styles = StyleSheet.create({
  safeArea: {
    flex: 1,
    backgroundColor: 'white',
    paddingTop: Platform.OS === 'android' ? StatusBar.currentHeight : 0
  }
})
