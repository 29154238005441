// React
import { useState, useEffect, useRef } from 'react'
// Components
import { Caption } from '@components/Typography/Caption'
import { Button } from '@components/Button/Button'
import { AttachmentList } from './AttachmentList'
// React Native
import { ScrollView, StyleSheet } from 'react-native'
// Paper
import { useTheme } from 'react-native-paper'
// Icons
import { Entypo } from '@expo/vector-icons'
// Document Picker
import * as DocumentPicker from 'expo-document-picker'
// Image picker
import * as ImagePicker from 'expo-image-picker'
// ActionSheet
import ActionSheet from 'react-native-actionsheet'
// i18next
import { useTranslation } from 'react-i18next'

export const AddAttachment = ({ onAttachment }) => {
  const { colors } = useTheme()
  const [attachments, setAttachments] = useState([])
  const actionSheet = useRef()
  const { t } = useTranslation()

  const pickImage = async () => {
    const result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.All,
      allowsEditing: true,
      aspect: [4, 3],
      quality: 1
    })

    result.name = result.uri.split('/').pop()
    result.mimeType = 'image/jpeg'

    if (!result.cancelled) {
      setAttachments(prev => [...prev, result])
    }
  }

  const openCamera = async () => {
    const cameraPermissions = await ImagePicker.requestCameraPermissionsAsync()

    if (cameraPermissions.granted) {
      const result = await ImagePicker.launchCameraAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.All,
        allowsEditing: true,
        aspect: [4, 3],
        quality: 1
      })

      if (!result.cancelled) {
        result.name = result.uri.split('/').pop()
        result.mimeType = 'image/jpeg'
        setAttachments(prev => [...prev, result])
      }
    }
  }

  useEffect(() => {
    onAttachment(attachments)
  }, [attachments.length])

  return (
    <>
      <ActionSheet
        ref={actionSheet}
        title={t('appealAttachment.title')}
        options={['Open Camera', 'Open Gallery', 'Open Documents', 'Cancel']}
        cancelButtonIndex={3}
        onPress={index => {
          switch (index) {
            case 0:
              openCamera()
              break

            case 1:
              pickImage()
              break

            case 2:
              DocumentPicker.getDocumentAsync().then(result => {
                if (result.type === 'success') {
                  setAttachments(prev => [...prev, result])
                }
              })
              break

            default:
              break
          }
        }}
      />
      <Button
        onPress={() => {
          actionSheet.current.show()
        }}
        icon='file-upload-outline'
      >
        {t('appealAttachment.uploadAttachments')}
      </Button>
      <Caption color='placeholder'>{t('appealAttachment.maxFile')}</Caption>
      <ScrollView contentContainerStyle={styles.scrollView}>
        {attachments.length === 0
          ? (
            <>
              <Entypo name='attachment' size={24} color={colors.placeholder} />
              <Caption bold color='placeholder'>
                {t('common.noAttachments')}
              </Caption>
            </>
            )
          : (
            <AttachmentList
              attachments={attachments}
              onPress={attachment =>
                setAttachments(
                  attachments.filter(item => item.uri !== attachment.uri)
                )}
            />
            )}
      </ScrollView>
    </>
  )
}
const styles = StyleSheet.create({
  scrollView: {
    minHeight: 100,
    flex: 1,
    backgroundColor: '#f7f7f7',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center'
  },
  itemContainer: {
    marginVertical: 4,
    marginHorizontal: 12,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  }
})
