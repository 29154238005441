// React
import React, { useEffect, useContext } from 'react'
// Context
import DrawerContext from '@context/DrawerContext'
// Components
import { UpdateProfileForm } from '@components/Profile/UpdateProfileForm'
// i18n
import { useTranslation } from 'react-i18next'

export const UpdateProfileScreen = ({ navigation }) => {
  const { t } = useTranslation()
  const drawerContext = useContext(DrawerContext)

  useEffect(() => {
    drawerContext.setDrawerScreenOptions({
      headerTitle: t('common.updateProfile'),
      goBack: () => navigation.goBack()
    })

    return () => {
      drawerContext.setDrawerScreenOptions({})
    }
  }, [])

  return (
    <UpdateProfileForm
      onUpdatedProfile={() => navigation.navigate('ProfilePassport')}
    />

  )
}
