// Firebase
import firebase from '@config/firebase'
// Storage
// Axios
import axios from 'axios'
// Constants
import Constants from 'expo-constants'

const checkUserDataStructure = userInfo => {
  return (
    userInfo.company && userInfo.name && userInfo.surname && userInfo.userType
  )
}

export const AuthService = {
  logout: async email => {
    if (email) {
      await firebase
        .firestore()
        .collection('usersMetadata')
        .doc(email)
        .set(
          {
            loggedTerminal: null
          },
          { merge: true }
        )
    }
    firebase.auth().signOut()
  },
  login: async ({ email, password }) => {
    const userInfo = await firebase
      .firestore()
      .collection('users')
      .doc(email)
      .get()

    if (!userInfo.exists) {
      throw new Error('No user found')
    }

    const checkStructure = checkUserDataStructure(userInfo.data())

    return new Promise((resolve, reject) => {
      if (checkStructure) {
        firebase
          .auth()
          .signInWithEmailAndPassword(email, password)
          .then(() => {
            resolve()
          })
          .catch(error => {
            reject(error)
          })
      } else {
        reject(new Error('User data structure is not correct'))
      }
    })
  },
  getEmailFromLoginWith: async ({ loginWithName, loginWithValue }) => {
    return new Promise((resolve, reject) => {
      firebase
        .firestore()
        .collection('users')
        .where(`loginWith.${loginWithName}`, '==', loginWithValue)
        .limit(1)
        .get()
        .then(userInfo => {
          if (userInfo.docs.length > 0) {
            resolve(userInfo.docs[0].id)
          } else {
            reject(
              new Error(
                `No matching user with ${loginWithName} ${loginWithValue}`
              )
            )
          }
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  },
  hasLoginWithEmailAsync: async ({ email, loginWithName }) => {
    const userInfo = await firebase
      .firestore()
      .collection('users')
      .doc(email)
      .get()

    if (userInfo.exists) {
      return !!userInfo.data()?.loginWith?.[loginWithName]
    } else {
      return false
    }
  },
  signup: ({
    userType,
    name,
    surname,
    email,
    password,
    company,
    loginWithName,
    loginWithValue,
    selectedTerminal,
    terminalInfo
  }) => {
    return axios.post(
      `${Constants.expoConfig.extra.FIREBASE_FUNCTIONS_BASE_PATH}/auth-createAccount`,
      {
        userType,
        name,
        surname,
        email,
        password,
        company,
        loginWithName,
        loginWithValue,
        selectedTerminal,
        terminalInfo
      }
    )
  }
}
