// External
import fb from 'firebase/compat/app'
// Config
import firebase from '@config/firebase'
// Models
import { Attachment, User } from '@common/models'
import { Contractor, ArrangementAnswer, HighRiskCategory, PTWStates } from '@permitToWork/models'
// Utils
import { uploadAttachments } from '@utils'

interface Params {
  selectedTerminal: string
  creator: User
  startDate: Date
  endDate: Date
  company: string
  personInCharge: User
  authorizersEmail?: string
  emergencyContactPhonePrefix: string
  emergencyContactPhoneNumber: string
  locations?: string[]
  otherLocation?: string
  description: string
  sharedWith: Contractor[]
  arrangements: Record<string, ArrangementAnswer>
  ppeRequirements?: string[]
  highRisks?: Partial<Record<HighRiskCategory, string[]>>
  attachments: Attachment[]
}

export const createPermitToWork = async ({
  selectedTerminal,
  creator,
  startDate,
  endDate,
  company,
  personInCharge,
  authorizersEmail,
  emergencyContactPhonePrefix,
  emergencyContactPhoneNumber,
  locations,
  otherLocation,
  description,
  sharedWith,
  arrangements,
  ppeRequirements,
  highRisks,
  attachments
}: Params): Promise<void> => {
  try {
    const permitToWorkRef = firebase.firestore()
      .collection('terminals')
      .doc(selectedTerminal)
      .collection('modules')
      .doc('permitToWork')
    const now = new Date()

    const creatorSnapshot = await permitToWorkRef
      .collection('contractors')
      .where('email', '==', creator.email)
      .where('company.uid', '==', company)
      .get()

    const personInChargeSnapshot = await permitToWorkRef
      .collection('contractors')
      .where('email', '==', personInCharge.email)
      .where('company.uid', '==', company)
      .get()

    const companySnapshot = await permitToWorkRef
      .collection('companies')
      .where('uid', '==', company)
      .get()
    const companyObject = {
      uid: companySnapshot.docs[0].data().uid,
      name: companySnapshot.docs[0].data().name
    }

    await firebase.firestore().runTransaction(async (t) => {
      const permitToWorkSnapshot = await t.get(permitToWorkRef)

      t.update(permitToWorkRef.collection('contractors').doc(creatorSnapshot.docs[0].id), {
        totalPermitsToWork: fb.firestore.FieldValue.increment(1),
        updatedAt: now
      })

      t.update(permitToWorkRef.collection('contractors').doc(personInChargeSnapshot.docs[0].id), {
        totalPermitsToWork: fb.firestore.FieldValue.increment(1),
        updatedAt: now
      })

      if (sharedWith !== undefined) {
        for (const contractor of sharedWith) {
          t.update(permitToWorkRef.collection('contractors').doc(contractor.id), {
            totalPermitsToWork: fb.firestore.FieldValue.increment(1),
            updatedAt: now
          })
        }
      }

      const counter: number = permitToWorkSnapshot.data()?.counter ?? 0
      const newPermitId = counter + 1

      const newAttachments = attachments.length > 0
        ? await uploadAttachments({
          attachments,
          uploadUrl: `terminals/${selectedTerminal}/modules/permitToWork/data/${newPermitId}`
        })
        : undefined

      t.update(permitToWorkRef, { counter: fb.firestore.FieldValue.increment(1) })
      t.set(permitToWorkRef.collection('data').doc(newPermitId.toString()), {
        creator,
        state: PTWStates.Pending,
        requestDate: now,
        startDate,
        endDate,
        personInCharge,
        ...(authorizersEmail !== undefined && {
          permitAuthorizer: {
            email: authorizersEmail,
            signatureDate: null
          }
        }),
        emergencyContactPhonePrefix,
        emergencyContactPhoneNumber,
        company: companyObject,
        ...locations !== undefined && { locations },
        ...otherLocation !== undefined && { otherLocation },
        description,
        sharedWith: sharedWith.map((contractor) => contractor.id),
        arrangements,
        ...(ppeRequirements !== undefined && { ppeRequirements }),
        ...(highRisks !== undefined && { highRisks }),
        ...((newAttachments !== undefined) && { files: newAttachments })
      })
    })
  } catch (error) {
    console.error('Error creating Permit To Work:', error)
    return await Promise.reject(error)
  }
}
