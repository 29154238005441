// External
import { View, ScrollView, TouchableOpacity, StyleSheet, TextInput as RNTextInput, ViewStyle, Keyboard, Platform } from 'react-native'
import { useTranslation } from 'react-i18next'
import { useState, forwardRef, ForwardedRef, useEffect, useContext } from 'react'
import { TextInput, HelperText } from 'react-native-paper'
// Components
import { P } from '@common/components/Typography'
import { AvatarImage } from '@common/components/Avatar'
// Constants
import { colors } from '@common/constants'
// Context
import UserContext from '@context/UserContext'
// Models
import { AdminUserWithEmail, User } from '@common/models'

type SearchUser = AdminUserWithEmail | User

interface Props {
  style?: ViewStyle
  disabled?: boolean
  placeholderTranslationKey: string
  noMoreResultsTranslationKey: string
  selectedUser: SearchUser | undefined
  onSelect: (user: SearchUser) => void
  onRemove: () => void
  users: SearchUser[]
  helperText?: string
}

export const UserSearchInner = ({
  style,
  disabled = false,
  placeholderTranslationKey,
  noMoreResultsTranslationKey,
  selectedUser,
  onSelect,
  onRemove,
  users,
  helperText
}: Props, ref: ForwardedRef<RNTextInput>): JSX.Element => {
  const [inputIsFocused, setInputIsFocused] = useState(false)
  const [search, setSearch] = useState('')
  const [showUsers, setShowUsers] = useState(false)
  const [filteredUsers, setFilteredUsers] = useState<SearchUser[]>([])
  const userContext = useContext(UserContext)
  const { t } = useTranslation()

  useEffect(() => {
    setFilteredUsers(users)
  }, [users, userContext])

  useEffect(() => {
    if (search.length > 0) {
      const newFilteredUsers = users.filter((user) => {
        const fullName = `${user.name} ${user.surname}`.toLowerCase()
        const lowercaseSerach = search.toLowerCase()

        return fullName.includes(lowercaseSerach) || user.email.includes(lowercaseSerach)
      })

      setFilteredUsers(newFilteredUsers)
      return
    }

    setFilteredUsers(users)
  }, [search])

  return (
    <View style={[{ zIndex: 1 }, style]}>
      <TextInput
        ref={ref}
        disabled={disabled}
        dense
        mode='outlined'
        returnKeyType='search'
        value={inputIsFocused ? search : selectedUser !== undefined ? `${selectedUser.name} ${selectedUser.surname}` : ''}
        onFocus={() => {
          setInputIsFocused(true)
          setShowUsers(true)
        }}
        onChange={(event) => {
          const { text } = event.nativeEvent
          setSearch(text)
        }}
        label={t(placeholderTranslationKey)}
        onBlur={() => {
          setInputIsFocused(false)
          setTimeout(() => setShowUsers(false), 100)
        }}
        {...(!inputIsFocused && selectedUser !== undefined) && {
          right: (
            <TextInput.Icon
              icon='close'
              forceTextInputFocus={false}
              onPress={() => {
                setSearch('')
                onRemove()
              }}
            />
          )
        }}
      />

      <HelperText type='info'>
        {helperText}
      </HelperText>

      {showUsers && (
        <ScrollView
          keyboardShouldPersistTaps='handled'
          style={styles.resultsContainer}
        >
          {filteredUsers.length === 0 && (
            <P color='text' style={styles.user}>
              {t(noMoreResultsTranslationKey)}
            </P>
          )}

          {filteredUsers.map((user, index) => (
            <TouchableOpacity
              onPress={() => {
                onSelect(user)
                Keyboard.dismiss()
                Platform.OS === 'web' && setShowUsers(false)
              }}
              key={index}
              style={styles.user}
            >
              <AvatarImage name={user.name} surname={user.surname} profilePhoto={user.profilePhoto} />

              <View style={{ marginLeft: 8 }}>
                <P color='text' style={styles.name}>
                  {user.name} {user.surname}
                </P>

                <P color='textLight' style={styles.email}>
                  {user.email}
                </P>
              </View>
            </TouchableOpacity>
          ))}
        </ScrollView>
      )}
    </View>
  )
}

export const UserSearch = forwardRef(UserSearchInner)

const styles = StyleSheet.create({
  resultsContainer: {
    borderWidth: 1,
    borderColor: colors.placeholder,
    position: 'absolute',
    width: '100%',
    backgroundColor: 'white',
    top: 52,
    maxHeight: 238,
    paddingHorizontal: 12
  },
  user: {
    marginTop: 10,
    marginBottom: 10,
    flexDirection: 'row',
    alignItems: 'center'
  },
  name: {
    fontSize: 12,
    lineHeight: 14
  },
  email: {
    fontSize: 10,
    lineHeight: 12
  }
})
