// External
import { ReactNode, useContext, useEffect } from 'react'
import { StyleSheet, ScrollView } from 'react-native'
import { useTranslation } from 'react-i18next'
import { useNavigation } from '@react-navigation/native'
import { StackScreenProps } from '@react-navigation/stack'
// Components
import { PermitToWorkDropdownMenu } from '@permitToWork/components'
import {
  DetailDescription,
  DetailHeader,
  DetailDates,
  DetailLocations,
  DetailSharedWith,
  DetailArrangements,
  DetailHighRisks,
  DetailSignatures,
  DetailUser,
  DetailAttachments,
  DetailRequirements,
  DetailPhones
} from '@permitToWork/components/Detail'
// Context
import AppContext from '@context/AppContext'
import UserContext from '@context/UserContext'
import DrawerContext from '@context/DrawerContext'
// Hooks
import usePermitToWork from '@permitToWork/hooks/usePermitToWork'
// Layouts
import { SafeArea } from '@common/layout'
// Models
import { PermitToWorkStackParamList } from '@permitToWork/models'

type Props = StackScreenProps<PermitToWorkStackParamList, 'PermitToWorkDetail'>

const PermitToWorkDetail = ({ route }: Props): ReactNode => {
  const userContext = useContext(UserContext)
  const drawerContext = useContext(DrawerContext)
  const { userAuth } = userContext
  const { t } = useTranslation()
  const { permitId } = route.params
  const navigation = useNavigation()
  const { selectedTerminal } = useContext(AppContext)
  const permitToWorkItem = usePermitToWork({ selectedTerminal, permitId })

  const headerTitle = t('modules.permitToWork') as string + ` #${permitId}`

  useEffect(() => {
    let unsubscribeBlur: () => void
    if (permitToWorkItem !== undefined) {
      drawerContext.setDrawerScreenOptions({
        headerTitle,
        headerRight: () => userAuth?.email === permitToWorkItem?.personInCharge.email && (
          <PermitToWorkDropdownMenu
            permitId={permitId}
            permitState={permitToWorkItem.state}
          />
        ),
        goBack: () => navigation.goBack()
      })

      unsubscribeBlur = navigation.addListener('blur', () => {
        drawerContext.setDrawerScreenOptions({})
      })

      return () => {
        unsubscribeBlur()
      }
    }
  }, [permitToWorkItem, permitId])

  if (permitToWorkItem === undefined) {
    return null
  }

  return (
    <SafeArea style={styles.container}>
      <DetailHeader item={permitToWorkItem} />

      <ScrollView style={styles.scroll} contentContainerStyle={styles.contentContainer}>
        <DetailUser item={permitToWorkItem} user='creator' />

        <DetailUser item={permitToWorkItem} user='personInCharge' />

        <DetailPhones item={permitToWorkItem} />

        <DetailDates item={permitToWorkItem} />

        <DetailDescription item={permitToWorkItem} />

        <DetailSharedWith item={permitToWorkItem} />

        <DetailLocations item={permitToWorkItem} />

        <DetailArrangements item={permitToWorkItem} />

        <DetailRequirements item={permitToWorkItem} />

        <DetailHighRisks item={permitToWorkItem} />

        <DetailAttachments item={permitToWorkItem} />
      </ScrollView>

      <DetailSignatures item={permitToWorkItem} />
    </SafeArea>
  )
}
export default PermitToWorkDetail

const styles = StyleSheet.create({
  scroll: {
    flex: 1,
    padding: 8,
    backgroundColor: '#fff',
    paddingHorizontal: 30
  },
  container: {
    flex: 1,
    backgroundColor: '#f8f8f8',
    borderTopColor: '#eee',
    borderTopWidth: 1
  },
  contentContainer: {
    gap: 24,
    paddingBottom: 24
  }
})
