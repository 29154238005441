import { useContext, useRef, useState } from 'react'
// Context
import AppContext from '@context/AppContext'
// Components
import { Button } from '@components/Button/Button'
import { ForgotPasswordButton } from '@components/Button/ForgotPasswordButton'
import { LanguageSelector } from '@components/Dropdown/LanguageSelector'
import { LoginFooter } from '@components/Footer/LoginFooter'
import { Container } from '@components/Layout/Container'
import { Hero } from '@components/Layout/Hero'
import { KeyboardFix } from '@components/Layout/KeyboardFix'
import { SafeArea } from '@components/Layout/SafeArea'
import { Flag } from '@common/components/Flag'
// Formik
import { Formik } from 'formik'
import * as Yup from 'yup'
// RN
import { Platform, StyleSheet, View } from 'react-native'
// Paper
import { colors } from '@common/constants'
import { Text, TextInput } from 'react-native-paper'
// Toast
import { useToast } from 'react-native-toast-notifications'
// Services
import { AuthService } from '@services/AuthService'
// i18n
import { useTranslation } from 'react-i18next'
// Flags
import DropDown from '@components/Dropdown/Dropdown'
import { DropdownTerminal } from '@components/Dropdown/DropdownTerminal'

export const LoginScreen = () => {
  const toast = useToast()
  const [visibleDropdown, setVisibleDropdown] = useState(false)
  const { t } = useTranslation()
  const passwordRef = useRef(null)
  const appContext = useContext(AppContext)
  const { terminals, selectedTerminal } = appContext
  const terminalInfo = terminals[selectedTerminal]
  try {
    const urlParams = new URLSearchParams(window.location?.search)
    const urlTerminal = urlParams.get('terminal')
    appContext.setSelectedTerminal(urlTerminal)
  } catch (error) {
    console.log(error)
  }

  const submitLogin = async ({ email, password, setSubmitting }) => {
    const processLogin = async ({ email, password }) => {
      AuthService.login({ email, password })
        .then(() => {
          setSubmitting(false)
        })
        .catch(err => {
          console.log(err)
          toast.show(err.message)
          setSubmitting(false)
        })
    }
    if (email.includes('@')) {
      processLogin({ email, password })
    } else {
      AuthService.getEmailFromLoginWith({
        loginWithName: terminalInfo.loginWith.name,
        loginWithValue: email.toUpperCase()
      })
        .then(realEmail => {
          processLogin({ email: realEmail, password })
        }).catch(error => {
          console.log(error)
          toast.show(error.message)
          setSubmitting(false)
        })
    }
  }

  return (
    <SafeArea>
      <KeyboardFix>
        <LanguageSelector />
        <Hero title={t('loginScreen.loginTo')} subtitle='Visitor HSSE' />
        <Formik
          validateOnMount
          initialValues={{
            email: '',
            password: ''
          }}
          validationSchema={Yup.object().shape({
            email: Yup.string().required(),
            password: Yup.string()
              .min(6)
              .required()
          })}
          onSubmit={(values, { setSubmitting }) => {
            submitLogin({ email: values.email, password: values.password, setSubmitting })
          }}
        >
          {({
            isSubmitting,
            handleSubmit,
            handleChange,
            handleBlur,
            isValid,
            values
          }) => (
            <Container style={{ flex: 1 }}>
              <View
                style={{
                  flex: 1,
                  justifyContent: 'flex-start',
                  marginTop: -18
                }}
              >
                {Platform.OS === 'web' && selectedTerminal && (
                  <View
                    style={styles.labelContainer}
                  >
                    <Flag
                      style={{ marginRight: 8, marginTop: 3 }}
                      code={terminalInfo?.countryISO}
                      size={32}
                    />
                    <Text style={styles.text}>
                      APM Terminals {terminalInfo?.name}
                    </Text>
                  </View>
                )}
                {Platform.OS === 'web' && !selectedTerminal && (
                  <DropDown
                    label='Select Terminal'
                    placeholder='Select Terminal'
                    mode='outlined'
                    visible={visibleDropdown}
                    showDropDown={() => setVisibleDropdown(true)}
                    onDismiss={() => setVisibleDropdown(false)}
                    value={selectedTerminal || undefined}
                    setValue={itemSelected => {
                      window.location.href = `?terminal=${itemSelected}`
                    }}
                    list={[
                      ...Object.keys(terminals).map(key => ({
                        label: terminals[key].name,
                        value: key
                      }))
                    ]}
                    inputProps={{ style: { height: 40 } }}

                  />
                )}
                {appContext.selectedTerminal && (
                  <>
                    <TextInput
                      onChangeText={handleChange('email')}
                      onBlur={handleBlur('email')}
                      returnKeyType='next'
                      blurOnSubmit={false}
                      mode='outlined'
                      dense
                      autoCapitalize='none'
                      autoCorrect={false}
                      label={`${t('loginScreen.email')} ${t('common.or')} ${
                        t('loginWith.' + appContext.terminals[appContext.selectedTerminal]
                          ?.loginWith?.name)
                      }`}
                      onSubmitEditing={() => passwordRef.current.focus()}
                    />
                    <TextInput
                      ref={passwordRef}
                      onChangeText={handleChange('password')}
                      onBlur={handleBlur('password')}
                      returnKeyType='done'
                      secureTextEntry
                      mode='outlined'
                      dense
                      label={t('loginScreen.password')}
                      onSubmitEditing={handleSubmit}
                    />
                  </>
                )}
                {Platform.OS !== 'web' && (
                  <DropdownTerminal />
                )}

                {selectedTerminal && (
                  <ForgotPasswordButton />

                )}
              </View>
              {appContext.selectedTerminal && (
                <Button
                  loading={isSubmitting}
                  style={{
                    alignSelf: 'flex-end',
                    width: '100%',
                    marginBottom: 24
                  }}
                  disabled={
                    !appContext.selectedTerminal || !isValid || isSubmitting
                  }
                  onPress={handleSubmit}
                >
                  {t('common.login')}
                </Button>
              )}
            </Container>
          )}
        </Formik>
      </KeyboardFix>
      <LoginFooter />
    </SafeArea>
  )
}

const styles = StyleSheet.create({

  labelContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    paddingVertical: 20
  },
  text: {
    fontFamily: 'Maersk-bold',
    fontSize: 24,
    color: colors.accent
  },
  placeholder: {
    fontSize: 14,
    color: colors.placeholder,
    flex: 1
  }
})
