import React, { useContext, useRef } from 'react'
// Context
import AppContext from '@context/AppContext'
import UserContext from '@context/UserContext'
// Services
import { UserServices } from '@services/UserServices'
// Components
import { Button } from '@components/Button/Button'
import { FormikInput } from '@components/Formik/FormikInput'
import { Container } from '@components/Layout/Container'
import { KeyboardFix } from '@components/Layout/KeyboardFix'
import { SafeArea } from '@components/Layout/SafeArea'
import { H3 } from '@components/Typography/H3'
// RN
import { View } from 'react-native'
// Formik
import { Formik } from 'formik'
import * as Yup from 'yup'
// Toast
import { useToast } from 'react-native-toast-notifications'
// i18next
import { P } from '@components/Typography/P'
import { useTranslation } from 'react-i18next'

export const UpdateProfileForm = ({ onUpdatedProfile }) => {
  const toast = useToast()
  const appContext = useContext(AppContext)
  const { terminals, selectedTerminal } = appContext
  const userContext = useContext(UserContext)
  const { userInfo, userAuth } = userContext
  const { t } = useTranslation()
  const terminalInfo = terminals[selectedTerminal]

  const INPUTS = [
    {
      name: 'userType',
      type: 'dropdown',
      dropdownValues: terminalInfo.userTypes.map(userType => ({
        label: t(`userTypes.${userType}`),
        value: userType
      })),
      initialValue: userInfo.userType,
      validationSchema: Yup.string()
        .oneOf(terminalInfo.userTypes)
        .required('Name is a required field.'),
      ref: useRef(),
      props: {
        label: t('common.userTypeLabel'),
        returnKeyType: 'next',
        autoCapitalize: 'none',
        autoCorrect: false,
        dense: true,
        mode: 'outlined',
        blurOnSubmit: true
      }
    },
    {
      name: 'name',
      type: 'textInput',
      initialValue: userInfo.name,
      validationSchema: Yup.string().required('Name is a required field.'),
      ref: useRef(),
      props: {
        label: t('common.nameLabel'),
        returnKeyType: 'next',
        autoCapitalize: 'words',
        autoCorrect: false,
        dense: true,
        mode: 'outlined',
        blurOnSubmit: true
      }
    },
    {
      name: 'surname',
      type: 'textInput',
      initialValue: userInfo.surname,
      validationSchema: Yup.string().required(t('common.surnameRequired')),
      ref: useRef(),
      props: {
        label: t('common.surnameLabel'),
        returnKeyType: 'done',
        autoCapitalize: 'words',
        autoCorrect: false,
        dense: true,
        mode: 'outlined',
        blurOnSubmit: true
      }
    },
    {
      name: 'company',
      type: 'textInput',
      initialValue: userInfo.company,
      validationSchema: Yup.string().required(t('common.companyTypeRequired')),
      ref: useRef(),
      props: {
        label: t('common.companyTypeLabel'),
        returnKeyType: 'next',
        autoCapitalize: 'words',
        autoCorrect: false,
        dense: true,
        mode: 'outlined',
        blurOnSubmit: true,
        keyboardType: 'default'
      }
    },
    {
      name: terminalInfo.loginWith.name,
      type: 'textInput',
      mask: terminalInfo.loginWith.inputMask || '',
      noWhiteSpace: terminalInfo.loginWith.noWhiteSpace,
      initialValue: userInfo?.loginWith?.[terminalInfo?.loginWith.name],
      validationSchema: Yup.string().required(
        `${terminalInfo.loginWith.name} type is a required field.`
      ),
      ref: useRef(),
      props: {
        label: t('loginWith.' + terminalInfo.loginWith.name),
        returnKeyType: 'next',
        autoCapitalize: 'characters',
        autoCorrect: false,
        dense: true,
        mode: 'outlined',
        blurOnSubmit: true,
        keyboardType: 'default'
      }
    }
  ]

  const EXTRA_INPUTS = Object.keys(terminalInfo?.extraInformation || {}).map(
    extraInfo => ({
      userTypes: terminalInfo?.extraInformation?.[extraInfo]?.userTypes,
      name: 'extraInformation.' + extraInfo,
      inputName: extraInfo,
      type: 'textInput',
      initialValue: userInfo?.extraInformation?.[extraInfo],
      validationSchema: Yup.string().required(
        `${extraInfo} type is a required field.`
      ),
      ref: useRef(),
      props: {
        label: t('extraInformation.' + extraInfo),
        returnKeyType: 'next',
        dense: true,
        mode: 'outlined',
        blurOnSubmit: true,
        ...terminalInfo?.extraInformation?.[extraInfo]?.inputProps
      }
    })
  )
  let yupObjectValidator = Yup.object()
  EXTRA_INPUTS.forEach(extraInput => {
    extraInput.userTypes.forEach(userType => {
      yupObjectValidator = yupObjectValidator.when('userType', {
        is: value => value === userType,
        then: () => Yup.object().shape({
          [extraInput.inputName]: extraInput.validationSchema
        })

      })
    })
  })

  return (
    <SafeArea>
      <KeyboardFix>
        <Formik
          enableReinitialize
          validateOnMount
          initialValues={{
            ...INPUTS.reduce(
              (acc, key) => ({ ...acc, [key.name]: key.initialValue }),
              {}
            ),
            extraInformation: EXTRA_INPUTS.reduce(
              (acc, key) => ({ ...acc, [key.inputName]: key.initialValue }),
              {}
            )
          }}
          validationSchema={Yup.object().shape({
            ...INPUTS.reduce(
              (acc, key) => ({ ...acc, [key.name]: key.validationSchema }),
              {}
            ),

            extraInformation: yupObjectValidator
          })}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(true)
            const extraInfoKeysToUpdate = Object.keys(
              terminalInfo?.extraInformation || {}
            ).filter(item =>
              terminalInfo?.extraInformation?.[item]?.userTypes.includes(
                values?.userType
              )
            )
            values.extraInformation = extraInfoKeysToUpdate.reduce(
              (acc, key) => ({ ...acc, [key]: values.extraInformation[key] }),
              {}
            )
            if (Object.keys(values.extraInformation).length === 0) {
              delete values.extraInformation
            }
            UserServices.updateProfile({
              data: values,
              email: userAuth.email,
              selectedTerminal,
              loginWithName: terminalInfo.loginWith.name,
              loginWithValue:
                userInfo?.loginWith?.[terminalInfo?.loginWith.name]
            })
              .then(() => {
                toast.show(t('updateMissingInformationScreen.success'))
                setSubmitting(false)
                onUpdatedProfile && onUpdatedProfile()
              })
              .catch(error => {
                setSubmitting(false)
                console.log(error)
                toast.show(error.message)
              })
          }}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            isValid,
            isSubmitting,
            touched,
            errors,
            values,
            setFieldValue
          }) => (
            <Container
              home
              style={{
                flex: 1
              }}
            >
              <View style={{ flex: 1 }}>
                <View style={{ marginBottom: 28 }}>
                  <H3 bold>
                    APM Terminals {terminalInfo.name} requires additional
                    information to login.
                  </H3>
                  <P light>Please provide all the fields</P>
                </View>
                {INPUTS.map((input, index) => (
                  <React.Fragment key={input.name}>
                    <FormikInput
                      input={input}
                      onChange={input?.onChange}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      inputs={INPUTS.concat(EXTRA_INPUTS)}
                      index={index}
                      handleSubmit={handleSubmit}
                      errors={errors}
                      touched={touched}
                      value={values[input.name]}
                      setFieldValue={setFieldValue}
                    />
                  </React.Fragment>
                ))}
                {EXTRA_INPUTS.filter(input =>
                  terminalInfo?.extraInformation?.[
                    input.inputName
                  ]?.userTypes?.includes(values?.userType)
                ).map((input, index) => (
                  <React.Fragment key={input.name}>
                    <FormikInput
                      input={input}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      inputs={EXTRA_INPUTS}
                      index={index}
                      handleSubmit={handleSubmit}
                      errors={errors}
                      touched={touched}
                      value={values.extraInformation[input.inputName]}
                      setFieldValue={setFieldValue}
                    />
                  </React.Fragment>
                ))}
              </View>
              <Button
                loading={isSubmitting}
                mode='contained'
                style={{ marginVertical: 34 }}
                disabled={!isValid || isSubmitting}
                onPress={handleSubmit}
              >
                {t('common.updateProfile')}
              </Button>
            </Container>
          )}
        </Formik>
      </KeyboardFix>
    </SafeArea>
  )
}
