// Components
import { H3 } from '@components/Typography/H3'
// Paper
import { useTheme, RadioButton } from 'react-native-paper'
// RN
import { View, StyleSheet } from 'react-native'
// Components
import { P } from '@components/Typography/P'

export const QuestionItem = ({
  question,
  questionNumber,
  selectedAnswer,
  onSelectAnswer,
  answers = []
}) => {
  const { colors } = useTheme()
  return (
    <View style={{ marginBottom: 24 }}>
      <H3 bold>
        {questionNumber}. {question}
      </H3>
      <RadioButton.Group
        onValueChange={onSelectAnswer}
        value={selectedAnswer} style={{ marginTop: 10 }}
      >
        {answers.map((answer, index) => (
          <View key={answer.title} style={styles.containerAnswers}>
            <RadioButton.Android color={colors.primary} value={index} />
            <View style={styles.containerAnswer}>
              <P>{answer.title}</P>
            </View>
          </View>
        ))}
      </RadioButton.Group>
      <View style={styles.divider} />
    </View>
  )
}

const styles = StyleSheet.create({
  containerAnswers: {
    marginVertical: 6,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  containerAnswer: {
    marginLeft: 8,
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  divider: {
    marginVertical: 8,
    width: '100%',
    backgroundColor: '#F2F2F2',
    height: 1.5
  }
})
