// External
import {
  StyleSheet,
  View
} from 'react-native'
import { useTranslation } from 'react-i18next'
import { MaterialCommunityIcons } from '@expo/vector-icons'
// Components
import { P } from '@common/components/Typography'
// Config
import { theme } from '@modules/common/config/theme'
// Models
import { HighRiskCategory, PermitToWork } from '@modules/permitToWork/models'

interface Props {
  item: PermitToWork
}

export const DetailHighRisks = ({ item }: Props): JSX.Element => {
  const { t } = useTranslation()

  return (
    <View>
      <View style={styles.dividerContainer}>
        <P color='accent' bold style={styles.sectionTitle}>
          {t('permitToWorkItem.typeOfWork')}
        </P>
        <View style={styles.divider} />
      </View>

      <View style={{ marginTop: 8 }}>
        {item.highRisks !== undefined && (
          <>
            {(Object.keys(item.highRisks) as HighRiskCategory[]).map((category) => (
              <View key={category} style={{ marginBottom: 8, marginLeft: 14 }}>
                <P bold color='textLight' style={styles.subSectionTitle}>
                  {t('highRisks.' + category)}
                </P>

                <View style={{ marginTop: 4, marginLeft: 14 }}>
                  {item.highRisks?.[category]?.map((highRiskItem) => (
                    <View style={styles.item} key={highRiskItem}>
                      <MaterialCommunityIcons name='check-bold' size={16} color={theme.colors.primary} />

                      <P light style={styles.sectionContent}>
                        {t('highRisks.' + highRiskItem)}
                      </P>
                    </View>
                  ))}
                </View>
              </View>
            ))}
          </>
        )}
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  dividerContainer: {
    flexDirection: 'row',
    alignItems: 'flex-end'
  },
  divider: {
    height: 0.5,
    backgroundColor: '#DADADA',
    flex: 1,
    marginBottom: 6,
    marginLeft: 18
  },
  sectionContent: {
    fontSize: 12,
    marginLeft: 8,
    paddingBottom: 8,
    lineHeight: 16
  },
  sectionTitle: {
    fontSize: 11,
    textTransform: 'uppercase',
    letterSpacing: 1
  },
  subSectionTitle: {
    fontSize: 11,
    textTransform: 'uppercase',
    letterSpacing: 1
  },
  item: {
    marginLeft: 8,
    flexDirection: 'row',
    justifyContent: 'flex-start'
  }
})
