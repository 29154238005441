import { useEffect, useContext, useState } from 'react'
// Context
import AppContext from '@context/AppContext'
// Webview
import { WebView } from 'react-native-webview'
// RN
import { StyleSheet, View } from 'react-native'
// Firebase
import firebase from '@config/firebase'
// Paper
import { ActivityIndicator } from 'react-native-paper'
// Components
import { P } from '@components/Typography/P'

export const PaymentScreen = ({ route, navigation }) => {
  const { uri, suspensionId } = route.params
  const appContext = useContext(AppContext)
  const { selectedTerminal } = appContext
  const [showLoading, setShowLoading] = useState(false)

  useEffect(() => {
    if (suspensionId) {
      firebase.firestore()
        .collection('terminals')
        .doc(selectedTerminal)
        .collection('paymentLogs')
        .doc(suspensionId)
        .onSnapshot(snapshot => {
          if (snapshot.exists) {
            navigation.navigate('AllSuspensions')
          }
        })
    }
  }, [suspensionId])

  if (showLoading) {
    return (
      <View style={{ justifyContent: 'center', alignItems: 'center', flex: 1 }}>
        <ActivityIndicator />
        <P color='caption' style={{ marginTop: 20 }}>We are processing the payment. Please wait</P>
      </View>
    )
  }

  return (
    <WebView
      style={styles.container}
      source={{ uri }}
      onNavigationStateChange={(newNavState) => {
        const { url } = newNavState
        if (url.includes('?paymentResult=success')) {
          // this.webview.stopLoading()
          setShowLoading(true)
        }
      }}
    />
  )
}

const styles = StyleSheet.create({
  container: {
    height: '100%',
    width: '100%'
  }
})
