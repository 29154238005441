// Config
import firebase from '@config/firebase'
// Models
import { User } from '@common/models'

interface Params {
  email: string
  data: Partial<Omit<User, 'terminals' | 'userType' | 'loginWith'>>
}

export const updateUser = async ({ email, data }: Params): Promise<void> => {
  try {
    await firebase.firestore().collection('users').doc(email).update(data)
  } catch (error) {
    console.error('Error updating user:', error)
    return await Promise.reject(error)
  }
}
