import React, { useContext, useRef } from 'react'
// Context
import AppContext from '@context/AppContext'
// Components
import { Button } from '@components/Button/Button'
import { LanguageSelector } from '@components/Dropdown/LanguageSelector'
import { SignupFooter } from '@components/Footer/SignupFooter'
import { FormikInput } from '@components/Formik/FormikInput'
import { Container } from '@components/Layout/Container'
import { KeyboardFix } from '@components/Layout/KeyboardFix'
import { SafeArea } from '@components/Layout/SafeArea'
import { StepFeature } from '@components/Layout/StepFeature'
import { H2 } from '@components/Typography/H2'
// RN
import { View } from 'react-native'
// Paper
import { Button as PaperButton, useTheme } from 'react-native-paper'
// Formik
import { Formik } from 'formik'
import * as Yup from 'yup'
// Services
import { AuthService } from '@services/AuthService'
// i18next
import { useTranslation } from 'react-i18next'
// Toast
import { useToast } from 'react-native-toast-notifications'

export const Step3 = ({ route, navigation }) => {
  const toast = useToast()
  const appContext = useContext(AppContext)
  const { t } = useTranslation()
  const { selectedTerminalToSignup, formValues = {} } = route.params
  const { colors } = useTheme()
  const terminalInfo = appContext.terminals[selectedTerminalToSignup]

  const autoLoginWith = terminalInfo?.autoLoginWith?.includes(
    formValues.userType
  )

  const STEP = {
    current: 3,
    total: 3
  }

  const INPUTS = [
    {
      name: 'company',
      type: 'textInput',
      initialValue: formValues.company,
      validationSchema: Yup.string().required(t('common.companyTypeRequired')),
      ref: useRef(),
      props: {
        label: t('common.companyTypeLabel'),
        returnKeyType: 'next',
        autoCapitalize: 'words',
        autoCorrect: false,
        dense: true,
        mode: 'outlined',
        blurOnSubmit: true,
        keyboardType: 'default'
      }
    },
    {
      name: terminalInfo.loginWith.name,
      type: 'textInput',
      mask: terminalInfo.loginWith.inputMask || '',
      noWhiteSpace: terminalInfo.loginWith.noWhiteSpace,
      initialValue: autoLoginWith
        ? Math.floor(Date.now() / 1000).toString()
        : formValues[terminalInfo.loginWith.name],
      validationSchema: Yup.string().required(
        `${terminalInfo.loginWith.name} ${t('common.typeRequired')}`
      ),
      ref: useRef(),
      invisible: autoLoginWith,
      props: {
        label: t('loginWith.' + terminalInfo.loginWith.name),
        returnKeyType: 'next',
        autoCapitalize: 'characters',
        autoCorrect: false,
        dense: true,
        mode: 'outlined',
        blurOnSubmit: true,
        keyboardType: 'default'
      }
    },
    {
      name: 'termsAndConditions',
      type: 'checkbox',
      initialValue: 'unchecked',
      validationSchema: Yup.string()
        .required(t('step3.termsRequired'))
        .oneOf(['checked'], t('step3.termsRequired')),
      ref: useRef(),
      props: {
        label: t('common.termsLabel')
      }
    }
  ]

  return (
    <SafeArea>
      <KeyboardFix>
        <LanguageSelector />
        <StepFeature
          title={t('signup.title')}
          subtitle={`${t('signup.step')} ${STEP.current} ${t('signup.of')} ${
            STEP.total
          }`}
          selectedStep={STEP.current}
          totalSteps={STEP.total}
        />
        <Formik
          enableReinitialize
          validateOnMount
          initialValues={INPUTS.reduce(
            (acc, key) => ({ ...acc, [key.name]: key.initialValue }),
            {}
          )}
          validationSchema={Yup.object().shape(
            INPUTS.reduce(
              (acc, key) => ({ ...acc, [key.name]: key.validationSchema }),
              {}
            )
          )}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(true)
            AuthService.signup({
              selectedTerminal: selectedTerminalToSignup,
              terminalInfo,
              loginWithName: terminalInfo.loginWith.name,
              loginWithValue: values[terminalInfo.loginWith.name],
              ...formValues,
              ...values
            })
              .then(() => {
                AuthService.login({ email: formValues.email, password: formValues.password })
              })
              .catch(error => {
                toast.show(error.response.data)
              })
              .finally(() => {
                setSubmitting(false)
              })
          }}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            isValid,
            isSubmitting,
            touched,
            errors,
            values,
            setFieldValue
          }) => (
            <Container
              style={{
                flex: 1
              }}
            >
              <View
                style={{ flex: 1, justifyContent: 'center', marginTop: -18 }}
              >
                <H2 regular>{t('step3.companyDetails')}</H2>
                <PaperButton
                  icon='chevron-left'
                  color={colors.placeholder}
                  style={{ alignSelf: 'flex-start' }}
                  uppercase={false}
                  compact
                  onPress={() => {
                    navigation.navigate('Step2', {
                      selectedTerminalToSignup,
                      formValues: { ...formValues }
                    })
                  }}
                >
                  {t('step3.backTo2')}
                </PaperButton>
                {INPUTS.map((input, index) => (
                  <React.Fragment key={input.name}>
                    <FormikInput
                      input={input}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      inputs={INPUTS}
                      index={index}
                      handleSubmit={handleSubmit}
                      errors={errors}
                      touched={touched}
                      value={values[input.name]}
                      setFieldValue={setFieldValue}
                    />
                  </React.Fragment>
                ))}
              </View>
              <Button
                mode='contained'
                style={{ marginVertical: 34 }}
                loading={isSubmitting}
                disabled={!isValid || isSubmitting}
                onPress={handleSubmit}
              >
                {t('step3.createAccount')}
              </Button>
            </Container>
          )}
        </Formik>
      </KeyboardFix>
      <SignupFooter />
    </SafeArea>
  )
}
