import * as React from 'react'
import Svg, { Path } from 'react-native-svg'

function SvgComponent (props) {
  return (
    <Svg
      width={19}
      height={19}
      viewBox='0 0 19 19'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <Path
        d='M1.398 8.51a.594.594 0 00.594-.593c0-2.274.885-4.41 2.492-6.019a.594.594 0 10-.84-.84 9.638 9.638 0 00-2.84 6.859c0 .327.266.593.594.593zM17.627 8.51a.594.594 0 00.594-.593 9.633 9.633 0 00-2.84-6.858.594.594 0 00-.84.84 8.45 8.45 0 012.492 6.018c0 .327.266.593.594.593z'
        fill='#FF6441'
      />
      <Path
        d='M6.5 4l3-1 1.5.5 1 .5 1 1 .5 1 .5 1.5v4l1 2 1.5 2-4.5 1-2.5 2-2.5-2-4.5-1L3 15l1.5-2.5L5 9V6.5L6.5 4z'
        fill='#FFB18C'
      />
      <Path
        d='M9.512 19a2.972 2.972 0 002.97-2.969.594.594 0 00-1.188 0c0 .983-.8 1.782-1.782 1.782a1.783 1.783 0 01-1.78-1.782.594.594 0 00-1.188 0A2.972 2.972 0 009.512 19z'
        fill='#FF6441'
      />
      <Path
        d='M2.981 16.625h13.063a1.387 1.387 0 00.9-2.438 5.306 5.306 0 01-1.89-4.063V7.917a5.548 5.548 0 00-5.542-5.542 5.548 5.548 0 00-5.541 5.542v2.207a5.304 5.304 0 01-1.883 4.057 1.386 1.386 0 00.893 2.444zM9.512 3.562a4.359 4.359 0 014.355 4.355v2.207c0 1.916.84 3.726 2.303 4.964a.198.198 0 01-.126.35H2.98a.2.2 0 01-.198-.198c0-.08.044-.129.07-.15a6.49 6.49 0 002.305-4.966V7.917a4.359 4.359 0 014.354-4.354z'
        fill='#FF6441'
      />
      <Path
        d='M9.512 3.563a.594.594 0 00.594-.594V.594a.594.594 0 00-1.187 0v2.375c0 .328.266.594.593.594z'
        fill='#FF6441'
      />
    </Svg>
  )
}

export default SvgComponent
