export const colors = {
  text: '#3C3C46',
  textLight: '#737373',
  caption: '#acacac',
  primary: '#FF6441',
  accent: '#004165',
  warning: '#EEAF30',
  danger: '#D75E72',
  error: '#D75E72',
  success: '#6FCF97',
  lightBlue: '#3CB6CE',
  background: '#FFFFFF',
  surface: '#FFFFFF',
  placeholder: '#D8D8D8',
  disabled: '#D8D8D880',
  notification: '#004165'
}
