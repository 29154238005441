import { useState, useCallback } from 'react'
// Components
import { H2 } from '@components/Typography/H2'
import { P } from '@components/Typography/P'
// Icons
import Astronaut from '@icons/Astronaut'
// React Native
import {
  View,
  RefreshControl,
  SafeAreaView,
  ScrollView,
  StyleSheet
} from 'react-native'

export const NoConnection = () => {
  const [refreshing, setRefreshing] = useState(false)

  const wait = (timeout) => {
    return new Promise(resolve => setTimeout(resolve, timeout))
  }

  const onRefresh = useCallback(() => {
    setRefreshing(true)
    wait(2000).then(() => setRefreshing(false))
  }, [])

  return (
    <SafeAreaView style={styles.container}>
      <ScrollView
        contentContainerStyle={styles.scrollView}
        refreshControl={
          <RefreshControl
            refreshing={refreshing}
            onRefresh={onRefresh}
          />
        }
      >
        <View style={styles.iconContainer}>
          <Astronaut />
        </View>
        <H2 bold>You are offline</H2>
        <P color='caption'>Please check your internet connection.</P>
        <P color='caption'>Pull down to try again.</P>
      </ScrollView>
    </SafeAreaView>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1
  },
  iconContainer: {
    width: '100%',
    height: 140,
    marginVertical: 50
  },
  scrollView: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center'
  }
})
