// External
import {
  View,
  StyleSheet
} from 'react-native'
import { useTranslation } from 'react-i18next'
import { MaterialCommunityIcons } from '@expo/vector-icons'
// Components
import { P } from '@common/components/Typography'
// Config
import { theme } from '@modules/common/config/theme'
// Models
import { PermitToWork } from '@permitToWork/models'

interface Props {
  item: PermitToWork
}

export const DetailRequirements = ({ item }: Props): JSX.Element => {
  const { t } = useTranslation()

  return (
    <View>
      <View style={styles.dividerContainer}>
        <P color='accent' bold style={styles.sectionTitle}>
          {t('permitToWorkItem.ppeRequirements')}
        </P>

        <View style={styles.divider} />
      </View>

      <View style={{ marginTop: 8 }}>
        {item?.ppeRequirements?.map(ppe => (
          <View style={styles.item} key={ppe}>
            <MaterialCommunityIcons name='check-bold' size={16} color={theme.colors.primary} />

            <P light style={styles.sectionContent}>
              {t('ppeRequirements.' + ppe)}
            </P>
          </View>
        ))}
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  dividerContainer: {
    flexDirection: 'row',
    alignItems: 'flex-end'
  },
  divider: {
    height: 0.5,
    backgroundColor: '#DADADA',
    flex: 1,
    marginBottom: 6,
    marginLeft: 18
  },
  sectionContent: {
    fontSize: 12,
    marginLeft: 8,
    paddingBottom: 8,
    lineHeight: 16
  },
  sectionTitle: {
    fontSize: 11,
    textTransform: 'uppercase',
    letterSpacing: 1
  },
  item: {
    marginLeft: 8,
    flexDirection: 'row',
    justifyContent: 'flex-start'
  }
})
