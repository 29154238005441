// External
import { Image, View, Dimensions, StyleSheet } from 'react-native'
// Components
import { H1, H2 } from '@common/components/Typography'

const windowWidth = Dimensions.get('window').width
const RATIO = 1500 / 1153

interface Props {
  intro: string
  title: string
  subtitle: string
}

export const Hero = ({ intro, title, subtitle }: Props): JSX.Element => {
  return (
    <View
      style={styles.container}
    >
      <Image
        defaultSource={require('@assets/images/stripes.png')}
        source={require('@assets/images/bg.png')}
        style={styles.bgImage}
      />
      <View>
        <H2>{intro}</H2>

        <H1>{title}</H1>
      </View>

      <H1 style={{ alignSelf: 'center' }} bold>{subtitle}</H1>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    paddingTop: 100,
    width: '100%',
    minHeight: windowWidth / RATIO,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  bgImage: {
    position: 'absolute',
    alignSelf: 'center',
    zIndex: -1,
    resizeMode: 'cover',
    width: '100%',
    height: windowWidth / RATIO
  },
  titleContainer: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center'
  }
})
