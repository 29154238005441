export const addOrRemoveFromArray = (value: string, array?: string[]): string[] => {
  if (array === undefined || array.length === 0) {
    return [value]
  }

  const index = array.indexOf(value)
  if (index === -1) {
    return [...array, value]
  }

  return array.filter(item => item !== value)
}
