// Paper
import { Text, useTheme } from 'react-native-paper'

export const P = ({ bold, light, link, color, style, underline, justify, ...props }) => {
  const { colors } = useTheme()

  return (
    <Text
      style={{
        fontSize: 14,
        lineHeight: 20,
        ...(underline && { textDecorationLine: 'underline' }),
        textAlign: justify ? 'justify' : 'left',
        color: colors[color] || colors.text,
        fontFamily: bold ? 'Maersk-Bold' : light ? 'Maersk-Light' : 'Maersk-Regular',
        ...(link && { textDecorationLine: 'underline', fontFamily: 'Maersk-Bold' }),
        ...style
      }}
      {...props}
    >
      {props.children}
    </Text>
  )
}
