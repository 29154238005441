import React, { useContext } from 'react'
// Context
import AppContext from '@context/AppContext'
// RN
import { View, StyleSheet, Platform } from 'react-native'
// Maps
import MapView, { Overlay, PROVIDER_GOOGLE } from 'react-native-maps'

const MAP_STYLE = [
  {
    featureType: 'administrative',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#444444'
      }
    ]
  },
  {
    featureType: 'landscape',
    elementType: 'all',
    stylers: [
      {
        color: '#f2f2f2'
      }
    ]
  },
  {
    featureType: 'poi',
    elementType: 'all',
    stylers: [
      {
        visibility: 'off'
      }
    ]
  },
  {
    featureType: 'road',
    elementType: 'all',
    stylers: [
      {
        saturation: -100
      },
      {
        lightness: 45
      }
    ]
  },
  {
    featureType: 'road.highway',
    elementType: 'all',
    stylers: [
      {
        visibility: 'simplified'
      }
    ]
  },
  {
    featureType: 'road.arterial',
    elementType: 'labels.icon',
    stylers: [
      {
        visibility: 'off'
      }
    ]
  },
  {
    featureType: 'transit',
    elementType: 'all',
    stylers: [
      {
        visibility: 'off'
      }
    ]
  },
  {
    featureType: 'water',
    elementType: 'all',
    stylers: [
      {
        color: '#D1EAEF'
      },
      {
        visibility: 'on'
      }
    ]
  }
]

const MAP_IMAGES = {
  portElizabeth: require('@assets/maps/portElizabeth.png'),
  pier400: require('@assets/maps/pier400.png'),
  castellon: require('@assets/maps/castellon.png'),
  valencia: require('@assets/maps/valencia.png'),
  gijon: require('@assets/maps/gijon.png'),
  mobile: require('@assets/maps/mobile.png'),
  barcelona: require('@assets/maps/barcelona.png')
}

export const Geoposition = ({ navigation }) => {
  const appContext = useContext(AppContext)
  const { selectedTerminal } = appContext
  const terminalInfo = appContext.terminals[appContext.selectedTerminal]
  // const [markerDragTop, setMarkerDragTop] = useState({
  //   latitude: 43.557867606751266,
  //   longitude: -5.691371597349644
  // })
  // const [markerDragBottom, setMarkerDragBottom] = useState({
  //   latitude: 43.55120069587931,
  //   longitude: -5.686501041054726
  // })

  const BOUNDS =
    Platform.OS === 'android'
      ? [
          [
            terminalInfo.map.bounds
              .rightBottomLatitude,
            terminalInfo.map.bounds
              .leftTopLongitude
          ],
          [
            terminalInfo.map.bounds
              .leftTopLatitude,
            terminalInfo.map.bounds
              .rightBottomLongitude
          ]
        ]
      : [
          [
            terminalInfo.map.bounds
              .leftTopLatitude,
            terminalInfo.map.bounds
              .leftTopLongitude
          ],
          [
            terminalInfo.map.bounds
              .rightBottomLatitude,
            terminalInfo.map.bounds
              .rightBottomLongitude
          ]
        ]

  return (
    <View style={styles.container}>
      <MapView
        showsCompass
        // mapType='hybrid'
        provider={Platform.OS === 'web' ? 'google' : PROVIDER_GOOGLE}
        showsMyLocationButton
        showsUserLocation
        followsUserLocation
        showsBuildings={false}
        showsPointsOfInterest={false}
        showsIndoors={false}
        loadingEnabled
        initialCamera={terminalInfo.map.camera}
        customMapStyle={MAP_STYLE}
        style={styles.map}
      >
        {/* <Marker
          onPress={e => console.log(Object.values(e.nativeEvent.coordinate))}
          draggable
          onDrag={e => setMarkerDragTop(e.nativeEvent.coordinate)}
          onDragEnd={e => setMarkerDragTop(e.nativeEvent.coordinate)}
          coordinate={markerDragTop}
        />
        <Marker
          pinColor='blue'
          onPress={e => console.log(Object.values(e.nativeEvent.coordinate))}
          draggable
          onDrag={e => setMarkerDragBottom(e.nativeEvent.coordinate)}
          onDragEnd={e => setMarkerDragBottom(e.nativeEvent.coordinate)}
          coordinate={markerDragBottom}
        /> */}
        {
          Platform.OS !== 'web' && (
            <Overlay
              bearing={terminalInfo.map.bearing}
              // bearing={34}
              bounds={BOUNDS}
              // bounds={[
              //   Object.values(markerDragTop),
              //   Object.values(markerDragBottom)
              // ]}
              image={MAP_IMAGES[selectedTerminal]}
            />
          )
        }
      </MapView>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: 'white',
    flex: 1
  },
  map: {
    flex: 1
  }
})
