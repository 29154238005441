// External
import { ReactNode } from 'react'
import { TextStyle, StyleProp } from 'react-native'
import { Text } from 'react-native-paper'
// Constants
import { colors } from '@common/constants'

interface Props {
  bold?: boolean
  light?: boolean
  link?: boolean
  color?: keyof typeof colors
  style?: StyleProp<TextStyle>
  underline?: boolean
  justify?: boolean
  children: ReactNode
  numberOfLines?: number
}

export const P = ({
  bold = false,
  light = false,
  link = false,
  color,
  style,
  underline = false,
  justify = false,
  children,
  numberOfLines
}: Props): JSX.Element => {
  return (
    <Text
      style={[{
        fontSize: 14,
        lineHeight: 20,
        ...(underline && { textDecorationLine: 'underline' }),
        textAlign: justify ? 'justify' : 'left',
        color: color !== undefined ? colors[color] : colors.accent,
        fontFamily: bold ? 'Maersk-Bold' : light ? 'Maersk-Light' : 'Maersk-Regular',
        ...(link && { textDecorationLine: 'underline', fontFamily: 'Maersk-Bold' })
      }, style]}
      numberOfLines={numberOfLines}
    >
      {children}
    </Text>
  )
}
