// External
import { useEffect, useContext, useState, useCallback, useMemo } from 'react'
import { View, ScrollView, StyleSheet } from 'react-native'
import { RadioButton, ActivityIndicator } from 'react-native-paper'
import { useTranslation } from 'react-i18next'
import { Formik, ErrorMessage } from 'formik'
import {
  Collapse,
  CollapseHeader,
  CollapseBody
  // @ts-expect-error - package has no types
} from 'accordion-collapse-react-native'
import { FontAwesome5 } from '@expo/vector-icons'
import { StackScreenProps } from '@react-navigation/stack'
import { object, string } from 'yup'
// Components
import { H2, P } from '@common/components/Typography'
import { Button } from '@common/components/Button'
// Constants
import { colors } from '@common/constants'
// Context
import AppContext from '@context/AppContext'
// Hooks
import { useCustomDrawerOptions } from '@hooks/useCustomDrawerOptions'
// Layout
import { SafeArea, StepFeatureCompact, Container } from '@common/layout'
// Models
import { NewPermitStackParamList, ArrangementAnswer } from '@modules/permitToWork/models'
// Services
import { getPermitToWorkData } from '@permitToWork/services'

type FormValues = Record<string, ArrangementAnswer>

type Props = StackScreenProps<NewPermitStackParamList, 'StepArrangements'>

const StepArrangements = ({ navigation, route }: Props): JSX.Element => {
  const [arrangements, setArrangements] = useState<{
    pre: string[]
    onSite: string[]
  }>()
  const [expandedCollapse, setExpandedCollapse] = useState({
    pre: false,
    onSite: false
  })
  const { selectedTerminal } = useContext(AppContext)
  const { t } = useTranslation()

  const validationSchema = useMemo(() => object().shape({
    ...arrangements?.pre.reduce(
      (acc, key) => ({ ...acc, [key]: string().oneOf(Object.values(ArrangementAnswer)).required('Please select an answer') }),
      {}
    ),
    ...arrangements?.onSite.reduce(
      (acc, key) => ({ ...acc, [key]: string().oneOf(Object.values(ArrangementAnswer)).required('Please select an answer') }),
      {}
    )
  }), [arrangements])

  const initialValues = useMemo(() => ({
    ...arrangements?.pre.reduce(
      (acc, key) => ({ ...acc, [key]: '' }),
      {}
    ),
    ...arrangements?.onSite.reduce(
      (acc, key) => ({ ...acc, [key]: '' }),
      {}
    )
  }), [arrangements])

  const onSubmit = useCallback((values: FormValues) => {
    navigation.navigate('StepPPERequirements', {
      ...route.params,
      arrangements: values
    })
  }, [])

  useCustomDrawerOptions({
    options: {
      goBack: () => navigation.goBack()
    }
  })

  useEffect(() => {
    void getPermitToWorkData({ selectedTerminal }).then(
      (res) => {
        setArrangements(res.arrangements)
      }
    )
  }, [])

  return (
    <SafeArea>
      <StepFeatureCompact
        title={t('common.newPermit')}
        subtitle={`${t('signup.step') as string} 5 ${t('signup.of') as string} 8`}
        selectedStep={5}
        totalSteps={8}
      />
      <Formik<FormValues>
        validateOnChange={false}
        validationSchema={validationSchema}
        initialValues={initialValues}
        enableReinitialize
        onSubmit={onSubmit}
      >
        {({ handleSubmit, isValid, values, setFieldValue, setFieldTouched, errors, touched }) => (
          <Container home style={{ flex: 1 }}>
            {(arrangements !== undefined)
              ? (
                <>
                  <ScrollView
                    style={{ flex: 1 }}
                    contentContainerStyle={{ flexGrow: 1 }}
                  >
                    {(Object.keys(arrangements) as Array<'pre' | 'onSite'>)
                      .sort((a, b) => (a === 'pre' && b === 'onSite' ? -1 : a === 'onSite' && b === 'pre' ? 1 : 0))
                      .map((key) => (
                        <Collapse
                          key={key}
                          touchableOpacityProps={{ activeOpacity: 0.5 }}
                          isExpanded={expandedCollapse[key]}
                          onToggle={(isExpanded: boolean) => {
                            setExpandedCollapse((prev) => ({
                              ...prev,
                              [key]: isExpanded
                            }))
                          }}
                        >
                          <CollapseHeader style={styles.collapseHeader}>
                            <H2 bold>{t(`permitToWorkStepArrangements.${key}`)}</H2>

                            <View style={styles.divider} />

                            <FontAwesome5
                              name={
                            expandedCollapse[key]
                              ? 'caret-up'
                              : 'caret-down'
                          }
                              size={14}
                              color='#ccc'
                            />
                          </CollapseHeader>

                          <CollapseBody>
                            {arrangements[key].map((arrangement) => (
                              <View style={styles.arrangement} key={arrangement}>
                                <P style={{ flexWrap: 'wrap', flex: 1 }}>
                                  {t('arrangements.' + arrangement)}
                                </P>

                                <RadioButton.Group
                                  onValueChange={(newValue) => {
                                    void setFieldValue(arrangement, newValue).then(() => {
                                      void setFieldTouched(arrangement, true)
                                    })
                                  }}
                                  value={values[arrangement]}
                                >
                                  {Object.entries(ArrangementAnswer).map(([key, value]) => (
                                    <View key={key} style={{ flexDirection: 'row', alignItems: 'center' }}>
                                      <RadioButton.Android value={value} color={colors.primary} />

                                      <P>{t(`arrangementAnswers.${key}`)}</P>
                                    </View>
                                  ))}
                                </RadioButton.Group>

                                <ErrorMessage name={arrangement}>
                                  {(msg) => <P color='danger'>{msg}</P>}
                                </ErrorMessage>
                              </View>
                            ))}
                          </CollapseBody>
                        </Collapse>
                      ))}

                  </ScrollView>

                  <Button
                    style={styles.continueButton}
                    disabled={!isValid || arrangements === undefined}
                    onPress={handleSubmit}
                  >
                    {t('common.continue')}
                  </Button>
                </>
                )
              : (
                <ActivityIndicator />
                )}
          </Container>
        )}
      </Formik>
    </SafeArea>
  )
}
export default StepArrangements

const styles = StyleSheet.create({
  continueButton: {
    alignSelf: 'flex-end',
    width: '100%',
    marginBottom: 24
  },
  divider: {
    marginHorizontal: 8,
    marginBottom: 4,
    backgroundColor: '#eee',
    flex: 1,
    height: 1
  },
  collapseHeader: {
    flexDirection: 'row',
    alignItems: 'flex-end',
    marginBottom: 12
  },
  arrangement: {
    marginLeft: 8,
    marginBottom: 24
  }
})
