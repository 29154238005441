// External
import { useContext } from 'react'
import { StyleSheet, TouchableOpacity, ScrollView, View } from 'react-native'
import { useTranslation } from 'react-i18next'
// Components
import { P } from '@common/components/Typography'
import { AvatarImage } from '@modules/common/components/Avatar'
// Context
import AppContext from '@context/AppContext'
// Constants
import { colors } from '@common/constants'
// Models
import { Contractor } from '@permitToWork/models'
// Services
import { editSharedWith } from '@permitToWork/services'

interface Props {
  users: Contractor[]
  permitId?: string
  onSelect?: (userItem: Contractor) => void
}

export const ShareDropdown = ({ users, permitId, onSelect }: Props): JSX.Element => {
  const { selectedTerminal } = useContext(AppContext)
  const { t } = useTranslation()

  return (
    <ScrollView
      keyboardShouldPersistTaps='handled'
      style={styles.resultsContainer}
    >
      {users.length === 0 && (
        <P color='text' style={styles.listItem}>
          {t('permitToWorkStepShare.noMoreShareWith')}
        </P>
      )}

      {users.map((userItem) => (
        <TouchableOpacity
          onPress={() => {
            if (onSelect !== undefined) {
              onSelect(userItem)
              return
            }

            if (permitId !== undefined) {
              void editSharedWith({
                selectedTerminal,
                permitId,
                contractorId: userItem?.id,
                action: 'add'
              })
            }
          }}
          key={userItem?.id}
          style={styles.listItem}
        >
          <AvatarImage name={userItem.user.name} surname={userItem.user.surname} profilePhoto={userItem.user.profilePhoto} />

          <View style={{ marginLeft: 8 }}>
            <View>
              <P color='text' style={styles.itemName}>
                {userItem?.user?.name} {userItem?.user?.surname}
              </P>

              <P color='textLight' style={styles.itemEmail}>
                {userItem?.email}
              </P>
            </View>
          </View>
        </TouchableOpacity>
      ))}
    </ScrollView>
  )
}

const styles = StyleSheet.create({
  resultsContainer: {
    borderWidth: 1,
    borderColor: colors.placeholder,
    position: 'absolute',
    width: '100%',
    backgroundColor: '#fff',
    top: 52,
    maxHeight: 238,
    paddingHorizontal: 12
  },
  listItem: {
    marginTop: 10,
    marginBottom: 10,
    flexDirection: 'row',
    alignItems: 'center'
  },
  itemName: {
    fontSize: 12,
    lineHeight: 14
  },
  itemEmail: {
    fontSize: 10,
    lineHeight: 12
  }
})
