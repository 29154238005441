// Firebase
import firebase from '@config/firebase'
// Config
import { SUSPENSION_STATES } from '@config/suspensionStates'
import { APPEAL_STATES } from '@config/AppealStates'
// Utils
import { uploadAttachments } from '@utils/uploadAttachments'

export const SuspensionServices = {
  agreeSuspension: ({ selectedTerminal, suspensionId }) => {
    return firebase.firestore()
      .collection('terminals')
      .doc(selectedTerminal)
      .collection('suspensions')
      .doc(suspensionId)
      .update({
        status: SUSPENSION_STATES.ACCEPTED_BY_USER
      })
  },
  submitAppeal: async ({ attachments, description, selectedTerminal, suspensionId }) => {
    const newAttachments = await uploadAttachments({ attachments, uploadUrl: `terminals/${selectedTerminal}/suspensions/${suspensionId}` })

    return firebase
      .firestore()
      .collection('terminals')
      .doc(selectedTerminal)
      .collection('suspensions')
      .doc(suspensionId)
      .update({
        appeal: {
          status: APPEAL_STATES.WAITING_ADMIN_RESPONSE,
          date: new Date(),
          description,
          files: newAttachments
        }
      })
  }
}
