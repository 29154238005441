// External
import React, { useContext, useState, useEffect } from 'react'
import {
  View,
  Pressable,
  Keyboard
} from 'react-native'
import { Dialog, Portal, TextInput } from 'react-native-paper'
import { useTranslation } from 'react-i18next'
// Components
import { P } from '@common/components/Typography'
import { SharedWithList } from './SharedWithList'
import { ShareDropdown } from './ShareDropdown'
// Context
import AppContext from '@context/AppContext'
// Hooks
import useCompanyContractors from '@permitToWork/hooks/useCompanyContractors'
// Models
import { Contractor, PermitToWork } from '@permitToWork/models'

interface Props {
  hideDialog: () => void
  visible: boolean
  sharedWith: any[]
  item: PermitToWork
}

export const ShareDialog = ({ hideDialog, visible, sharedWith, item }: Props): JSX.Element => {
  const [search, setSearch] = useState('')
  const { selectedTerminal } = useContext(AppContext)
  const users = useCompanyContractors({
    selectedTerminal,
    companyUid: item?.company?.uid
  })
  const [filteredUsers, setFilteredUsers] = useState<Contractor[]>([])
  const [showUsers, setShowUsers] = useState(false)
  const { t } = useTranslation()
  const sharedWithEmails = sharedWith.map((user) => user.email)

  useEffect(() => {
    if (users.length > 0) {
      const usersCopy = [...users]
      usersCopy.sort((a, b) => {
        if (a?.user?.name < b?.user?.name) {
          return -1
        }
        if (a?.user?.name > b?.user?.name) {
          return 1
        }
        return 0
      })

      const filteredUsersCopy = usersCopy.filter((user) => (user.email !== item.personInCharge.email) && !sharedWithEmails.includes(user.email))
      setFilteredUsers(filteredUsersCopy)
    }
  }, [users, sharedWith])

  useEffect(() => {
    if (search.length > 0) {
      const filteredUsersCopy = filteredUsers
        .filter((user) => (user.user.name.toLowerCase().includes(search.toLowerCase()) || user.email.toLowerCase().includes(search.toLowerCase())) &&
        !sharedWithEmails.includes(user.email))
      setFilteredUsers(filteredUsersCopy)
      return
    }

    const filteredUsersCopy = [...users].filter((user) => (user.email !== item.personInCharge.email) && !sharedWithEmails.includes(user.email))
    setFilteredUsers(filteredUsersCopy)
  }, [search])

  return (
    <Portal>
      <Dialog
        style={{ position: 'relative' }}
        dismissable={!showUsers}
        visible={visible}
        onDismiss={hideDialog}
      >
        <Pressable onPress={() => {
          setShowUsers(false)
          Keyboard.dismiss()
        }}
        >
          <Dialog.Title>
            {t('permitToWorkItem.sharePermitToWork', { permitId: item.id })}
          </Dialog.Title>

          <Dialog.Content>
            <View style={{ position: 'relative', zIndex: 1 }}>
              <TextInput
                returnKeyType='search'
                onFocus={() => setShowUsers(true)}
                onChangeText={(text) => setSearch(text)}
                style={{ marginBottom: 16 }}
                label={t('common.emailOrName')}
                mode='outlined'
                dense
              />

              {showUsers && <ShareDropdown users={filteredUsers} permitId={item.id} />}
            </View>

            <P>{t('permitToWorkItem.sharedWith')}</P>

            <SharedWithList
              permitId={item.id}
              totalItems={item.sharedWith?.length ?? 0}
              items={sharedWith}
            />
          </Dialog.Content>
        </Pressable>
      </Dialog>
    </Portal>
  )
}
