import { PTWStates, AppStates } from '@permitToWork/models'

export const PTW_MAP_STATES = {
  [PTWStates.Approved]: [AppStates.Active, AppStates.Queued],
  [PTWStates.Pending]: [AppStates.Pending],
  [PTWStates.Rejected]: [AppStates.Rejected],
  [AppStates.Expired]: [AppStates.Expired],
  [AppStates.Cancelled]: [AppStates.Cancelled],
  [PTWStates.Closed]: [AppStates.Closed]
}
