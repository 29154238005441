import React, { useState } from 'react'
// Context
import AppContext from '@context/AppContext'
// Navigation
import { useNavigation } from '@react-navigation/native'
// Components
import { Dialog } from '@components/Dialog/Dialog'
import { P } from '@components/Typography/P'
// RN
import { Platform, TouchableOpacity } from 'react-native'
// i18n
import { useTranslation } from 'react-i18next'

export const MapButton = ({ disabled }) => {
  const { t } = useTranslation()
  const navigation = useNavigation()
  const context = React.useContext(AppContext)
  const [dialog, setDialog] = useState({
    show: false,
    title: '',
    subtitle: ''
  })

  return (
    <>
      <Dialog
        title={dialog.title}
        subtitle={dialog.subtitle}
        visible={dialog.show}
        onDismiss={() => setDialog(prev => ({ ...prev, show: false }))}
      />
      <TouchableOpacity
        onPress={() => {
          if (!disabled) {
            if (context.terminals[context.selectedTerminal]) {
              if (context.terminals[context.selectedTerminal].map) {
                if (Platform.OS === 'web') {
                  window.open(context.terminals[context.selectedTerminal].map.vr, '_blank')
                } else {
                  navigation.navigate('3DMapLocation')
                }
              } else {
                setDialog(prev => ({
                  ...prev,
                  title: 'Error',
                  subtitle: t('mapButton.errorMsg'),
                  show: true
                }))
              }
            } else {
              setDialog(prev => ({
                ...prev,
                title: 'Error',
                subtitle: t('mapButton.pleaseSelectTerminal'),
                show: true
              }))
            }
          }
        }}
      >
        <P style={{ opacity: disabled ? 0.2 : 1 }} link color='accent'>
          {t('mapButton.map3d')}
        </P>
      </TouchableOpacity>
    </>
  )
}
