// External
import { PlatformOSType } from 'react-native'
import {
  MD2LightTheme,
  configureFonts
} from 'react-native-paper'
import { Fonts } from 'react-native-paper/lib/typescript/types'
// Constants
import { colors } from '@common/constants'

type MD2FontsConfig = {
  [platform in PlatformOSType | 'default']?: Fonts;
}

export const fontConfig: MD2FontsConfig = {
  web: {
    regular: {
      fontFamily: 'Maersk-Regular',
      fontWeight: 'normal'
    },
    medium: {
      fontFamily: 'Maersk-Bold',
      fontWeight: 'normal'
    },
    light: {
      fontFamily: 'Maersk-Light',
      fontWeight: 'normal'
    },
    thin: {
      fontFamily: 'Maersk-Light',
      fontWeight: 'normal'
    }
  },
  ios: {
    regular: {
      fontFamily: 'Maersk-Regular',
      fontWeight: 'normal'
    },
    medium: {
      fontFamily: 'Maersk-Bold',
      fontWeight: 'normal'
    },
    light: {
      fontFamily: 'Maersk-Light',
      fontWeight: 'normal'
    },
    thin: {
      fontFamily: 'Maersk-Light',
      fontWeight: 'normal'
    }
  },
  android: {
    regular: {
      fontFamily: 'Maersk-Regular',
      fontWeight: 'normal'
    },
    medium: {
      fontFamily: 'Maersk-Bold',
      fontWeight: 'normal'
    },
    light: {
      fontFamily: 'Maersk-Light',
      fontWeight: 'normal'
    },
    thin: {
      fontFamily: 'Maersk-Light',
      fontWeight: 'normal'
    }
  }
}

export const theme = {
  ...MD2LightTheme,
  roundness: 0,
  colors,
  version: 2,
  fonts: configureFonts({ config: fontConfig, isV3: false })
}
