// Firebase
import firebase from '@config/firebase'

export const NotificationServices = {
  addRead: async ({ notificationId, email }) => {
    return firebase
      .firestore()
      .collection('users')
      .doc(email)
      .collection('notifications')
      .doc(notificationId)
      .set(
        {
          read: true
        },
        { merge: true }
      )
  }
}
