import React from 'react'
// RN
import {
  SafeAreaView,
  StyleSheet
} from 'react-native'
// Components
import { StepFeature } from '@components/Layout/StepFeature'
import { Container } from '@components/Layout/Container'
import { P } from '@components/Typography/P'
import { Button } from '@components/Button/Button'
import { LanguageSelector } from '@components/Dropdown/LanguageSelector'
// Icons
import LocationIcon from '@icons/Location'
// Location
import * as Location from 'expo-location'
// i18n
import { useTranslation } from 'react-i18next'

export const BackgroundScreen = ({ navigation }) => {
  const { t } = useTranslation()

  const enableLocation = async () => {
    try {
      await Location.requestBackgroundPermissionsAsync()
      navigation.navigate('Notifications')
    } catch (error) {
      navigation.navigate('Notifications')
    }
  }

  return (
    <SafeAreaView style={styles.container}>
      <LanguageSelector />
      <StepFeature
        title={t('backgroundScreen.title')}
        subtitle={t('backgroundScreen.subtitle')}
        image={require('@assets/images/background-hero.jpg')}
        selectedStep={1}
        totalSteps={2}
      />
      <Container style={styles.textContainer}>
        <P justify>
          {t('backgroundScreen.intro1')}
        </P>
        <P justify style={{ marginTop: 12 }}>
          {t('backgroundScreen.intro2')}
        </P>
        <P justify color='caption' style={{ marginTop: 24 }}>
          {t('backgroundScreen.intro3')}
        </P>
      </Container>
      <Container style={styles.footer}>
        <Button full onPress={() => enableLocation()}>
          <LocationIcon style={{ marginTop: -4 }} />
          {'  '}
          {t('backgroundScreen.buttonText')}
        </Button>
      </Container>
    </SafeAreaView>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    backgroundColor: '#fff'
  },
  textContainer: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    textAlign: 'center'
  },
  footer: {
    marginBottom: 20,
    justifyContent: 'center',
    alignItems: 'center'
  }
})
