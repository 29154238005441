import { useContext } from 'react'
// Components
import { H3 } from '@components/Typography/H3'
// Paper
import { useTheme } from 'react-native-paper'
// Context
import AppContext from '@context/AppContext'
// Expo
import * as Linking from 'expo-linking'
// Icons
import { MaterialCommunityIcons } from '@expo/vector-icons'
// RN
import { View } from 'react-native'
// i18 next
import { useTranslation } from 'react-i18next'

export const DrawerButtonSOS = () => {
  const context = useContext(AppContext)
  const { colors } = useTheme()
  const { t } = useTranslation()
  return (
    <View style={{ marginHorizontal: '10%' }}>
      <MaterialCommunityIcons.Button
        name='phone'
        onPress={() => Linking.openURL(`tel:${context.terminals[context.selectedTerminal].phone}`)}
        backgroundColor='transparent'
        underlayColor='transparent'
        size={28}
        color={colors.primary}
        iconStyle={{ marginRight: 24 }}
      >
        <H3 style={{ letterSpacing: 0 }}>
          {t('drawerButtonSos.sos')}
        </H3>
      </MaterialCommunityIcons.Button>
    </View>
  )
}
