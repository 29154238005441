// External
import { useEffect, useRef, useState } from 'react'
// Config
import firebase from '@config/firebase'
// Models
import { PermitToWork, PermitToWorkSchema } from '@permitToWork/models'

interface Props {
  selectedTerminal: string
  userAuthEmail: string
}

const useInvitedPermitsToWork = ({ selectedTerminal, userAuthEmail }: Props): {
  invitedPermitsToWork: PermitToWork[]
  loadingInvited: boolean
  errorInvited: boolean
} => {
  const [invitedPermitsToWork, setInvitedPermitsToWork] = useState<PermitToWork[]>([])
  const [loadingInvited, setLoadingInvited] = useState(true)
  const [errorInvited, setErrorInvited] = useState(false)
  const snapshotRef = useRef<(() => void) | null>(null)

  useEffect(() => {
    void (async () => {
      const contractorQuery = await firebase
        .firestore()
        .collection('terminals')
        .doc(selectedTerminal)
        .collection('modules')
        .doc('permitToWork')
        .collection('contractors')
        .where('user.email', '==', userAuthEmail)
        .get()
      if (contractorQuery.docs.length > 0) {
        const contractorData = {
          id: contractorQuery.docs[0].id,
          ...contractorQuery.docs[0].data()
        }
        snapshotRef.current = firebase
          .firestore()
          .collection('terminals')
          .doc(selectedTerminal)
          .collection('modules')
          .doc('permitToWork')
          .collection('data')
          .where('sharedWith', 'array-contains', contractorData.id)
          .orderBy('requestDate', 'desc')
          .onSnapshot(
            (items) => {
              if (items.docs.length > 0) {
                const allItems = items.docs
                  .map(item => ({
                    id: item.id,
                    ...item.data()
                  }))
                const validatedItems: PermitToWork[] = []

                for (const item of allItems) {
                  try {
                    const validatedItem = PermitToWorkSchema.validateSync(item)

                    validatedItems.push(validatedItem as PermitToWork)
                  } catch (error) {
                    console.error(`Error parsing invited PTW #${item.id}:`, error)
                  }
                }

                setInvitedPermitsToWork(validatedItems)
                setLoadingInvited(false)
                setErrorInvited(false)
              } else {
                setInvitedPermitsToWork([])
                setLoadingInvited(false)
                setErrorInvited(false)
              }
            },
            error => {
              console.error('Error on useInvitedPermitsToWork snapshot:', error)
              setInvitedPermitsToWork([])
              setLoadingInvited(false)
              setErrorInvited(true)
            }
          )
      } else {
        setInvitedPermitsToWork([])
        setLoadingInvited(false)
        setErrorInvited(false)
      }
    })()

    return () => {
      if (snapshotRef.current !== null) {
        snapshotRef.current()
      }
    }
  }, [selectedTerminal, userAuthEmail])

  return { invitedPermitsToWork, loadingInvited, errorInvited }
}
export default useInvitedPermitsToWork
