import { useEffect, useState, useRef } from 'react'
// Firebase
import firebase from '@config/firebase'
// Config
import { SUSPENSION_STATES } from '@config/suspensionStates'

export const useSuspensions = ({ selectedTerminal, userAuth }) => {
  const [suspensions, setSuspensions] = useState()
  const snapshotRef = useRef()

  useEffect(() => {
    if (selectedTerminal && userAuth?.email) {
      snapshotRef.current = firebase
        .firestore()
        .collection('terminals')
        .doc(selectedTerminal)
        .collection('suspensions')
        .where('email', '==', userAuth.email)
        .orderBy('date', 'desc')
        .onSnapshot(
          suspensions => {
            if (suspensions.docs.length > 0) {
              setSuspensions(
                suspensions.docs
                  .filter(suspension => {
                    return [
                      SUSPENSION_STATES.ACTIVE,
                      SUSPENSION_STATES.EXPIRED,
                      SUSPENSION_STATES.CANCELLED
                    ].includes(suspension.data()?.status)
                  })
                  .map(suspension => ({
                    id: suspension.id,
                    ...suspension.data()
                  }))
              )
            } else {
              setSuspensions([])
            }
          },
          error => {
            console.log(error)
            setSuspensions([])
          }
        )
    }

    return () => {
      snapshotRef.current && snapshotRef.current()
    }
  }, [selectedTerminal, userAuth?.email])

  return suspensions
}
