// External
import fb from 'firebase/compat/app'
// Config
import firebase from '@config/firebase'

interface Params {
  selectedTerminal: string
  permitId: string
  contractorId: string
  action: 'add' | 'remove'
}

export const editSharedWith = async ({
  selectedTerminal,
  permitId,
  contractorId,
  action
}: Params): Promise<void> => {
  const batch = firebase.firestore().batch()

  const permitToWokRef = firebase.firestore()
    .collection('terminals')
    .doc(selectedTerminal)
    .collection('modules')
    .doc('permitToWork')

  batch.update(permitToWokRef.collection('data').doc(permitId), {
    sharedWith: action === 'remove' ? fb.firestore.FieldValue.arrayRemove(contractorId) : fb.firestore.FieldValue.arrayUnion(contractorId)
  })

  batch.update(permitToWokRef.collection('contractors').doc(contractorId), {
    totalPermitsToWork: fb.firestore.FieldValue.increment(action === 'remove' ? -1 : 1),
    updatedAt: new Date()
  })

  return await batch.commit()
}
