// react

// react-native
import { Platform, StyleSheet, View } from 'react-native'
// external
import { useTranslation } from 'react-i18next'
// ui
import { H2, P } from '@common/components/Typography'
import { Container, SafeArea } from '@common/layout'
import { CreateVisitForm } from '../components/CreateVisitForm'

export const VisitsManagementHome = (): JSX.Element => {
  const { t } = useTranslation()

  return (
    <SafeArea>
      <Container
        home={false}
        style={styles.container}
      >

        <H2
          regular
          color='text'
          style={styles.title}
        >
          {t('visitsManagement.createForm.title') as string}
        </H2>

        <P light color='textLight'>
          {t('visitsManagement.createForm.description')}
        </P>

        <View style={Platform.OS === 'web' ? { display: 'flex', flexDirection: 'column', gap: 2 } : { flex: 1 }}>
          <CreateVisitForm />
        </View>
      </Container>
    </SafeArea>
  )
}

const styles = StyleSheet.create({
  title: {
    fontSize: 26
  },
  container: {
    flex: 1,
    gap: 10,
    paddingTop: 40,
    paddingBottom: 20
  }
})
