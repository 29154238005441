// External
import { WebView } from 'react-native-webview'
import React, { useContext } from 'react'
// Context
import AppContext from '@context/AppContext'

export const Map3DLocationScreen = ({ navigation }) => {
  const appContext = useContext(AppContext)
  const terminalInfo = appContext.terminals[appContext.selectedTerminal]

  return (
    <WebView source={{ uri: terminalInfo.map.vr }} style={{ flex: 1 }} />
  )
}
