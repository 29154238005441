// External
import React, { useContext } from 'react'
import { View, StyleSheet, TouchableOpacity } from 'react-native'
import { Avatar } from 'react-native-paper'
import { AntDesign } from '@expo/vector-icons'
import {
  Placeholder,
  ShineOverlay,
  PlaceholderLine,
  PlaceholderMedia
} from 'rn-placeholder'
// Components
import { P } from '@common/components/Typography'
// Context
import AppContext from '@context/AppContext'
// Constants
import { colors } from '@common/constants'
// Models
import { Contractor } from '@permitToWork/models'
// Services
import { editSharedWith } from '@permitToWork/services'

interface Props {
  items: Contractor[]
  totalItems?: number
  permitId?: string
  onRemove?: (item: Contractor) => void
}

export const SharedWithList = ({ items, totalItems = 0, permitId, onRemove }: Props): JSX.Element => {
  const appContext = useContext(AppContext)
  const { selectedTerminal } = appContext

  if (items.length > 0) {
    return (
      <View style={styles.listContainer}>
        {items.map((item) => (
          <View
            key={item.id}
            style={{ ...styles.listItemContainer, height: 38 }}
          >
            <View style={styles.listItem}>
              {item.user.profilePhoto !== undefined
                ? (
                  <Avatar.Image
                    size={32}
                    style={{
                      backgroundColor: colors.placeholder
                    }}
                    source={{ uri: item?.user?.profilePhoto }}
                  />
                  )
                : (
                  <Avatar.Text
                    size={32}
                    color='white'
                    style={{
                      backgroundColor: colors.placeholder
                    }}
                    label={item.user.name[0] + item?.user?.surname?.charAt(0)}
                  />
                  )}
              <View style={{ flex: 1 }}>
                <P color='text' style={styles.sectionContent}>
                  {item?.user?.name} {item?.user?.surname}
                </P>

                <P color='textLight' style={styles.sectionContent}>
                  {item.email}
                </P>
              </View>

              <TouchableOpacity
                onPress={() => {
                  if (onRemove !== undefined) {
                    onRemove(item)
                    return
                  }

                  if (permitId !== undefined) {
                    void editSharedWith({
                      contractorId: item.id,
                      permitId,
                      selectedTerminal,
                      action: 'remove'
                    })
                  }
                }}
              >
                <AntDesign
                  name='deleteuser'
                  size={24}
                  color={colors.placeholder}
                />
              </TouchableOpacity>
            </View>
          </View>
        ))}
      </View>
    )
  }

  return (
    <View style={styles.listContainer}>
      {Array.from(new Array(totalItems), (x, i) => i + 1).map(
        (item) => (
          <View
            key={item}
            style={{
              ...styles.listItemContainer,
              height: 50
            }}
          >
            <Placeholder Animation={ShineOverlay} Left={PlaceholderMedia}>
              <PlaceholderLine width={80} />

              <PlaceholderLine />
            </Placeholder>
          </View>
        )
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  listContainer: {
    marginHorizontal: 'auto',
    width: '100%',
    flexDirection: 'row',
    flexWrap: 'wrap',
    paddingLeft: 8
  },
  listItemContainer: {
    marginTop: 8,
    flex: 1,
    minWidth: '100%',
    maxWidth: '100%',
    justifyContent: 'center',
    alignItems: 'flex-start'
  },
  listItem: {
    flex: 1,
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 4
  },

  sectionContent: {
    fontSize: 14,
    marginLeft: 8,
    lineHeight: 16
  }
})
