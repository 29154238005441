// Config
import firebase from '@config/firebase'
import { type VisitManagementQuestions, VisitManagmentQuestionsSchema } from '../models/VisitManagementQuestion'
// Common
import { COLLECTIONS } from '@modules/common/constants'

interface Params {
  selectedTerminal: string
}

export const getTerminalVisitQuestions = async ({ selectedTerminal }: Params): Promise<VisitManagementQuestions> => {
  try {
    const questionDocs = await firebase.firestore()
      .collection(COLLECTIONS.TERMINALS)
      .doc(selectedTerminal)
      .collection(COLLECTIONS.VISITS_MANAGEMENT_QUESTIONS)
      .get()

    const data = questionDocs.docs.map((doc) => doc.data())

    const validatedData = await VisitManagmentQuestionsSchema.validate(data)

    if (validatedData == null) {
      await Promise.reject(new Error('Error validating data'))
    }

    return validatedData
  } catch (error) {
    console.error('Error fetching questions:', error)
    return await Promise.reject(error)
  }
}
