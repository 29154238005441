// External
import { useContext, useEffect, useState, useRef } from 'react'
import { TextInput as RNTextInput } from 'react-native'
import { ActivityIndicator } from 'react-native-paper'
import { useToast } from 'react-native-toast-notifications'
import { useTranslation } from 'react-i18next'
import { StackScreenProps } from '@react-navigation/stack'
// Components
import { Button } from '@common/components/Button'
import { UserSearch } from '@permitToWork/components'
import { DropdownInput } from '@common/components/Input'
// Context
import AppContext from '@context/AppContext'
import UserContext from '@context/UserContext'
// Hooks
import { useCustomDrawerOptions } from '@hooks/useCustomDrawerOptions'
// Layout
import { Container, SafeArea, KeyboardFix, StepFeatureCompact } from '@common/layout'
// Models
import { NewPermitStackParamList } from '@modules/permitToWork/models'
import { AdminUserWithEmail, User } from '@common/models'
// Services
import { getStepPersonInChargeData, getCompanyContractors } from '@permitToWork/services'

type Props = StackScreenProps<NewPermitStackParamList, 'StepPersonInCharge'>

const StepPersonInCharge = ({ navigation }: Props): JSX.Element => {
  const [company, setCompany] = useState<string>('')
  const [personInCharge, setPersonInCharge] = useState<User | undefined>()
  const [authorizersEmail, setAuthorizersEmail] = useState<string>()
  const [stepPersonInChargeData, setStepPersonInChargeData] = useState<{
    companies: Array<{
      uid: string
      name: string
    }>
    authorizers?: AdminUserWithEmail[]
  }>()
  const [selectedCompanyContractors, setSelectedCompanyContractors] = useState<User[]>([])
  const { selectedTerminal } = useContext(AppContext)
  const { userInfo } = useContext(UserContext)
  const useAlternateNames = ['barcelona', 'valencia'].includes(selectedTerminal)

  const toast = useToast()
  const { t } = useTranslation()

  const personInChargeSearchInputRef = useRef<RNTextInput>(null)
  const authorizerSearchInputRef = useRef<RNTextInput>(null)

  useCustomDrawerOptions({
    options: {
      goBack: () => navigation.goBack()
    }
  })

  useEffect(() => {
    void getStepPersonInChargeData({ selectedTerminal, contractorEmail: userInfo.email })
      .then((res) => {
        setStepPersonInChargeData(res)
      })
      .catch((error) => {
        toast.show(error.message)
        navigation.goBack()
      })
  }, [selectedTerminal, userInfo])

  useEffect(() => {
    if (company === '') {
      return
    }

    void getCompanyContractors({ selectedTerminal, company })
      .then((res) => {
        setSelectedCompanyContractors(res.map((contractor) => contractor.user))
      })
      .catch((error) => {
        toast.show(error.message)
      })
  }, [company])

  return (
    <SafeArea>
      <KeyboardFix keyboardShouldPersistTaps='handled'>
        <StepFeatureCompact
          title={t('common.newPermit')}
          subtitle={`${t('signup.step') as string} 1 ${t('signup.of') as string} 8`}
          selectedStep={1}
          totalSteps={8}
        />

        <Container
          home
          style={{
            flex: 1
          }}
        >
          {(stepPersonInChargeData !== undefined)
            ? (
              <>
                <DropdownInput
                  name='company'
                  label={t('permitToWorkStepInformation.company')}
                  placeholder={t('permitToWorkStepInformation.company')}
                  value={company}
                  setValue={(value) => {
                    setCompany(value)
                  }}
                  list={stepPersonInChargeData.companies?.map((company) => ({
                    label: company.name,
                    value: company.uid
                  }))}
                />

                <UserSearch
                  style={{ marginBottom: 16, zIndex: 2 }}
                  ref={personInChargeSearchInputRef}
                  disabled={company === ''}
                  users={selectedCompanyContractors}
                  placeholderTranslationKey='common.personInCharge'
                  noMoreResultsTranslationKey='permitToWorkStepPersonInCharge.noMoreContractors'
                  selectedUser={personInCharge}
                  onSelect={(user) => {
                    setPersonInCharge(user as User)
                  }}
                  onRemove={() => {
                    setPersonInCharge(undefined)
                  }}
                />

                {stepPersonInChargeData.authorizers !== undefined && (
                  <UserSearch
                    ref={authorizerSearchInputRef}
                    disabled={company === ''}
                    users={stepPersonInChargeData.authorizers}
                    placeholderTranslationKey={`common.${useAlternateNames ? 'permitApprover' : 'permitAuthorizer'}`}
                    noMoreResultsTranslationKey={`permitToWorkStepPersonInCharge.${useAlternateNames ? 'noMoreApprovers' : 'noMoreAuthorizers'}`}
                    selectedUser={stepPersonInChargeData.authorizers.find((authorizer) => authorizer.email === authorizersEmail)}
                    onSelect={(user) => {
                      setAuthorizersEmail(user.email)
                    }}
                    onRemove={() => {
                      setAuthorizersEmail(undefined)
                    }}
                  />
                )}

                <Button
                  disabled={
                    company === '' ||
                    personInCharge === undefined ||
                    (stepPersonInChargeData?.authorizers !== undefined && authorizersEmail === undefined)
                  }
                  style={{ marginTop: 'auto' }}
                  onPress={() => {
                    navigation.navigate('StepInformation', {
                      company,
                      personInCharge: personInCharge as User,
                      ...(authorizersEmail !== undefined && { authorizersEmail })
                    })
                  }}
                >
                  {t('common.continue')}
                </Button>
              </>
              )
            : <ActivityIndicator />}
        </Container>
      </KeyboardFix>
    </SafeArea>
  )
}

export default StepPersonInCharge
