// external
import * as yup from 'yup'

export type VisitManagementQuestionTypes = 'dropdown'

export enum VisitManagementQuestionOptions {
  BUILDING_COB = 'buildingCOB',
  YARD = 'yard',

  A_MEETING = 'aMeeting',
  CONTRACT_WORK_IN_YARD = 'contractWorkInYard',
  GET_PTW = 'getPTW',
  WORK_IN_BUILDING = 'workInBuilding',
  DELIVERY_YARD = 'deliveryYard',
  PORT_TOUR = 'portTour',
}

export enum VisitManagementQuestionTitles {
  ENTERING_BUILDING = 'enteringBuilding',
  MEETING_PURPOSE = 'meetingPurpose'
}

export interface VisitManagementQuestion {
  question: VisitManagementQuestionTitles
  options: VisitManagementQuestionOptions[]
  type: VisitManagementQuestionTypes
}

export const VisitManagmentQuestionSchema = yup.object().shape({
  question: yup.string().required(),
  options: yup.array().of(yup.string().required()),
  type: yup.string().required()
})

export const VisitManagmentQuestionsSchema = yup.array().of(VisitManagmentQuestionSchema)

export type VisitManagementQuestions = yup.InferType<typeof VisitManagmentQuestionsSchema>
