// External
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Platform, ScrollView, View, TouchableOpacity, Image } from 'react-native'
import * as Linking from 'expo-linking'
import Constants from 'expo-constants'
import { useNavigation } from '@react-navigation/native'
// Components
import { H2 } from '@components/Typography/H2'
import { P } from '@components/Typography/P'
import { Button } from '@components/Button/Button'
// Constants
import { colors } from '@modules/common/constants'
// Context
import UserContext from '@context/UserContext'
import AppContext from '@context/AppContext'
// Layout
import { SafeArea } from '@components/Layout/SafeArea'
import { Container } from '@components/Layout/Container'
// Services
import { AuthService } from '@services/AuthService'

export const DisabledFacility = () => {
  const { t } = useTranslation()
  const userContext = useContext(UserContext)
  const { selectedTerminal, terminals } = useContext(AppContext)
  const renderGooglePlayButton = ['android', 'web'].includes(Platform.OS)
  const renderAppStoreButton = ['ios', 'web'].includes(Platform.OS)
  const webAppURL = Constants.expoConfig.extra.ENVIRONMENT === 'DEV' ? 'https://app-dev.stowlog.com' : 'https://app.stowlog.com'
  const navigation = useNavigation()

  if (userContext) {
    const { userInfo, userAuth } = userContext
    return (
      <SafeArea>
        <Container home>
          <ScrollView>
            <H2 style={{ marginBottom: 32, textAlign: 'center' }} bold>{t('migration.title')}</H2>

            <P style={{ marginBottom: 8 }}>
              {t('migration.dear')}<P bold>{` ${userInfo.name} ${userInfo.surname}`}</P>
            </P>

            <P style={{ marginBottom: 8 }}>{t('migration.firstParagraph')}</P>

            <P style={{ marginBottom: 8 }} bold>{t('migration.secondParagraphBold')}
              <P>{t('migration.secondParagraph')}</P>
            </P>

            <P style={{ marginBottom: 16 }}>{t('migration.followTheseSteps')}</P>

            <P style={{ marginBottom: 8 }} bold>{t('migration.firstStep')}</P>

            <View style={{ marginBottom: 24, flexDirection: 'row', justifyContent: 'center', gap: 16, alignItems: 'center' }}>
              {renderAppStoreButton && (
                <TouchableOpacity onPress={async () => {
                  try {
                    if (Platform.OS === 'web') {
                      window.open('https://apps.apple.com/app/stowlog/id6463200501', '_blank')
                      return
                    }

                    await Linking.openURL('itms-apps://apps.apple.com/app/stowlog/id6463200501')
                  } catch (error) {
                    console.error('error opening itms-apps://apps.apple.com/app/stowlog/id6463200501:', error)
                    await Linking.openURL('https://www.apple.com/us/search/Stowlog?src=globalnav')
                  }
                }}
                >
                  <Image style={{ height: 54, width: 160 }} source={require('@assets/images/app-store-badge.png')} />
                </TouchableOpacity>
              )}

              {renderGooglePlayButton && (
                <TouchableOpacity onPress={() => {
                  if (Platform.OS === 'web') {
                    window.open('https://play.google.com/store/apps/details?id=com.estudiocactus.stowlog', '_blank')
                    return
                  }

                  Linking.openURL('https://play.google.com/store/apps/details?id=com.estudiocactus.stowlog')
                }}
                >
                  <Image style={{ height: 45, width: 153 }} source={require('@assets/images/google-play-badge.png')} />
                </TouchableOpacity>
              )}
            </View>

            <P style={{ marginBottom: 8 }} bold>{t('migration.secondStep')}</P>

            <View style={{ marginHorizontal: 24, marginBottom: 24 }}>
              <P bold>{t('migration.email')}
                <P>{userInfo.email}</P>
              </P>

              <P bold>{t('migration.password')}
                <P style={{ color: colors.placeholder }}>{t('migration.yourPasswordOnVisitor')}</P>
              </P>

              <TouchableOpacity onPress={() => {
                Linking.openURL(`${webAppURL}/signin?resetPasswordEmail=${userInfo.email}`)
              }}
              >
                <P style={{ color: colors.primary }} link>{t('migration.clickHereIfYouForgot')}</P>
              </TouchableOpacity>
            </View>

            <P style={{ marginBottom: 8 }} bold>{t('migration.thirdStep', { terminal: terminals[selectedTerminal].name })}</P>

            <P>{t('migration.aWebVersionIsAvailable')}</P>

            <TouchableOpacity onPress={() => {
              if (Platform.OS === 'web') {
                window.open(webAppURL, '_blank')
                return
              }

              Linking.openURL(webAppURL)
            }}
            >
              <P style={{ marginBottom: 32, color: colors.primary }} link>{webAppURL}</P>
            </TouchableOpacity>

            <P style={{ textAlign: 'center' }}>{t('migration.footer')}</P>

            <TouchableOpacity onPress={async () => {
              try {
                await Linking.openURL('mailto:support@stowlog.com')
              } catch (error) {
                console.error('error opening mailto:support@stowlog.com:', error)
              }
            }}
            >
              <P style={{ marginBottom: 16, textAlign: 'center', color: colors.primary }} link>support@stowlog.com</P>
            </TouchableOpacity>

            <Button
              color={colors.placeholder}
              icon='logout-variant'
              uppercase={false}
              mode='text'
              onPress={() => AuthService.logout(userAuth.email)}
            >
              {t('common.logout')}
            </Button>
          </ScrollView>
        </Container>
      </SafeArea>
    )
  }

  return (
    <SafeArea>
      <Container home>
        <ScrollView>
          <H2 style={{ marginBottom: 32, textAlign: 'center' }} bold>{t('migration.title')}</H2>
          <P style={{ marginBottom: 8 }}>{t('migration.firstParagraph')}</P>

          <P style={{ marginBottom: 8 }} bold>{t('migration.secondParagraphBold')}
            <P>{t('migration.secondParagraph')}</P>
          </P>

          <P style={{ marginBottom: 16 }}>{t('migration.followTheseSteps')}</P>

          <P style={{ marginBottom: 8 }} bold>{t('migration.firstStep')}</P>

          <View style={{ marginBottom: 24, flexDirection: 'row', justifyContent: 'center', gap: 16, alignItems: 'center' }}>
            {renderAppStoreButton && (
              <TouchableOpacity onPress={async () => {
                try {
                  if (Platform.OS === 'web') {
                    window.open('https://apps.apple.com/app/stowlog/id6463200501', '_blank')
                    return
                  }

                  await Linking.openURL('itms-apps://apps.apple.com/app/stowlog/id6463200501')
                } catch (error) {
                  console.error('error opening itms-apps://apps.apple.com/app/stowlog/id6463200501:', error)
                  await Linking.openURL('https://www.apple.com/us/search/Stowlog?src=globalnav')
                }
              }}
              >
                <Image style={{ height: 54, width: 160 }} source={require('@assets/images/app-store-badge.png')} />
              </TouchableOpacity>
            )}

            {renderGooglePlayButton && (
              <TouchableOpacity onPress={() => {
                if (Platform.OS === 'web') {
                  window.open('https://play.google.com/store/apps/details?id=com.estudiocactus.stowlog', '_blank')
                  return
                }

                Linking.openURL('https://play.google.com/store/apps/details?id=com.estudiocactus.stowlog')
              }}
              >
                <Image style={{ height: 45, width: 153 }} source={require('@assets/images/google-play-badge.png')} />
              </TouchableOpacity>
            )}
          </View>

          <P style={{ marginBottom: 8 }} bold>{t('migration.thirdStep', { terminal: terminals[selectedTerminal].name }).replace('3', '2')}</P>

          <P>{t('migration.aWebVersionIsAvailable')}</P>

          <TouchableOpacity onPress={() => {
            if (Platform.OS === 'web') {
              window.open(webAppURL, '_blank')
              return
            }

            Linking.openURL(webAppURL)
          }}
          >
            <P style={{ marginBottom: 32, color: colors.primary }} link>{webAppURL}</P>
          </TouchableOpacity>

          <P style={{ textAlign: 'center' }}>{t('migration.footer')}</P>

          <TouchableOpacity onPress={async () => {
            try {
              await Linking.openURL('mailto:support@stowlog.com')
            } catch (error) {
              console.error('error opening mailto:support@stowlog.com:', error)
            }
          }}
          >
            <P style={{ marginBottom: 16, textAlign: 'center', color: colors.primary }} link>support@stowlog.com</P>
          </TouchableOpacity>

          <Button
            color={colors.placeholder}
            icon='chevron-left'
            uppercase={false}
            mode='text'
            onPress={() => navigation.goBack()}
          >
            {t('migration.goBack')}
          </Button>
        </ScrollView>
      </Container>
    </SafeArea>
  )
}
