import { useContext, useState } from 'react'
// Navigation
import {
  createDrawerNavigator,
  DrawerContentScrollView
} from '@react-navigation/drawer'
import { HeaderBackButton } from '@react-navigation/elements'
import { createStackNavigator } from '@react-navigation/stack'
// Context
import AppContext from '@context/AppContext'
import DrawerContext from '@context/DrawerContext'
import UserContext from '@context/UserContext'
// Views
import { NotificationBell } from '@components/Notification/NotificationBell'
import PermitToWorkStack from '@permitToWork/navigators/PermitToWorkStack'
import { Geoposition } from '@views/Geoposition/Geoposition'
import { InductionNavigator } from '@views/Home/InductionNavigator'
import { NotificationsScreen } from '@views/Home/NotificationsScreen'
import { ProfileNavigator } from '@views/Home/ProfileNavigator'
import { SettingsScreen } from '@views/Home/SettingsScreen'
import { SuspensionsNavigator } from '@views/Home/SuspensionsNavigator'
import { UpdateMissingInformationScreen } from '@views/Home/UpdateMissingInformationScreen'
import { VisitsScreen } from '@views/Home/VisitsScreen'
import { Map3DLocationScreen } from '@views/Map3D/Map3DLocationScreen'
// RN
import { Platform, useWindowDimensions } from 'react-native'
// Components
import { DrawerButton } from '@components/Drawer/DrawerButton'
import { DrawerButtonSOS } from '@components/Drawer/DrawerButtonSOS'
import { DrawerButtonSupport } from '@components/Drawer/DrawerButtonSupport'
import { DrawerFooter } from '@components/Drawer/DrawerFooter'
import { HeroDrawer } from '@components/Drawer/HeroDrawer'
// Config
import { MODULES } from '@config/modules'
// Hooks
import { useHasExtraInformation } from '@hooks/useHasExtraInformation'
import { useHasLoginWith } from '@hooks/useHasLoginWith'
import { useHasProfileInformation } from '@hooks/useHasProfileInformation'
import { useInitialInduction } from '@hooks/useInitialInduction'
import { useNotificationHandler } from '@hooks/useNotificationHandler'
// Paper
import { useTheme } from 'react-native-paper'
// i18next
import { useTranslation } from 'react-i18next'
// Services
import { VisitsManagementHome } from '@modules/visitsManagement/views/VisitsManagementHome'
import { AuthService } from '@services/AuthService'

const HeaderBackButtonCustom = ({ colors, drawerScreenOptions }) => (
  <HeaderBackButton
    style={{ marginLeft: 10 }}
    tintColor={colors.accent}
    onPress={() => drawerScreenOptions.goBack()}
    labelVisible={false}
  />
)

export const HomeNavigator = ({ navigation }) => {
  const appContext = useContext(AppContext)
  const userContext = useContext(UserContext)
  const { userInfo, userAuth } = userContext
  const { t } = useTranslation()
  const { selectedTerminal, terminals } = appContext
  const terminalInfo = terminals[selectedTerminal]
  const Drawer = createDrawerNavigator()
  const dimensions = useWindowDimensions()
  useNotificationHandler()
  const hasExtraInformation = useHasExtraInformation({ userInfo, terminalInfo })
  const hasLoginWith = useHasLoginWith({ userInfo, terminalInfo })
  const hasProfileInformation = useHasProfileInformation({ userInfo, terminalInfo })
  useInitialInduction({
    selectedTerminal,
    terminalInfo,
    userInfo,
    userAuth,
    hasLoginWith
  })
  const [drawerScreenOptions, setDrawerScreenOptions] = useState({})

  const isLargeScreen = Platform.OS === 'web' && dimensions.width >= 768

  const { colors } = useTheme()

  if (hasExtraInformation && hasLoginWith && hasProfileInformation) {
    function CustomDrawerContent (props) {
      const selectedRoute = props.state.routeNames[props.state.index]
      const drawerButtons = []

      Object.keys(MODULES).forEach((moduleName) => {
        const module = MODULES[moduleName]

        if (terminalInfo?.modules?.includes(moduleName) && module?.userTypes?.includes(userInfo.userType)) {
          if (moduleName === 'geoposition' && Platform.OS === 'web') {
            return
          }

          drawerButtons.push(
            <DrawerButton
              navigation={props.navigation}
              {...(moduleName === 'map3d' && terminalInfo?.map?.vr && Platform.OS === 'web') && {
                onPressOverride: () => {
                  window.open(terminalInfo.map.vr, '_blank')
                }
              }}
              screen={MODULES[moduleName].screen}
              key={moduleName}
              isSelected={selectedRoute === MODULES[moduleName].screen}
              label={t('modules.' + moduleName)}
              icon={MODULES[moduleName].icon}
            />
          )
        }
      })

      return (
        <>
          <HeroDrawer
            fullName={userInfo.name + ' ' + userInfo.surname}
            userTypeLabel={t('userTypes.' + userInfo.userType)}
            email={userAuth.email}
            terminalInfo={terminalInfo}
            loginWithName={t('loginWith.' + terminalInfo.loginWith.name)}
            loginWithValue={
              userInfo?.loginWith?.[terminalInfo?.loginWith?.name]
            }
          />
          <DrawerContentScrollView
            contentContainerStyle={{ flex: 1 }}
            {...props}
          >
            <DrawerButton
              navigation={props.navigation}
              screen='Profile'
              isSelected={selectedRoute === 'Profile'}
              label={t('modules.profile')}
              icon='account-box-multiple'
            />
            {drawerButtons}
            <DrawerButtonSOS />
            <DrawerButtonSupport />
          </DrawerContentScrollView>
          <DrawerFooter navigation={props.navigation} />
        </>
      )
    }

    return (
      <DrawerContext.Provider
        value={{
          drawerScreenOptions,
          setDrawerScreenOptions
        }}
      >
        <Drawer.Navigator
          screenOptions={{
            headerTitleStyle: {
              fontFamily: 'Maersk-Bold',
              color: colors.text,
              textTransform: 'uppercase'
            },
            headerRight: drawerScreenOptions.headerRight || (() => <NotificationBell navigation={navigation} />),
            ...(drawerScreenOptions.goBack && {
              headerLeft: () => (
                <HeaderBackButtonCustom
                  colors={colors}
                  drawerScreenOptions={drawerScreenOptions}
                />
              )
            }),
            ...(isLargeScreen
              ? {
                  headerLeft: () => drawerScreenOptions.goBack
                    ? <HeaderBackButtonCustom
                        colors={colors}
                        drawerScreenOptions={drawerScreenOptions}
                      />
                    : null
                }
              : {}),
            headerTintColor: colors.accent,
            drawerType: isLargeScreen ? 'permanent' : 'back',
            overlayColor: '#44444488',
            drawerStyle: {
              width: isLargeScreen ? 400 : 280
            }
          }}
          initialRouteName='Profile'
          drawerContent={CustomDrawerContent}
        >
          <Drawer.Screen
            name='Profile'
            component={ProfileNavigator}
            options={{
              headerTitle: t('modules.profile'),
              ...drawerScreenOptions
            }}
          />
          <Drawer.Screen
            name='Induction'
            component={InductionNavigator}
            options={{
              headerTitle: t('modules.induction'),
              ...drawerScreenOptions
            }}
          />
          <Drawer.Screen
            name='Visits'
            component={VisitsScreen}
            options={{
              headerTitle: t('modules.visits'),
              ...drawerScreenOptions
            }}
          />
          <Drawer.Screen
            name='VisitsManagementHome'
            component={VisitsManagementHome}
            options={{
              headerTitle: t('modules.visitsManagement'),
              ...drawerScreenOptions
            }}
          />
          <Drawer.Screen
            name='Suspensions'
            component={SuspensionsNavigator}
            options={{
              headerTitle: t('modules.suspensions'),
              ...drawerScreenOptions
            }}
          />
          <Drawer.Screen
            name='PermitToWork'
            component={PermitToWorkStack}
            options={{
              headerTitle: t('modules.permitToWork'),
              ...drawerScreenOptions
            }}
          />
          <Drawer.Screen
            name='Geoposition'
            component={Geoposition}
            options={{
              headerTitle: t('modules.geoposition'),
              ...drawerScreenOptions
            }}
          />
          <Drawer.Screen
            name='Map3D'
            component={Map3DLocationScreen}
            options={{
              headerTitle: t('modules.map3d'),
              ...drawerScreenOptions
            }}
          />
          <Drawer.Screen
            name='Settings'
            component={SettingsScreen}
            options={{
              headerTitle: t('modules.settings'),
              ...drawerScreenOptions
            }}
          />
          <Drawer.Screen
            name='Notifications'
            component={NotificationsScreen}
            options={{
              headerTitle: t('modules.notifications'),
              ...drawerScreenOptions
            }}
          />
        </Drawer.Navigator>
      </DrawerContext.Provider>
    )
  } else {
    const Stack = createStackNavigator()
    return (
      <Stack.Navigator
        screenOptions={{
          headerTitleStyle: {
            fontFamily: 'Maersk-Bold',
            color: colors.text,
            textTransform: 'uppercase'
          },
          headerLeft: () => (
            <HeaderBackButton
              label={t('updateMissingInformationScreen.back')}
              labelVisible
              tintColor={colors.accent}
              onPress={() => {
                AuthService.logout(userAuth.email)
              }}
            />
          ),
          headerTintColor: colors.accent
        }}
      >
        <Stack.Screen
          name='UpdateMissingInformationScreen'
          component={UpdateMissingInformationScreen}
          options={{ headerTitle: t('modules.profile') }}
        />
      </Stack.Navigator>
    )
  }
}
