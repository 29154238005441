import React, { useEffect } from 'react'
// Context
import AppContext from '@context/AppContext'
// RN
import { ScrollView, StyleSheet, TouchableOpacity, View } from 'react-native'
// Paper
import { ActivityIndicator, Caption, Modal, Portal, useTheme } from 'react-native-paper'
// Components
import { Button } from '@components/Button/Button'
import { H2 } from '@components/Typography/H2'
import { P } from '@components/Typography/P'
import { Flag } from '@common/components/Flag'
// Icons
import { Ionicons } from '@expo/vector-icons'
// Location
import * as Location from 'expo-location'
// Geolib
import { getDistance } from 'geolib'
// Flags
import lookup from 'country-code-lookup'
// i18next
import { useTranslation } from 'react-i18next'

export const TerminalSelectorModal = ({
  onSelectedTerminal,
  showModal,
  onDismiss
}) => {
  const context = React.useContext(AppContext)
  const [sortedTerminals, setSortedTerminals] = React.useState([])
  const { colors } = useTheme()
  const { t } = useTranslation()

  useEffect(() => {
    (async () => {
      const { granted } = await Location.getForegroundPermissionsAsync()
      if (granted) {
        sortTerminals()
      } else {
        setSortedTerminals(
          Object.keys(context.terminals).map(terminal => {
            const terminalInfo = context.terminals[terminal]
            return {
              ...terminalInfo,
              id: terminal
            }
          })
        )
      }
    })()
  }, [])

  const sortTerminals = async () => {
    try {
      const location = await Location.getLastKnownPositionAsync({
        accuracy: Location.Accuracy.Highest,
        maximumAge: 10000
      })
      if (location) {
        const terminalsDistances = Object.keys(context.terminals).map(
          terminal => {
            const terminalInfo = context.terminals[terminal]
            const distance = getDistance(
              {
                latitude: location?.coords?.latitude,
                longitude: location?.coords?.longitude
              },
              {
                latitude: terminalInfo.coords.latitude,
                longitude: terminalInfo.coords.longitude
              }
            )
            return {
              ...terminalInfo,
              id: terminal,
              distance
            }
          }
        )
        setSortedTerminals(
          terminalsDistances.sort((a, b) => a.distance - b.distance)
        )
      }
    } catch (error) {
      const terminals = Object.keys(context.terminals).map(
        terminal => {
          const terminalInfo = context.terminals[terminal]
          return {
            ...terminalInfo,
            id: terminal
          }
        }
      )
      setSortedTerminals(terminals)
      console.log(error)
    }
  }

  const styles = StyleSheet.create({
    containerStyle: {
      backgroundColor: '#fff',
      padding: 10,
      flex: 1
    },
    modalContainer: {
      flex: 1
    },
    terminalContainer: {
      paddingHorizontal: 14,
      paddingVertical: 12,
      flexDirection: 'row',
      alignItems: 'center'
    },
    terminalContainerTitle: {
      paddingHorizontal: 24,
      flex: 1
    },
    divider: {
      width: 120,
      backgroundColor: '#F2F2F2',
      height: 1,
      marginLeft: 20,
      marginBottom: 14
    }
  })

  return (
    <>
      <Portal>
        <Modal
          visible={showModal}
          onDismiss={() => onDismiss()}
          contentContainerStyle={styles.containerStyle}
        >
          <View
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              paddingHorizontal: 10,
              alignItems: 'center',
              flexDirection: 'row'
            }}
          >
            <H2>{t('common.selectTerminal')}</H2>
            <Button onPress={() => onDismiss()} mode='text'>
              {t('common.close')}
            </Button>
          </View>
          <ScrollView style={styles.modalContainer}>
            {sortedTerminals.length === 0 && (
              <ActivityIndicator style={{ marginTop: 24 }} />
            )}
            {sortedTerminals.map(terminal => {
              return (
                <View key={terminal.id}>
                  <TouchableOpacity
                    onPress={() => {
                      onSelectedTerminal(terminal.id)
                      onDismiss(false)
                    }}
                    style={styles.terminalContainer}
                  >
                    <View style={styles.flagContainer}>
                      <Flag code={terminal.countryISO} size={32} />
                    </View>
                    <View style={styles.terminalContainerTitle}>
                      <H2 bold>{terminal.name}</H2>
                      <P style={{ textTransform: 'capitalize', marginTop: -8 }}>
                        {terminal.region},{' '}
                        {lookup.byIso(terminal?.countryISO)?.country}
                      </P>
                      {terminal?.distance &&
                        (terminal.distance < 1000
                          ? (
                            <P color='caption'>{t('terminalSelectorModal.lessThan1Km')}</P>
                            )
                          : (
                            <Caption style={{ marginTop: -4, marginBottom: -2 }}>
                              {Math.round(
                                terminal.distance / 1000
                              ).toLocaleString('en-US', {
                                dateStyle: 'medium',
                                timeStyle: 'short'
                              })}{' '}
                              KM
                            </Caption>
                            ))}
                    </View>
                    <Ionicons
                      name='chevron-forward'
                      size={20}
                      color={colors.placeholder}
                    />
                  </TouchableOpacity>
                  <View style={styles.divider} />
                </View>
              )
            })}
          </ScrollView>
        </Modal>
      </Portal>
    </>
  )
}
