import React from 'react'
// RN
import { View, StyleSheet } from 'react-native'
// Components
import { H1 } from '@components/Typography/H1'
import { P } from '@components/Typography/P'
// Paper
import { useTheme } from 'react-native-paper'
// Grid
import { Col, Grid } from 'react-native-easy-grid'

export const StepFeatureCompact = ({
  title,
  subtitle,
  image,
  selectedStep = 1,
  totalSteps = 0,
  ...props
}) => {
  const { colors } = useTheme()

  const styles = StyleSheet.create({
    container: {
    },
    featureContainer: {
      backgroundColor: '#F8F8F8',
      width: '80%',
      alignSelf: 'flex-start',
      margin: 24,
      shadowColor: '#000',
      shadowOffset: {
        width: 0,
        height: 4
      },
      shadowOpacity: 0.08,
      shadowRadius: 10,
      elevation: 1
    },
    subtitleBox: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      alignItems: 'center'
    },
    subtitleContainer: {
      height: 1.5,
      width: 20,
      marginRight: 10,
      backgroundColor: colors.accent
    },
    gridSteps: {
      position: 'absolute',
      bottom: 0,
      width: '100%',
      height: 3
    }
  })

  return (
    <View style={styles.container}>
      <View style={styles.featureContainer}>
        <View style={{ padding: 10 }}>
          <H1 bold>{title}</H1>
          <View style={styles.subtitleBox}>
            <View style={styles.subtitleContainer} />
            <P bold color='accent'>
              {subtitle}
            </P>
          </View>
        </View>
        {totalSteps && (
          <Grid style={styles.gridSteps}>
            {Array.from(Array(totalSteps), (step, index) => {
              if (index + 1 === selectedStep) {
                return (
                  <Col
                    key={index}
                    style={{ backgroundColor: colors.lightBlue }}
                  />
                )
              } else {
                return <Col key={index} />
              }
            })}
          </Grid>
        )}
      </View>
    </View>
  )
}
