// External
import { compareAsc } from 'date-fns'
// Models
import { PermitToWork, AppStates } from '@permitToWork/models'

interface Params {
  ptw: PermitToWork
}

export const getAppState = ({ ptw }: Params): AppStates => {
  if (
    // @ts-expect-error - AppStates "extends" PTWStates
    ptw.state === AppStates.Approved &&
      compareAsc(new Date(), ptw.startDate.toDate()) === 1
  ) {
    if (compareAsc(new Date(), ptw.endDate.toDate()) === 1) {
      return AppStates.Expired
    }

    return AppStates.Active
  }

  if (
    // @ts-expect-error - AppStates "extends" PTWStates
    ptw.state === AppStates.Approved &&
      compareAsc(new Date(), ptw.startDate.toDate()) === -1
  ) {
    return AppStates.Queued
  }

  if (
    // @ts-expect-error - AppStates "extends" PTWStates
    ptw.state === AppStates.Pending &&
      compareAsc(new Date(), ptw.endDate.toDate()) === 1
  ) {
    return AppStates.Expired
  }

  // @ts-expect-error - AppStates "extends" PTWStates
  return ptw.state as AppStates
}
