// External
import { useState, useEffect, useRef } from 'react'
// @ts-expect-error - package has no types
import ActionSheet from 'react-native-actionsheet'
import { Entypo } from '@expo/vector-icons'
import * as DocumentPicker from 'expo-document-picker'
import * as ImagePicker from 'expo-image-picker'
import { useTranslation } from 'react-i18next'
import { Platform, ScrollView, StyleSheet } from 'react-native'
// Components
import { Caption } from '@common/components/Typography'
import { Button } from '@common/components/Button'
import { AttachmentList } from './AttachmentList'
// Constants
import { colors } from '@common/constants'
// Models
import { Attachment } from '@common/models'

interface Props {
  onAttachment: (attachments: Attachment[]) => void
}

export const AddAttachment = ({ onAttachment }: Props): JSX.Element => {
  const [attachments, setAttachments] = useState<Attachment[]>([])
  const actionSheet = useRef()
  const { t } = useTranslation()

  const pickImage = async (): Promise<void> => {
    const result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.All,
      allowsEditing: true,
      aspect: [4, 3],
      quality: 1
    })

    if (!result.canceled) {
      const asset = { ...result.assets[0] }
      const attachment = {
        ...asset,
        name: asset.uri.split('/').pop(),
        mimeType: 'image/jpeg'
      }
      setAttachments((prev) => [...prev, attachment])
    }
  }

  const openCamera = async (): Promise<void> => {
    const cameraPermissions = await ImagePicker.requestCameraPermissionsAsync()

    if (cameraPermissions.granted) {
      const result = await ImagePicker.launchCameraAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.All,
        allowsEditing: true,
        aspect: [4, 3],
        quality: 1
      })

      if (!result.canceled) {
        const asset = { ...result.assets[0] }
        const attachment = {
          ...asset,
          name: asset.uri.split('/').pop(),
          mimeType: 'image/jpeg'
        }
        setAttachments((prev) => [...prev, attachment])
      }
    }
  }

  useEffect(() => {
    onAttachment(attachments)
  }, [attachments.length])

  return (
    <>
      <ActionSheet
        ref={actionSheet}
        title={t('appealAttachment.title')}
        options={['Open Camera', 'Open Gallery', 'Open Documents', 'Cancel']}
        cancelButtonIndex={3}
        onPress={(index: number) => {
          switch (index) {
            case 0:
              void openCamera()
              break
            case 1:
              void pickImage()
              break
            case 2:
              void DocumentPicker.getDocumentAsync().then((result) => {
                if (result.type === 'success') {
                  setAttachments((prev) => [...prev, result])
                }
              })
              break
          }
        }}
      />

      <Button
        onPress={() => {
          if (Platform.OS === 'web') {
            void DocumentPicker.getDocumentAsync().then((result) => {
              if (result.type === 'success') {
                setAttachments((prev) => [...prev, result])
              }
            })
            return
          }
          // @ts-expect-error - package has no types
          actionSheet.current?.show()
        }}
        icon='file-upload-outline'
      >
        {t('appealAttachment.uploadAttachments')}
      </Button>

      <Caption color='placeholder'>{t('appealAttachment.maxFile')}</Caption>

      <ScrollView contentContainerStyle={styles.scrollView}>
        {attachments.length === 0
          ? (
            <>
              <Entypo name='attachment' size={24} color={colors.placeholder} />

              <Caption bold color='placeholder'>
                {t('common.noAttachments')}
              </Caption>
            </>
            )
          : (
            <AttachmentList
              attachments={attachments}
              onPress={(attachment) => {
                setAttachments(
                  attachments.filter((item) => item.name !== attachment.name)
                )
              }}
            />
            )}
      </ScrollView>
    </>
  )
}
const styles = StyleSheet.create({
  scrollView: {
    minHeight: 100,
    flex: 1,
    backgroundColor: '#f7f7f7',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center'
  },
  itemContainer: {
    marginVertical: 4,
    marginHorizontal: 12,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  }
})
