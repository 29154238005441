import { useEffect, useRef, useState } from 'react'
// Firebase
import firebase from '@config/firebase'

export const useInduction = ({ selectedTerminal, userInfo, terminals }) => {
  const [induction, setInduction] = useState()
  const snapshotRef = useRef()

  useEffect(() => {
    if (selectedTerminal && userInfo && terminals) {
      const terminalInfo = terminals[selectedTerminal]
      const loginWithName = terminalInfo?.loginWith?.name
      const loginWithValue = userInfo?.loginWith?.[loginWithName]

      if (loginWithValue && loginWithName) {
        snapshotRef.current = firebase
          .firestore()
          .collection('terminals')
          .doc(selectedTerminal)
          .collection('inductions')
          .where(`user.loginWith.${loginWithName}`, '==', loginWithValue)
          .where('userType', '==', userInfo.userType)
          .limit(1)
          .onSnapshot(induction => {
            if (induction.docs.length > 0) {
              setInduction({
                id: induction.docs[0].id,
                ...induction.docs[0].data()
              })
            } else {
              setInduction(null)
            }
          }, error => {
            console.log(error)
            setInduction(null)
          })
      } else {
        setInduction(null)
      }
    }

    return () => {
      snapshotRef.current && snapshotRef.current()
    }
  }, [selectedTerminal, userInfo, terminals])

  return induction
}
