// Firebase
import firebase from '@config/firebase'
// Date Fns
import { add } from 'date-fns'

export const InductionServices = {
  getInductionVideo: async ({ selectedTerminal, userType, locale }) => {
    try {
      const snapshot = await firebase
        .firestore()
        .collection('terminals')
        .doc(selectedTerminal)
        .collection('videos')
        .where('userType', '==', userType)
        .where('language', '==', locale)
        .limit(1)
        .get()
      if (snapshot.docs[0]?.data()?.links?.app) {
        return snapshot.docs[0].data().links.app
      } else {
        const snapshotFallback = await firebase
          .firestore()
          .collection('terminals')
          .doc(selectedTerminal)
          .collection('videos')
          .where('userType', '==', userType)
          .where('language', '==', 'en')
          .limit(1)
          .get()
        return snapshotFallback.docs[0]?.data()?.links?.app
      }
    } catch (error) {
      console.log(error)
    }
  },
  getInductionQuestions: async ({ selectedTerminal, userType, locale }) => {
    try {
      const snapshot = await firebase
        .firestore()
        .collection('terminals')
        .doc(selectedTerminal)
        .collection('questions')
        .where('userType', '==', userType)
        .where('language', '==', locale)
        .get()
      if (snapshot.docs.length > 0) {
        return snapshot.docs.map(question => question.data())
      } else {
        const snapshotFallback = await firebase
          .firestore()
          .collection('terminals')
          .doc(selectedTerminal)
          .collection('questions')
          .where('userType', '==', userType)
          .where('language', '==', 'en')
          .get()
        if (snapshotFallback.docs.length > 0) {
          return snapshotFallback.docs.map(question => question.data())
        } else {
          return []
        }
      }
    } catch (error) {
      console.log(error)
    }
  },
  getInductionExpiration: ({ inductionDate, inductionValid }) => {
    if (inductionValid === 'unlimited') {
      return inductionDate
    }
    return add(inductionDate, inductionValid)
  },
  createInduction: ({
    selectedTerminal,
    userType,
    inductionStatus,
    email,
    inductionDate,
    inductionExpiration,
    userInfo
  }) => {
    return firebase
      .firestore()
      .collection('terminals')
      .doc(selectedTerminal)
      .collection('inductions')
      .add({
        email,
        inductionDate,
        inductionExpiration,
        inductionStatus,
        user: userInfo,
        userType,
        appVersion: 'v3'
      })
  },
  updateInduction: ({
    selectedTerminal,
    userType,
    inductionStatus,
    inductionId,
    email,
    inductionDate,
    inductionExpiration,
    userInfo
  }) => {
    return firebase
      .firestore()
      .collection('terminals')
      .doc(selectedTerminal)
      .collection('inductions')
      .doc(inductionId)
      .update({
        email,
        inductionDate,
        inductionExpiration,
        inductionStatus,
        user: userInfo,
        userType,
        appVersion: 'v3'
      })
  }
}
