// External
import * as Sentry from 'sentry-expo'
import { Platform } from 'react-native'
import type { ReactNode } from 'react'
// Views
import { ErrorView } from '@common/views/ErrorView'

interface Props {
  children: ReactNode
}

const ErrorBoundary = ({ children }: Props): JSX.Element => {
  if (Platform.OS === 'web') {
    return (
      <Sentry.Browser.ErrorBoundary fallback={ErrorView}>
        {children}
      </Sentry.Browser.ErrorBoundary>
    )
  }

  return (
    <Sentry.Native.ErrorBoundary fallback={ErrorView}>
      {children}
    </Sentry.Native.ErrorBoundary>
  )
}
export default ErrorBoundary
