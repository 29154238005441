// External
import { useEffect, useState, useRef } from 'react'
// Config
import firebase from '@config/firebase'
// Models
import { PermitToWorkSchema, PermitToWork } from '@permitToWork/models'

interface Props {
  selectedTerminal: string
  permitId: string
}

const usePermitToWork = ({ selectedTerminal, permitId }: Props): PermitToWork | undefined => {
  const [permitToWork, setPermitToWork] = useState<PermitToWork>()
  const snapshotRef = useRef<((() => void) | null)>(null)

  useEffect(() => {
    snapshotRef.current = firebase
      .firestore()
      .collection('terminals')
      .doc(selectedTerminal)
      .collection('modules')
      .doc('permitToWork')
      .collection('data')
      .doc(permitId)
      .onSnapshot(item => {
        if (item.exists) {
          const parsedItem = PermitToWorkSchema.validateSync({ id: item.id, ...item.data() })

          setPermitToWork(parsedItem as PermitToWork)
        } else {
          setPermitToWork(undefined)
        }
      })

    return () => {
      if (snapshotRef.current !== null) {
        snapshotRef.current()
      }
    }
  }, [selectedTerminal, permitId])

  return permitToWork
}
export default usePermitToWork
