import { AppStates } from '@permitToWork/models'
import { colors } from '@common/constants'

export const STATE_COLORS: Record<AppStates, keyof typeof colors> = {
  approved: 'success',
  pending: 'warning',
  active: 'success',
  rejected: 'danger',
  expired: 'textLight',
  queued: 'lightBlue',
  cancelled: 'text',
  closed: 'text'
}
