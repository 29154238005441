import React, { useState } from 'react'
// Components
import { P } from '@components/Typography/P'
import { Container } from '@components/Layout/Container'
import { Dialog } from '@components/Dialog/Dialog'
// RN
import { StyleSheet, TouchableOpacity, View } from 'react-native'
// Updates
import * as Updates from 'expo-updates'
// Expo
import Constants from 'expo-constants'
// i18next
import { useTranslation } from 'react-i18next'

export const SettingsFooter = () => {
  const [dialog, setDialog] = useState({
    show: false,
    title: '',
    subtitle: '',
    agreeText: '',
    showAgreeText: false
  })
  const { t } = useTranslation()

  return (
    <Container>
      <Dialog
        title={dialog.title}
        subtitle={dialog.subtitle}
        visible={dialog.show}
        onDismiss={() => setDialog(prev => ({ ...prev, show: false }))}
        agreeText={dialog.agreeText || ''}
        showAgreeText={dialog.showAgreeText}
        onAgree={() => {
          Updates.reloadAsync()
          setDialog(prev => ({ ...prev, show: false }))
        }}
      />
      <View style={styles.footerContainer}>
        <View style={styles.divider} />
        <View style={styles.footerBox}>
          <View style={styles.versionBox}>
            <P color='caption'>{t('settingsFooter.appVersion')}</P>
            <P color='caption' style={{ marginLeft: 4 }} bold>{Constants.expoConfig.version}</P>
          </View>
          <TouchableOpacity onPress={() => {
            Updates.checkForUpdateAsync()
              .then(({ isAvailable }) => {
                if (isAvailable) {
                  setDialog(prev => ({
                    ...prev,
                    title: t('settingsFooter.title'),
                    subtitle: t('settingsFooter.subtitle'),
                    showAgreeText: true,
                    agreeText: t('settingsFooter.download'),
                    show: true
                  }))
                } else {
                  setDialog(prev => ({
                    ...prev,
                    title: t('settingsFooter.title2'),
                    subtitle: t('settingsFooter.subtitle2'),
                    showAgreeText: false,
                    show: true
                  }))
                }
              }).catch(error => {
                console.log(error)
              })
          }}
          >
            <P link color='primary'>
              {t('settingsFooter.checkUpdates')}
            </P>
          </TouchableOpacity>
        </View>
      </View>
    </Container>
  )
}

const styles = StyleSheet.create({
  footerContainer: {
    flexDirection: 'column'
  },
  divider: {
    width: '100%',
    backgroundColor: '#F2F2F2',
    height: 1
  },
  footerBox: {
    marginBottom: 1,
    paddingVertical: 12,
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  versionBox: {
    flexDirection: 'row'
  }
})
