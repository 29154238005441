// Components
import { Button } from '@common/components/Button'
import { H2, P } from '@common/components/Typography'
// Icons
import Astronaut from '@icons/Astronaut'
import { colors } from '@modules/common/constants'
import { AuthService } from '@services/AuthService'
// React Native
import {
  SafeAreaView,
  StyleSheet,
  TouchableOpacity,
  View
} from 'react-native'
import { Text } from 'react-native-paper'

interface Props {
  resetError: () => void
  error: any
}

export const ErrorView = ({ resetError, error }: Props): JSX.Element => {
  console.log(error)
  return (
    <SafeAreaView style={styles.container}>
      <View style={styles.iconContainer}>
        <Astronaut />
      </View>
      <H2 bold>An unknown error has occurred</H2>
      <P color='caption'>Reload using the button below. if the error persists please {' '}
        <TouchableOpacity
          onPress={() => {
            void AuthService.logout()
            resetError()
          }}
        >
          <Text style={styles.logoutButtonText}>click here to logout.</Text>
        </TouchableOpacity>
      </P>
      <Button
        mode='outlined'
        onPress={resetError}
      >Reload
      </Button>
    </SafeAreaView>
  )
}

const styles = StyleSheet.create({
  logoutButtonText: {
    color: colors.primary,
    textDecorationLine: 'underline'
  },
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center'
  },
  iconContainer: {
    width: '100%',
    height: 140,
    marginVertical: 50
  },
  scrollView: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center'
  }
})
