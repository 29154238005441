// External
import { View, StyleSheet } from 'react-native'
import {
  Placeholder,
  ShineOverlay,
  PlaceholderLine,
  PlaceholderMedia
} from 'rn-placeholder'
// Components
import { P } from '@common/components/Typography'
import { AvatarImage } from './AvatarImage'

interface Props {
  items: Array<{
    email: string
    name: string
    surname: string
    profilePhoto?: string
  }>
  totalItems?: number
}

export const AvatarList = ({ items, totalItems = 0 }: Props): JSX.Element => {
  if (items.length > 0) {
    return (
      <View style={styles.listContainer}>
        {items.map(item => (
          <View
            key={item.email}
            style={{ ...styles.listItemContainer, height: 34 }}
          >
            <View style={styles.listItem}>
              <AvatarImage size={34} name={item.name} surname={item.surname} profilePhoto={item.profilePhoto} />

              <P color='textLight' style={styles.sectionContent}>
                {item.name} {item.surname}
              </P>
            </View>
          </View>
        ))}
      </View>
    )
  }

  return (
    <View style={styles.listContainer}>
      {Array.from(new Array(totalItems), (x, i) => i + 1).map(
        (item, index) => (
          <View
            key={item}
            style={{
              ...styles.listItemContainer,
              height: 50,
              paddingLeft: index % 2 === 0 ? 0 : 8
            }}
          >
            <Placeholder Animation={ShineOverlay} Left={PlaceholderMedia}>
              <PlaceholderLine width={80} />

              <PlaceholderLine />
            </Placeholder>
          </View>
        )
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  listContainer: {
    marginHorizontal: 'auto',
    width: '100%',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginLeft: 8
  },
  listItemContainer: {
    marginTop: 8,
    flex: 1,
    minWidth: '50%',
    maxWidth: '50%',
    justifyContent: 'center',
    alignItems: 'flex-start'
  },
  listItem: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 4
  },

  sectionContent: {
    fontSize: 11,
    marginLeft: 8,
    lineHeight: 14
  }
})
