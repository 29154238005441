// External
import { useContext, useEffect, useState, useCallback, useRef } from 'react'
import { Formik } from 'formik'
import { object, string, array, InferType } from 'yup'
import { ActivityIndicator } from 'react-native-paper'
import { useTranslation } from 'react-i18next'
import { StackScreenProps } from '@react-navigation/stack'
import { TextInput as RNTextInput } from 'react-native'
// Components
import { Button } from '@common/components/Button'
import { TextInput, SelectInput } from '@modules/common/components/Input'
import { P } from '@common/components/Typography'
// Context
import AppContext from '@context/AppContext'
// Hooks
import { useCustomDrawerOptions } from '@hooks/useCustomDrawerOptions'
// Layout
import { Container, KeyboardFix, SafeArea, StepFeatureCompact } from '@common/layout'
// Models
import { NewPermitStackParamList } from '@modules/permitToWork/models'
// Services
import { getStepInformationData } from '@permitToWork/services'

const FormSchema = object().shape({
  locations: array().of(string().required()).optional(),
  otherLocation: string().when('locations', ([locations]) => {
    if (locations.length === 0) {
      return string().required('Please enter a location or select one')
    }

    return string().optional()
  }),
  description: string().min(10, 'Description must be at least 10 characters long').required('Description is required')
})

type FormValues = InferType<typeof FormSchema>

type Props = StackScreenProps<NewPermitStackParamList, 'StepLocations'>

const StepLocations = ({ navigation, route }: Props): JSX.Element => {
  const [locations, setLocations] = useState<string[]>()
  const { selectedTerminal } = useContext(AppContext)
  const { t } = useTranslation()
  const descriptionInputRef = useRef<RNTextInput>(null)

  const onSubmit = useCallback((values: FormValues) => {
    if ((values.locations === undefined || values.locations.length === 0) && values.otherLocation !== undefined) {
      navigation.navigate('StepShare', {
        ...route.params,
        otherLocation: values.otherLocation,
        description: values.description
      })
      return
    }

    navigation.navigate('StepShare', {
      ...route.params,
      locations: values.locations as string[],
      description: values.description
    })
  }, [])

  useCustomDrawerOptions({
    options: {
      goBack: () => navigation.goBack()
    }
  })

  useEffect(() => {
    void getStepInformationData({ selectedTerminal }).then(
      (res) => setLocations(res.locations)
    )
  }, [selectedTerminal])

  return (
    <SafeArea>
      <KeyboardFix>
        <StepFeatureCompact
          title={t('common.newPermit')}
          subtitle={`${t('signup.step') as string} 3 ${t('signup.of') as string} 8`}
          selectedStep={3}
          totalSteps={8}
        />

        <Formik<FormValues>
          validateOnChange={false}
          initialValues={{
            locations: [],
            otherLocation: '',
            description: ''
          }}
          validationSchema={FormSchema}
          onSubmit={onSubmit}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            isValid,
            touched,
            errors,
            values,
            setFieldValue
          }) => (
            <Container
              home
              style={{
                flex: 1
              }}
            >
              {(locations !== undefined)
                ? (
                  <>
                    <SelectInput
                      name='locations'
                      arrayList={locations?.map((location) => ({
                        _id: location,
                        value: t('locations.' + location)
                      }))}
                      value={values.locations}
                      label={t('permitToWorkStepLocations.locations')}
                      multiEnable
                      onSelection={(selection) => {
                        void setFieldValue('locations', selection)
                      }}
                      touched={touched}
                      errors={errors}
                    />

                    <P color='placeholder' style={{ marginBottom: 4 }}>{t('permitToWorkStepLocations.locationNotInSelect')}</P>

                    <TextInput
                      disabled={values.locations !== undefined && values.locations.length > 0}
                      name='otherLocation'
                      label={t('permitToWorkStepLocations.otherLocation')}
                      value={values.otherLocation}
                      onChangeText={handleChange('otherLocation')}
                      onBlur={handleBlur('otherLocation')}
                      onSubmitEditing={() => descriptionInputRef.current?.focus()}
                      touched={touched}
                      errors={errors}
                      returnKeyType='done'
                      autoCorrect={false}
                      dense
                      mode='outlined'
                      blurOnSubmit
                    />

                    <P color='placeholder' style={{ marginBottom: 4 }}>{t('permitToWorkStepLocations.descriptionOfWork')}</P>

                    <TextInput
                      ref={descriptionInputRef}
                      style={{ minHeight: 180 }}
                      name='description'
                      label={t('permitToWorkStepLocations.description')}
                      value={values.description}
                      onChangeText={handleChange('description')}
                      onBlur={handleBlur('description')}
                      onSubmitEditing={() => handleSubmit()}
                      touched={touched}
                      errors={errors}
                      numberOfLines={10}
                      multiline
                      returnKeyType='done'
                      autoCorrect={false}
                      dense
                      mode='outlined'
                      blurOnSubmit
                    />

                    <Button disabled={!isValid} onPress={handleSubmit}>
                      {t('common.continue')}
                    </Button>
                  </>
                  )
                : <ActivityIndicator />}
            </Container>
          )}
        </Formik>
      </KeyboardFix>
    </SafeArea>
  )
}

export default StepLocations
