// Config
import firebase from '@config/firebase'

interface Params {
  selectedTerminal: string
  permitId: string
}

export const cancelPermit = async ({ selectedTerminal, permitId }: Params): Promise<void> => {
  try {
    await firebase.firestore()
      .collection('terminals')
      .doc(selectedTerminal)
      .collection('modules')
      .doc('permitToWork')
      .collection('data')
      .doc(permitId)
      .update({ state: 'cancelled' })
  } catch (error) {
    console.error('Error cancelling permit:', error)
    return await Promise.reject(error)
  }
}
