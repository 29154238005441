// ui
import { Button } from '@common/components/Button'
import { CalendarInput, DropdownInput } from '@common/components/Input'
import { H3, P } from '@common/components/Typography'
import { ActivityIndicator } from 'react-native-paper'
// Context
import AppContext from '@context/AppContext'
// external
import { FormikProvider, getIn } from 'formik'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { View } from 'react-native'
import { useCreateVisitForm } from '../hooks/useCreateVisitForm'
import { VisitManagementQuestions } from '../models'

export const CreateVisitForm = (): JSX.Element => {
  const appContext = useContext(AppContext)
  const { selectedTerminal } = appContext
  const { t } = useTranslation()

  const {
    hosts,
    isLoading,
    filteredTimeValues,
    form
  } = useCreateVisitForm()

  const questions = getIn(form.values, 'questions') as VisitManagementQuestions

  const { values, setFieldTouched, setFieldValue, handleSubmit, errors, touched, isSubmitting } = form

  if (isLoading) {
    return (
      <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
        <ActivityIndicator animating size='large' />
      </View>
    )
  }

  // TODO: Remove this when we have the approval to create visits for pier400
  const submitButtonDisabled = isSubmitting || selectedTerminal === 'pier400'

  return (
    <View style={{ flex: 1, marginTop: 20, gap: 12, justifyContent: 'flex-start' }}>
      <FormikProvider value={form}>
        <CalendarInput
          minDate={new Date().toDateString()}
          onDayPress={(date) => {
            void setFieldValue('date', new Date(date.dateString))
          }}
          errors={errors}
          value={values.date}
          label={t('visitsManagement.createForm.inputs.date')}
          touched={touched}
          name='date'
        />

        <View style={{ flex: 0.23, flexDirection: 'row', gap: 12 }}>
          <View style={{ flex: 1 }}>
            <DropdownInput
              style={{ flex: 1 }}
              name='checkIn'
              label={t('visitsManagement.createForm.inputs.checkIn')}
              placeholder=''
              value={values.checkIn}
              setValue={(value) => {
                void setFieldValue('checkIn', value)
                void setFieldTouched('checkIn', true)
              }}
              list={filteredTimeValues}
              touched={touched}
              errors={errors}
            />
          </View>
          <View style={{ flex: 1 }}>
            <DropdownInput
              style={{ flex: 1 }}
              name='checkOut'
              label={t('visitsManagement.createForm.inputs.checkOut')}
              placeholder=''
              value={values.checkOut}
              setValue={(value) => {
                void setFieldValue('checkOut', value)
                void setFieldTouched('checkOut', true)
              }}
              list={filteredTimeValues}
              touched={touched}
              errors={errors}
            />
          </View>
        </View>

        <DropdownInput
          name='host'
          label={t('visitsManagement.createForm.inputs.host')}
          placeholder=''
          value={values.host}
          setValue={(value) => {
            void setFieldValue('host', value)
            void setFieldTouched('host', true)
          }}
          list={hosts?.map(host => ({
            label: `${host.name} ${host.surname}`,
            value: host.id
          }))}
          touched={touched}
          errors={errors}
        />

        <H3 color='text' bold>{t('visitsManagement.questions.title')}</H3>

        <View style={{ flex: 0.5, gap: 12 }}>
          {questions?.map((question, index) => (
            <View key={`${question.question}.${index}`} style={{ flex: 1, justifyContent: 'flex-start' }}>
              <DropdownInput
                style={{ flex: 1 }}
                name={`questions[${index}]answer` as any}
                label={t(`visitsManagement.questions.${question.question}`)}
                placeholder={t('visitsManagement.questions.options.placeholder')}
                value={values?.questions?.[index]?.answer as any}
                setValue={(value) => {
                  void setFieldValue(`questions[${index}].answer`, value, true)
                  void setFieldTouched(`questions[${index}]`, true)
                }}
                list={question?.options?.map((answer) => ({
                  label: t(`visitsManagement.questions.options.${answer}`),
                  value: answer
                }))}
                touched={touched}
                errors={(errors.questions?.[index] as any)?.answer}
              />
            </View>
          )
          )}
        </View>

        <Button
          loading={isSubmitting}
          disabled={submitButtonDisabled}
          style={{ marginTop: 'auto' }}
          onPress={handleSubmit}
          mode='contained'
        >
          <P color='surface' bold>{isSubmitting ? t('common.loading') : t('visitsManagement.createForm.submit')}</P>
        </Button>
      </FormikProvider>

    </View>
  )
}
