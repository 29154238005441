import * as React from 'react'
import Svg, { G, Path, Circle } from 'react-native-svg'

const SvgComponent = props => (

  <Svg
    height='100%' width='100%' viewBox='0 0 58 58'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <G fill='none'>
      <Path
        d='M43 42.62a1 1 0 0 1-.92-1.4L48 27.79V15.88a1 1 0 0 1 2 0c0 13.18 0 12.24-.08 12.52l-6 13.6a1 1 0 0 1-.92.62z'
        fill='#9fc9d3'
      />
      <Path
        d='M55 42.62a1 1 0 0 1-.92-.6l-6-13.62c-.12-.27-.08.77-.08-12.52a1 1 0 0 1 2 0v11.91l5.92 13.43a1 1 0 0 1-.92 1.4z'
        fill='#9fc9d3'
      />
      <Path
        d='M4.6 33.64a3 3 0 0 1-1.86-2.34L.39 15.61a3 3 0 0 1 2.14-3.33l7-2.28L9 35.11a17.6 17.6 0 0 1-4.4-1.47z'
        fill='#2c3e50'
      />
      <Circle cx={15.5} cy={44.5} fill='#95a5a5' r={13.5} />
      <Path
        d='M28 39.4c0 2.49.12 3.23-.35 4.17A3.47 3.47 0 0 1 23 45.21a3.52 3.52 0 0 1-2-3.15V44a3.56 3.56 0 0 1-2.77 3.52A3.49 3.49 0 0 1 14 44.06v-6.49a1 1 0 0 0-.76-1c-5.13-1.27-4.64-1.14-4.85-1.22a3.5 3.5 0 0 1-1.64-1.17A13.51 13.51 0 0 1 28 39.4z'
        fill='#7f8c8d'
      />
      <Circle cx={13} cy={51} fill='#7f8c8d' r={3} />
      <Path
        d='M28 30.5c0 10.63.13 10.5-.35 11.5A3.47 3.47 0 0 1 23 43.65a3.52 3.52 0 0 1-2-3.15v1.9a3.56 3.56 0 0 1-2.77 3.52A3.49 3.49 0 0 1 14 42.5V36a1 1 0 0 0-.76-1c-5.07-1.22-4.64-1.1-4.85-1.18A3.55 3.55 0 0 1 6 30.49V19.56l15.78-1.12 5.33 9.73c.574.64.891 1.47.89 2.33z'
        fill='#35495e'
      />
      <Path
        d='M23 31v12.65a3.48 3.48 0 0 1-2-3.15c0-10.53.13-10-.37-10.81a1 1 0 1 1 1.7-1A4.48 4.48 0 0 1 23 31z'
        fill='#2c3e50'
      />
      <Path
        d='M20 21.25c2.38 5.25.28 6 1.24 8.07 1.25 2.74 7.52 3 5.73-6.2-.33-1.72-.93-4.19-2.32-5.38z'
        fill='#3f5c6c'
      />
      <Path
        d='M21 36c1.93-2.17 4.73-5.23 6.66-7 5.25-4.86 14.16-11.68 24.77-14a2 2 0 0 0-.86-3.9c-9.17 2-20 7.74-30.17 18.49a4.78 4.78 0 0 0-.4.41z'
        fill='#a56a43'
      />
      <Circle cx={49} cy={47} fill='#95a5a5' r={9} />
      <Circle cx={45.5} cy={47.38} fill='#7f8c8d' r={2.5} />
      <G fill='#f0c419'>
        <Path d='M35 12a1 1 0 0 1-1-1V7a1 1 0 0 1 2 0v4a1 1 0 0 1-1 1z' />
        <Path d='M37 10h-4a1 1 0 0 1 0-2h4a1 1 0 0 1 0 2zM41 33a1 1 0 0 1-1-1v-4a1 1 0 0 1 2 0v4a1 1 0 0 1-1 1z' />
        <Path d='M43 31h-4a1 1 0 0 1 0-2h4a1 1 0 0 1 0 2zM53 6a1 1 0 0 1-1-1V1a1 1 0 0 1 2 0v4a1 1 0 0 1-1 1z' />
        <Path d='M55 4h-4a1 1 0 0 1 0-2h4a1 1 0 0 1 0 2z' />
      </G>
      <Path
        d='M27.08 51.45a13.54 13.54 0 0 1-6 5.32 4.01 4.01 0 0 1 6-5.32zM9 42.5a4.5 4.5 0 0 1-6.87 3.82 13.36 13.36 0 0 1 1.49-8.23A4.5 4.5 0 0 1 9 42.5z'
        fill='#7f8c8d'
      />
      <Path
        d='M6.44 16.43c-.39.65-.38.94-.67 2.71a9 9 0 0 0 4.85 9.53C17.12 31.91 17.07 32 18 32a3 3 0 0 0 2.56-4.56c-.6-1-1.35-1.18-3.83-2.43l-3.42-1.71a3 3 0 0 1-1.62-2.18z'
        fill='#3f5c6c'
      />
      <Path
        d='M5 11c0 6.075 4.925 11 11 11s11-4.925 11-11S22.075 0 16 0 5 4.925 5 11z'
        fill='#547580'
      />
      <Path
        d='M25.61 16.35C21.49 18.93 15.28 18.42 12 15.3 7.16 10.73 11.58 4 19.5 4c2.15-.03 4.267.54 6.11 1.65a11 11 0 0 1 0 10.7z'
        fill='#f0c419'
      />
      <Path
        d='M24 12a1 1 0 0 1-1-1c0-3-3.84-3-4-3a1 1 0 0 1 0-2c2.08 0 6 1.05 6 5a1 1 0 0 1-1 1z'
        fill='#f9eab0'
      />
      <Path
        d='M26.59 14a10.76 10.76 0 0 1-1 2.37 12.24 12.24 0 0 1-9.72 1.13c-5.09-1.54-7.26-5.9-5-9.42 3.18 3.71 8.28 7.23 15.72 5.92z'
        fill='#f29c1f'
      />
      <Path
        d='M57.92 48.15a9 9 0 0 1-4.21 6.51 4 4 0 1 1 4.21-6.51zM53 39.38a2.78 2.78 0 0 1-3 2.5c-2.28 0-3.74-2-2.61-3.74a9 9 0 0 1 5.56.77 3 3 0 0 1 .05.47z'
        fill='#7f8c8d'
      />
    </G>
  </Svg>

)

export default SvgComponent
