import React, { useState } from 'react'
// Context
import AppContext from '@context/AppContext'
// Components
import { P } from '@components/Typography/P'
import { Dialog } from '@components/Dialog/Dialog'
// RN
import { TouchableOpacity } from 'react-native'
// Expo
import * as Linking from 'expo-linking'
// i18n
import { useTranslation } from 'react-i18next'

export const SOSButton = ({ disabled }) => {
  const { t } = useTranslation()
  const context = React.useContext(AppContext)
  const [dialog, setDialog] = useState({
    show: false,
    title: '',
    subtitle: ''
  })

  return (
    <>
      <Dialog
        title={dialog.title}
        subtitle={dialog.subtitle}
        visible={dialog.show}
        onDismiss={() => setDialog(prev => ({ ...prev, show: false }))}
      />
      <TouchableOpacity
        onPress={() => {
          if (!disabled) {
            if (context.terminals[context.selectedTerminal].phone) {
              Linking.openURL(`tel:${context.terminals[context.selectedTerminal].phone}`)
            } else {
              setDialog(prev => ({
                ...prev,
                title: 'Error',
                subtitle: t('sosButton.errorMsg'),
                show: true
              }))
            }
          }
        }}
      >
        <P style={{ opacity: disabled ? 0.2 : 1 }} link color='danger'>
          S.O.S
        </P>
      </TouchableOpacity>
    </>
  )
}
