import { useContext } from 'react'
// Context
import AppContext from '@context/AppContext'
import UserContext from '@context/UserContext'
// Components
import { Container } from '@components/Layout/Container'
import { KeyboardFix } from '@components/Layout/KeyboardFix'
import { SafeArea } from '@components/Layout/SafeArea'
import { InductionMissingAlert } from '@components/Profile/InductionMissingAlert'
import { ProfileHero } from '@components/Profile/ProfileHero'
import { H2 } from '@components/Typography/H2'
// Footer
import { ProfileFooter } from '@components/Footer/ProfileFooter'
// RN
import { View } from 'react-native'
// QR
import QRCodeSVG from 'react-native-qrcode-svg'
// Paper
import { useTheme } from 'react-native-paper'
// i18next
import { useTranslation } from 'react-i18next'

export const ProfileScreen = () => {
  const appContext = useContext(AppContext)
  const userContext = useContext(UserContext)
  const { userInfo, userAuth } = userContext
  const { t } = useTranslation()
  const { selectedTerminal, terminals } = appContext
  const { induction } = userContext
  const terminalInfo = terminals[selectedTerminal]
  const { colors } = useTheme()

  return (
    <SafeArea>
      {(induction?.inductionStatus === 'pending' ||
          induction?.inductionStatus === 'expired') && (
            <InductionMissingAlert />
      )}
      <KeyboardFix>
        <ProfileHero
          fullName={userInfo.name + ' ' + userInfo.surname}
          userTypeLabel={t('userTypes.' + userInfo.userType)}
          email={userAuth.email}
          terminalInfo={terminalInfo}
          loginWithName={t('loginWith.' + terminalInfo.loginWith.name)}
          loginWithValue={userInfo?.loginWith?.[terminalInfo?.loginWith.name]}
        />
        <Container home style={{ alignItems: 'center', flex: 1 }}>
          <View
            style={{
              flex: 1,
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <H2 style={{ marginBottom: 4 }} bold>
              {t('profileScreen.authorizationPass')}
            </H2>

            <View style={{ padding: 3 }}>
              <QRCodeSVG value={userAuth.email} color={colors.accent} size={200} />
            </View>
          </View>
        </Container>
      </KeyboardFix>
      <ProfileFooter />
    </SafeArea>
  )
}
