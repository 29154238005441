import { useEffect, useState, useRef } from 'react'
// Firebase
import firebase from '@config/firebase'
// Notifications
import * as Notifications from 'expo-notifications'

export const useNotifications = ({ selectedTerminal, userAuth }) => {
  const [notifications, setNotifications] = useState()
  const snapshotRef = useRef()

  useEffect(() => {
    if (selectedTerminal && userAuth?.email) {
      snapshotRef.current = firebase
        .firestore()
        .collection('users')
        .doc(userAuth.email)
        .collection('notifications')
        .where('terminal', '==', selectedTerminal)
        .orderBy('date', 'desc')
        .onSnapshot(
          notifications => {
            Notifications.setBadgeCountAsync(
              notifications.docs.filter(
                notification => !notification.data().read
              ).length
            )
            if (notifications.docs.length > 0) {
              setNotifications(
                notifications.docs.map(notification => ({
                  id: notification.id,
                  ...notification.data()
                }))
              )
            } else {
              Notifications.setBadgeCountAsync(0)
              setNotifications([])
            }
          },
          error => {
            console.error(error)
            Notifications.setBadgeCountAsync(0)
            setNotifications([])
          }
        )
    }

    return () => {
      Notifications.setBadgeCountAsync(0)
      snapshotRef.current && snapshotRef.current()
    }
  }, [selectedTerminal, userAuth?.email])

  return notifications
}
