// External
import { forwardRef, ForwardedRef } from 'react'
import { View, TextInput as RNTextInput } from 'react-native'
import { TextInput as PaperTextInput, HelperText, TextInputProps } from 'react-native-paper'
import { FormikTouched, FormikErrors } from 'formik'

interface Props<T> extends Omit<TextInputProps, 'theme'> {
  name: keyof T
  invisible?: boolean
  noWhiteSpace?: boolean
  lowerCase?: boolean
  touched?: FormikTouched<T>
  errors?: FormikErrors<T>
}

const TextInputInner = <T, >({
  name,
  invisible = false,
  value,
  onChangeText,
  noWhiteSpace = false,
  lowerCase = false,
  onBlur,
  onSubmitEditing,
  touched,
  errors,
  ...rest
}: Props<T>, ref: ForwardedRef<RNTextInput>): JSX.Element => {
  return (
    <View style={{ display: invisible ? 'none' : 'flex' }}>
      <PaperTextInput
        ref={ref}
        value={value}
        onChangeText={text => {
          if (onChangeText !== undefined) {
            if (noWhiteSpace) {
              onChangeText(text.replace(/\s/g, ''))
              return
            }

            if (lowerCase) {
              onChangeText(text.toLowerCase())
              return
            }

            onChangeText(text)
          }
        }}
        onBlur={onBlur}
        onSubmitEditing={onSubmitEditing}
        {...rest}
      />

      <HelperText
        style={{ marginBottom: 4 }}
        type='info'
        visible={Boolean(touched?.[name])}
      >
        {errors?.[name] !== undefined ? String(errors[name]) : ''}
      </HelperText>
    </View>
  )
}

export const TextInput = forwardRef(TextInputInner)
