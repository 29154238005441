import { useState } from 'react'
// Firebase
import firebase from '@config/firebase'
// RM
import { StyleSheet } from 'react-native'
// Components
// Paper
import {
  Dialog,
  Portal,
  TextInput
} from 'react-native-paper'
// Formik
import { Formik } from 'formik'
import * as Yup from 'yup'
// Toast
import { useToast } from 'react-native-toast-notifications'
// i18n
import { useTranslation } from 'react-i18next'
import { Button } from '@modules/common/components/Button'

export const ForgotPasswordButton = () => {
  const toast = useToast()
  const { t } = useTranslation()
  const [dialog, setDialog] = useState({
    show: false,
    title: '',
    subtitle: ''
  })

  return (
    <>
      <Button
        style={styles.button}
        compact
        mode='text'
        onPress={() => setDialog(prev => ({
          ...prev,
          title: 'Restore Password',
          subtitle:
            'Enter your email',
          show: true
        }))}
      >
        {t('common.forgottenPassword')}
      </Button>

      <Portal>
        <Dialog
          visible={dialog.show}
          onDismiss={() => setDialog(prev => ({
            ...prev,
            show: false
          }))}
        >
          <Dialog.Title>{t('forgotPasswordButton.dialogTitle')}</Dialog.Title>
          <Formik
            validateOnMount
            initialValues={{
              email: ''
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string().email().required()
            })}
            onSubmit={async (values, { setSubmitting }) => {
              setSubmitting(true)
              firebase
                .auth()
                .sendPasswordResetEmail(values.email)
                .then(() => {
                  setSubmitting(false)
                  setDialog(prev => ({
                    ...prev,
                    show: false
                  }))
                  toast.show(t('forgotPasswordButton.dialogSuccess'))
                })
                .catch(err => {
                  setSubmitting(false)
                  toast.show(err.message)
                  console.log(err)
                })
            }}
          >
            {({
              isSubmitting,
              handleSubmit,
              handleChange,
              handleBlur,
              isValid
            }) => (
              <>
                <Dialog.Content>
                  <TextInput
                    onChangeText={handleChange('email')}
                    onBlur={handleBlur('email')}
                    returnKeyType='done'
                    blurOnSubmit={false}
                    mode='outlined'
                    dense
                    autoCapitalize='none'
                    autoCorrect={false}
                    label='Email'
                    onSubmitEditing={handleSubmit}
                  />
                </Dialog.Content>
                <Dialog.Actions>
                  <Button
                    mode='text'
                    onPress={() => setDialog(prev => ({
                      ...prev,
                      show: false
                    }))}
                  >
                    {t('common.close')}
                  </Button>
                  <Button
                    disabled={!isValid || isSubmitting}
                    style={{ marginLeft: 20 }}
                    mode='contained'
                    onPress={handleSubmit}
                  >
                    {t('forgotPasswordButton.resetPasswordButton')}
                  </Button>
                </Dialog.Actions>
              </>
            )}
          </Formik>
        </Dialog>
      </Portal>

    </>
  )
}

const styles = StyleSheet.create({
  button: {
    alignSelf: 'flex-end',
    marginTop: 0
  }
})
