// External
import Svg, { Path } from 'react-native-svg'
import { View } from 'react-native'
import { PropsWithChildren } from 'react'
// Paper
import { Button as PaperButton, ButtonProps, useTheme } from 'react-native-paper'

interface Params extends PropsWithChildren<ButtonProps> {
  onPress: () => void
  featured?: boolean
  full?: boolean
}

export const Button = ({
  onPress,
  mode = 'outlined',
  featured = false,
  full = false,
  disabled = false,
  style,
  icon,
  loading,
  labelStyle,
  children
}: Params): JSX.Element => {
  const { colors } = useTheme()

  return (
    <View
      style={[{
        opacity: disabled ? 0.3 : 1,
        marginVertical: 20,
        ...(full && { width: '100%' })
      }, style]}
    >
      {featured && (
        <Svg
          width={53}
          height={27}
          viewBox='0 0 53 27'
          fill='none'
          style={{
            position: 'absolute',
            top: -12,
            left: -14,
            zIndex: 1
          }}
        >
          <Path fill='#fff' d='M1 2.356h52v24H1z' />

          <Path
            d='M25 12.453L0 22.638v1.62l25-10.185v-1.62zM4 17.736L46 .625v1.62L4 19.355v-1.62z'
            fill='#FF6441'
          />
        </Svg>
      )}

      <PaperButton
        disabled={disabled}
        onPress={onPress}
        icon={icon}
        loading={loading}
        style={{
          ...(mode === 'outlined' && {
            borderColor: colors.primary,
            borderWidth: 1.5
          }),
          display: 'flex'

        }}
        labelStyle={[{
          textTransform: 'capitalize',
          color:
            mode === 'outlined' || mode === 'text' ? colors.primary : '#fff'
        }, labelStyle]}
        uppercase={false}
        mode={mode}
      >
        {children}
      </PaperButton>
    </View>
  )
}
