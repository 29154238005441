// external
import * as yup from 'yup'

export interface VisitManagementHost {
  id: string
  email: string
  name: string
  surname: string
}

export const VisitManagmentHostSchema = yup.object().shape({
  id: yup.string().required(),
  email: yup.string().email().required(),
  name: yup.string().required(),
  surname: yup.string().required()
})

export const VisitManagmentHostsSchema = yup.array().of(
  VisitManagmentHostSchema
)

export type VisitManagementHosts = yup.InferType<typeof VisitManagmentHostsSchema>
