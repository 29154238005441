// RN
import { ScrollView } from 'react-native'
// Keyboard Aware
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'

export const KeyboardFix = ({ children }) => {
  return (
    <KeyboardAwareScrollView
      behavior='padding'
      enableOnAndroid
      keyboardVerticalOffset={120}
      extraHeight={120}
      contentContainerStyle={{ flexGrow: 1 }}
    >
      <ScrollView style={{ flex: 1 }} contentContainerStyle={{ flex: 1 }}>
        {children}
      </ScrollView>
    </KeyboardAwareScrollView>
  )
}
