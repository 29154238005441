import { useState, useEffect } from 'react'
// Expo
import * as SplashScreen from 'expo-splash-screen'
import * as Font from 'expo-font'

export const useAssets = () => {
  const [assetsReady, setAssetsReady] = useState(false)
  useEffect(() => {
    async function prepareAssets () {
      try {
        await SplashScreen.hideAsync()
        await Font.loadAsync({
          'Maersk-Regular': require('@assets/fonts/MaerskText-Regular.ttf'),
          'Maersk-Light': require('@assets/fonts/MaerskText-Light.ttf'),
          'Maersk-Bold': require('@assets/fonts/MaerskText-Bold.ttf')
        })
        setAssetsReady(true)
      } catch (e) {
        setAssetsReady(false)
        console.warn(e)
      }
    }
    prepareAssets()
  }, [])

  return assetsReady
}
