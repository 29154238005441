// External
import { View, StyleSheet } from 'react-native'
import { Col, Grid } from 'react-native-easy-grid'
// Components
import { H1, P } from '@common/components/Typography'
import { colors } from '../constants'

interface Props {
  title: string
  subtitle: string
  selectedStep?: number
  totalSteps?: number
}

export const StepFeatureCompact = ({
  title,
  subtitle,
  selectedStep = 1,
  totalSteps = 0
}: Props): JSX.Element => {
  return (
    <View style={styles.container}>
      <View style={styles.featureContainer}>
        <View style={{ padding: 10 }}>
          <H1 bold>{title}</H1>

          <View style={styles.subtitleBox}>
            <View style={[styles.subtitleContainer, { backgroundColor: colors.accent }]} />

            <P bold color='accent'>
              {subtitle}
            </P>
          </View>
        </View>

        <Grid style={styles.gridSteps}>
          {Array.from(Array(totalSteps), (step, index) => {
            if (index + 1 === selectedStep) {
              return (
                <Col
                  key={index}
                  style={{ backgroundColor: colors.lightBlue }}
                />
              )
            }

            return <Col key={index} />
          })}
        </Grid>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
  },
  featureContainer: {
    backgroundColor: '#F8F8F8',
    width: '80%',
    alignSelf: 'flex-start',
    margin: 24,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 4
    },
    shadowOpacity: 0.08,
    shadowRadius: 10,
    elevation: 1
  },
  subtitleBox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  subtitleContainer: {
    height: 1.5,
    width: 20,
    marginRight: 10
  },
  gridSteps: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    height: 3
  }
})
