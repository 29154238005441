// External
import { useState } from 'react'
import { View, StyleProp, ViewStyle } from 'react-native'
import { HelperText } from 'react-native-paper'
import { FormikTouched, FormikErrors } from 'formik'
// Components
import { Dropdown } from '@common/components/Dropdown'
// Models
import { DropDownProps } from '@common/models'

interface Props<T> extends Omit<DropDownProps, 'mode' | 'visible' | 'setVisible' | 'showDropDown' | 'onDismiss'> {
  style?: StyleProp<ViewStyle>
  name: keyof T
  value: string
  touched?: FormikTouched<T>
  errors?: FormikErrors<T>
}

export const DropdownInput = <T,>({
  style,
  name,
  label,
  placeholder,
  value,
  setValue,
  list,
  touched,
  errors,
  inputProps,
  ...rest
}: Props<T>): JSX.Element => {
  const [visible, setVisible] = useState(false)

  return (
    <View style={style}>
      <Dropdown
        label={label}
        placeholder={placeholder}
        mode='outlined'
        visible={visible}
        showDropDown={() => setVisible(true)}
        onDismiss={() => setVisible(false)}
        value={value}
        setValue={setValue}
        list={list}
        inputProps={{ style: { height: 40 }, ...inputProps }}
        {...rest}
      />

      <HelperText
        style={{ marginBottom: 4 }}
        type='info'
        visible={Boolean(touched?.[name])}
      >
        {errors?.[name] !== undefined ? String(errors[name]) : ''}
      </HelperText>
    </View>
  )
}
