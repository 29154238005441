// Paper
import { Subheading, useTheme } from 'react-native-paper'

export const H3 = ({ bold, regular, color, style, ...props }) => {
  const { colors } = useTheme()

  return (
    <Subheading
      style={{
        fontSize: 16,
        lineHeight: 18,
        color: colors[color] || colors.accent,
        fontFamily: bold
          ? 'Maersk-Bold'
          : regular
            ? 'Maersk-Regular'
            : 'Maersk-Light',
        ...style
      }}
      {...props}
    >
      {props.children}
    </Subheading>
  )
}
