import { useContext, useEffect, useState } from 'react'
// Context
import AppContext from '@context/AppContext'
import UserContext from '@context/UserContext'
// Components
import { Button } from '@components/Button/Button'
import { Container } from '@components/Layout/Container'
import { KeyboardFix } from '@components/Layout/KeyboardFix'
import { SafeArea } from '@components/Layout/SafeArea'
import { StepFeatureCompact } from '@components/Layout/StepFeatureCompact'
import { H2 } from '@components/Typography/H2'
import { P } from '@components/Typography/P'
// Services
import { InductionServices } from '@services/InductionServices'
// RN
import {
  Image,
  Platform,
  StyleSheet,
  TouchableOpacity,
  View
} from 'react-native'
// Video
import { ResizeMode, Video } from 'expo-av'
// i18next
import { useTranslation } from 'react-i18next'

export const StepVideoScreen = ({ navigation }) => {
  const appContext = useContext(AppContext)
  const userContext = useContext(UserContext)
  const { userInfo } = userContext
  const { i18n, t } = useTranslation()
  const locale = i18n.language
  const [urlVideo, setUrlVideo] = useState()
  const [shouldPlay, setShouldPlay] = useState(false)
  const { selectedTerminal, terminals } = appContext
  const terminalInfo = terminals[selectedTerminal]

  useEffect(() => {
    ;(async () => {
      setUrlVideo(
        await InductionServices.getInductionVideo({
          selectedTerminal,
          userType: userInfo.userType,
          locale
        })
      )
    })()
  }, [selectedTerminal, userInfo.userType, locale])

  return (
    <SafeArea>
      <KeyboardFix>
        <StepFeatureCompact
          title={t('stepVideoScreen.title')}
          subtitle={t('stepVideoScreen.subtitle')}
          selectedStep={1}
          totalSteps={2}
        />
        {Platform.OS !== 'web' && (
          <View style={styles.videoContainer}>
            {!shouldPlay
              ? (
                <TouchableOpacity
                  style={{ zIndex: 1, height: '100%', width: '100%' }}
                  onPress={async () => {
                    setShouldPlay(true)
                  }}
                >
                  <Image
                    style={styles.video}
                    source={require('@assets/images/play.png')}
                  />
                </TouchableOpacity>
                )
              : (
                <Video
                  source={{
                    uri: urlVideo
                  }}
                  rate={1.0}
                  volume={1.0}
                  isMuted={false}
                  useNativeControls
                  resizeMode={ResizeMode.CONTAIN}
                  shouldPlay={shouldPlay}
                  usePoster
                  posterSource={require('@assets/images/play.png')}
                  posterStyle={styles.posterStyle}
                  isLooping={false}
                  onError={err => console.log(err)}
                  style={styles.video}
                />
                )}
          </View>

        )}
        {Platform.OS === 'web' && (
          <Container home style={{ flex: 1 }}>
            <View style={styles.videoContainerWeb}>
              {!shouldPlay
                ? (
                  <TouchableOpacity
                    style={{ zIndex: 1, height: '100%', width: '100%' }}
                    onPress={async () => {
                      setShouldPlay(true)
                    }}
                  >
                    <Image
                      style={styles.video}
                      source={require('@assets/images/play.png')}
                    />
                  </TouchableOpacity>
                  )
                : (
                  <Video
                    source={{
                      uri: urlVideo
                    }}
                    videoStyle={{
                      width: '100%',
                      height: '100%'
                    }}
                    rate={1.0}
                    volume={1.0}
                    isMuted={false}
                    useNativeControls
                    resizeMode={ResizeMode.CONTAIN}
                    shouldPlay={shouldPlay}
                    usePoster
                    posterSource={require('@assets/images/play.png')}
                    posterStyle={styles.posterStyle}
                    isLooping={false}
                    onError={err => console.log(err)}
                    style={styles.video}
                  />
                  )}
            </View>

          </Container>
        )}
        <Container home style={{ flex: 1 }}>
          <H2 regular>
            {t('userTypes.' + userInfo.userType)} -{' '}
            {t('stepVideoScreen.safetyInductionVideo')}
          </H2>
          <P light color='textLight'>
            APM Terminals {terminalInfo.name}
          </P>
          <Button
            style={{ marginVertical: 34 }}
            onPress={() => navigation.navigate('Test')}
          >
            {t('common.continue')}
          </Button>
        </Container>
      </KeyboardFix>
    </SafeArea>
  )
}

const styles = StyleSheet.create({
  videoContainerWeb: {
    aspectRatio: 16 / 9,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 4
    },
    shadowOpacity: 0.08,
    shadowRadius: 10,
    borderRadius: 10,
    overflow: 'hidden'
  },
  videoContainer: {
    position: 'relative',
    width: '100%',
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 4
    },
    shadowOpacity: 0.08,
    shadowRadius: 10
  },
  video: {
    aspectRatio: 16 / 9,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    resizeMode: 'cover'
  },
  posterStyle: {
    width: '100%',
    aspectRatio: 16 / 9,
    resizeMode: 'cover'
  }
})
