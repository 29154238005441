// External
import { StyleSheet, View } from 'react-native'
import { useTranslation } from 'react-i18next'
// Components
import { P } from '@common/components/Typography'
// Models
import { PermitToWork } from '@permitToWork/models'

interface Props {
  item: PermitToWork
}

export const DetailPhones = ({ item }: Props): JSX.Element => {
  const { t } = useTranslation()
  const { creator, personInCharge } = item

  return (
    <View style={styles.container}>
      <View style={styles.titleContainer}>
        <View style={{ flexDirection: 'row', alignItems: 'flex-end' }}>
          <P color='accent' bold style={styles.sectionTitle}>
            {t('permitToWorkItem.phoneNumbers')}
          </P>

          <View style={styles.divider} />
        </View>

        {(creator.phonePrefix !== undefined && creator.phoneNumber !== undefined) && (
          <View style={{ marginBottom: 12 }}>
            <P
              color='primary'
              bold
              style={styles.phone}
            >
              {t('common.personInCharge')}
            </P>

            <P
              color='textLight'
              style={{ fontSize: 11 }}
            >
              {`${creator.phonePrefix} ${creator.phoneNumber}`}
            </P>
          </View>
        )}

        {(personInCharge.phonePrefix !== undefined && personInCharge.phoneNumber !== undefined) && (
          <View>
            <P
              color='primary'
              bold
              style={styles.phone}
            >
              {t('permitToWorkItem.emergency')}
            </P>

            <P
              color='textLight'
              style={{ fontSize: 11 }}
            >
              {`${personInCharge.phonePrefix} ${personInCharge.phoneNumber}`}
            </P>
          </View>
        )}
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    width: '100%'
  },
  titleContainer: {
    flexDirection: 'column',
    flex: 1
  },
  sectionTitle: {
    fontSize: 11,
    textTransform: 'uppercase',
    letterSpacing: 1
  },
  divider: {
    height: 0.5,
    backgroundColor: '#DADADA',
    flex: 1,
    marginBottom: 6,
    marginLeft: 18,
    marginRight: 32
  },
  phone: {
    fontSize: 11,
    textTransform: 'uppercase'
  }
})
