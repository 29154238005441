import { useEffect, useRef, useContext } from 'react'
// Context
import UserContext from '@context/UserContext'
// Components
import { H3 } from '@components/Typography/H3'
import { LanguageSelector } from '@components/Dropdown/LanguageSelector'
import { SafeArea } from '@components/Layout/SafeArea'
import { KeyboardFix } from '@components/Layout/KeyboardFix'
import { Container } from '@components/Layout/Container'
// Footer
import { SettingsFooter } from '@components/Footer/SettingsFooter'
// RN
import { View, AppState } from 'react-native'
// Paper
import { useTheme, Checkbox } from 'react-native-paper'
// Location
import * as Location from 'expo-location'
// Expo
import * as Linking from 'expo-linking'
// Notifications
import * as Notifications from 'expo-notifications'
// i18next
import { useTranslation } from 'react-i18next'
// Services
import { UserServices } from '@services/UserServices'

export const SettingsScreen = () => {
  const userContext = useContext(UserContext)
  const { userAuth } = userContext
  const [
    notificationPermission,
    requestNotificationPermission
  ] = Notifications.usePermissions()
  const [
    foregroundStatus,
    requestForegroundPermission
  ] = Location.useForegroundPermissions()
  const [
    backgroundStatus,
    requestBackgroundPermission
  ] = Location.useBackgroundPermissions()
  const { colors } = useTheme()
  const appState = useRef(AppState.currentState)
  const { t } = useTranslation()

  useEffect(() => {
    AppState.addEventListener('change', _handleAppStateChange)

    return () => {
      AppState.removeEventListener('change', _handleAppStateChange)
    }
  }, [])

  useEffect(() => {
    if (foregroundStatus) {
      UserServices.updateMetadata({
        email: userAuth.email,
        key: 'foregroundPermission',
        value: foregroundStatus.granted
      })
    }
  }, [foregroundStatus])

  useEffect(() => {
    if (backgroundStatus) {
      UserServices.updateMetadata({
        email: userAuth.email,
        key: 'backgroundPermission',
        value: backgroundStatus.granted
      })
    }
  }, [backgroundStatus])

  useEffect(() => {
    if (notificationPermission) {
      UserServices.updateMetadata({
        email: userAuth.email,
        key: 'notificationPermission',
        value: notificationPermission.granted
      })
    }
  }, [notificationPermission])

  const _handleAppStateChange = nextAppState => {
    if (
      appState.current.match(/inactive|background/) &&
      nextAppState === 'active'
    ) {
      requestForegroundPermission()
      requestBackgroundPermission()
      requestNotificationPermission()
    }

    appState.current = nextAppState
  }

  return (
    <SafeArea>
      <KeyboardFix>
        <Container home style={{ flex: 1, alignItems: 'center' }}>
          <View
            style={{
              width: '100%',
              flexDirection: 'row',
              alignItems: 'baseline',
              justifyContent: 'space-between',
              marginVertical: 6,
              borderBottomWidth: 1,
              paddingBottom: 0,
              borderBottomColor: '#F2F2F2'
            }}
          >
            <H3 color='textLigh'>{t('settingsScreen.language')}</H3>
            <LanguageSelector relative />
          </View>
          <View
            style={{
              width: '100%',
              flexDirection: 'row',
              alignItems: 'baseline',
              justifyContent: 'space-between',
              marginVertical: 6,
              borderBottomWidth: 1,
              paddingBottom: 18,
              borderBottomColor: '#F2F2F2'
            }}
          >
            <H3 color='textLigh'>{t('settingsScreen.notifications')}</H3>
            <Checkbox
              status={notificationPermission?.granted ? 'checked' : 'unchecked'}
              color={colors.primary}
              onPress={() => {
                if (notificationPermission?.granted) {
                  Linking.openSettings()
                } else {
                  if (!notificationPermission.canAskAgain) {
                    Linking.openSettings()
                  } else {
                    requestNotificationPermission()
                  }
                }
              }}
            />
          </View>

          <View
            style={{
              width: '100%',
              flexDirection: 'row',
              alignItems: 'baseline',
              justifyContent: 'space-between',
              marginVertical: 6,
              borderBottomWidth: 1,
              paddingBottom: 18,
              borderBottomColor: '#F2F2F2'
            }}
          >
            <H3 color='textLigh'>{t('settingsScreen.foregroundLocation')}</H3>
            <Checkbox
              color={colors.primary}
              status={foregroundStatus?.granted ? 'checked' : 'unchecked'}
              onPress={() => {
                if (foregroundStatus?.granted) {
                  Linking.openSettings()
                } else {
                  if (!foregroundStatus.canAskAgain) {
                    Linking.openSettings()
                  } else {
                    requestForegroundPermission()
                  }
                }
              }}
            />
          </View>
          <View
            style={{
              width: '100%',
              flexDirection: 'row',
              alignItems: 'baseline',
              justifyContent: 'space-between',
              marginVertical: 6,
              borderBottomWidth: 1,
              paddingBottom: 18,
              borderBottomColor: '#F2F2F2'
            }}
          >
            <H3 color='textLigh'>{t('common.backgroundLocation')}</H3>
            <Checkbox
              color={colors.primary}
              status={backgroundStatus?.granted ? 'checked' : 'unchecked'}
              onPress={() => {
                if (foregroundStatus?.granted) {
                  Linking.openSettings()
                } else {
                  if (!foregroundStatus.canAskAgain) {
                    Linking.openSettings()
                  } else {
                    requestBackgroundPermission()
                  }
                }
              }}
            />
          </View>
        </Container>
      </KeyboardFix>
      <SettingsFooter />
    </SafeArea>
  )
}
