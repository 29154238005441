// External
import { object, string, boolean, InferType, number } from 'yup'
// Models
import { UserSchema } from '@common/models'

export const ContractorSchema = object().shape({
  id: string().required(),
  company: object().shape({
    uid: string(),
    name: string()
  }).required(),
  email: string().email().required(),
  isAuthorized: boolean().required(),
  isCompanyAdmin: boolean().required(),
  user: UserSchema.required(),
  totalPermitsToWork: number().required()
})

export type Contractor = InferType<typeof ContractorSchema>
