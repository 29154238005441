// External
import { Duration, intervalToDuration } from 'date-fns'
// Models
import { PermitToWork, AppStates } from '../models'
// Utils
import { getAppState } from './getAppState'

interface Params {
  ptw: PermitToWork
}

export const getIntervalItemDuration = ({ ptw }: Params): string | null => {
  const formatInterval = (intervalDuration: Duration): string | null => {
    const { days, hours, minutes, seconds } = intervalDuration

    if (days !== undefined && hours !== undefined && days > 0) {
      return `${days}d ${hours}h`
    }

    if (hours !== undefined && minutes !== undefined && hours > 0) {
      return `${hours}h ${minutes}m`
    }

    if (minutes !== undefined && seconds !== undefined && minutes > 0) {
      return `${minutes}m ${seconds}s`
    }

    if (seconds !== undefined) {
      return `${seconds}s`
    }

    return null
  }

  if (getAppState({ ptw }) === AppStates.Active) {
    const intervalDuration = intervalToDuration({
      start: new Date(),
      end: ptw.endDate.toDate()
    })

    return formatInterval(intervalDuration)
  }

  if (getAppState({ ptw }) === AppStates.Queued) {
    const intervalDuration = intervalToDuration({
      start: new Date(),
      end: ptw.startDate.toDate()
    })

    return formatInterval(intervalDuration)
  }

  return null
}
