// Config
import firebase from '@config/firebase'
// Common
import { COLLECTIONS } from '@modules/common/constants'
import { PendingVisitUser, VisitManagementHost } from '../models'
import moment from 'moment'

interface Params {
  selectedTerminal: string
  hostId: string
  userInfo: any
  date: Date
  checkIn: string
  checkOut: string
  questions?: Array<Record<string, string>>
}

const getUser = async (email: string): Promise<PendingVisitUser> => {
  const userDoc = await firebase.firestore().collection(COLLECTIONS.USERS).doc(email).get()

  return {
    id: userDoc.id,
    ...userDoc.data() as any
  }
}

const getHost = async (hostId: string, selectedTerminal: string): Promise<VisitManagementHost> => {
  const hostDoc = await firebase.firestore()
    .collection(COLLECTIONS.TERMINALS)
    .doc(selectedTerminal)
    .collection(COLLECTIONS.VISITS_MANAGEMENT_HOSTS)
    .doc(hostId)
    .get()

  return {
    id: hostDoc.id,
    ...hostDoc.data() as any
  }
}

export const createPendingVisit = async ({ selectedTerminal, date, hostId, checkIn, checkOut, questions, userInfo }: Params): Promise<void> => {
  try {
    const user = await getUser(userInfo.email)
    const host = await getHost(hostId, selectedTerminal)

    const checkInTime = moment(checkIn, 'HHmmA')
    checkInTime.date(moment(date).date())
    checkInTime.month(moment(date).month())
    checkInTime.year(moment(date).year())

    const checkOutTime = moment(checkOut, 'HHmmA')
    checkOutTime.date(moment(date).date())
    checkOutTime.month(moment(date).month())
    checkOutTime.year(moment(date).year())

    // check if checkin and checkout are after current time
    const now = moment()
    if (checkInTime.isBefore(now)) {
      throw new Error('Check in time is before current time')
    }
    if (checkOutTime.isBefore(now)) {
      throw new Error('Check out time is before current time')
    }

    // checkin time must be before checkout time
    if (checkInTime.isAfter(checkOutTime)) {
      throw new Error('Check in time is after check out time')
    }

    const createdDoc = await firebase.firestore()
      .collection(COLLECTIONS.TERMINALS)
      .doc(selectedTerminal)
      .collection(COLLECTIONS.VISITS_MANAGEMENT_PENDING_VISITS)
      .add({
        createdAt: date,
        checkIn: checkInTime.toDate(),
        checkOut: checkOutTime.toDate(),
        host,
        email: userInfo.email,
        user
      })

    if (questions != null) {
      await Promise.all(
        questions.map(async (question) => {
          return await createdDoc.set({
            questions: { [question.question]: question.answer }
          }, { merge: true })
        })
      )
    }
  } catch (error) {
    console.error('Error cancelling visit:', error)
    return await Promise.reject(error)
  }
}
