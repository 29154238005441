import React from 'react'
// RN
import { SafeAreaView, StyleSheet, Platform } from 'react-native'
// Components
import { StepFeature } from '@components/Layout/StepFeature'
import { Container } from '@components/Layout/Container'
import { P } from '@components/Typography/P'
import { Button } from '@components/Button/Button'
import { LanguageSelector } from '@components/Dropdown/LanguageSelector'
// Icons
import NotificationsIcon from '@icons/Notifications'
// Notifications
import * as Notifications from 'expo-notifications'
// Expo
import * as Device from 'expo-device'

export const NotificationsScreen = ({ setShowWelcomeScreen }) => {
  const enableNotifications = async () => {
    if (Device.isDevice) {
      const settings = await Notifications.requestPermissionsAsync()
      if (settings.granted || settings.ios?.status === Notifications.IosAuthorizationStatus.PROVISIONAL) {
        if (Platform.OS === 'android') {
          await Notifications.setNotificationChannelAsync('default', {
            name: 'default',
            importance: Notifications.AndroidImportance.MAX,
            vibrationPattern: [0, 250, 250, 250],
            lightColor: '#FF231F7C'
          })
        }
      }
    }
    setShowWelcomeScreen(false)
  }

  return (
    <SafeAreaView style={styles.container}>
      <LanguageSelector />
      <StepFeature
        title='Notifications'
        subtitle='Push Notifications'
        image={require('@assets/images/notifications-hero.jpg')}
        selectedStep={2}
        totalSteps={2}
      />
      <Container style={styles.content}>
        <P>
          Keep you safety and well informed is our main priority, for this purpose we need to be able to send you push notificiations with our last terminal updates.
        </P>
        <P justify color='caption' style={{ marginTop: 24 }}>
          * You can always update your preferences later on Settings screen.
        </P>
      </Container>
      <Container style={styles.content}>
        <Button
          full
          onPress={() => enableNotifications()}
        >
          <NotificationsIcon style={{ marginTop: -4 }} />
          {'  '}
          Enable Notifications
        </Button>
      </Container>
    </SafeAreaView>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'white',
    alignItems: 'center'
  },
  content: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center'
  }
})
